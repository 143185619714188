import React, { useState } from 'react';
import { Dialog, Theme, useMediaQuery, useTheme } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { ErrorModel, ProfileReadExternal, ProfileUpdateExternal } from '@askporter/client-grieg-lyric';
import { useToast } from '@askporter/component-library';
import { useTranslation } from '@askporter/config-provider';
import { useProfile } from '@askporter/profile-provider';
import { API } from '../../utils';
import CustomiseView from './CustomiseView';
import InitialView from './InitialView';

interface CookieManagementProps {
  children: React.ReactNode | React.ReactNode[];
}

interface CookiePreferences {
  rum: boolean;
}

const CookieManagement: React.FC<React.PropsWithChildren<CookieManagementProps>> = ({
  children,
}: CookieManagementProps) => {
  const { t } = useTranslation();
  const { breakpoints } = useTheme();
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { showError } = useToast();
  const queryClient = useQueryClient();
  const { data: profileData, isLoading: profileIsLoading } = useProfile();
  const hasAlreadySetCookiePreference: boolean =
    typeof profileData?.consent?.cookieConsent === 'boolean' && !!profileData?.consent?.cookieConsentLastUpdated;

  const [dialogView, setDialogView] = useState<'INITIAL' | 'CUSTOMISE'>('INITIAL');
  const [cookies, setCookies] = useState<CookiePreferences>({ rum: true });

  const toggleCookiePreference = (cookie: keyof CookiePreferences) => {
    setCookies((prev) => ({ ...prev, [cookie]: !cookies?.[cookie] }));
  };

  const profileAPIPath = 'profile';
  const mutateProfile = useMutation<AxiosResponse<ProfileReadExternal>, AxiosError<ErrorModel>, ProfileUpdateExternal>(
    (payload: ProfileUpdateExternal) => API().post({ path: profileAPIPath, payload }),
    {
      onMutate: async (profileUpdate) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(profileAPIPath);

        // Snapshot the previous value
        const previousProfileData: ProfileReadExternal = queryClient.getQueryData(profileAPIPath);

        // Optimistically update to the new value
        queryClient.setQueryData(profileAPIPath, (old: ProfileReadExternal) => ({ ...old, ...profileUpdate }));

        // Return a context object with the snapshotted value
        return previousProfileData;
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (_err, _assetUpdate, context: ProfileReadExternal) => {
        showError(t('ns.common:error:problem'));
        queryClient.setQueryData(profileAPIPath, context);
      },
      // Always refetch after error or success:
      onSettled: () => queryClient.invalidateQueries(profileAPIPath),
    },
  );

  const updateProfile = (cookieConsent: boolean) => {
    const consent = { cookieConsent, cookieConsentLastUpdated: new Date().toISOString() };
    mutateProfile.mutate({ consent });
  };

  const handleAcceptAllOnClick = () => {
    setCookies((prev) => {
      const cookiePreferencesAllAccepted = Object.keys(prev || {}).reduce<CookiePreferences>((prev, curr) => {
        return { ...prev, [curr]: true };
      }, prev);

      updateProfile(cookiePreferencesAllAccepted.rum);

      return cookiePreferencesAllAccepted;
    });
  };

  const handleSaveOnClick = () => {
    updateProfile(cookies.rum);
  };

  if (hasAlreadySetCookiePreference || profileIsLoading || (!profileIsLoading && !profileData?.uid))
    return <>{children}</>;
  else
    return (
      <>
        {children}
        <Dialog
          open={true}
          PaperProps={{
            sx: {
              position: 'absolute',
              bottom: 0,
              mb: isSmallDevice ? 0 : 4,
              mx: 0,
              maxWidth: breakpoints.values.sm,
              width: '100%',
            },
          }}
        >
          {dialogView === 'INITIAL' ? (
            <InitialView
              t={t}
              setDialogView={setDialogView}
              handleAcceptAllOnClick={handleAcceptAllOnClick}
              isSmallDevice={isSmallDevice}
            />
          ) : null}
          {dialogView === 'CUSTOMISE' ? (
            <CustomiseView
              t={t}
              cookies={cookies}
              toggleCookiePreference={toggleCookiePreference}
              setDialogView={setDialogView}
              handleSaveOnClick={handleSaveOnClick}
            />
          ) : null}
        </Dialog>
      </>
    );
};

export default CookieManagement;
