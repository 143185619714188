import React, { useEffect, useState } from 'react';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
  SelectProps,
  TextField,
  DrawerProps,
  CircularProgress,
  TextFieldProps,
  MenuItemProps,
  Tooltip,
} from '@mui/material';
import { Drawer } from '../Drawer';
import { renderOption, renderGroup } from './utils';

export interface SelectItem {
  value: MenuItemProps['value'];
  label?: string;
  hidden?: boolean;
}

export type autocomplete = AutocompleteProps<unknown, boolean, boolean, boolean, 'div'>;

export interface AutoCompleteProps
  extends Omit<autocomplete, 'onChange' | 'onOpen' | 'onClose' | 'renderInput' | 'ref'> {
  /**
   * Prefix for all id's and labels, including data-testid
   */
  idPrefix: string;
  /**
   * True if small viewport
   */
  isSmallDevice: boolean;
  /**
   * Serves two purposes, field label & also the small device drawer title
   */
  label?: string;
  /**
   * Due to custom behavior on the autocomplete, this is essentially an extension of the autocomplete
   * onChange, it does not override the custom behavior
   */
  onChange?: (newValue: unknown) => void;
  /**
   * Error state, useful if you are using this on a form with validation
   */
  error?: boolean;
  /**
   * The helper text for the Autocomplete input
   */
  helperText?: string;
  /**
   * A start adornment element, if you need to add an icon
   */
  startAdornment?: SelectProps['startAdornment'];
  /**
   * An end adornment element, if you need to add an icon at the end
   */
  endAdornment?: SelectProps['endAdornment'];
  /**
   * If true, the Autocomplete is free solo, meaning that the user input is not bound to provided options
   */
  freeSolo?: boolean;
  /**
   * Any specific styles to pass to the Drawer component that wraps the Autocomplete (mobile only)
   */
  drawerSx?: DrawerProps['sx'];
  /**
   * Mobile drawer PaperProps
   */
  MobilePaperProps?: DrawerProps['PaperProps'];
  /**
   * Whether the autocomplete options are loading. If true it displays a spinner
   */
  isLoading?: boolean;
  /**
   * The variant for the Autocomplete text field. Can be 'filled', 'outlined', or 'standard'
   */
  textFieldVariant?: TextFieldProps['variant'];
  /**
   * Any specific styles to pass to the Autocomplete text field
   */
  textFieldSx?: TextFieldProps['sx'];
  /**
   * Close the component programmatically
   */
  closeComponent?: { shouldClose: boolean; reset: () => void };
  /**
   * Render input
   */
  renderInput?: autocomplete['renderInput'];
  /**
   * Placeholder for the input
   */
  placeholder?: string;
  /**
   * Whether the input is read only
   */
  readOnly?: boolean;
  /**
   * Text to display in a tooltip when readOnly is true
   */
  readOnlyText?: string;
}

/**
 * Renders a Autocomplete component used to select task types, assets and organisation in forms.
 */
export const Autocomplete: React.ForwardRefExoticComponent<React.PropsWithChildren<AutoCompleteProps>> =
  React.forwardRef(
    (
      {
        idPrefix,
        isSmallDevice = false,
        label,
        onChange: extendedOnChange = () => null,
        startAdornment,
        endAdornment = <></>,
        error = false,
        helperText,
        freeSolo = false,
        drawerSx = {},
        isLoading,
        textFieldVariant = 'filled',
        textFieldSx = {},
        closeComponent,
        MobilePaperProps,
        placeholder,
        readOnly,
        readOnlyText,
        ...autocompleteProps
      }: AutoCompleteProps,
      ref,
    ) => {
      const [standardComponentOpen, setStandardComponentOpen] = useState(false);
      const [inputValue, setInputValue] = React.useState(''); // autocomplete search value
      const [drawer, setDrawer] = useState(false); // mobile drawer visibility
      const isDisabled = autocompleteProps?.disabled;
      const mobileDrawerStyles = {
        zIndex: 1500,
        '& .MuiDrawer-paperAnchorBottom': {
          position: 'fixed',
          top: 0,

          '& .MuiAutocomplete-listbox': {
            maxHeight: 'none',
          },
          '& .MuiAutocomplete-paper': {
            boxShadow: 'none',
          },
        },
      };
      useEffect(() => {
        if (closeComponent?.shouldClose) {
          setStandardComponentOpen(false);
          setDrawer(false);
          closeComponent?.reset();
        }
      }, [closeComponent]);

      const commonAutocompleteProps: Partial<autocomplete> = {
        id: `${idPrefix}-autocomplete`,
        open: standardComponentOpen,
        onOpen: isSmallDevice ? undefined : () => setStandardComponentOpen(true),
        onClose: () => setStandardComponentOpen(false),
        inputValue: inputValue,
        onInputChange: (_: React.SyntheticEvent, newInputValue: string) => {
          setInputValue(newInputValue);
        },
        onChange: (_: React.SyntheticEvent, newValue: unknown) => {
          isSmallDevice && setDrawer(false);
          extendedOnChange(newValue);
        },
        renderOption,
        renderGroup,
        freeSolo,
        loading: isLoading,
      };

      const mainComponent = (
        <MuiAutocomplete
          renderInput={(params) => (
            <Tooltip title={readOnly ? readOnlyText : undefined} placement="top-end">
              <TextField
                {...params}
                placeholder={placeholder}
                helperText={helperText}
                error={error}
                onClick={
                  isSmallDevice
                    ? () => setDrawer(!isDisabled && !readOnly && true)
                    : () => setStandardComponentOpen(!isDisabled && !readOnly && true)
                }
                onKeyPress={isSmallDevice ? () => setDrawer(!isDisabled && !readOnly && true) : undefined}
                variant={textFieldVariant}
                label={label}
                InputProps={{
                  ...params.InputProps,
                  startAdornment,
                  endAdornment: (
                    <>
                      {isLoading && <CircularProgress size={24} sx={{ mt: -4 }} />}
                      {endAdornment}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                  readOnly,
                }}
                sx={textFieldSx}
              />
            </Tooltip>
          )}
          {...commonAutocompleteProps}
          {...autocompleteProps}
          ref={ref}
          data-testid={`${commonAutocompleteProps.id}-main`}
          readOnly={readOnly}
        />
      );

      const mobileOpenComponent = (
        <Drawer
          open={drawer}
          onClose={setDrawer}
          title={label}
          dataTestId={`${idPrefix}-drawer`}
          sx={{ ...mobileDrawerStyles, ...drawerSx }}
          PaperProps={MobilePaperProps}
        >
          <MuiAutocomplete
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  placeholder={placeholder}
                  error={error}
                  helperText={helperText}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoading && <CircularProgress size={24} sx={{ mt: -4 }} />}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              );
            }}
            {...commonAutocompleteProps}
            {...autocompleteProps}
            disableCloseOnSelect
            disablePortal
            open={true}
            data-testid={`${commonAutocompleteProps.id}-mobile`}
          />
        </Drawer>
      );

      return (
        <>
          {!isSmallDevice && mainComponent}
          {isSmallDevice && !drawer && mainComponent}
          {isSmallDevice && mobileOpenComponent}
        </>
      );
    },
  );

Autocomplete.displayName = 'Autocomplete';
