import React from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';

export enum RoundButtonSize {
  SM = '32px',
  MD = '40px',
  LG = '48px',
}

export interface RoundButtonProps {
  /**
   * (optional) the button size, either 'SM', 'MD', or 'LG'
   */
  size?: RoundButtonSize;
  /**
   * (optional) if the button appears selected
   */
  isSelected?: boolean;
}

/**
 * Round toggle button. Usually used in the DayTime select component to select days of the week.
 */
function ButtonBase<C extends React.ElementType>(
  {
    onClick,
    children,
    size = RoundButtonSize.MD,
    isSelected = false,
  }: Omit<MuiButtonProps<C, { component?: C }>, 'size'> & RoundButtonProps,
  ref: React.Ref<HTMLButtonElement>,
): JSX.Element {
  return (
    <MuiButton
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      variant={isSelected ? 'contained' : 'outlined'}
      ref={ref}
      sx={{
        borderRadius: '24px',
        height: size,
        width: size,
        minHeight: 'auto',
        minWidth: 'auto',
      }}
    >
      {children}
    </MuiButton>
  );
}

export const RoundButton = React.forwardRef(ButtonBase);
