import { useCallback } from 'react';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import i18next from 'i18next';
import { setCacheBuster } from '@askporter/api-client';
import { useAuthProvider, GetAttributesRes, SetAttributesRes } from '@askporter/auth-provider';
import { captureException } from '@askporter/exception-logger';
import { UserAttributes } from '../types';

/**
 * Provides a simple interface for the cognito attributes (get and set)
 */
export const useAttributes = (): {
  getAttr: () => Promise<Partial<UserAttributes>> | undefined;
  setAttr: (attributes: CognitoUserAttribute[]) => Promise<boolean>;
} => {
  const { getAttributes, setAttributes } = useAuthProvider();

  /**
   * Fetches the user's attributes and converts the array return from cognito into an object
   *
   * @returns An object containing the user attributes, empty object on error
   */
  const getAttr = useCallback(async () => {
    return (async () => {
      try {
        const attributesRes: GetAttributesRes = await getAttributes();

        const attributes: UserAttributes = <UserAttributes>{};
        if (attributesRes.complete) {
          attributesRes.cognitoUserAttributes.forEach((attribute) => {
            attributes[attribute.getName()] = attribute.getValue();
          });
        }

        return attributes;
      } catch (error) {
        captureException(error);
      }
    })();
  }, []);

  /**
   * Sets one or more user attributes
   *
   * @param attributes - an array of Cognito attribute objects (names and values) to set/update, for example
   * [{ Name: 'email', Value: 'e@mail.com' }]
   * @returns True if successful otherwise an error is thrown
   */
  const setAttr = useCallback(async (attributes: CognitoUserAttribute[]) => {
    return (async () => {
      const attributesRes: SetAttributesRes = await setAttributes(attributes);
      if (attributesRes.result === 'SUCCESS') {
        setCacheBuster(); // after successfully setting an attribute we should bust the cache
        return true;
      }

      throw new Error(`${i18next.t('ns.common:error:update_details')}`);
    })();
  }, []);

  return {
    getAttr,
    setAttr,
  };
};
