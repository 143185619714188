export interface CreateUnregisteredUserDefaultValues {
  givenName: string;
  familyName: string;
  email: string;
  phoneNumber: string;
}

export const getCreateUnregisteredUserDefaultValues = (input: string): CreateUnregisteredUserDefaultValues => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\+?[0-9]{1,4}?[-.\s]?(\([0-9]{1,3}\)|[0-9]{1,4})[-.\s]?[0-9]+[-.\s]?[0-9]+[-.\s]?[0-9]+$/;
  const nameRegex = /^[a-zA-Z]+$/;
  const fullNameRegex = /^[a-zA-Z]+ [a-zA-Z]+$/;

  const result: CreateUnregisteredUserDefaultValues = {
    givenName: '',
    familyName: '',
    email: '',
    phoneNumber: '',
  };

  if (emailRegex.test(input)) {
    return {
      ...result,
      email: input,
    };
  } else if (phoneRegex.test(input)) {
    return {
      ...result,
      phoneNumber: input,
    };
  } else if (fullNameRegex.test(input)) {
    return {
      ...result,
      givenName: input.split(' ')[0],
      familyName: input.split(' ')[1],
    };
  } else if (nameRegex.test(input)) {
    return {
      ...result,
      givenName: input,
    };
  }

  return result;
};
