/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import { InputLabel, Theme, useMediaQuery } from '@mui/material';
import { utils } from '@rjsf/core';
import { format, isValid } from 'date-fns';
import { DateAndTimePicker, DateAndTimePickerProps } from '../../';
import { CustomWidgetProps } from '../types';

const { utcToLocal, getDisplayLabel } = utils;

const DateWidget: React.FC<React.PropsWithChildren<CustomWidgetProps>> = ({
  id,
  placeholder,
  disabled,
  label,
  readonly: readOnly,
  value,
  onChange,
  autofocus,
  schema,
  uiSchema,
  formContext: { onHasChanged, t },
}: CustomWidgetProps) => {
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const localeProps: DateAndTimePickerProps['localeProps'] = {
    okText: t('ns.common:date_picker:ok'),
    clearText: t('ns.common:date_picker:clear'),
    cancelText: t('ns.common:date_picker:cancel'),
    toggleVisibilityText: t('ns.common:date_picker:toggle_visibility'),
  };

  const _value = utcToLocal(value);
  const _onHasChanged = (newValue: string, oldValue: string) => {
    const oldDateOnly = isValid(new Date(oldValue)) ? format(new Date(oldValue), 'y-MM-dd') : '';
    const newDateOnly = isValid(new Date(newValue)) ? format(new Date(newValue), 'y-MM-dd') : '';

    if (oldDateOnly !== newDateOnly) onHasChanged({ id, value: newValue });
  };
  const _onChange = (value: string) => {
    try {
      if (value) {
        const dateOnly = isValid(new Date(value)) ? format(new Date(value), 'y-MM-dd') : value;
        onChange(dateOnly);
      } else {
        onChange(undefined);
      }
    } catch (e) {
      onChange(undefined);
    }
  };

  const displayLabel = getDisplayLabel(schema, uiSchema);

  return (
    <>
      <InputLabel variant="filled" htmlFor={id}>
        {displayLabel ? label || schema.title : false}
      </InputLabel>
      <DateAndTimePicker
        value={_value}
        onChange={(value: string) => {
          _onChange(value);
          onHasChanged && setTimeout(() => _onHasChanged(value, _value), 0);
        }}
        isSmallDevice={isSmallDevice}
        localeProps={localeProps}
        disabled={disabled}
        controls={['calendar']}
        filledInputOnly={true}
        inputProps={{
          inputProps: { 'data-testid': 'date-widget' },
          id: `${id}-input`,
          key: `${Date.now()}-datetime-input`,
          placeholder: placeholder,
          autoFocus: autofocus,
          disabled: disabled,
          readOnly,
        }}
      />
    </>
  );
};

export default DateWidget;
