import React from 'react';
import { Accordion as MuiAccordion, AccordionProps as MuiAccordionProps, Divider } from '@mui/material';

type MuiAccordionPropsNoChildren = Pick<MuiAccordionProps, Exclude<keyof MuiAccordionProps, 'children'>>;
export interface AccordionProps extends MuiAccordionPropsNoChildren {
  /**
   * the MUI AccordionSummary component
   */
  titleComponent: React.ReactNode;
  /**
   * (optional) the mui AccordionDetails component
   */
  contentComponent?: React.ReactNode;
  /**
   * (optional) the data-testid string for the button
   */
  dataTestId?: string;
  /**
   * (optional) boolean for whether to display top and bottom dividers
   */
  displayDivider?: boolean;
  /**
   * (optional) defaults to right, determines whether the expand icon is placed to the left or right off the title
   */
  expandIconPosition?: 'left' | 'right';
}

/**
 * Accordion in ASK component library style
 */
export const Accordion: React.FC<React.PropsWithChildren<AccordionProps>> = ({
  titleComponent,
  contentComponent,
  dataTestId = 'ask-accordion',
  displayDivider = true,
  expandIconPosition = 'right',
  sx,
  ...accordionProps
}: AccordionProps): JSX.Element => {
  return (
    <MuiAccordion
      elevation={0}
      disableGutters={true}
      data-testid={dataTestId}
      sx={{
        ...sx,
        '&:before': { display: displayDivider ? undefined : 'none' },
        '& .MuiAccordionSummary-root': {
          flexDirection: expandIconPosition === 'left' ? 'row-reverse' : undefined,
        },
      }}
      {...accordionProps}
    >
      {titleComponent}
      {displayDivider && <Divider sx={{ mb: 1 }} />}
      {contentComponent && contentComponent}
    </MuiAccordion>
  );
};
