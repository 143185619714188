import { useCallback, useState } from 'react';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import i18next from 'i18next';
import { useAuthProvider, AuthResult, ApUserAttribute } from '@askporter/auth-provider';
import { useToast } from '@askporter/component-library';
import { useAttributes } from './useAttributes';

/**
 * Handles the attribute verification process
 */
export const useVerifyAttribute = (): {
  handleSendCode: (attribute: ApUserAttribute, number: string) => Promise<boolean>;
  handleVerifyCode: (attribute: ApUserAttribute, code: string) => Promise<boolean>;
  loadingSendCode: boolean;
  loadingVerifyCode: boolean;
} => {
  const { verifyAttribute } = useAuthProvider();
  const { setToast } = useToast();
  const { getAttr, setAttr } = useAttributes();
  const [loadingSendCode, setLoadingSendCode] = useState<boolean>(false);
  const [loadingVerifyCode, setLoadingVerifyCode] = useState<boolean>(false);
  /**
   * Send a verification code for the specified attribute, where an enteredValue is provided compare it with cognito
   * to determine if it should be updated
   *
   * @param attribute - the attribute to update
   * @param enteredValue - optional value (most likely from a form) to compare with cognito
   * @returns A boolean indicating whether a code was sent or not
   */
  const handleSendCode = useCallback(async (attribute: ApUserAttribute, enteredValue?: string) => {
    return (async () => {
      try {
        setLoadingSendCode(true);
        const attributes = enteredValue && (await getAttr()); // avoid unnecessary calls using &&

        // If an attribute is provided by the user (for example on a form) and it is not the same as in cognito we
        // need to update cognito to ensure the verification code is sent to the right place
        if (enteredValue && enteredValue !== attributes[attribute]) {
          await setAttr([new CognitoUserAttribute({ Name: attribute, Value: enteredValue })]);
        }
        const res: AuthResult = await verifyAttribute(attribute, 'sendCode');
        if (!res.complete) {
          setToast({
            open: true,
            severity: 'error',
            children: i18next.t('ns.common:error:send_code'),
          });
        }
        return res.complete;
      } catch (error) {
        setToast({
          open: true,
          severity: 'error',
          children: i18next.t('ns.common:error:send_code'),
        });
        return false;
      } finally {
        setLoadingSendCode(false);
      }
    })();
  }, []);

  /**
   * Verify attribute using provided code
   *
   * @param attribute - the attribute to update
   * @param code - the attribute verification code
   * @returns A boolean indicating whether the attribute was verified or not
   */
  const handleVerifyCode = useCallback(async (attribute: ApUserAttribute, code: string) => {
    return (async () => {
      try {
        setLoadingVerifyCode(true);
        const res: AuthResult = await verifyAttribute(attribute, 'inputCode', code);

        setToast({
          open: true,
          severity: res.complete ? 'success' : 'error',
          children: res.complete
            ? i18next.t('ns.common:success:details_verified')
            : i18next.t('ns.common:error:details_verified'),
        });

        return res.complete;
      } catch (error) {
        setToast({
          open: true,
          severity: 'error',
          children: i18next.t('ns.common:error:details_verified'),
        });
        return false;
      } finally {
        setLoadingVerifyCode(false);
      }
    })();
  }, []);

  return {
    handleSendCode,
    handleVerifyCode,
    loadingSendCode,
    loadingVerifyCode,
  };
};
