import { RoleSlug } from '@askporter/grieg-types';
import { checkPermissions } from '../../';

export const roleOptions = (currentRoleSlug: RoleSlug): Array<RoleSlug> => {
  if (checkPermissions(currentRoleSlug, [RoleSlug.PLATADMIN, RoleSlug.CLIENTADMIN, RoleSlug.AGENTSPR])) {
    return [RoleSlug.SVCUSR, RoleSlug.SUPP, RoleSlug.OWNER, RoleSlug.AGENT];
  }
  if (checkPermissions(currentRoleSlug, [RoleSlug.AGENT])) {
    return [RoleSlug.SVCUSR, RoleSlug.SUPP, RoleSlug.OWNER];
  }
  if (checkPermissions(currentRoleSlug, [RoleSlug.OWNERSPR])) {
    return [RoleSlug.SVCUSR, RoleSlug.OWNER];
  }
  if (checkPermissions(currentRoleSlug, [RoleSlug.OWNER])) {
    return [RoleSlug.SVCUSR];
  }
  if (checkPermissions(currentRoleSlug, [RoleSlug.SUPPSPR])) {
    return [RoleSlug.SUPP];
  }
  if (checkPermissions(currentRoleSlug, [RoleSlug.SVCUSRSPR])) {
    return [RoleSlug.SVCUSR];
  }
};

export const superRoleOptions = (currentRoleSlug: RoleSlug): Array<RoleSlug> => {
  if (checkPermissions(currentRoleSlug, [RoleSlug.PLATADMIN])) {
    return [
      RoleSlug.SVCUSRSPR,
      RoleSlug.SUPPSPR,
      RoleSlug.OWNERSPR,
      RoleSlug.AGENTSPR,
      RoleSlug.CLIENTADMIN,
      RoleSlug.PLATADMIN,
    ];
  }
  if (checkPermissions(currentRoleSlug, [RoleSlug.CLIENTADMIN])) {
    return [RoleSlug.SVCUSRSPR, RoleSlug.SUPPSPR, RoleSlug.OWNERSPR, RoleSlug.AGENTSPR, RoleSlug.CLIENTADMIN];
  }
  if (checkPermissions(currentRoleSlug, [RoleSlug.AGENT])) {
    return [RoleSlug.SVCUSRSPR, RoleSlug.SUPPSPR, RoleSlug.OWNERSPR];
  }
  if (checkPermissions(currentRoleSlug, [RoleSlug.AGENTSPR])) {
    return [RoleSlug.SVCUSRSPR, RoleSlug.SUPPSPR, RoleSlug.OWNERSPR, RoleSlug.AGENTSPR];
  }
  if (checkPermissions(currentRoleSlug, [RoleSlug.OWNERSPR])) {
    return [RoleSlug.SVCUSRSPR, RoleSlug.OWNERSPR];
  }
  if (checkPermissions(currentRoleSlug, [RoleSlug.OWNER, RoleSlug.SVCUSRSPR])) {
    return [RoleSlug.SVCUSRSPR];
  }
  if (checkPermissions(currentRoleSlug, [RoleSlug.SUPPSPR, RoleSlug.SVCUSRSPR])) {
    return [RoleSlug.SUPPSPR];
  }
};
