type MaintenanceKeys = 'ns.maintenance_page:title' | 'ns.maintenance_page:message';

const languageCodesWithFallback = ['en-GB', 'de-DE', 'en-US', 'fr-FR'] as const;
type LanguageCodeWithFallback = typeof languageCodesWithFallback[number];

const fallbackStrings: Record<MaintenanceKeys, Record<LanguageCodeWithFallback, string>> = {
  'ns.maintenance_page:title': {
    'en-GB': 'Scheduled maintenance',
    'de-DE': 'Planmäßige Wartung',
    'en-US': 'Scheduled maintenance',
    'fr-FR': 'Entretien programmé',
  },
  'ns.maintenance_page:message': {
    'en-GB': "Sorry, we're currently performing maintenance. We'll be back soon!",
    'de-DE':
      'Unsere Plattform ist, aufgrund eines Updates, temporär nicht vollumfänglich erreichbar. Bitte wenden Sie sich heute an das Service Center der WISAG Facility Management unter +49 800 4480030 oder an Ellie.Servicecenter@wisag.de.',
    'en-US': "Sorry, we're currently performing maintenance. We'll be back soon!",
    'fr-FR': 'Désolé, nous effectuons actuellement une maintenance. Nous serons bientôt de retour !',
  },
};

const getLanguageCode = (): LanguageCodeWithFallback => {
  let languageCode: LanguageCodeWithFallback;

  try {
    languageCode = (localStorage.getItem('selectedLanguage') as LanguageCodeWithFallback) || 'en-GB';
  } catch (_) {
    languageCode = 'en-GB';
  }

  return languageCode;
};

export const getMaintenanceFallbackString = (key: MaintenanceKeys): string => {
  const languageCode = getLanguageCode();

  if (languageCodesWithFallback.includes(languageCode)) return fallbackStrings[key][languageCode];
  else return fallbackStrings[key]['en-GB'];
};
