import { CognitoUser } from 'amazon-cognito-identity-js';
import { wrapError } from '.';
import { AuthAction, AuthResult, CognitoError } from './types';

/**
 * Attempts to reset the user's password using the old and new password
 * @param oldPassword - the old password
 * @param newPassword - the new password
 */

export const cognitoChangePassword = async (
  oldPassword: string,
  newPassword: string,
  cognitoUser: CognitoUser,
  dispatch: React.Dispatch<AuthAction>,
): Promise<AuthResult> => {
  return new Promise((resolve, reject) => {
    const fallbackErrMessage = 'Error occurred while attempting to change password';
    try {
      cognitoUser.changePassword(oldPassword, newPassword, (err: CognitoError) => {
        if (err) {
          dispatch({ type: 'setFeedback', payload: `${err.message} (${err.code})` });
          reject({
            error: wrapError(err, fallbackErrMessage),
            message: 'Error: unable to change password',
            complete: false,
          });
        } else {
          dispatch({ type: 'setFeedback', payload: 'password changed' });
          resolve({ error: undefined, message: 'Success: password changed', complete: true });
        }
      });
    } catch (err) {
      dispatch({ type: 'setFeedback', payload: err.message });
      reject({
        error: wrapError(err, fallbackErrMessage),
        message: 'Error: unable to change password',
        complete: false,
      });
    }
  });
};
