import { CognitoUser } from 'amazon-cognito-identity-js';
import { wrapError } from '.';
import { GetAttributesRes, AuthAction, CognitoError } from './types';

/**
 * Get attributes for current user
 *
 * @param dispatch - reference to the auth state reducer dispatch method
 * @param cognitoUser - the current session user
 * @returns
 */
export const cognitoGetAttributes = async (
  dispatch: React.Dispatch<AuthAction>,
  cognitoUser: CognitoUser,
): Promise<GetAttributesRes> => {
  return new Promise((resolve, reject) => {
    const fallbackErrMessage = 'Unable to get attribute(s)';
    try {
      cognitoUser.getUserAttributes((err: CognitoError, result) => {
        if (err) {
          dispatch({ type: 'setFeedback', payload: `${err.message} (${err.code})` });
          return reject({
            complete: false,
            error: wrapError(err, fallbackErrMessage),
            message: 'Error: unable to get attribute(s)',
          });
        }
        dispatch({ type: 'setFeedback', payload: `attributes retrieved: ${JSON.stringify(result)}` });
        return resolve({
          complete: true,
          cognitoUserAttributes: result,
          error: undefined,
          message: 'Success: retrieved attribute(s)',
        });
      });
    } catch (err) {
      dispatch({ type: 'setFeedback', payload: err.message });
      return reject({
        complete: false,
        error: wrapError(err, fallbackErrMessage),
        message: 'Error: unable to get attribute(s)',
      });
    }
  });
};
