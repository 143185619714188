import { FilePath } from './FilePath';
import { OrgTypeSlug } from './OrgTypeSlug';
import { RegistrationStatus } from './RegistrationStatus';
import { RoleSlug } from './RoleSlug';
import { UIDReadOnly } from './UIDReadOnly';
import { URNReadOnly } from './URNReadOnly';

export interface PersonSearchResultExternal {
  personScope: PersonSearchResultExternal_PersonScopeEnum;
  uid: UIDReadOnly;
  urn: URNReadOnly;
  givenName?: string;
  familyName?: string;
  roleSlug?: RoleSlug;
  registrationStatus?: RegistrationStatus;
  vip?: boolean;
  profilePhoto?: FilePath;
  name?: string;
  orgTypeSlug?: OrgTypeSlug;
}

export type PersonSearchResultExternal_PersonScopeEnum = 'USER' | 'ORG' | 'TEAM';

export const PersonSearchResultExternal_PersonScopeEnum = {
  USER: 'USER' as PersonSearchResultExternal_PersonScopeEnum,
  ORG: 'ORG' as PersonSearchResultExternal_PersonScopeEnum,
  TEAM: 'TEAM' as PersonSearchResultExternal_PersonScopeEnum,
};
