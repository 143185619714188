import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { wrapError } from '.';
import { AuthAction, AuthResult, CognitoError, ApUserAttribute, VerificationTask } from './types';

/**
 * Attempt to reset the user's password
 * @param dispatch - reference to the auth state reducer dispatch method
 * @param cognitoUser - the current session user
 * @param attribute - the attribute to verify
 * @param task - the verification task to perform
 * @returns Promise with result
 */
export const cognitoVerifyAttribute = async (
  dispatch: React.Dispatch<AuthAction>,
  cognitoUser: CognitoUser,
  attribute: ApUserAttribute,
  task: VerificationTask,
  code?: string,
): Promise<AuthResult> => {
  // If user is null then we should not proceed
  if (cognitoUser === null) {
    const msg = 'Cannot send a verification code when user is null';
    dispatch({ type: 'setFeedback', payload: msg });
    return Promise.reject({
      complete: false,
      error: wrapError(msg),
      message: msg,
    });
  }

  cognitoUser.getSession(async (err: CognitoError, session: CognitoUserSession) => {
    if (err) {
      const fallbackErrMessage = 'Could not retrieve user session';
      dispatch({ type: 'setFeedback', payload: `${err.message} (${err.code})` });
      return Promise.reject({
        complete: false,
        error: wrapError(err, fallbackErrMessage),
        message: 'error, could not get session',
      });
    } else if (!session.isValid()) {
      const msg = 'Cannot send a verification code when session is invalid';
      dispatch({ type: 'setFeedback', payload: msg });
      return Promise.reject({
        complete: false,
        error: wrapError(err, msg),
        message: msg,
      });
    }
  });

  // Where there is a valid session perform the requested task
  if (task === 'sendCode') return sendCode(dispatch, cognitoUser, attribute);
  if (task === 'inputCode') return inputCode(dispatch, cognitoUser, attribute, code);
};

const sendCode = async (
  dispatch: React.Dispatch<AuthAction>,
  cognitoUser: CognitoUser,
  attribute: ApUserAttribute,
): Promise<AuthResult> => {
  return new Promise((resolve, reject) => {
    const fallbackErrMessage = 'Failed to retrieve attribute verification code';
    try {
      cognitoUser.getAttributeVerificationCode(attribute, {
        onSuccess() {
          dispatch({ type: 'setFeedback', payload: 'attribute verification code sent' });
          return resolve({ complete: true, error: undefined, message: 'Success: attribute verification code sent' });
        },
        onFailure(err: CognitoError) {
          dispatch({ type: 'setFeedback', payload: `${err.message} (${err.code})` });
          return reject({
            complete: false,
            error: wrapError(err, fallbackErrMessage),
            message: 'Failed: could not send attribute verification code',
          });
        },
      });
    } catch (err) {
      dispatch({ type: 'setFeedback', payload: err.message });
      return reject({
        complete: false,
        error: wrapError(err, fallbackErrMessage),
        message: 'Error: could not send attribute verification code',
      });
    }
  });
};

const inputCode = async (
  dispatch: React.Dispatch<AuthAction>,
  cognitoUser: CognitoUser,
  attribute: ApUserAttribute,
  code: string,
): Promise<AuthResult> => {
  return new Promise((resolve, reject) => {
    const fallbackErrMessage = 'Failed to retrieve attribute verification code';
    try {
      cognitoUser.verifyAttribute(attribute, code, {
        onSuccess() {
          dispatch({ type: 'setFeedback', payload: 'attribute verified' });
          return resolve({
            complete: true,
            error: undefined,
            message: `Success: ${attribute} verified`,
          });
        },
        onFailure(err: CognitoError) {
          dispatch({ type: 'setFeedback', payload: `${err.message} (${err.code})` });
          return reject({
            complete: false,
            error: wrapError(err, fallbackErrMessage),
            message: `Failure: unable to verify ${attribute}`,
          });
        },
      });
    } catch (err) {
      dispatch({ type: 'setFeedback', payload: err.message });
      return reject({
        complete: false,
        error: wrapError(err, fallbackErrMessage),
        message: `Error: unable to verify ${attribute}`,
      });
    }
  });
};
