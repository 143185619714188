import React from 'react';
import { AuthAction, AuthResult, CognitoError, SessionState } from './types';
import { CognitoUserSession, CognitoUser, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { wrapError } from '.';

/**
 * Refresh a user session using a cognito refresh token, resolves when a valid session exists.
 * @param dispatch      - reference to the auth state reducer dispatch method
 * @param cognitoUser   - cognito user object
 * @param refreshType   - label used to indicate how the session is being refreshed TODO - remove this param if possible when user feedback is finalized
 */
export const refreshCognitoSession = (
  dispatch: React.Dispatch<AuthAction>,
  cognitoUser: CognitoUser,
  refreshType: SessionState,
): Promise<AuthResult> => {
  return new Promise((resolve, reject) => {
    dispatch({ type: 'setAuthState', payload: { sessionState: refreshType } });
    try {
      let refreshToken: CognitoRefreshToken;
      cognitoUser.getSession((err: CognitoError, session: CognitoUserSession) => {
        if (err) {
          dispatch({ type: 'setAuthState', payload: { sessionState: SessionState.invalid } });
          return reject({
            complete: false,
            error: wrapError(err, 'Error retrieving user session'),
            message: 'error getting session',
          });
        }

        if (session && session.isValid()) {
          refreshToken = session.getRefreshToken();
        }

        if (refreshToken === null || refreshToken === undefined) {
          // at this point we must be missing the local auth token, sign in is now required
          dispatch({ type: 'setAuthState', payload: { sessionState: SessionState.invalid } });
          return reject({
            complete: false,
            error: wrapError('Unable to retrieve refresh token'),
            message: 'no local refresh token',
          });
        }

        // cognitoUser.getSession actually automatically handles refreshing of sessions, so in the majority of scenarios
        // the if statement below will resolve to false. Wrapping this in an if prevents superfluous refreshes, without
        // it we would be fetching new tokens on every page navigation and potentially in the future for every API call
        const currentTime = Math.floor(Date.now() / 1000);
        const idTokenExpiryTime = session.getIdToken().getExpiration();

        if (currentTime >= idTokenExpiryTime) {
          cognitoUser.refreshSession(refreshToken, (err: Error, session: CognitoUserSession) => {
            if (err) {
              dispatch({
                type: 'setAuthState',
                payload: { sessionState: SessionState.invalid, feedbackMessage: `${err.message}` },
              });
              return reject({
                complete: false,
                error: wrapError(err, 'Error occurred while attempting to refresh token'),
                message: err.message,
              });
            } else {
              cognitoUser.setSignInUserSession(session);
              const authToken = cognitoUser.getSignInUserSession().getIdToken().getJwtToken();

              dispatch({
                type: 'refreshSession',
                payload: {
                  cognitoUser,
                  sessionState: SessionState.valid,
                  feedbackMessage: `new session created from ${SessionState[refreshType]}`,
                  authToken,
                },
              });
              return resolve({ complete: true, error: undefined, message: 'new session created' });
            }
          });
        } else {
          const authToken = cognitoUser.getSignInUserSession().getIdToken().getJwtToken();
          dispatch({
            type: 'restoreSession',
            payload: {
              cognitoUser,
              sessionState: SessionState.valid,
              feedbackMessage: `session found on ${SessionState[refreshType]}` + Date.now().toString(),
              authToken,
            },
          });
          return resolve({ complete: true, error: undefined, message: 'existing session found' });
        }
      });
    } catch (err) {
      dispatch({ type: 'setAuthState', payload: { sessionState: SessionState.invalid } });
      return reject({
        complete: false,
        error: wrapError(err, 'Error occurred while attempting to refresh session'),
        message: err.message,
      });
    }
  });
};
