import React from 'react';
import { Autocomplete, TextField, FilledInputProps, MenuItem } from '@mui/material';
import { utils } from '@rjsf/core';
import { CustomWidgetProps } from '../types';

const AutocompleteWidget = ({
  id,
  options = {},
  label,
  disabled,
  readonly,
  value,
  onChange,
  onBlur,
  onFocus,
  schema,
  uiSchema,
  formContext: { onHasChanged },
}: CustomWidgetProps): JSX.Element => {
  const { enumOptions, emptyValue = '' } = options;

  const _onChange = (value: string) => {
    onChange(value === '' ? emptyValue : value);
    setTimeout(() => onHasChanged({ id, value }), 0);
  };
  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onBlur(id, value);
  const _onFocus = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value);
  const displayLabel = utils.getDisplayLabel(schema, uiSchema);

  return (
    <Autocomplete
      id={id}
      options={enumOptions as Array<{ value: string; label: string }>}
      defaultValue={value}
      value={value || null}
      disabled={disabled}
      readOnly={readonly}
      isOptionEqualToValue={(option, selectedValue) => option.value === selectedValue}
      onChange={(_, newOption) => _onChange(newOption?.value || emptyValue)}
      onBlur={_onBlur}
      onFocus={_onFocus}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label={displayLabel && (label || schema.title)}
          inputProps={{ ...params.inputProps }}
          InputProps={{ ...params.InputProps, 'data-testid': 'select-widget' } as FilledInputProps}
        />
      )}
      renderOption={(props, option) => (
        <MenuItem {...props} key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      )}
      sx={{
        '& .MuiTextField-root': {
          margin: 0,
        },
      }}
    />
  );
};

export default AutocompleteWidget;
