import React from 'react';
import { Typography } from '@mui/material';
import { FieldProps } from '@rjsf/core';
import { CustomWidgetProps } from '../types';

type Props = Omit<FieldProps, 'formContext'> & { formContext: CustomWidgetProps['formContext'] };

/** Custom title field component for RJSF */
const CustomTitleField: React.FC<React.PropsWithChildren<Props>> = ({ title, required, id, formContext }: Props) => {
  const legend = required ? title + '*' : title;

  return (
    <legend>
      <Typography id={id} variant="h6" {...formContext?.titleField}>
        {legend}
      </Typography>
    </legend>
  );
};

export default CustomTitleField;
