import axios, { AxiosRequestConfig } from 'axios';
import * as i18n from 'i18next';
import HttpApi, { HttpBackendOptions } from 'i18next-http-backend';
import { TFunction, initReactI18next } from 'react-i18next';
import { captureException } from '@askporter/exception-logger';
import { config, getCacheBuster } from '../utils/';

const fallbackLanguage = 'en-GB';

interface i18InitParams {
  /**
   * a string representing the language code
   */
  language?: string;
  /**
   * an object of strings with custom request headers, e.g. \{ 'x-ap-lang': 'en-GB' \}, can also be a function that
   * returns the equivalent
   */
  customHeaders?: { [key: string]: string } | (() => { [key: string]: string });
  /**
   * the k value, will be sent as a query param e.g. /api/v1/static/strings?k=35146afc-4e89-479d-bcc7-4a3f109ab164
   */
  k?: string;
  /**
   * func executed when a missing key is discovered
   */
  onMissingKey?: () => void;
  /**
   * whether to enable debug mode
   */
  debug?: boolean;

  /**
   * callback executed when i18n is initialised
   * */
  onInitialised?: (error: Error, t: TFunction) => void;
}

/**
 * Function that initializes i18n and manages all of the static config, the elements that rely on a react component can
 * be passed in when this function is called
 *
 * @returns void
 */
export const i18nInit = ({
  language = localStorage.getItem('selectedLanguage') || fallbackLanguage,
  customHeaders = {
    'x-ap-lang': localStorage.getItem('selectedLanguage') || fallbackLanguage,
  },
  onMissingKey,
  debug,
  onInitialised,
}: i18InitParams): void => {
  const backend: HttpBackendOptions = {
    loadPath: `${config.API_URL}static/strings`,
    allowMultiLoading: false,
    customHeaders,
    request: async (options, url, _payload, callback) => {
      const headers = typeof options?.customHeaders === 'function' ? options?.customHeaders() : options?.customHeaders;

      let retry = 0;
      const maxRetries = 3;

      const k = getCacheBuster().k;

      const fetchStrings = async (unintercepted = true) => {
        const axiosRequestConfig: AxiosRequestConfig = {
          method: 'GET',
          url,
          params: { ...(k ? { k } : {}) },
          headers,
        };

        try {
          const uninterceptedAxiosInstance = axios.create();
          const res = unintercepted
            ? await uninterceptedAxiosInstance(axiosRequestConfig)
            : await axios(axiosRequestConfig);

          callback(null, res);
        } catch (err) {
          if (retry < maxRetries) {
            retry += 1;
            const isFinalRetry = retry === maxRetries - 1;
            setTimeout(async () => await fetchStrings(!isFinalRetry), 1000 * retry);
          } else {
            callback(err, null);
          }
        }
      };

      fetchStrings();
    },
  };

  i18n
    .use(HttpApi)
    .use(initReactI18next)
    .init<HttpBackendOptions>(
      {
        backend,
        lng: language, // the language to set it to on initialisation
        // we have a couple of approaches that provide a "fallback" for example initialising a default language = 'en-GB'
        // if language is missing. So setting this to false to avoid unnecessary & incorrect default loads (additional calls)
        fallbackLng: false,
        load: 'currentOnly',
        interpolation: {
          escapeValue: false, // react already safes from xss
        },
        ns: ['ns'],
        defaultNS: 'ns',
        debug: debug === true || config.ENV === 'development',
        returnObjects: false,
        nsSeparator: '.',
        keySeparator: false,

        // we need to report missing keys so they can be resolved in the backend/dictionaries repo
        saveMissing: true,
        missingKeyHandler: (lngs: readonly string[], _ns: string, key: string) => {
          onMissingKey?.();

          try {
            const errObj = new Error(`${key} is missing from current language (${lngs})`);
            // eslint-disable-next-line functional/immutable-data
            errObj.name = 'i18n Missing Key';
          } catch (error) {
            captureException(error);
          }
        },
      },
      (err, t) => onInitialised?.(err, t),
    );
};

export const t = i18n.t;
