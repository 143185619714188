type LocalizedTimeFormat = () => 'HH:mm' | 'hh:mm A';

export const is24HourFormat = () => {
  return Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone === 'Europe/Berlin';
};
/**
 * A function to get localized time format
 * @returns mobiscroll timeFormat: 'HH:mm' or 'hh:mm A'
 */
export const getLocalizedTimeFormat: LocalizedTimeFormat = () => {
  // Get device timezone e.g. Europe/Berlin
  const timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
  // For Berlin, return 24h format
  return timeZone === 'Europe/Berlin' ? 'HH:mm' : 'hh:mm A';
};

export const setTimeDisplayFormat = (time: string) => {
  if (is24HourFormat()) {
    // BE return time as 24h format
    return time;
  } else {
    return new Date('1970-01-01T' + time + 'Z').toLocaleTimeString('en-US', {
      timeZone: 'UTC',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
    });
  }
};
