import {
  SearchAssetRequest,
  SearchMessageRequest,
  SearchOrgRequest,
  SearchProductCatalogueRequest,
  SearchProductRequest,
  SearchTagRequest,
  SearchTaskRequest,
  SearchTeamRequest,
  SearchUserRequest,
} from '@askporter/grieg-types';

type SearchRequestUnion =
  | SearchTaskRequest
  | SearchAssetRequest
  | SearchUserRequest
  | SearchOrgRequest
  | SearchTeamRequest
  | SearchProductRequest
  | SearchProductCatalogueRequest
  | SearchMessageRequest
  | SearchTagRequest;

/**
 * When a freeText search is performed, this function will handle what to pass as the sort value.
 */
export const handleFreeTextSearch = <SearchPayload extends SearchRequestUnion>({
  search,
  freeText,
  defaultSort,
  hasUserSelectedSort,
}: {
  search: SearchPayload;
  freeText: string;
  defaultSort: SearchPayload['sort'];
  hasUserSelectedSort: boolean;
}): SearchPayload => {
  // If a user has selected a sort option, we want to keep that sort option
  if (hasUserSelectedSort) {
    return { ...search, freeText, page: 1 };
  }

  // Else if the user has not selected a sort option, we want to set the sort to RELEVANCE when a freeText search is performed
  return { ...search, freeText, sort: freeText ? 'RELEVANCE' : defaultSort, page: 1 };
};
