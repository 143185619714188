// splits a string into number of groups (chunks)
export const chunkSubString = (str: string, size: number): string[] => {
  let chunks = [];
  if (str && size) {
    const numChunks = Math.ceil(str.length / size);
    chunks = new Array(numChunks);

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      chunks[i] = str.substr(o, size);
    }
  }
  return chunks;
};
