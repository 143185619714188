import type { QueryFunctionContext } from 'react-query';
import { ExportsListExternal } from '@askporter/grieg-types';
import { API } from '../../../utils/api';

export default async ({
  queryKey,
}: QueryFunctionContext<[string, { page_token: string | undefined }]>): Promise<ExportsListExternal> => {
  const page_token = queryKey[1]?.page_token;

  const data = await API().get({ path: `exports`, queryParams: { page_token } });

  return data;
};
