import { useHistory } from 'react-router-dom';

let _returnTo: string;

function useReturnTo(): { returnTo: string; setReturnTo: (fullPath: string) => void; setReturnToThisPage: () => void } {
  const history = useHistory();

  return {
    returnTo: _returnTo,
    setReturnTo: (path: string) => (_returnTo = path),
    setReturnToThisPage: () => {
      _returnTo = `${history.location.pathname}${history.location.search}`;
    },
  };
}

export default useReturnTo;
