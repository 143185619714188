export type DayOfWeek = 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN';

export const DayOfWeek = {
  MON: 'MON' as DayOfWeek,
  TUE: 'TUE' as DayOfWeek,
  WED: 'WED' as DayOfWeek,
  THU: 'THU' as DayOfWeek,
  FRI: 'FRI' as DayOfWeek,
  SAT: 'SAT' as DayOfWeek,
  SUN: 'SUN' as DayOfWeek,
};
