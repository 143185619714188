/**
 * Copies a string to the clipboard. Must be called from within an
 * event handler such as click. May return false if it failed, but
 * this is not always possible. Browser support for Chrome 43+,
 * Firefox 42+, Safari 10+ and Edge.
 *
 * @param text - the string to copy to the clipboard
 * @returns boolean whether the operation was successful or not
 */
export function copyToClipboard(text: string, captureException: (...args: unknown[]) => void): boolean {
  if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand('copy'); // Security exception may be thrown by some browsers.
    } catch (error) {
      return false;
    } finally {
      try {
        document.body.removeChild(textarea);
      } catch (error) {
        const customError = new Error('Error in copyToClipboard function - Error removing textarea from DOM');

        captureException(customError, {
          error,
        });
      }
    }
  }
}
