import React from 'react';
import { Datepicker, locale } from '@mobiscroll/react';
import type { MbscDatepickerOptions } from '@mobiscroll/react/dist/src/core/components/datepicker/datepicker';
import { Box, useTheme } from '@mui/material';

export interface TimeRangePickerProps extends MbscDatepickerOptions {
  /**
   * Boolean to determine if the current viewport is that of a small device
   */
  isSmallDevice: boolean;
  /**
   * The value of the date range picker
   */
  defaultValue?: [Date | null, Date | null];
  /**
   * The onChange event handler for the date range picker
   */
  onChange: (times: [Date | null, Date | null]) => void;
  /**
   * The translation function
   */
  t: (key: string) => string;
  /**
   * The language country code e.g. en-GB
   */
  language: string;
}

/** Renders a TimeRangePicker component */
export const TimeRangePicker: React.FC<React.PropsWithChildren<TimeRangePickerProps>> = ({
  t,
  isSmallDevice,
  defaultValue = [null, null],
  onChange,
  language,
  ...rest
}: TimeRangePickerProps) => {
  const {
    palette: {
      primary: { main: color },
    },
  } = useTheme();
  const dateLocale = locale[language] || locale[language?.split('-')[0]] || locale['en-GB'];

  const styles = {
    "& [class^='mbsc-range-control-'].mbsc-material": {
      textTransform: 'none',
    },
    '& .mbsc-material.mbsc-range-control-wrapper .mbsc-material.mbsc-segmented-button.mbsc-selected': {
      borderBottomColor: color,
    },
    '& .mbsc-selected .mbsc-range-control-label.mbsc-material': { color },
    '.mbsc-range-control-label.mbsc-material': { color },
    ".mbsc-segmented-item.mbsc-range-value-nonempty.mbsc-material [class^='mbsc-range-control-'].mbsc-material": {
      color,
    },
  };

  return (
    <Box sx={styles}>
      <Datepicker
        theme="material"
        themeVariant="light"
        controls={['time']}
        select="range"
        display="inline"
        touchUi={isSmallDevice}
        locale={dateLocale}
        defaultValue={defaultValue}
        onChange={({ value } = { value: [null, null] }) => onChange(value)}
        rangeStartLabel={t('ns.common:time_range_picker:start_label')}
        rangeStartHelp={t('ns.common:time_range_picker:start_helper_text')}
        rangeEndLabel={t('ns.common:time_range_picker:end_label')}
        rangeEndHelp={t('ns.common:time_range_picker:end_helper_text')}
        {...rest}
      />
    </Box>
  );
};
