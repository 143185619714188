import React from 'react';
import { Pagination as MuiPagination, PaginationProps as MuiPaginationProps } from '@mui/material';

interface PaginationProps extends MuiPaginationProps {
  /**
   * How to horizontally align the Pagination component
   */
  align?: 'left' | 'right' | 'center';
  /**
   * Provide a page count limit to, if supplied the component will only render up to this number of pages. The
   * boundaryCount will be set to 0, but supplying a boundaryCount will override this.
   */
  limitCount?: number;
}

/**
 * Renders a Pagination control.
 */
export const Pagination = ({ align = 'center', limitCount, count, ...props }: PaginationProps): JSX.Element => {
  const justifyContent = align === 'left' ? 'start' : align === 'right' ? 'end' : 'center';
  const countHasMetLimit = limitCount !== undefined && count >= limitCount;

  return (
    <MuiPagination
      boundaryCount={countHasMetLimit ? 0 : undefined}
      {...props}
      count={countHasMetLimit ? limitCount : count}
      sx={{
        '& .MuiPagination-ul': { justifyContent },
        ...props.sx,
      }}
    />
  );
};
