import React, { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { CoreLayout, Header, PageMeta, Theme } from '@askporter/component-library';
import { useConfig, useTranslation } from '@askporter/config-provider';
import { useExportContext } from '../../providers';
import { ExportList } from './ExportList';

interface ExportsProps {
  navigation: React.ReactNode;
}

/**
 * The Exports component is the main component for the Exports list page.
 */
export const Exports: React.FC<ExportsProps> = ({ navigation }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    tenantDisplay: {
      logos: { favicon, touch },
    },
  } = useConfig();
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { clearAllExports } = useExportContext();

  useEffect(() => {
    clearAllExports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageMeta language={language} favicon={favicon} touch={touch} title={t('export:export_list:page_meta')} />
      <CoreLayout
        layoutType="simplePageLayout"
        navigation={navigation}
        header={<Header isListPage isSmallDevice={isSmallDevice} title={t('export:export_list:page_meta')} />}
        main={<ExportList t={t} />}
      />
    </>
  );
};
