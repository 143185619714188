import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { DynamicError, StaticError } from '@askporter/component-library';
import { useConfig } from '@askporter/config-provider';
import CoreNavigation from '../../components/CoreNavigation';
import { getMaintenanceFallbackString } from './utils';

type Props = { shouldShowStaticErrorPage?: boolean };

const REFRESH_PERIOD = 60000 * 5;

/** Renders the MaintenancePage */
const MaintenancePage: React.FC<React.PropsWithChildren<Props>> = ({ shouldShowStaticErrorPage = false }: Props) => {
  const config = useConfig();
  const {
    i18n: { language },
  } = useTranslation();

  const fallbackTitle = getMaintenanceFallbackString('ns.maintenance_page:title');
  const fallbackMsg = getMaintenanceFallbackString('ns.maintenance_page:message');

  const title = t('ns.maintenance_page:title', fallbackTitle) || fallbackTitle;
  const errMsg = t('ns.maintenance_page:message', fallbackMsg) || fallbackMsg;

  // refresh page after the defined refresh period
  useEffect(() => {
    setTimeout(() => window.location.reload(), REFRESH_PERIOD);
  }, []);

  if (shouldShowStaticErrorPage) return <StaticError title={title} error={errMsg} hideReturnLink={true} />;

  return (
    <DynamicError
      navigation={<CoreNavigation />}
      t={t}
      language={language}
      config={config}
      errTitle={title}
      errMsg={errMsg}
      hideReturnLink={true}
    />
  );
};

export default MaintenancePage;
