import { FatalError } from '../types';

/**
 * Create a custom FatalError that can be thrown
 * @param code the error code to use, for example 500
 * @param message the error message (for logging purposes)
 * @returns an error object that can be thrown or reported to askporter/exception-logger
 */
const fatalError = (code: number, message: string): FatalError => {
  const err = new Error(message);
  const fatalErr: FatalError = err;
  fatalErr.code = code;
  fatalErr.name = 'FatalError';

  return fatalErr;
};

export default fatalError;
