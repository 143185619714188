/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import { FilledInput, InputLabel } from '@mui/material';
import { utils } from '@rjsf/core';
import { CustomWidgetProps } from '../types';

const { getDisplayLabel } = utils;

const TextareaWidget = ({
  id,
  placeholder,
  value,
  disabled,
  autofocus,
  label,
  readonly,
  onBlur,
  onFocus,
  onChange,
  options,
  schema,
  uiSchema,
  required,
  formContext: { onHasChanged },
}: CustomWidgetProps): JSX.Element => {
  const [hasChanged, setHasChanged] = useState(false);
  const { emptyValue = required ? undefined : '' } = options;
  const _onChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    onChange(value === '' ? emptyValue : value);
    setHasChanged(true);
  };
  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => {
    hasChanged && onHasChanged({ id, value });
    onBlur(id, value);
    setHasChanged(false);
  };
  const _onFocus = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value);
  const displayLabel = getDisplayLabel(schema, uiSchema);

  return (
    <>
      <InputLabel variant="filled" htmlFor={id}>
        {displayLabel ? label || schema.title : false}
      </InputLabel>
      <FilledInput
        data-testid="filled-textarea-widget"
        id={id}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readonly}
        value={value || value === 0 ? value : ''}
        autoFocus={autofocus}
        multiline={true}
        minRows={(options.rows as number) || 3}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
      />
    </>
  );
};

export default TextareaWidget;
