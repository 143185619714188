import { DateTime } from './DateTime';
import { UserSummary } from './UserSummary';

export interface WorkOrderHistoryItemResolved {
  action?: WorkOrderHistoryItemResolved_ActionEnum;
  date?: DateTime;
  user?: UserSummary;
}

export type WorkOrderHistoryItemResolved_ActionEnum = 'CREATED' | 'REASSIGNED' | 'ACCEPTED' | 'DECLINED' | 'QUOTE_ACCEPTED' | 'QUOTE_DECLINED';

export const WorkOrderHistoryItemResolved_ActionEnum = {
  CREATED: 'CREATED' as WorkOrderHistoryItemResolved_ActionEnum,
  REASSIGNED: 'REASSIGNED' as WorkOrderHistoryItemResolved_ActionEnum,
  ACCEPTED: 'ACCEPTED' as WorkOrderHistoryItemResolved_ActionEnum,
  DECLINED: 'DECLINED' as WorkOrderHistoryItemResolved_ActionEnum,
  QUOTEACCEPTED: 'QUOTE_ACCEPTED' as WorkOrderHistoryItemResolved_ActionEnum,
  QUOTEDECLINED: 'QUOTE_DECLINED' as WorkOrderHistoryItemResolved_ActionEnum,
};
