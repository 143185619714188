import { CognitoError } from './types';

// Create a custom error class for cognito errors so the code is made available
// based on https://stackoverflow.com/questions/41102060/typescript-extending-error-class
class CognitoErr extends Error {
  code: string;

  constructor(m: string, code: string) {
    super(m);
    this.code = code;
    this.name = 'CognitoError';

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, CognitoErr.prototype);
  }
}

/**
 * Wraps the error with an Error object
 *
 * @param e - The error to wrap and format (can be a plain string, existing Error or CognitoError)
 * @param fallback - If the error.message cannot be obtained show the fallback error message
 *
 * @returns Error
 */
export const wrapError = (e: string | Error | CognitoError, fallback?: string): Error => {
  if (typeof e === 'string') {
    return new Error(e);
  } else {
    let errorString = '';
    if (Object.prototype.hasOwnProperty.call(e, 'code')) {
      const cognitoError = e as CognitoError;
      const error = new CognitoErr(cognitoError?.message || fallback, cognitoError?.code);

      return error;
    } else {
      const error = e as Error;
      errorString = `${error?.name || ''} ${error?.message || fallback}`;
    }

    return new Error(errorString.trim());
  }
};
