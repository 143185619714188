import React from 'react';
import { FieldProps } from '@rjsf/core';
import isEqual from 'lodash/isEqual';
import { Media, formatMediaItems } from '@askporter/utils';
import { DragAndDropUpload } from '../../';

const MultiFileUploadField = ({
  formData,
  idSchema,
  schema,
  onChange,
  required,
  uiSchema,
  disabled,
  readonly,
  formContext: { onHasChanged, t, API },
}: FieldProps): JSX.Element => {
  const value = formatMediaItems(formData);
  const isRequired = uiSchema && !!uiSchema['ui:softRequired'] ? true : required;
  const _onChange = (uploadedFiles: Media[]) => {
    const newValue = formatMediaItems(uploadedFiles);
    onChange(newValue);

    // the MultiFileUpload component fires the onChange multiple times on render, so only fire the onHasChanged when it
    // really has changed.
    if (value?.length !== newValue?.length || !isEqual(value, newValue)) {
      setTimeout(() => onHasChanged({ id: idSchema.$id, value: newValue }), 0);
    }
  };

  return (
    <DragAndDropUpload
      idPrefix={idSchema.$id}
      label={schema?.title}
      onChange={_onChange}
      value={value || []}
      required={isRequired}
      disabled={disabled || readonly}
      t={t}
      API={API}
      helperText={t('ns.common:file_upload:help_text')}
    />
  );
};

export default MultiFileUploadField;
