import { UiSchema } from '@rjsf/core';
import { JSONSchema7 } from 'json-schema';
import { cloneDeep } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import {
  ExtendedMetadataAttributeConfigReadExternalValidationRules,
  ExtendedMetadataAttributeConfigReadExternal,
  ExtendedMetadataAttributeConfigReadExternal_RequiredEnum as RequiredEnum,
  ExtendedMetadataConfigReadExternal,
  ExtendedMetadataDataType,
  ExtendedMetadataConfigReadExternalRolePolicy,
  PermissionRole,
  RoleSlug,
  TaskTypeReadExternalExtMetadataConfig,
  RoleExtMetadataReadExternalExtMetadataConfig,
  TaskActivityConfigReadExternal,
  OrgTypeExtMetadataReadExternal,
  RoleExtMetadataReadExternal,
  AssetTypeReadExternal,
} from '@askporter/grieg-types';
import { TaskActivityConfig, ExtMetadata, ResolvedExtMetadataPolicy, TaskFullTaskActivitiesModified } from '../types';
import { checkPermissions } from './checkPermissions';
import { formatMediaItem, formatMediaItems } from './formatMedia';
import { getAllowedRolesByRolePolicy } from './rolePolicyMapping';

// maps the type enums from the API to JSON schema types
const dataTypes = {
  DAYTIME: { type: 'array', items: { type: 'object' } },
  DAYTIMERANGE: { type: 'array', items: { type: 'object' } },
  MULTILANGSTRING: { type: 'object' },
  STRING: { type: 'string' },
  LABEL: { type: 'string' },
  MULTILINESTRING: { type: 'string' },
  INTEGER: { type: 'integer' },
  NUMBER: { type: 'number' },
  DATETIME: { type: 'string', format: 'date-time' },
  DATE: { type: 'string', format: 'date' },
  BOOLEAN: { type: 'boolean' },
  FILE: {
    type: ['object', 'string', 'null'],
  },
  MULTIFILE: {
    type: 'array',
    items: {
      type: 'object',
    },
  },
};

// maps the validations rules from the API to JSON schema syntax
const validationRules: Record<keyof ExtendedMetadataAttributeConfigReadExternalValidationRules, string> = {
  minValue: 'minimum',
  maxValue: 'maximum',
  regex: 'pattern',
  allowedValues: 'enum',
};

// returns an array of required fields
export const getRequiredFields = (
  attributes: Array<ExtendedMetadataAttributeConfigReadExternal>,
  type: RequiredEnum,
): string[] => attributes.filter((a) => a.required === type).map((a) => a.jsonElementName);

// converts to the equivalent JSON schema validation syntax
export const convertValidationRules = (
  rules: ExtendedMetadataAttributeConfigReadExternalValidationRules,
): Record<string, unknown> => {
  const result: Record<string, number | string | string[]> = {};
  if (rules) {
    Object.keys(validationRules).forEach((rule: keyof ExtendedMetadataAttributeConfigReadExternalValidationRules) => {
      if (Object.prototype.hasOwnProperty.call(rules, rule)) {
        result[validationRules[rule]] = rules[rule];
      }
    });
  }
  return result;
};

// converts single metadata object with attributes to JSON schema
export const convertExtMetadataToSchema = (
  extMetadataConfig: ExtendedMetadataConfigReadExternal,
  extMetadata: ExtMetadata,
  currentUserRole: RoleSlug,
): JSONSchema7['properties'] => {
  const result: Record<string, unknown> = {};
  const userPermissions = resolveExtMetadataRolePolicy(currentUserRole, extMetadataConfig.rolePolicy);

  // no access if user can't read or write data
  if (!userPermissions.canWrite && !userPermissions.canRead) {
    return {};
  }

  if (extMetadataConfig.attributes) {
    extMetadataConfig.attributes.map((a) => {
      // return the json schema for an attribute only if the user
      // has write access or read but there is data to display
      if (
        (userPermissions.canWrite && !a?.uiEditDisabled) ||
        (userPermissions.canWrite &&
          a?.uiEditDisabled === true &&
          (extMetadata?.[a?.jsonElementName] || !isEmpty(extMetadata?.[a?.jsonElementName]))) ||
        (userPermissions.canRead && (extMetadata?.[a?.jsonElementName] || !isEmpty(extMetadata?.[a?.jsonElementName])))
      ) {
        result[a.jsonElementName] = {
          ...dataTypes[a.dataType],
          ...convertValidationRules(a.validationRules),
          title: a.displayName,
        };
      }
    });
  }

  if (isEmpty(result)) {
    return {};
  }

  return {
    [extMetadataConfig.jsonElementName]: {
      title: extMetadataConfig.displayName,
      description: extMetadataConfig?.guidance || '',
      type: 'object',
      required: [].concat(getRequiredFields(extMetadataConfig.attributes || [], RequiredEnum.HARD)),
      properties: result,
    },
  };
};

// converts all the extended metadata objects to a single JSON schema
export const getDynamicProperties = (
  extMetadataConfigArray: ExtendedMetadataConfigReadExternal[],
  extMetadata: ExtMetadata,
  currentUserRole: RoleSlug,
): JSONSchema7['properties'] =>
  extMetadataConfigArray.reduce(
    (prev, curr) => ({
      ...prev,
      ...convertExtMetadataToSchema(curr, extMetadata?.[curr?.jsonElementName], currentUserRole),
    }),
    {},
  );

// deduces a UI schema from the extended metadata
export const getDynamicUISchema = (
  extMetadata: ExtendedMetadataConfigReadExternal[],
  currentUserRole: RoleSlug,
  dataTypeTransformMap: Record<string, Record<string, unknown>> = {},
): UiSchema => {
  return extMetadata.reduce(
    (prevMeta, currMeta) => ({
      ...prevMeta,
      ...(currMeta.attributes || []).reduce((prevAttr, currAttr) => {
        const softRequired = getRequiredFields(currMeta.attributes, RequiredEnum.SOFT);
        const uiOverride = currAttr.dataType in dataTypeTransformMap;
        const isSoftRequired = softRequired.includes(currAttr.jsonElementName);
        const userPermissions = resolveExtMetadataRolePolicy(currentUserRole, currMeta.rolePolicy);

        if (uiOverride || isSoftRequired || userPermissions.canWrite || userPermissions.canRead) {
          return {
            [currMeta.jsonElementName]: {
              ...prevAttr[currMeta.jsonElementName],
              [currAttr.jsonElementName]: {
                ...(uiOverride ? dataTypeTransformMap[currAttr.dataType] : {}),
                ...(isSoftRequired ? { 'ui:softRequired': true } : {}),
                'ui:readonly':
                  (!userPermissions.canWrite && userPermissions.canRead) || currAttr?.uiEditDisabled === true,
              },
            },
          };
        }

        return { ...prevAttr };
      }, {} as Record<string, Record<string, unknown>>),
    }),
    {},
  );
};

// generates full JSON schema (merges the static and dynamic)
export const generateJSONSchema = (
  extMetadataConfigArray: ExtendedMetadataConfigReadExternal[],
  extMetadata: ExtMetadata,
  currentUserRole: RoleSlug,
  staticSchema?: JSONSchema7['properties'],
  options?: { staticAfterDynamic?: boolean },
): JSONSchema7 => {
  let properties = {
    ...(staticSchema || {}),
    ...getDynamicProperties(extMetadataConfigArray, extMetadata, currentUserRole),
  };

  if (options?.staticAfterDynamic) {
    properties = {
      ...getDynamicProperties(extMetadataConfigArray, extMetadata, currentUserRole),
      ...(staticSchema || {}),
    };
  }

  return {
    type: 'object',
    properties,
  };
};

// generates full UI schema (merges the static and dynamic)
export const generateUISchema = (
  extMetadata: ExtendedMetadataConfigReadExternal[],
  currentUserRole: RoleSlug,
  dataTypeTransformMap: Record<string, Record<string, unknown>>,
  staticSchema?: UiSchema,
): UiSchema => ({
  ...(staticSchema || {}),
  ...getDynamicUISchema(extMetadata, currentUserRole, dataTypeTransformMap),
});

// returns the full list of resolved roles using the permission roles
// see https://github.com/askporter/front-end/issues/1533
export const resolveRoles = (roles: Array<PermissionRole>): Array<RoleSlug> => {
  return (roles || []).reduce((prev, curr) => {
    const userRoles = getAllowedRolesByRolePolicy(curr);
    return [...Array.from(new Set([...prev, ...(userRoles ? userRoles : [])]))];
  }, []);
};

// based on the role policy of the ext metadata group config
// it returns an object with the user's read and write permissions
export const resolveExtMetadataRolePolicy = (
  currentUserRole: RoleSlug,
  policy: ExtendedMetadataConfigReadExternalRolePolicy,
): ResolvedExtMetadataPolicy => {
  const resolvedUserRolesRead = resolveRoles(policy?.readRoles);
  const resolvedUserRolesWrite = resolveRoles(policy?.writeRoles);

  const permissions = {
    canRead: checkPermissions(currentUserRole, resolvedUserRolesRead),
    canWrite: checkPermissions(currentUserRole, resolvedUserRolesWrite),
  };

  return permissions;
};

// strip out anything that's not visible based on the role policy
export const resolveExtMetadataConfig = (
  extMetadataConfig: Array<TaskTypeReadExternalExtMetadataConfig | RoleExtMetadataReadExternalExtMetadataConfig>,
  currentUserRole: RoleSlug,
): Array<TaskTypeReadExternalExtMetadataConfig | RoleExtMetadataReadExternalExtMetadataConfig> => {
  return extMetadataConfig?.filter(({ extMetadataGroupConfig }) => {
    const permissions = resolveExtMetadataRolePolicy(currentUserRole, extMetadataGroupConfig?.rolePolicy);
    if (!permissions.canRead && !permissions.canWrite) {
      return false;
    }
    return true;
  });
};

// strip out anything that's not visible based on the resolved
// ext metadata config
export const resolveExtendedMetadata = (
  extMetadata: ExtMetadata,
  resolvedExtendedMetadataConfigArray: Array<
    TaskTypeReadExternalExtMetadataConfig | RoleExtMetadataReadExternalExtMetadataConfig
  >,
): ExtMetadata => {
  const keys = resolvedExtendedMetadataConfigArray.map(({ extMetadataGroupConfig }) => {
    return extMetadataGroupConfig.jsonElementName;
  });

  return keys.reduce((prev, curr) => {
    return { ...prev, ...(extMetadata?.[curr] ? { [curr]: extMetadata[curr] } : {}) };
  }, {});
};

// strips out any activities the user doesn't have access to
// using the resolved extended metadata config
export const resolveActivities = (
  activities: Array<TaskFullTaskActivitiesModified | TaskActivityConfigReadExternal>,
  resolvedExtendedMetadataConfig: Array<
    TaskTypeReadExternalExtMetadataConfig | RoleExtMetadataReadExternalExtMetadataConfig
  >,
): Array<TaskFullTaskActivitiesModified | TaskActivityConfig> => {
  return activities?.reduce((prev, curr) => {
    if (curr.activityAction) {
      const type = Object.keys(curr.activityAction);
      // resolving activities using role policy is only applicable
      // to ones with extended metadata
      if (type[0] === 'extendedMetadata') {
        const { extMetadataGroupConfigUid } = curr.activityAction?.extendedMetadata;
        const foundExtMetadataConfig = resolvedExtendedMetadataConfig?.find(({ extMetadataGroupConfig }) => {
          return extMetadataGroupConfig.uid === extMetadataGroupConfigUid;
        });
        if (foundExtMetadataConfig) {
          return [...prev, curr];
        } else {
          return [...prev];
        }
      }
      return [...prev, curr];
    }
    return [...prev, curr];
  }, []);
};

// work out whether the user only has read access for the ext metadata form
// so we know whether we need to display a submit button or not
export const canOnlyReadExtMetadata = (
  extMetadataConfig: ExtendedMetadataConfigReadExternal,
  currentUserRole: RoleSlug,
): boolean => {
  const permissions = resolveExtMetadataRolePolicy(currentUserRole, extMetadataConfig?.rolePolicy);
  return permissions.canRead && !permissions.canWrite;
};

/**
 * Some data types are not in an optimal format for sending back in the POST request, so format them here
 */
export const transformExtendedMetadata = (
  extMetadata: ExtMetadata,
  resolvedExtendedMetadataConfigArray:
    | Array<TaskTypeReadExternalExtMetadataConfig>
    | RoleExtMetadataReadExternal['extMetadataConfig']
    | OrgTypeExtMetadataReadExternal['extMetadataConfig']
    | AssetTypeReadExternal['extMetadataConfig'],
): ExtMetadata => {
  const transformedExtMetadata = cloneDeep(extMetadata);

  resolvedExtendedMetadataConfigArray?.forEach((group) => {
    group?.extMetadataGroupConfig?.attributes?.forEach((attribute) => {
      const media =
        transformedExtMetadata?.[group?.extMetadataGroupConfig.jsonElementName]?.[attribute?.jsonElementName];

      if (media) {
        if (attribute.dataType === ExtendedMetadataDataType.MULTIFILE) {
          transformedExtMetadata[group.extMetadataGroupConfig.jsonElementName][attribute.jsonElementName] =
            formatMediaItems(media);
        } else if (attribute.dataType === ExtendedMetadataDataType.FILE) {
          transformedExtMetadata[group.extMetadataGroupConfig.jsonElementName][attribute.jsonElementName] =
            formatMediaItem(media);
        }
      }
    });
  });

  return transformedExtMetadata;
};
