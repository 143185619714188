import { useEffect, useState } from 'react';
import { uploadFiles, UploadedFile, SimplifiedIAPIClient } from '../';

interface UseFileUploadParams {
  onUploadedFile: (uploadState: UploadedFile) => void;
  accept?: string;
  API: () => SimplifiedIAPIClient;
  captureException: (...args: unknown[]) => void;
}

interface UseFileUploadReturn {
  chooseFile: () => void;
}

export function useFileUpload({
  onUploadedFile,
  accept,
  API,
  captureException,
}: UseFileUploadParams): UseFileUploadReturn {
  const [fileUploadState, setFileUploadState] = useState<UploadedFile>();

  useEffect(() => {
    if (fileUploadState) {
      onUploadedFile(fileUploadState);
      setFileUploadState(undefined);
    }
  }, [fileUploadState, onUploadedFile]);

  const chooseFile = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = accept;
    input.id = 'file-upload-hook-input';
    document.body.appendChild(input);

    const handleChange = async (event: Event) => {
      const target = event.target as HTMLInputElement;
      const uploadResult = await uploadFiles([target?.files[0]], API, captureException);
      setFileUploadState(uploadResult[0]);

      target.removeEventListener('change', handleChange);
      target.remove();
    };
    input.addEventListener('change', handleChange);
    input.click();
  };

  return { chooseFile };
}
