import { RemoveExportsInProgressItem } from '../providers/providerTypes';
import { FetchExportByUidData } from './fetchExportByUid';

/**
 * A function to handle the cleanup of exports in progress
 */
export const handleExportsCleanup = ({
  uid,
  requestedAt,
  removeExportsInProgressItem,
}: {
  uid: FetchExportByUidData['uid'];
  requestedAt: FetchExportByUidData['requestedAt'];
  removeExportsInProgressItem: RemoveExportsInProgressItem;
}): void => {
  const dateNow = new Date().getTime();
  const dateRequested = new Date(requestedAt).getTime();

  // If the export has been requested for more than 24 hours, remove it from the list
  if (dateNow - dateRequested > 1000 * 60 * 60 * 24) {
    removeExportsInProgressItem(uid);
  }
};
