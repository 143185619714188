/**
 * Used for the drag and drop functionality on the photos page.
 * Based on what the user drag and dropped, it will re-order the media array
 * before doing the post request to update the new media order
 *
 * @param array - the array to perform the operation on (warning it will mutate it)
 * @param from - the index of the item to move
 * @param to - the new index to move the item to
 */
export const moveElementInArray = (arr: Array<unknown>, from: number, to: number): void => {
  if (from < 0 || to < 0 || from > arr.length) return;

  if (to >= arr.length) {
  
    let k = to - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(to, 0, arr.splice(from, 1)[0]);
};
