import type { ExportListState, ExportListActions } from './types';

export const initialState = (): ExportListState => ({
  page_token: undefined,
  previousPageTokens: [],
});

export default (state: ExportListState, action: ExportListActions): ExportListState => {
  switch (action.type) {
    case 'SET_PAGE_TOKEN':
      return { ...state, page_token: action.payload };

    case 'HANDLE_NEXT_BUTTON_CLICK':
      return {
        ...state,
        previousPageTokens: [...state.previousPageTokens, state.page_token],
        page_token: action.payload,
      };

    case 'HANDLE_PREVIOUS_BUTTON_CLICK':
      return {
        ...state,
        previousPageTokens: state.previousPageTokens.slice(0, -1),
        // eslint-disable-next-line functional/immutable-data
        page_token: state.previousPageTokens.pop(),
      };

    default:
      return state;
  }
};

export type { ExportListState };
