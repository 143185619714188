import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { TenantConfig } from '@askporter/grieg-types';
import { Error } from '../../';
import { CoreLayout } from '../../../CoreLayout';
import { SimplePageLayout } from '../../../CoreLayout/types';
import { PageMeta } from '../../../PageMeta';
import { ErrorWrapperProps, RouterState } from './types';
import { getPageTitleByErrorStatusCode } from './utils';

export interface DynamicErrorProps {
  /** translation function */
  t: (key: string) => string;
  /** language code */
  language: string;
  /** header component */
  navigation: React.ReactNode;
  /** tenant configuration, populates page meta */
  config?: TenantConfig;
  /** (optional) the error title */
  errTitle?: string;
  /** (optional) message to display in the body */
  errMsg?: string;
  /** (optional) the HTML page title */
  errMetaTitle?: string;
  /** (optional) the return link */
  errLinkText?: string;
  /** (optional) the HTTP status code of the error */
  statusCode?: number;
  /** (optional) hide the return link */
  hideReturnLink?: true;
}

/**
 * Error page which can display various error messages
 */
export const DynamicError: React.FC<React.PropsWithChildren<DynamicErrorProps>> = ({
  t,
  errTitle = `${t('ns.error_page:error')}`,
  errMsg,
  errMetaTitle,
  errLinkText = `${t('ns.error_page:404:link_text')}`,
  statusCode,
  config,
  language,
  navigation,
  hideReturnLink,
}: DynamicErrorProps) => {
  const ErrorWrapper: React.FC<React.PropsWithChildren<ErrorWrapperProps>> = ({
    message,
    errTitle,
    errLinkText,
  }: ErrorWrapperProps) => {
    // Get the router history and check if a homeLink has been passed, otherwise default to landing page
    const history = useHistory();
    const state: RouterState | undefined = history.location.state;
    const homeLink = state && state.homeLink ? state.homeLink : '/';

    return (
      <Error
        t={t}
        errMsg={message}
        errTitle={errTitle}
        errLinkText={errLinkText}
        statusCode={statusCode}
        homeLink={homeLink}
        testId="dynamic-error-page"
        hideReturnLink={hideReturnLink}
      />
    );
  };

  const layout: SimplePageLayout = {
    main: (
      <Route>
        <ErrorWrapper message={errMsg} errTitle={errTitle} errLinkText={errLinkText} />
      </Route>
    ),
  };

  const metaTitle = getPageTitleByErrorStatusCode(statusCode, t);

  return (
    <>
      <PageMeta
        title={errMetaTitle ? errMetaTitle : metaTitle || t('ns.error_page:404:page_title')}
        favicon={config?.tenantDisplay?.logos?.favicon}
        touch={config?.tenantDisplay?.logos?.touch}
        language={language}
      />
      <CoreLayout navigation={navigation} layoutType="simplePageLayout" {...layout} />
    </>
  );
};
