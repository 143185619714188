import React, { useEffect } from 'react';
import { FieldProps } from '@rjsf/core';
import {
  DayTimeRange,
  DayTimeRangeType,
  DEFAULT_STATUS_KEYS,
  MuiMaterial,
  Theme,
  translateObjectValues,
  AutoSaveStatusText,
  AutoSaveStatus,
} from '../../';

const { FormControl, useMediaQuery } = MuiMaterial;

const DayTimeRangeField = ({
  formData,
  idSchema,
  schema,
  onChange,
  disabled,
  readonly,
  formContext: { t, onHasChanged, autosaveStatuses, clearAutosaveStatus },
}: FieldProps): JSX.Element => {
  const translateKeys = translateObjectValues(t);
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const id = idSchema?.$id;
  const title = schema?.title;

  const autosaveStatus = autosaveStatuses?.[id];
  const showAutosaveStatus: boolean = !!autosaveStatus && autosaveStatus !== AutoSaveStatus.NONE;
  const [data, setData] = React.useState<Array<DayTimeRangeType>>(formData || []);

  const _onAdd = (value: Array<DayTimeRangeType>) => {
    const postValue = value.filter((v) => v.fromTime || v.toTime);
    onChange(postValue);
    setData(postValue);
    setTimeout(() => onHasChanged({ id }), 0);
  };

  useEffect(() => {
    setData(formData);
  }, [formData]);

  return (
    <FormControl fullWidth={true} key={`${id}-form-control`}>
      <DayTimeRange
        value={data}
        title={title}
        t={t}
        isSmallDevice={isSmallDevice}
        onAdd={_onAdd}
        disabled={disabled || readonly}
      />
      {showAutosaveStatus && (
        <AutoSaveStatusText
          statusText={translateKeys(DEFAULT_STATUS_KEYS)}
          autosaveStatus={autosaveStatus}
          clearAutosaveStatus={() => clearAutosaveStatus(id)}
          label={title}
        />
      )}
    </FormControl>
  );
};

export default DayTimeRangeField;
