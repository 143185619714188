export const getMessageByErrorStatusCode = (statusCode: number, t: (key: string) => string): string | undefined => {
  const message = {
    202: `${t('ns.error_page:202:page_subtitle')}`,
    500: `${t('ns.error_page:500:subtitle')}`,
    502: `${t('ns.error_page:500:subtitle')}`,
    401: `${t('ns.error_page:403:subtitle')}`,
    403: `${t('ns.error_page:403:subtitle')}`,
  }[statusCode];

  return message;
};
