import React from 'react';
import { Helmet, HelmetProps } from 'react-helmet';

export type PageMetaProps = HelmetProps & {
  children?: React.ReactNode;
  title: string;
  language: string;
  favicon: string;
  touch: string;
};

/**
 * This component can be included on a page to set the page title or
 * add any additional meta tags inside <head></head>
 *
 * @param children - The tags to include in the <head></head> element
 * @param title - The HTML document's title
 * @param rest  - Any valid react-helmet props
 */
export const PageMeta: React.FC<React.PropsWithChildren<PageMetaProps>> = ({
  children,
  title,
  language,
  favicon,
  touch,
  ...rest
}: PageMetaProps) => {
  return (
    <Helmet {...rest} htmlAttributes={{ lang: language, 'xml:lang': language }}>
      <link rel="shortcut icon" href={favicon} />
      <link rel="apple-touch-icon" href={touch} />
      <meta name="Content-Language" content={language} />
      <meta httpEquiv="Content-Language" content={language} />
      <title>{title} | AskPorter</title>
      {children}
    </Helmet>
  );
};
