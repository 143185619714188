import { SearchStatsElement } from '@askporter/grieg-types';
import { TransformedTaskFilterValues } from '@askporter/task-list-sidebar';

interface CommonFilterValueProperties {
  uid: string;
  displayName: string;
}

/**
 * Transforms the task-filter-values for a single key into a consistent object and optionally groups by display name
 *
 * @param values - values for a single key from the task-filter-values endpoint, for example values of "statuses"
 * @param groupByDisplayName - optional, groups items by displayname and returns a list of uid's rather than a uid
 * @returns a TransformedTaskFilterValues object
 */
export function transformTaskFilterValues<T extends CommonFilterValueProperties>({
  values,
  groupByDisplayName,
  stats,
}: {
  values: Array<T>;
  groupByDisplayName?: boolean;
  stats: Array<SearchStatsElement>;
}): TransformedTaskFilterValues {
  if (!values) return [];

  if (groupByDisplayName === true) {
    // Create an object with displayName as the key and a list of uid's
    const group = values?.reduce((acc, curr) => {
      acc[curr?.displayName] = acc[curr?.displayName] || [];
      acc[curr?.displayName].push(curr?.uid);
      return acc;
    }, {} as Record<string, Array<string>>);

    // transform the data into the correct shape
    const grouped: TransformedTaskFilterValues = Object.keys(group)
      .map((key) => {
        return {
          displayName: key,
          groupId: key?.replace(/ /g, '_')?.toLowerCase(),
          uidList: group[key],
          count: group[key].reduce((prev, curr) => (stats?.find((stat) => stat.uid === curr)?.count || 0) + prev, 0),
        };
      })
      .filter((g) => g.count > 0);

    return grouped;
  } else {
    const transformedValues: TransformedTaskFilterValues = values
      ?.map(({ uid, displayName = '' }) => ({
        uid,
        displayName,
        count: stats?.find((stat) => stat.uid === uid)?.count,
      }))
      .filter((t) => t.count > 0);

    return transformedValues;
  }
}
