import React from 'react';
import { Chip } from '../../';

interface VerifiedTagProps {
  status: boolean;
  t: (key: string, options?: Record<string, string | number>) => string;
  dataTestId?: string;
}

/**
 * The tag displayed next to the phone number/email with the "Verified"/"Not verified" text
 * @param status - Decides the tag's color: green - verified, red - not verified
 * @param t - Translation function
 */

export const VerifiedTag = ({ status, t, dataTestId = 'status-tag' }: VerifiedTagProps): JSX.Element => {
  return (
    <Chip
      data-testid={dataTestId}
      sx={{
        bgcolor: `${status ? 'success.main' : 'error.main'}`,
        color: `${status ? 'success.contrastText' : 'error.contrastText'}`,
      }}
      label={status ? `${t('ns.common:tag:verified')}` : `${t('ns.common:tag:not_verified')}`}
    />
  );
};
