import React, { ChangeEventHandler, useEffect, useState, useRef } from 'react';
import { Publish as PublishIcon } from '@mui/icons-material';
import { Box, CircularProgress, FormControl, FormControlProps } from '@mui/material';
import { captureException } from '@askporter/exception-logger';
import { UploadedFile } from '@askporter/utils';
import { Button, ButtonProps } from '../../';
import uploadFiles, { SimplifiedIAPIClient } from './uploadFiles';

interface SingleFileUploadButtonProps extends Omit<ButtonProps, 'onChange'> {
  onChange?: (uploadState: UploadedFile) => void;
  variant?: ButtonProps['variant'];
  label: string;
  t: (key: string, options?: Record<string, string | number>) => string;
  API: () => SimplifiedIAPIClient;
  formControlProps?: FormControlProps;
  disableToMargin?: boolean;
}

const SingleFileUploadButton = ({
  onChange = () => ({}),
  variant = 'contained',
  label,
  sx = {},
  formControlProps = {},
  t,
  API,
  disableToMargin,
  ...buttonProps
}: SingleFileUploadButtonProps): JSX.Element => {
  const ref = useRef(null);
  const [startWidth, setStartWidth] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [fileUploadState, setFileUploadState] = useState<UploadedFile>();

  const handleFileAdd: ChangeEventHandler<HTMLInputElement> = async (e) => {
    setIsLoading(true);
    const uploadResult = await uploadFiles([e?.target?.files[0]], API, captureException);
    setFileUploadState(uploadResult[0]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (fileUploadState) {
      onChange(fileUploadState);
      setFileUploadState(undefined);
    }
  }, [fileUploadState, onChange]);

  useEffect(() => {
    if (!isLoading) setStartWidth(ref?.current?.offsetWidth || 0);
  }, [isLoading, label]);

  return (
    <FormControl {...formControlProps} sx={disableToMargin ? { mb: 0 } : {}}>
      <Button
        component="label"
        disabled={isLoading}
        variant={variant}
        data-testid={`upload-button`}
        endIcon={<PublishIcon />}
        html-for="single-file-input"
        ref={ref}
        sx={{ ...(isLoading ? { width: startWidth ? `${startWidth}px` : undefined } : {}), ...sx }}
        {...buttonProps}
      >
        {isLoading && (
          <Box width="100%" display="flex" justifyContent="center">
            <CircularProgress data-testid="loading-indicator" />
          </Box>
        )}
        {!isLoading && (
          <>
            <input
              autoComplete="off"
              id="single-file-input"
              multiple={false}
              onChange={handleFileAdd}
              style={{ display: 'none' }}
              type="file"
            />
            {label || t('ns.common:file_upload:single:input_button')}
          </>
        )}
      </Button>
    </FormControl>
  );
};

export default SingleFileUploadButton;
