import { SearchFreeText } from './SearchFreeText';
import { SearchOrgFilter } from './SearchOrgFilter';
import { SearchOrgSort } from './SearchOrgSort';
import { TimezoneName } from './TimezoneName';

export interface OrgExportCreate {
  name: string;
  timezone: TimezoneName;
  freeText?: SearchFreeText;
  format: OrgExportCreate_FormatEnum;
  fields?: Array<OrgExportCreate_FieldsEnum>;
  filter?: SearchOrgFilter;
  sort?: SearchOrgSort;
}

export type OrgExportCreate_FormatEnum = 'STANDARD' | 'EXCEL_COMPATIBLE';

export const OrgExportCreate_FormatEnum = {
  STANDARD: 'STANDARD' as OrgExportCreate_FormatEnum,
  EXCELCOMPATIBLE: 'EXCEL_COMPATIBLE' as OrgExportCreate_FormatEnum,
};

export type OrgExportCreate_FieldsEnum = 'REGISTRATION_TOKEN';

export const OrgExportCreate_FieldsEnum = {
  REGISTRATIONTOKEN: 'REGISTRATION_TOKEN' as OrgExportCreate_FieldsEnum,
};
