import { ErrorPageStrings } from '../Config/types';

// Translations for when something goes very wrong, this should ship with the front end and not be moved to strings
// service. As these messages would only ever be called on when something really key is missing from the Config (or the
// config itself is missing) - see config provider for more info
export const languageOptions = ['en-GB', 'de-DE'] as const;
export type LanguageOption = typeof languageOptions[number]; // convert array into type
export const fatalErrorTranslations: Record<LanguageOption, ErrorPageStrings> = {
  'en-GB': {
    errMsg: 'Sorry there seems to be a problem, please try again later.',
    errLinkText: 'Go to homepage',
    errTitle: 'Error',
    errMetaTitle: 'Error',
  },
  'de-DE': {
    errMsg: 'Es scheint ein Problem aufgetreten zu sein. Bitte versuchen Sie es später noch einmal.',
    errLinkText: 'Zur Homepage gehen',
    errTitle: 'Fehler',
    errMetaTitle: 'Fehler',
  },
};
