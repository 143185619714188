import React from 'react';
import { DynamicError, DynamicErrorProps } from '@askporter/component-library';
import { useTranslation } from '@askporter/config-provider';

type ThemedErrorProps = Omit<DynamicErrorProps, 't' | 'language'>;

/** Renders a ThemedError component */
const ThemedError: React.FC<React.PropsWithChildren<ThemedErrorProps>> = (props: ThemedErrorProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return <DynamicError navigation={props?.navigation} t={t} language={language} {...props} />;
};

export default ThemedError;
