import { SearchFreeText } from './SearchFreeText';
import { SearchTaskFilter } from './SearchTaskFilter';
import { SearchTaskSort } from './SearchTaskSort';
import { TimezoneName } from './TimezoneName';

export interface TaskExportCreate {
  name: string;
  timezone: TimezoneName;
  freeText?: SearchFreeText;
  format: TaskExportCreate_FormatEnum;
  fields?: Array<TaskExportCreate_FieldsEnum>;
  filter?: SearchTaskFilter;
  sort?: SearchTaskSort;
}

export type TaskExportCreate_FormatEnum = 'STANDARD' | 'EXCEL_COMPATIBLE';

export const TaskExportCreate_FormatEnum = {
  STANDARD: 'STANDARD' as TaskExportCreate_FormatEnum,
  EXCELCOMPATIBLE: 'EXCEL_COMPATIBLE' as TaskExportCreate_FormatEnum,
};

export type TaskExportCreate_FieldsEnum = 'TASK_RESOURCES';

export const TaskExportCreate_FieldsEnum = {
  TASKRESOURCES: 'TASK_RESOURCES' as TaskExportCreate_FieldsEnum,
};
