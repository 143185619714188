import React from 'react';
import { FallbackRender, ReactErrorBoundary } from '../components/ReactErrorBoundary';

export interface GeneralErrorBoundaryProps {
  /**
   * A fallback component that gets rendered when the error boundary encounters an error.
   */
  fallback: FallbackRender;
  /**
   * The child components that should be wrapped with the error boundary
   */
  children: React.ReactNode;
}

/**
 * An error boundary used to catch global errors, an entire page error as opposed to a localised error.
 */
const GeneralErrorBoundary: React.FC<React.PropsWithChildren<GeneralErrorBoundaryProps>> = ({
  fallback,
  children,
}: GeneralErrorBoundaryProps) => {
  return <ReactErrorBoundary fallback={fallback}>{children}</ReactErrorBoundary>;
};

export { GeneralErrorBoundary };
