import { DayOfWeek } from '@askporter/grieg-types';
import { DayTimeType } from '../types';
import { parseDayTime } from './parseDayTime';

/**
 * Merges the current DayTime data with the new data selected by the user
 *
 * @param currentState - The current DayTime data
 * @param newState - The new DayTime data selected by the user
 *
 * @returns The merged DayTime data
 */
export const mergeDayTimeState = (
  currentState: Array<DayTimeType>,
  newState: Array<DayTimeType>,
): Array<DayTimeType> => {
  const result: Array<DayTimeType> = [];

  const currentStateParsed = parseDayTime(currentState);
  const newStateParsed = parseDayTime(newState);

  const mergedState = { ...currentStateParsed, ...newStateParsed };

  Object.keys(mergedState).forEach((key: DayOfWeek) => {
    const value = mergedState[key];
    if (value) {
      const entry: DayTimeType = { days: [key], time: value };
      result.push(entry);
    }
  });

  return result;
};
