import React, { useState } from 'react';
import UploadFile from '@mui/icons-material/UploadFile';
import { Box, List, Stack, Theme, Typography, alpha, styled, useMediaQuery, useTheme } from '@mui/material';
import { FilePath } from '@askporter/grieg-types';
import { Media, SimplifiedIAPIClient, UploaderFileTypes } from '@askporter/utils';
import { FileItem } from '../FileItem';
import { FilePreview } from '../FilePreview';
import { IconSize } from '../Icon';
import { useDragAndDropUpload } from './util/useDragAndDropUpload';

interface DragAndDropUploadProps {
  /**
   * label for the file uploader
   */
  label?: string;
  /**
   * Translation function
   */
  t: (key: string, options?: Record<string, string | number>) => string;
  /**
   * Api client
   */
  API: () => SimplifiedIAPIClient;
  /**
   * id prefix used for testing
   */
  idPrefix: string;
  /**
   * Uploaded files
   */
  value?: Array<Media>;
  /**
   * Is file upload disabled
   */
  disabled?: boolean;
  /*
   * If the file is required
   */
  required?: boolean;
  /**
   * The file types to accept, if nothing is passed, it will accept all file types
   */
  accept?: UploaderFileTypes;
  /**
   * The callback function to call when a file is uploaded
   */
  onChange?: (uploadedFiles: Array<Media>) => void;
  /**
   * Hide the preview of the uploaded files
   */
  hidePreview?: boolean;
  /**
   * If true, this will be a multiple file upload
   */
  multiple?: boolean;
  /**
   * The upload helper text which shows the accepted file types and the max file size
   */
  helperText?: string;
}

const Span = styled('span')(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  textUnderlineOffset: theme.spacing(1),
}));

export const DragAndDropUpload = ({
  idPrefix,
  label,
  required = false,
  t,
  API,
  value,
  hidePreview = false,
  disabled = false,
  multiple = true,
  onChange = () => ({}),
  helperText,
  accept = undefined,
}: DragAndDropUploadProps): JSX.Element => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [filePreview, setFilePreview] = useState<FilePath | undefined>();
  const {
    filesUploading,
    filesFailedToUpload,
    setFilesFailedToUpload,
    filesNewlyUploaded,
    filesPreviouslyUploaded,
    setFilesSuccessfullyUploaded,
    unsupportedFileError,
    mapOfURNToObjectURL,
    dragAndDrop: { getInputProps, getRootProps },
  } = useDragAndDropUpload({ API, existingFiles: value, multiple, onChange, accept, disabled });

  const filterFileFailedToUpload = (index: number) => {
    setFilesFailedToUpload((prev) => prev.filter((_, _id) => _id !== index));
  };

  const filterFileSuccessfullyUploaded = (index: number) => {
    setFilesSuccessfullyUploaded((prev) => prev.filter((_, _id) => _id !== index));
  };

  return (
    <>
      {label && <Typography variant="body1">{label}</Typography>}
      <Box
        {...getRootProps()}
        sx={{
          border: unsupportedFileError
            ? `1px solid ${theme.palette.error.main}`
            : `1px dashed ${theme.palette.text.secondary}`,
          background: unsupportedFileError ? alpha(theme.palette.error.main, 0.04) : undefined,
          cursor: !disabled && 'pointer',
          '&:hover': !disabled && {
            border: `1px dashed ${theme.palette.primary.main}`,
            background: alpha(theme.palette.primary.main, 0.08),
          },
          padding: isSmallDevice ? 4 : 10,
          marginY: 4,
        }}
        data-testid={`${idPrefix}-uploader-drag-and-drop`}
      >
        <input
          required={required}
          disabled={disabled}
          {...getInputProps()}
          data-testid={`${idPrefix}-uploader-input`}
        />
        <Stack direction="column" spacing={2} alignItems="center" justifyContent={'center'}>
          <UploadFile
            sx={{
              fontSize: IconSize.LG,
              color: unsupportedFileError ? theme.palette.error.main : theme.palette.primary.main,
            }}
          />
          <Typography variant="body1">
            <Span>{t('common:file_uploader:label_highlighted')}</Span>
            {t('common:file_uploader:label_rest')}
          </Typography>
          {unsupportedFileError ? (
            <Typography variant="body2" sx={{ color: theme.palette.error.main }}>
              {t('common:file_uploader:error:unsupported_file')}
            </Typography>
          ) : (
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
              {helperText || t('common:file_uploader:helper_text')}
            </Typography>
          )}
        </Stack>
      </Box>

      {!hidePreview && (
        <List>
          {filesUploading.map((file, _id) => (
            <FileItem
              idPrefix={`${idPrefix}-uploader-list`}
              disabled={disabled}
              t={t}
              key={_id}
              file={{
                ...file,
                state: 'loading',
              }}
            />
          ))}
          {filesFailedToUpload.map((file, _id) => (
            <FileItem
              idPrefix={`${idPrefix}-uploader-list`}
              disabled={disabled}
              t={t}
              key={_id}
              file={{
                error: file.error as string,
                name: file.name,
                state: 'error',
                handleRemove: () => filterFileFailedToUpload(_id),
              }}
            />
          ))}
          {filesNewlyUploaded.map((file, _id) => (
            <FileItem
              idPrefix={`${idPrefix}-uploader-list`}
              disabled={disabled}
              t={t}
              key={_id}
              file={{
                ...file,
                path: mapOfURNToObjectURL[file.file.fileRef] || file.path,
                state: 'success',
                handleRemove: () => filterFileSuccessfullyUploaded(_id),
                setFilePreview: () =>
                  setFilePreview({
                    fileUrn: file.file.fileRef,
                    filePath: mapOfURNToObjectURL[file.file.fileRef] || file.path,
                  }),
              }}
            />
          ))}
          {filesPreviouslyUploaded.map((file, _id) => (
            <FileItem
              idPrefix={`${idPrefix}-uploader-list`}
              disabled={disabled}
              t={t}
              key={_id}
              isShowingProgressIndicator={false}
              file={{
                ...file,
                path: mapOfURNToObjectURL[file.file.fileRef] || file.path,
                state: 'success',
                handleRemove: () => filterFileSuccessfullyUploaded(_id),
                setFilePreview: () =>
                  setFilePreview({
                    fileUrn: file.file.fileRef,
                    filePath: mapOfURNToObjectURL[file.file.fileRef] || file.path,
                  }),
              }}
            />
          ))}
        </List>
      )}

      {filePreview && (
        <FilePreview
          t={t}
          isSmallDevice={isSmallDevice}
          file={filePreview}
          handleClose={() => setFilePreview(undefined)}
        />
      )}
    </>
  );
};
