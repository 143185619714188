export interface ThreadGetResponseExternalFollowOnAction {
  actionName?: ThreadGetResponseExternalFollowOnAction_ActionNameEnum;
  actionEnabled?: boolean;
  actionMetadata?: { [key: string]: any };
}

export type ThreadGetResponseExternalFollowOnAction_ActionNameEnum = 'UPLOAD_FILE';

export const ThreadGetResponseExternalFollowOnAction_ActionNameEnum = {
  UPLOADFILE: 'UPLOAD_FILE' as ThreadGetResponseExternalFollowOnAction_ActionNameEnum,
};
