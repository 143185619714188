import { Media, UploadedFile, getFileDetails } from '../../';

export const transformUploadData = (data: UploadedFile): Media => {
  return {
    file: {
      fileRef: data.urn,
    },
    addedAt: data.addedAt,
    nameAndType: getFileDetails(data.signedUploadPath),
  };
};
