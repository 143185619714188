import { DayTimeAction, DayTimeState } from '../types';

export const reducer = (state: DayTimeState, action: DayTimeAction): DayTimeState => {
  switch (action.type) {
    case 'addDayTime':
      return {
        ...state,
        dayTimes: action.payload as DayTimeState['dayTimes'],
        isVisible: false,
        noServiceCheckbox: false,
        timeValue: null,
      };
    case 'selectDay':
      return {
        ...state,
        ...(action.payload as { selectedDays: DayTimeState['selectedDays']; timeValue: DayTimeState['timeValue'] }),
        isVisible: true,
      };
    case 'selectAllDays':
      return { ...state, selectedDays: action.payload as DayTimeState['selectedDays'], isVisible: true };
    case 'selectMonFri':
      return { ...state, selectedDays: action.payload as DayTimeState['selectedDays'], isVisible: true };
    case 'selectSatSun':
      return { ...state, selectedDays: action.payload as DayTimeState['selectedDays'], isVisible: true };
    case 'setModalVisibility':
      return {
        ...state,
        isVisible: action.payload as DayTimeState['isVisible'],
        noServiceCheckbox: false,
        timeValue: null,
      };
    case 'setTimeValue':
      return { ...state, timeValue: action.payload as DayTimeState['timeValue'] };
    case 'setNoServiceCheckbox':
      return { ...state, noServiceCheckbox: action.payload as DayTimeState['noServiceCheckbox'] };
    case 'setSelectedDays':
      return { ...state, selectedDays: action.payload as DayTimeState['selectedDays'] };
    default:
      return state;
  }
};
