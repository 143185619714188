import { Media } from '../types';
import { getFileDetails } from './getFileDetails';

// strip out filePath and fileUrn because backend only likes fileRef when we are saving back to the backend
export const formatMediaItem = (mediaItem: Media): Media => {
  return mediaItem?.file
    ? {
        file: { fileRef: mediaItem.file.fileUrn || mediaItem.file.fileRef },
        addedAt: mediaItem.addedAt,
        path: mediaItem.file.filePath || mediaItem.path,
        nameAndType: mediaItem.file.filePath ? getFileDetails(mediaItem.file.filePath) : mediaItem.nameAndType,
      }
    : undefined;
};

export const formatMediaItems = (mediaItems: Array<Media>): Array<Media> => {
  const formatted = mediaItems?.map((mediaItem: Media) => formatMediaItem(mediaItem)).filter(Boolean);

  if (formatted?.length > 0) return formatted;
  return undefined;
};
