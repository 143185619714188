import {
  TaskExportReadExternal_FieldsEnum,
  OrgExportReadExternal_FieldsEnum,
  AssetExportReadExternal_FieldsEnum,
  UserExportReadExternal_FieldsEnum,
  RoleSlug,
} from '@askporter/grieg-types';
import { ManagerRoles, SupplierRoles, ServiceUserRoles } from '@askporter/utils';
import { CheckboxEssentialFields } from '../types';

// Roles and fields detailed here https://askproducts.atlassian.net/browse/AP-2545

export const exportTasksCheckboxFieldsPermissionMapping = {
  [CheckboxEssentialFields.TASK_ESSENTIALS]: [
    ...ManagerRoles,
    RoleSlug.SUPPSPR, // The super supplier role
    ...SupplierRoles,
    RoleSlug.SVCUSRSPR, // The super service user role
    ...ServiceUserRoles,
  ],
  [TaskExportReadExternal_FieldsEnum.TASKRESOURCES]: [
    ...ManagerRoles,
    RoleSlug.SUPPSPR,
    ...SupplierRoles,
    RoleSlug.SVCUSRSPR,
    ...ServiceUserRoles,
  ],
} as Record<
  Extract<keyof typeof CheckboxEssentialFields, 'TASK_ESSENTIALS'> | TaskExportReadExternal_FieldsEnum,
  RoleSlug[]
>;

export const exportAssetsCheckboxFieldsPermissionMapping = {
  [CheckboxEssentialFields.ASSET_ESSENTIALS]: [...ManagerRoles, RoleSlug.SUPPSPR, RoleSlug.SVCUSRSPR],
  [AssetExportReadExternal_FieldsEnum.ASSETDETAILS]: [...ManagerRoles, RoleSlug.SUPPSPR, RoleSlug.SVCUSRSPR],
  [AssetExportReadExternal_FieldsEnum.ASSETSETTINGS]: [...ManagerRoles],
  [AssetExportReadExternal_FieldsEnum.REGISTRATIONTOKEN]: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  [AssetExportReadExternal_FieldsEnum.ONSITEASSISTANT]: [...ManagerRoles],
} as Record<
  Extract<keyof typeof CheckboxEssentialFields, 'ASSET_ESSENTIALS'> | AssetExportReadExternal_FieldsEnum,
  RoleSlug[]
>;

export const exportUsersCheckboxFieldsPermissionMapping = {
  [CheckboxEssentialFields.USER_ESSENTIALS]: [...ManagerRoles, RoleSlug.SUPPSPR, RoleSlug.SVCUSRSPR],
  [UserExportReadExternal_FieldsEnum.ACCOUNTSETTINGS]: [...ManagerRoles, RoleSlug.SUPPSPR, RoleSlug.SVCUSRSPR],
  [UserExportReadExternal_FieldsEnum.LINKEDTOUSER]: [...ManagerRoles, RoleSlug.SUPPSPR, RoleSlug.SVCUSRSPR],
} as Record<
  Extract<keyof typeof CheckboxEssentialFields, 'USER_ESSENTIALS'> | UserExportReadExternal_FieldsEnum,
  RoleSlug[]
>;

export const exportOrgsCheckboxFieldsPermissionMapping = {
  [CheckboxEssentialFields.ORG_ESSENTIALS]: [...ManagerRoles, RoleSlug.SUPPSPR, RoleSlug.SVCUSRSPR],
  [OrgExportReadExternal_FieldsEnum.REGISTRATIONTOKEN]: [...ManagerRoles, RoleSlug.SVCUSRSPR],
} as Record<
  Extract<keyof typeof CheckboxEssentialFields, 'ORG_ESSENTIALS'> | OrgExportReadExternal_FieldsEnum,
  RoleSlug[]
>;

export const exportTeamsCheckboxFieldsPermissionMapping = {
  [CheckboxEssentialFields.TEAM_ESSENTIALS]: [...ManagerRoles],
} as Record<Extract<keyof typeof CheckboxEssentialFields, 'TEAM_ESSENTIALS'>, RoleSlug[]>;

export const exportFieldsPermissionMapping = {
  tasks: exportTasksCheckboxFieldsPermissionMapping,
  assets: exportAssetsCheckboxFieldsPermissionMapping,
  users: exportUsersCheckboxFieldsPermissionMapping,
  orgs: exportOrgsCheckboxFieldsPermissionMapping,
  teams: exportTeamsCheckboxFieldsPermissionMapping,
};
