import React, { useState } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Button } from '@mui/material';

export interface ExportButtonProps {
  /**
   * The label of the button
   */
  label: string;
  /**
   * Whether the device is small
   */
  isSmallDevice: boolean;
  /**
   * The render function for the Export modal
   */
  render: ({ handleClose }: { handleClose: () => void }) => JSX.Element;
  /**
   * Optional data test id for the button
   */
  dataTestId?: string;
  disabled: boolean;
}

export const ExportButton = ({
  label,
  isSmallDevice,
  render,
  dataTestId = undefined,
  disabled,
}: ExportButtonProps): JSX.Element => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <Button
        variant="outlined"
        aria-label={label}
        onClick={handleClick}
        sx={{ mr: 2 }}
        data-testid={dataTestId}
        disabled={disabled}
      >
        {isSmallDevice ? <FileDownloadIcon /> : label}
      </Button>

      {!!anchorElement && render({ handleClose })}
    </>
  );
};
