import { useState } from 'react';
import { ISubmitEvent } from '@rjsf/core';
import { flushSync } from 'react-dom';
import { AutoSaveStatus, FieldAutoSaveStatuses } from '../../';
import { RJSFProps } from '../RJSF';

/**
 * A hook that brings together the reusable elements of the autosave feature.
 * @param submitButtonRef - a ref to the submit button
 * @param handleSubmit - the handleSubmit function from the form
 * @returns an object with the following properties:
 * - updateAutosaveStatus - function to update the autosave status
 * - formProps - the form props that are passed to the RJSF component
 *
 */
export const useAutosave = (
  submitButtonRef: React.MutableRefObject<HTMLButtonElement>,
  handleSubmit: (
    e: ISubmitEvent<unknown>,
    fieldId: string,
  ) => Promise<{ fieldId: string; autoSaveStatus: AutoSaveStatus }>,
): {
  formProps: Partial<RJSFProps>;
} => {
  const [liveValidate, setLiveValidate] = useState(false);
  const [lastChanged, setLastChanged] = useState<string>('');
  const [autosaveStatuses, setAutosaveStatuses] = useState<FieldAutoSaveStatuses>({});

  const updateAutosaveStatus = (id: string, status: AutoSaveStatus) =>
    setAutosaveStatuses((prevState) => ({ ...prevState, [id]: status }));

  const onError: RJSFProps['onError'] = ({ length: errCount }) => {
    if (errCount > 0) {
      // encountered a bug whereby errors were not being cleared, so whe errors are found we switch to live
      // validation until a successful submission occurs. https://github.com/rjsf-team/react-jsonschema-form/issues/2309 & https://github.com/rjsf-team/react-jsonschema-form/issues/486
      setLiveValidate(true);

      // when validation errors occur set any autosaved fields that are "SAVING" to "FORM_VALIDATION_FAILED",
      // this ensures a warning is displayed to the user that states the form was unable to save due to one or
      // more other fields having validation errors. If the field itself has a validation error, then that will
      // take precedence over the FORM_VALIDATION_FAILED status.
      const adjustedStatuses = Object.keys(autosaveStatuses || {}).reduce((acc, curr) => {
        if (autosaveStatuses[curr] === AutoSaveStatus.SAVING) acc[curr] = AutoSaveStatus.FORM_VALIDATION_FAILED;
        return acc;
      }, {} as FieldAutoSaveStatuses);

      setAutosaveStatuses((prev) => ({ ...prev, ...adjustedStatuses }));
    }
  };

  const onHasChanged: RJSFProps['onHasChanged'] = ({ id }) => {
    // flag the field as the last changed and set its autosave status to true

    flushSync(() => {
      /**
       * This is needed as the autosave status isn't updated correctly for certain fields that
       * call do setTimeout(() => onHasChanged(...), 0) in their onChange handlers.
       */
      setLastChanged(id);
      updateAutosaveStatus(id, AutoSaveStatus.SAVING);
    });

    // submit the form
    submitButtonRef.current.click();
  };

  const clearAutosaveStatus: RJSFProps['clearAutosaveStatus'] = (id: string) =>
    updateAutosaveStatus(id, AutoSaveStatus.NONE);

  const onSubmit: RJSFProps['onSubmit'] = async (e: ISubmitEvent<unknown>) => {
    const { fieldId, autoSaveStatus } = await handleSubmit(e, lastChanged);
    updateAutosaveStatus(fieldId, autoSaveStatus);
    setLiveValidate(false);
  };

  return {
    formProps: { onError, autosaveStatuses, onHasChanged, clearAutosaveStatus, liveValidate, onSubmit },
  };
};
