import { DayOfWeek } from '@askporter/grieg-types';

export const days = {
  [DayOfWeek.MON]: 0,
  [DayOfWeek.TUE]: 1,
  [DayOfWeek.WED]: 2,
  [DayOfWeek.THU]: 3,
  [DayOfWeek.FRI]: 4,
  [DayOfWeek.SAT]: 5,
  [DayOfWeek.SUN]: 6,
};

export const daysByIndex: Record<string, DayOfWeek> = {
  0: DayOfWeek.MON,
  1: DayOfWeek.TUE,
  2: DayOfWeek.WED,
  3: DayOfWeek.THU,
  4: DayOfWeek.FRI,
  5: DayOfWeek.SAT,
  6: DayOfWeek.SUN,
};

export const defaultDays = {
  0: false,
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
};

export const selectAllDays = {
  0: true,
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: true,
};

// returns an array of days of the week from an object of selected days e.g.
//
// {
//  0: true,
//  1: true,
//  2: false,
//  3: false,
//  4: false,
//  5: false,
//  6: false,
// } => ['MON', 'TUE']
export const getDays = (selectedWeekDays: Record<string, boolean>): DayOfWeek[] =>
  Object.keys(selectedWeekDays || {})
    .filter((k) => selectedWeekDays[k] === true)
    .map((d) => daysByIndex[d]);
