// https://github.com/rjsf-team/react-jsonschema-form/blob/master/packages/material-ui/src/FieldTemplate/FieldTemplate.tsx
import React, { useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { FieldTemplateProps } from '@rjsf/core';
import { ExtendedMetadataDataType } from '@askporter/grieg-types';
import { AutoSaveStatusText, AutoSaveStatus, translateObjectValues, DEFAULT_STATUS_KEYS } from '../../';
import WrapIfAdditional from './WrapIfAdditional';

const FieldTemplate: React.FC<React.PropsWithChildren<FieldTemplateProps>> = ({
  id,
  children,
  classNames,
  disabled,
  displayLabel,
  hidden,
  label,
  onKeyChange,
  readonly,
  required,
  rawErrors = [],
  rawHelp,
  rawDescription,
  schema,
  uiSchema,
  formContext: { t, autosaveStatuses, clearAutosaveStatus },
}: FieldTemplateProps) => {
  const translateKeys = translateObjectValues(t);
  // as the raw errors (validation) take precedence over the autosave status we clear the autosave status to avoid any
  // rogue messages being displayed when an invalid field transitions from invalid -> valid prior to re-submission
  useEffect(() => {
    if (rawErrors.length) clearAutosaveStatus(id);
  }, [rawErrors.length]);

  if (hidden) {
    return children;
  }

  const autosaveStatus = autosaveStatuses?.[id];
  const showAutosaveStatus: boolean =
    // file upload fields and multi language string component should not have an autosave status
    ![
      ExtendedMetadataDataType.MULTIFILE,
      ExtendedMetadataDataType.FILE,
      ExtendedMetadataDataType.MULTILANGSTRING,
      ExtendedMetadataDataType.DAYTIME,
      ExtendedMetadataDataType.DAYTIMERANGE,
    ].includes(uiSchema?.['ui:extendedMetadataType']) &&
    // if the field has errors then the autosave status should be hidden
    !rawErrors.length &&
    // if there is no autosave status / it is undefined then the autosave status should be hidden
    !!autosaveStatus &&
    autosaveStatus !== AutoSaveStatus.NONE;

  const isRequired = uiSchema && !!uiSchema['ui:softRequired'] ? true : required;
  const error = rawErrors.length || autosaveStatus === AutoSaveStatus.ERROR ? true : false;

  return (
    <WrapIfAdditional
      classNames={classNames}
      disabled={disabled}
      id={id}
      label={label}
      onKeyChange={onKeyChange}
      readonly={readonly}
      required={isRequired}
      schema={schema}
    >
      <FormControl
        fullWidth={true}
        error={error}
        required={isRequired}
        className={classNames}
        data-testid="field-template"
      >
        {children}
        {displayLabel && rawDescription ? (
          <Typography variant="caption" color="textSecondary">
            {rawDescription}
          </Typography>
        ) : null}
        {rawErrors.length > 0 && (
          <List dense={true} disablePadding={true}>
            {rawErrors.map((error, i: number) => {
              return (
                <ListItem key={i} disableGutters={true}>
                  <FormHelperText id={id}>{error}</FormHelperText>
                </ListItem>
              );
            })}
          </List>
        )}

        {/* display the autosave status if ther are no rawErrors and autosave is in use with a valid status */}
        {showAutosaveStatus && (
          <AutoSaveStatusText
            statusText={translateKeys(DEFAULT_STATUS_KEYS)}
            autosaveStatus={autosaveStatus}
            clearAutosaveStatus={() => clearAutosaveStatus(id)}
            label={label}
          />
        )}

        {rawHelp && <FormHelperText id={id}>{rawHelp}</FormHelperText>}
      </FormControl>
    </WrapIfAdditional>
  );
};

export default FieldTemplate;
