import React from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';

/** Renders a button. Consult https://mui.com/material-ui/api/button/ for more usage info.*/
function ButtonBase<C extends React.ElementType>(
  props: MuiButtonProps<C, { component?: C }>,
  ref: React.Ref<HTMLButtonElement>,
): JSX.Element {
  return (
    <MuiButton {...props} ref={ref}>
      {props.children}
    </MuiButton>
  );
}

export const Button = React.forwardRef(ButtonBase);

export type ButtonProps = MuiButtonProps;
