import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { getMessageByErrorStatusCode } from './utils';

// Export error pages
export type { DynamicErrorProps } from './pages/Dynamic';
export { DynamicError } from './pages/Dynamic';
export { StaticError } from './pages/Static';

interface ErrorProps {
  /**
   * The translation function
   */
  t: (key: string) => string;
  /**
   * The error title
   */
  errTitle?: string;
  /**
   * The error body message
   */
  errMsg?: string;
  /**
   * The return link
   */
  errLinkText?: string;
  /**
   * The HTTP status code of the error. Used to determine the error message
   */
  statusCode?: number;
  /**
   * The link for going back to a working page
   */
  homeLink?: string;
  /**
   * The id for the data-testid attribute
   */
  testId?: string;
  /**
   * Hides the return link
   */
  hideReturnLink?: true;
  /**
   * Determines how to align the error content within the page
   */
  alignment?: 'top' | 'center';
}

/**
 * Error component which display an error title with
 * some copy and a link to go back
 */
export const Error: React.FC<React.PropsWithChildren<ErrorProps>> = ({
  t,
  errTitle = `${t('ns.error_page:error')}`,
  errMsg,
  errLinkText = `${t('ns.error_page:404:link_text')}`,
  statusCode,
  homeLink = '/app',
  testId = 'error-section',
  hideReturnLink,
  alignment = 'center',
}: ErrorProps) => {
  const message = getMessageByErrorStatusCode(statusCode, t);

  return (
    <Box
      data-testid={testId}
      component="section"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: alignment === 'top' ? 'flex-start' : 'center',
        height: '100%',
        width: '100%',
        textAlign: 'center',
      }}
    >
      <Typography variant="h2" component="h1" data-testid="error-title">
        {errTitle}
      </Typography>
      <Typography component="article">
        <Box component="div" data-testid="error-subtitle">
          {errMsg ? errMsg : message || t('ns.error_page:404:subtitle')}
        </Box>
        {hideReturnLink ? null : (
          <Link data-testid="return-link" href={homeLink}>
            {errLinkText}
          </Link>
        )}
      </Typography>
    </Box>
  );
};

export default Error;
