interface HandleExportModalWrapperOpenProps {
  /**
   * The total number of search results
   */
  totalSearchResults: number;
  /**
   * Function that opens export limit dialog
   */
  openExportLimitDialog: () => void;
  /**
   * Function that opens export modal
   */
  openExportModal: () => void;
}

/**
 * A function that handles opening the correct modal based on the total number of search results
 */
export const handleExportModalWrapperOpen = ({
  totalSearchResults,
  openExportLimitDialog,
  openExportModal,
}: HandleExportModalWrapperOpenProps): void => {
  if (totalSearchResults > 10000) {
    openExportLimitDialog();
  } else {
    openExportModal();
  }
};
