export const createDateObjectFromTimeString = (time: string): Date | null => {
  if (!time || typeof time !== 'string') {
    return null;
  }

  const [hours, minutes] = time.split(':');

  if (!hours || !minutes) {
    return null;
  }

  const date = new Date();
  date.setHours(parseInt(hours, 10));
  date.setMinutes(parseInt(minutes, 10));
  return date;
};
