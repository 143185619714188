import React from 'react';
import { AuthProvider as CognitoAuthProvider } from '@askporter/auth-provider';
import { useConfig } from '@askporter/config-provider';

interface AuthProviderProps {
  children: JSX.Element[] | JSX.Element;
}

export const AuthProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }: AuthProviderProps) => {
  const { tenantCognito } = useConfig();
  return <CognitoAuthProvider tenantCognito={tenantCognito}>{children}</CognitoAuthProvider>;
};

export default AuthProvider;
