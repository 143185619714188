import { parseISO } from 'date-fns';

type CommonFormats = 'dd MM' | 'dd MMM yyyy' | 'dd MMM yy HH:mm' | 'HH:mm';

/**
 * Attempst to format a UTC string date into the specified outputFormat
 * @param dateTimeString - a utc dateTime string (ISO 8601)
 * @param outputFormat - the format to return
 * @returns formatted date or undefined on failure
 *
 */
export const formatDate = (dateTimeString: string, outputFormat: CommonFormats): string | undefined => {
  try {
    let formattedDate;
    // parses an ISO 8601 date-time string into a Date object
    const parsedDate = parseISO(dateTimeString);
    const fallbackLang = 'en-GB';
    const selectedLanguage = localStorage.getItem('selectedLanguage') || fallbackLang;

    if (outputFormat === 'dd MM') {
      formattedDate = parsedDate?.toLocaleString(selectedLanguage, {
        month: '2-digit',
        day: '2-digit',
      });
    } else if (outputFormat === 'dd MMM yyyy') {
      formattedDate = parsedDate?.toLocaleString(selectedLanguage, {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      });
    } else if (outputFormat === 'dd MMM yy HH:mm') {
      formattedDate = parsedDate?.toLocaleString(selectedLanguage, {
        month: 'short',
        day: '2-digit',
        year: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
      });
    } else if (outputFormat === 'HH:mm') {
      formattedDate = parsedDate?.toLocaleString(selectedLanguage, {
        hour: 'numeric',
        minute: 'numeric',
      });
    }

    if (formattedDate === 'Invalid Date') {
      return undefined;
    }

    return formattedDate;
  } catch (err) {
    return undefined;
  }
};
