import React from 'react';
import { Box, Card, CardContent, Link, Typography } from '@mui/material';
import { LoadingButton, Logo } from '../../';

type onClickParams = React.SyntheticEvent;

export interface GenericPubFormLayoutProps {
  title: string;
  introText?: string;
  email?: string;
  loginLinkDescription?: string;
  loginLinkText?: string;
  linkText?: string;
  linkHref?: string;
  linkOnClick?: (event?: onClickParams) => void;
  linkTestId?: string;
  isLinkExternal?: boolean;
  buttonText?: string | JSX.Element;
  buttonHref?: string;
  buttonOnClick?: (event?: onClickParams) => void;
  buttonTestId?: string;
  buttonDisabled?: boolean;
  buttonType?: 'button' | 'submit' | 'reset';
  onlyChildMargin?: string;
  children?: React.ReactNode | React.ReactNode[];
  after?: React.ReactNode;
  isSmallDevice: boolean;
  logo?: string;
  tenantName?: string;
  isLoading?: boolean;
  spinnerTestId?: string;
}

/**
 * Renders the generic common layout used by mobile verification and other registration forms
 * @param title - the h1 title for the form
 * @param introText - block of text immediately after heading
 * @param email - (optional) the user's email address, if provided this will be displayed in a card that cannot be edited
 * @param linkText - (optional) the link element text, if a button is also provided the link is positioned on the left
 * @param linkHref - (optional) the link href
 * @param linkOnClick - (optional) the onClick function for the link
 * @param loginLinkDescription - (optional) the description for the login link
 * @param loginLinkText - (optional) the link element text for the login link
 * @param linkTestId - (optional) the data-testid string for the link
 * @param isLinkExternal - (optional) the link goes to an external site, i.e. don't use react router
 * @param buttonText - (optional) the button element text, if a link is also provided the link is positioned on the left
 * @param buttonHref - (optional) the button href
 * @param buttonOnClick - (optional) the onClick function for the button
 * @param buttonTestId - (optional) the data-testid string for the button
 * @param buttonDisabled - (optional) the disabled boolean flag for the button
 * @param buttonType - (optional) defaults to button, but can be updated to submit or reset
 * @param onlyChildMargin - (optional) if either the link or button are not provided then you can provide margin to
 * adjust the position of the only-child, by default it will left aligned. e.g. to center it you could provide '0 auto'.
 * @param children - React.ReactNode | React.ReactNode[];
 * @param after - (optional) React.ReactNode;
 * @param isSmallDevice - true / false flag whether we are above the 'sm' (768px) breakpoint
 * @param logo - (optional) the URL for the regular sized client logo
 * @param tenantName - (optional) the name of the tenant to include in the logo image 'alt' attribute
 * @param isLoading - (optional) true if a network request has been made
 * @param spinnerTestId - (optional) the data-testid for the spinner
 */
export const GenericPubFormLayout: React.FC<React.PropsWithChildren<GenericPubFormLayoutProps>> = ({
  title,
  introText,
  email,
  linkText,
  linkHref,
  linkOnClick,
  loginLinkDescription,
  loginLinkText,
  linkTestId,
  isLinkExternal,
  buttonText,
  buttonHref,
  buttonOnClick,
  buttonTestId,
  buttonDisabled = false,
  buttonType = 'button',
  onlyChildMargin,
  children,
  after,
  isSmallDevice,
  logo,
  tenantName,
  isLoading = false,
  spinnerTestId,
}: GenericPubFormLayoutProps) => {
  return (
    <Box
      component="form"
      sx={{
        maxWidth: (theme) => `${theme.breakpoints.values.sm}px`,
        mx: 'auto',
        px: { xxs: 6, sm: 8, md: 16 },
        py: { xxs: 6 },
        '& .MuiTypography-h1': { mb: 6 },
        '& .MuiTypography-body1, .MuiPaper-root': { mb: 4 },
      }}
      width="100%"
      height="100%"
      noValidate
      display="flex"
      justifyContent="center"
      alignItems={isSmallDevice ? 'flex-start' : 'center'}
    >
      <Box
        display={isSmallDevice ? 'flex' : 'block'}
        flexWrap="wrap"
        justifyContent="center"
        alignContent="space-between"
        height={isSmallDevice ? '100%' : 'auto'}
        width="100%"
      >
        <Box width="100%">
          {logo && <Logo logoURL={logo} tenantName={tenantName} />}
          <Typography component="h1" variant="h3" align="center" sx={{ mb: 4 }}>
            {title}
          </Typography>
          <Typography variant="body1" align="center">
            {introText}
          </Typography>
          {loginLinkDescription && loginLinkText && (
            <Box>
              <Typography sx={{ mr: 2, display: 'inline' }}>{loginLinkDescription}</Typography>
              <Link
                href={linkHref}
                rel={isLinkExternal ? 'external' : ''}
                onClick={linkOnClick}
                data-testid={linkTestId}
              >
                {loginLinkText}
              </Link>
            </Box>
          )}
          {email && (
            <Card
              variant="outlined"
              sx={{
                backgroundColor: 'secondary.main',
                '.css-ikup6e-MuiCardContent-root': {
                  '&:last-child': {
                    pb: 4,
                  },
                },
              }}
            >
              <CardContent sx={{ textAlign: 'center', p: 4 }}>{email}</CardContent>
            </Card>
          )}
          {children}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          paddingTop="40px"
          width="100%"
          sx={{ mt: 8, '& :only-child': { margin: onlyChildMargin } }}
        >
          {linkText && (
            <Link href={linkHref} rel={isLinkExternal ? 'external' : ''} onClick={linkOnClick} data-testid={linkTestId}>
              {linkText}
            </Link>
          )}

          {buttonText && (
            <>
              <Box position="relative">
                <LoadingButton
                  href={buttonHref}
                  onClick={buttonOnClick}
                  dataTestId={buttonTestId}
                  type={buttonType}
                  isLoading={isLoading}
                  spinnerDataTestId={spinnerTestId}
                  disabled={buttonDisabled}
                >
                  {buttonText}
                </LoadingButton>
              </Box>
            </>
          )}
        </Box>
        {after}
      </Box>
    </Box>
  );
};
