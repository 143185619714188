import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Theme, useMediaQuery } from '@mui/material';
import { useQueries, useQueryClient } from 'react-query';
import {
  Snackbar,
  ListItem,
  IconButton,
  MuiListItemText,
  MuiListItemButton,
  MuiList,
  MuiCollapse,
  Button,
} from '@askporter/component-library';
import { TFunction, useTranslation } from '@askporter/config-provider';
import { useExportContext } from '../../providers';
import type { ExportsInProgressArray } from '../../types';
import fetchExportByUid, { FetchExportByUidData } from '../../utils/fetchExportByUid';
import { handleExportsCleanup } from '../../utils/handleExportsCleanup';
import { ExportsInProgressListItem } from './ExportsInProgressListItem';

interface ExportsInProgressSnackbarProps {
  /**
   * The open state of the snackbar
   */
  isOpen: boolean;
  /**
   * The list of exports to show progress for
   */
  exports: ExportsInProgressArray;
  /**
   * Whether the snackbar is expanded
   */
  isExportsInProgressSnackbarExpanded: boolean;
  /**
   * Function to set the expanded state of the snackbar
   */
  setIsExportsInProgressSnackbarExpanded: (value: boolean) => void;
}

const getExportTitle = ({ t, exports }: { t: TFunction; exports: ExportsInProgressArray }): string => {
  const pendingExports = exports?.filter((exportItem) => exportItem.status === 'PENDING');
  if (pendingExports.length > 0) {
    return t('export:export_progress_snackbar:pending_exports:title', { count: pendingExports.length });
  }
  const completedExports = exports?.filter((exportItem) => exportItem.status === 'COMPLETED');
  if (completedExports.length > 0) {
    return t('export:export_progress_snackbar:completed_exports:title', { count: completedExports.length });
  }
  const failedExports = exports?.filter((exportItem) => exportItem.status === 'FAILED');
  return t('export:export_progress_snackbar:failed_exports:title', { count: failedExports.length });
};

/**
 * The export progress snackbar component, used to show the progress of all pending exports
 */
export const ExportsInProgressSnackbar: React.FC<ExportsInProgressSnackbarProps> = ({
  isOpen,
  exports,
  isExportsInProgressSnackbarExpanded,
  setIsExportsInProgressSnackbarExpanded,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { updateExportsInProgressItem, removeExportsInProgressItem, closeExportsInProgressSnackbar } =
    useExportContext();

  const exportGetRequestResults = useQueries(
    exports.map(({ uid, status }) => ({
      queryKey: [`exports/${uid}`],
      queryFn: () => fetchExportByUid(uid),
      refetchInterval: status === 'PENDING' && 10000,
      onSuccess: (data: FetchExportByUidData): void => {
        handleExportsCleanup({ uid: data?.uid, requestedAt: data?.requestedAt, removeExportsInProgressItem });
        /**
         * If the status of the export changes from PENDING to something else,
         * we should update the status in the context
         */
        if (status === 'PENDING' && data?.progress?.status !== 'PENDING') {
          queryClient.invalidateQueries(['export-list']);
          updateExportsInProgressItem({ uid, status: data?.progress?.status, name: data?.name });
        }
      },
    })),
  );

  return (
    <Snackbar
      open={isOpen}
      /**
       * We should only close when a user clicks the close button
       * therefore clicking outside the snackbar should just minimize it
       */
      onClose={(_, reason) => reason === 'clickaway' && setIsExportsInProgressSnackbarExpanded(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      autoHideDuration={null}
      sx={{
        width: isSmallDevice ? '300px' : '400px',
        bgcolor: 'background.paper',
        borderRadius: '5px',
        border: '2px solid grey',
      }}
      data-testid="exports-in-progress-snackbar"
    >
      <MuiList sx={{ width: '100%', padding: 0 }}>
        <ListItem sx={{ paddingY: 0, paddingLeft: 0 }}>
          <MuiListItemButton
            onClick={() => setIsExportsInProgressSnackbarExpanded(!isExportsInProgressSnackbarExpanded)}
          >
            <MuiListItemText primary={getExportTitle({ t, exports })} />
            {isExportsInProgressSnackbarExpanded ? <ExpandMore /> : <ExpandLess />}
          </MuiListItemButton>
          <IconButton
            aria-label={t('export:export_progress_snackbar:close_button')}
            onClick={closeExportsInProgressSnackbar}
          >
            <CloseIcon />
          </IconButton>
        </ListItem>
        <MuiCollapse in={isExportsInProgressSnackbarExpanded} timeout="auto">
          <MuiList component="div" disablePadding>
            {exportGetRequestResults.slice(0, 5).map(({ isLoading, data }, index) => (
              <ExportsInProgressListItem
                key={index}
                isLoading={isLoading}
                t={t}
                uid={data?.uid}
                status={data?.progress?.status}
                name={data?.name}
              />
            ))}
          </MuiList>

          {exportGetRequestResults?.length > 5 && (
            <Button
              sx={{ width: '100%' }}
              href="/app/exports"
              onClick={() => {
                setIsExportsInProgressSnackbarExpanded(!isExportsInProgressSnackbarExpanded);
                closeExportsInProgressSnackbar();
              }}
            >
              {t('export:export_progress_snackbar:see_more_button')}
            </Button>
          )}
        </MuiCollapse>
      </MuiList>
    </Snackbar>
  );
};
