import React, { useState } from 'react';
import { Box } from '@mui/material';
import { ImageWithFallbackProps } from '../../types';

/**
 * Attempts to render an image with the provided path, img tag onError results in fallback JSX element rendering
 * @param imagePath - path of image that should be loaded
 * @param fallback - the element to render if the image fails to load
 * @param size - if mode icon then this prop accepts a number which represents the px height and width of the icon,
 * if the mode is image then it accepts an object which optionally contains height & width and max height & width.
 * @param additionalImgStyles - optional styles applied to the img tag
 * @param idPrefix - optional prefix defaults to image-with-fallback, used for data-testid, currently two:
 * - {idPrefix}-img-tag = the image tag element
 * - {idPrefix}-wrapper = a box element that wraps either the box or fallback
 * @param altText - the img alt text
 */
export const ImageWithFallback: React.FC<React.PropsWithChildren<ImageWithFallbackProps>> = ({
  imagePath,
  colorMask,
  fallback,
  size: sizeProp,
  idPrefix = 'image-with-fallback',
  altText,
  mode,
  justifyContent = 'center',
  wrapperStyles = {},
  additionalImgStyles = {},
}: ImageWithFallbackProps) => {
  // const imageStyles = mode === 'image' ? (rest as Partial<ImageModeProps>)?.additionalImgStyles || {} : {};
  const [hasError, setHasError] = useState<boolean>(false);
  const size =
    mode === 'icon'
      ? {
          height: `${sizeProp}px`,
          width: `${sizeProp}px`,
        }
      : (sizeProp as Record<string, string>);

  const Image = (
    <img
      data-testid={`${idPrefix}-img-tag`}
      src={imagePath?.toString() || ''}
      alt={altText}
      onError={() => setHasError(true)}
    />
  );

  const Fallback = fallback ? (
    mode === 'icon' ? (
      <Box sx={{ ...size, '& svg': { width: '100%', height: '100%' } }}>{fallback}</Box>
    ) : (
      fallback
    )
  ) : null;

  if (hasError && !fallback) return <></>;

  return (
    <>
      <Box
        display="flex"
        justifyContent={justifyContent}
        alignItems="center"
        data-testid={`${idPrefix}-wrapper`}
        sx={{
          ...wrapperStyles,
          '& img': { opacity: 1, ...size, ...additionalImgStyles },
        }}
      >
        {hasError || !imagePath ? Fallback : Image}
        {colorMask && (
          <Box
            sx={{
              position: 'absolute',
              backgroundColor: colorMask.color,
              opacity: 0.25,
              width: colorMask.width,
              height: colorMask.height,
              borderRadius: colorMask?.borderRadius,
            }}
          />
        )}
      </Box>
    </>
  );
};
