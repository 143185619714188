import { RelationshipLabel, URN } from '@askporter/grieg-types';
import { CreateRelationshipMutation } from '../types';

/**
 * Generic function for linking an array of URNs to an entity with a provided relationship label
 *
 * @param party - the party type (aParty or bParty)
 * @param partyURN - the party URN, all items provided later will be linked to this party
 * @param relLabel - the relationship label
 * @param addRelationship - the react-query mutation function to call
 *
 * @returns an async function - that accepts an itemsToLink (array of URNs) and returns a BatchSummary as a result
 */
export const linkItems =
  (
    party: 'aParty' | 'bParty',
    partyURN: URN,
    relLabel: RelationshipLabel,
    addRelationship: CreateRelationshipMutation,
  ) =>
  async (itemsToLink: Array<URN>): Promise<void> => {
    addRelationship.mutate({
      relationshipLabel: relLabel,
      parties: itemsToLink.map((urn) => ({
        aParty: party === 'aParty' ? partyURN : urn,
        bParty: party === 'bParty' ? partyURN : urn,
      })),
    });
  };
