export type RelationshipLabel = 'ALLOWED_TEAM' | 'ASSET_CHILD' | 'ASSET_PARENT' | 'ASSET_LINKED_ORG' | 'ASSET_LINKED_TEAM' | 'ASSET_LINKED_USER' | 'ASSET_TAG' | 'ASSIGNED_TEAM' | 'ASSIGNED_USER' | 'LINKED_TASK' | 'ORG_MEMBER' | 'TASK_LINKED_ORG' | 'TASK_LINKED_TEAM' | 'TASK_LINKED_USER' | 'TASK_MERGED_TO' | 'TASK_OTHER_ASSET' | 'TASK_PRINCIPAL_ASSET' | 'TASK_INITIATOR' | 'TASK_TAG' | 'TEAM_MEMBER' | 'PRODUCT_CATALOGUE_LINKED_ORG';

export const RelationshipLabel = {
  ALLOWEDTEAM: 'ALLOWED_TEAM' as RelationshipLabel,
  ASSETCHILD: 'ASSET_CHILD' as RelationshipLabel,
  ASSETPARENT: 'ASSET_PARENT' as RelationshipLabel,
  ASSETLINKEDORG: 'ASSET_LINKED_ORG' as RelationshipLabel,
  ASSETLINKEDTEAM: 'ASSET_LINKED_TEAM' as RelationshipLabel,
  ASSETLINKEDUSER: 'ASSET_LINKED_USER' as RelationshipLabel,
  ASSETTAG: 'ASSET_TAG' as RelationshipLabel,
  ASSIGNEDTEAM: 'ASSIGNED_TEAM' as RelationshipLabel,
  ASSIGNEDUSER: 'ASSIGNED_USER' as RelationshipLabel,
  LINKEDTASK: 'LINKED_TASK' as RelationshipLabel,
  ORGMEMBER: 'ORG_MEMBER' as RelationshipLabel,
  TASKLINKEDORG: 'TASK_LINKED_ORG' as RelationshipLabel,
  TASKLINKEDTEAM: 'TASK_LINKED_TEAM' as RelationshipLabel,
  TASKLINKEDUSER: 'TASK_LINKED_USER' as RelationshipLabel,
  TASKMERGEDTO: 'TASK_MERGED_TO' as RelationshipLabel,
  TASKOTHERASSET: 'TASK_OTHER_ASSET' as RelationshipLabel,
  TASKPRINCIPALASSET: 'TASK_PRINCIPAL_ASSET' as RelationshipLabel,
  TASKINITIATOR: 'TASK_INITIATOR' as RelationshipLabel,
  TASKTAG: 'TASK_TAG' as RelationshipLabel,
  TEAMMEMBER: 'TEAM_MEMBER' as RelationshipLabel,
  PRODUCTCATALOGUELINKEDORG: 'PRODUCT_CATALOGUE_LINKED_ORG' as RelationshipLabel,
};
