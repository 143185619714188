import React from 'react';
import { ThemeProvider as AskPorterThemeProvider } from '@askporter/component-library';
import { useConfig } from '@askporter/config-provider';

interface ThemeProviderProps {
  children: React.ReactNode[] | React.ReactNode;
}

/**
 * Provider component which passes down the theme data
 *
 * @param children - The React children components to wrap with the provider
 * @returns the AskPorter ThemeProvider component with all the children
 */
export const ThemeProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }: ThemeProviderProps) => {
  const {
    tenantDisplay: { palette },
  } = useConfig();

  return <AskPorterThemeProvider palette={palette}>{children}</AskPorterThemeProvider>;
};
