import { DateTime } from './DateTime';
import { UserSummary } from './UserSummary';

export interface AppointmentHistoryItemResolved {
  action?: AppointmentHistoryItemResolved_ActionEnum;
  date?: DateTime;
  user?: UserSummary;
}

export type AppointmentHistoryItemResolved_ActionEnum = 'CREATED' | 'SCHEDULED' | 'EN_ROUTE' | 'JOB_STARTED' | 'ACCESS_ISSUE_ADDED' | 'JOB_ISSUE_ADDED' | 'RESCHEDULED' | 'COMPLETED';

export const AppointmentHistoryItemResolved_ActionEnum = {
  CREATED: 'CREATED' as AppointmentHistoryItemResolved_ActionEnum,
  SCHEDULED: 'SCHEDULED' as AppointmentHistoryItemResolved_ActionEnum,
  ENROUTE: 'EN_ROUTE' as AppointmentHistoryItemResolved_ActionEnum,
  JOBSTARTED: 'JOB_STARTED' as AppointmentHistoryItemResolved_ActionEnum,
  ACCESSISSUEADDED: 'ACCESS_ISSUE_ADDED' as AppointmentHistoryItemResolved_ActionEnum,
  JOBISSUEADDED: 'JOB_ISSUE_ADDED' as AppointmentHistoryItemResolved_ActionEnum,
  RESCHEDULED: 'RESCHEDULED' as AppointmentHistoryItemResolved_ActionEnum,
  COMPLETED: 'COMPLETED' as AppointmentHistoryItemResolved_ActionEnum,
};
