import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { Permissions } from '@askporter/auth-provider';
import { AssetExportCreate, AssetExportCreate_FieldsEnum } from '@askporter/grieg-types';
import { exportAssetsCheckboxFieldsPermissionMapping } from '../../../utils/exportCheckboxFieldPermissionMapping';
import { ExportFieldCheckbox } from '../../ExportFieldCheckbox';

interface ExportAssetsFieldsProps {
  /**
   * The translation function
   */
  t: (key: string, options?: Record<string, string | number>) => string;
  /**
   * The form control
   */
  control: Control<AssetExportCreate>;
  // TODO: Weird error(Passing AssetExportModalFormControlType from ../../../types causes a resolver error)
}

/**
 * Renders the checkboxes for the assets export modal based on the user's role
 */
export const ExportAssetsFields: React.FC<ExportAssetsFieldsProps> = ({ t, control }) => {
  const nonEssentialsFields = Object.values(AssetExportCreate_FieldsEnum);

  return (
    <>
      <Permissions requiredRoles={exportAssetsCheckboxFieldsPermissionMapping.ASSET_ESSENTIALS}>
        <ExportFieldCheckbox
          fieldId="ASSET_ESSENTIALS"
          disabled={true}
          label={t('assets:export_modal:asset_essentials:checkbox:label')}
          subLabel={t('assets:export_modal:asset_essentials:checkbox:subLabel')}
          isChecked={true}
        />
      </Permissions>

      {nonEssentialsFields.map((fieldId, index) => (
        <Permissions key={index} requiredRoles={exportAssetsCheckboxFieldsPermissionMapping[fieldId]}>
          <Controller
            name="fields"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <ExportFieldCheckbox
                fieldId={fieldId}
                label={t(`assets:export_modal:${fieldId.toLowerCase()}:checkbox:label`)}
                subLabel={t(`assets:export_modal:${fieldId.toLowerCase()}:checkbox:subLabel`)}
                isChecked={field.value.includes(fieldId)}
                onChange={(checked) =>
                  field.onChange(checked ? [...field.value, fieldId] : field.value.filter((value) => value !== fieldId))
                }
                disabled={field.disabled}
              />
            )}
          />
        </Permissions>
      ))}
    </>
  );
};
