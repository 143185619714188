import { useCallback } from 'react';
import { t } from 'i18next';
import { clearCacheBuster } from '@askporter/api-client';
import { useAuthProvider } from '@askporter/auth-provider';
import { useToast } from '@askporter/component-library';
import { useConfig } from '@askporter/config-provider';
import { getUserFriendlyError } from '@askporter/utils';

/**
 * Handles the logout request
 */

export const useLogout = (): {
  handleSignOut: () => Promise<void>;
} => {
  const { signOut } = useAuthProvider();
  const { setToast } = useToast();
  const { tenantContact } = useConfig();
  const email = tenantContact && tenantContact.email ? tenantContact.email : '';

  const handleSignOut = useCallback(async () => {
    return (async () => {
      try {
        const res = await signOut();
        if (res && res.error) {
          setToast({
            open: true,
            severity: 'error',
            children: getUserFriendlyError(t, 'Unexpected', undefined, { email }),
          });
        } else {
          clearCacheBuster();
          setToast({
            open: true,
            severity: 'success',
            children: t('ns.common:sign_out:success'),
          });
        }
      } catch (error) {
        setToast({
          open: true,
          severity: 'error',
          children: getUserFriendlyError(t, 'Unexpected', undefined, { email }),
        });
      }
    })();
  }, []);

  return {
    handleSignOut,
  };
};
