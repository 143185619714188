import isArray from 'lodash/isArray';
import unionBy from 'lodash/unionBy';

/**
 * Used as the 'customiser' in lodash's 'mergeWith' function.
 * See here: https://lodash.com/docs/4.17.15#mergeWith
 *
 * @param objValue - the current (destination) array of stats
 * @param srcValue - the current (source) array of stats
 *
 * @returns - array of objects
 */
export function mergeArraysWithObjects(objValue: unknown, srcValue: unknown): Array<unknown> | undefined {
  // we only need to perform this merging array of objects from the same key
  if (isArray(objValue) && isArray(srcValue)) {
    // attempt to find the key of the individual 'SearchStatsElement'
    // e.g. 'uid', 'statusType', 'discoverability' etc
    // to then use as the unionKey
    const unionKey =
      Object.keys(objValue?.[0] || {}).filter((f) => f !== 'count')?.[0] ||
      Object.keys(srcValue?.[0] || {}).filter((f) => f !== 'count')?.[0];
    /*
      So that it can merge and overwrite the objects in the array as expected e.g.

       array1 = [  
        {
          discoverability: 'PUBLIC',
          count: 123,
        },
        {
          discoverability: 'PRIVATE',
          count: 88,
        }
       ]; 

       array2 = [  
        {
          discoverability: 'PUBLIC',
          count: 41,
        },
       ]; 

       unionBy(array1, array2, 'discoverability');

       Will result in:

       result = [  
        {
          discoverability: 'PUBLIC',
          count: 41,
        },
        {
          discoverability: 'PRIVATE',
          count: 88,
        }
       ]; 

    */
    return unionBy(srcValue, objValue, unionKey);
  }
}
