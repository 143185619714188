import { datadogRum } from '@datadog/browser-rum';

/**
 * Essentially an alias to datadogRum.addError, but allows us to swap out the implementation if needed without having
 * to change all the places we use it. Also allows us to add additional functionality, such as checking if RUM is enabled
 * and if not sending to a custom log server in the future if necessary.
 *
 */
export const captureException = (error: unknown, context?: Record<string, unknown>): void => {
  try {
    datadogRum.addError(error, context);
  } catch (e) {
    // keen to keep this function resilient to errors, so if datadogRum.addError fails for any reason then don't throw
  }
};
