import React, { memo, useRef, useLayoutEffect } from 'react';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';

export interface SingleOTPInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  focus?: boolean;
  idx: number;
  t: (key: string, options?: Record<string, string | number>) => string;
  errors: number[];
  preventSelectOnFocus?: boolean;
}

const KEY_SUFFIX = Date.now().toString();

/**
 * Component which renders a single input box. Used by parent OTP input component
 *
 * @param focus - A true/false flag to indicate whether the focus is on the current input
 * @param idx - The index of the input
 * @param t - The translation function
 * @param errors - An array of indexes where the errors occur e.g. [4, 5] would mean the last two inputs are empty
 * @param preventSelectOnFocus - Prevents selection of all characters within the input box when focussed
 * @param maxLength - max number of characters inside the input
 * @param value - The value of the input
 * @param onFocus - Function to execute when focus is on current input
 * @param onKeyDown - Function to execute when a key is pressed
 * @param onBlur - Function to execute when focus has changed away from current input
 * @param onPaste - Function to execute when something is pasted in input
 * @param disabled - whether the input should be disabled or not
 *
 */
export const SingleOTPInputComponent: React.FC<React.PropsWithChildren<SingleOTPInputProps>> = (
  props: SingleOTPInputProps,
) => {
  const {
    focus,
    idx,
    t,
    onChange,
    errors,
    value,
    onFocus,
    onKeyDown,
    onBlur,
    onPaste,
    disabled,
    preventSelectOnFocus = false,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (inputRef.current && focus) {
      inputRef.current.focus();
      !preventSelectOnFocus && inputRef.current.select();
    }
  }, [focus]);

  return (
    <FormControl key={`${idx}_${KEY_SUFFIX}`}>
      {/* Style label to ensure it is accessible, but not visible on screen, refer "Absolutely positioning 
      content": https://webaim.org/techniques/css/invisiblecontent/ */}
      <InputLabel
        id={`verification-code-input-digit-${(idx + 1).toString()}-label`}
        htmlFor={`code-digit-${(idx + 1).toString()}`}
        sx={{
          position: 'absolute',
          left: '-10000px',
          top: 'auto',
          width: '1px',
          height: '1px',
          overflow: 'hidden',
        }}
      >
        {/* Label text */}
        {t('ns.verification_code:digit', {
          number: (idx + 1).toString(),
        })}
      </InputLabel>
      <OutlinedInput
        disabled={disabled}
        error={errors.includes(idx)}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        value={value}
        name={`code-digit-${(idx + 1).toString()}`}
        id={`code-digit-${(idx + 1).toString()}`}
        inputRef={inputRef}
        onChange={onChange}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
          'data-testid': `single-otp-input-${idx + 1}`,
          autoComplete: idx === 0 ? 'one-time-code' : undefined,
        }}
        sx={{
          '& input': {
            textAlign: 'center',
          },
        }}
      />
    </FormControl>
  );
};

export default memo(SingleOTPInputComponent);
