import React from 'react';
import { ToggleButton } from '@mui/material';

interface QuickFilterButtonProps {
  displayName: string;
  filterKey: string;
  value: boolean;
  onClick: () => void;
  ariaLabel?: string;
  fullHeight?: boolean;
}

/**
 * Accessible quick filter button, should be wrapped in a ToggleButtonGroup
 * @param displayName - text to be displayed on the button
 * @param filterKey - key that will be appended to the data-testid like so `quick-filter-${filterKey}`
 * @param value - boolean indication of whether the item is selected or not
 * @param onClick - on click handler
 * @param ariaLabel - optional aria label, displayName used if not provided
 * @param fullHeight - optional boolean, if true the button will take 100% height of the parent
 */
const QuickFilterButton: React.FC<React.PropsWithChildren<QuickFilterButtonProps>> = ({
  displayName,
  filterKey,
  value,
  onClick,
  ariaLabel,
  fullHeight,
}: QuickFilterButtonProps) => {
  return (
    <ToggleButton
      size="small"
      color="primary"
      onClick={() => onClick()}
      data-testid={`quick-filter-${filterKey}`}
      selected={value}
      value={value}
      aria-pressed={value}
      aria-label={ariaLabel || displayName}
      sx={{ mr: 2, mb: 0, whiteSpace: 'nowrap', minWidth: 'max-content', height: fullHeight ? '100%' : 'auto' }}
      disableRipple
    >
      {displayName}
    </ToggleButton>
  );
};

export { QuickFilterButton };
