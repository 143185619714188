import React from 'react';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
  CognitoUserPool,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js';
import { flushSync } from 'react-dom';
import { filterReadOnlyAttributes } from './filterAttributes';
import { AuthAction, AuthResult, CognitoError } from './types';
import { wrapError } from '.';

/**
 * Attempt to sign in with user credentials or temporary password from invite
 *
 * @param username    - user's username, email address
 * @param password    - user's password
 * @param dispatch    - reference to the auth state reducer dispatch method
 * @param userPool    - userPool object
 * @param newPassword - (optional) resets temporary password with new password if invite
 * @param attributes  - (optional) update the user's attributes when creating account from invite
 * @returns             Promise with auth result
 */
export const cognitoSignIn = (
  username: string,
  password: string,
  dispatch: React.Dispatch<AuthAction>,
  userPool: CognitoUserPool,
  newPassword?: string,
  attributes?: CognitoUserAttribute[],
): Promise<AuthResult> => {
  dispatch({ type: 'setFeedback', payload: 'signing in using credentials' });
  if (username !== '' && password !== '') {
    const user = new CognitoUser({
      Pool: userPool,
      Username: username,
    });
    const submittedCredentials = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    return new Promise((resolve, reject) => {
      user.authenticateUser(submittedCredentials, {
        onSuccess: (session: CognitoUserSession) => {
          user.setSignInUserSession(session);

          /* 
          With React 18 Automatic Batching was introduced.
          This causes /app/verify-number route to be skipped when going through the sign in flow
          flushSync is used to force the state update to be synchronous
          */
          flushSync(() => {
            dispatch({
              type: 'authenticateUser',
              payload: {
                cognitoUser: user,
                cognitoUserName: user.getUsername(),
                feedbackMessage: 'signed in',
              },
            });
          });
          return resolve({ complete: true, error: undefined, message: 'signed in' });
        },
        newPasswordRequired: (userAttributes: Record<string, unknown>) => {
          const filteredAttributes = filterReadOnlyAttributes(userAttributes);

          // triggered if the user was created with a temporary password
          // store this in state for use by the update temporary password challenge
          user.completeNewPasswordChallenge(newPassword, filteredAttributes, {
            onSuccess: (session: CognitoUserSession) => {
              user.setSignInUserSession(session);

              user.updateAttributes(attributes, (err: CognitoError, result) => {
                if (err) {
                  dispatch({ type: 'setFeedback', payload: `${err.message} (${err.code})` });
                  const fallbackErrMessage = 'Error occurred while setting attribute(s)';
                  return reject({
                    complete: false,
                    error: wrapError(err, fallbackErrMessage),
                    message: 'Error: occurred while setting attribute(s)',
                  });
                }

                dispatch({
                  type: 'authenticateUser',
                  payload: {
                    cognitoUser: user,
                    cognitoUserName: user.getUsername(),
                    feedbackMessage: `attributes set: ${JSON.stringify(result)}`,
                  },
                });

                return resolve({
                  complete: true,
                  message: 'Success: attribute(s) updated',
                });
              });
            },
            onFailure: (err: CognitoError) => {
              const fallbackErrMessage = 'Failed signing up user from invite';
              return reject({
                complete: false,
                error: wrapError(err, fallbackErrMessage),
                message: err.message,
              });
            },
          });
        },
        onFailure: (err: CognitoError) => {
          // handle forced password reset here 'PasswordResetRequiredException'
          dispatch({ type: 'setFeedback', payload: `${err.message} (${err.code})` });
          const fallbackErrMessage = 'Failed authenticating user';
          return reject({
            complete: true,
            error: wrapError(err, fallbackErrMessage),
            message: err.message,
          });
        },
      });
    });
  } else {
    dispatch({ type: 'setFeedback', payload: `submit missing credentials` });
    return Promise.reject({
      complete: true,
      error: wrapError(`MissingCredentials: Missing Credentials`),
      message: 'Missing Credential',
    });
  }
};
