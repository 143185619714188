import { AxiosResponse } from 'axios';
import { QueryFunction } from 'react-query';
import { API } from './api';

// Define a default query function that will receive the query key
const defaultQueryFn: QueryFunction<unknown, string> = async ({ queryKey }): Promise<AxiosResponse['data']> => {
  return API().get({ path: queryKey[0] });
};

export const clientConfig = {
  defaultOptions: {
    queries: {
      cacheTime: 0,
      queryFn: defaultQueryFn,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
};
