import React from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import { SlaCurrentStatusReadOnly } from '@askporter/client-grieg-lyric';
import { Icon, IconSize } from '@askporter/component-library';
import { slaIcons } from '@askporter/utils';

interface SlaStatusCheckboxLabelProps {
  label: string;
  status: Exclude<SlaCurrentStatusReadOnly, 'NA'>;
}

export const SlaStatusCheckboxLabel: React.FC<React.PropsWithChildren<SlaStatusCheckboxLabelProps>> = ({
  label,
  status,
}) => {
  const { palette } = useTheme();

  const iconColor = {
    [SlaCurrentStatusReadOnly.Running]: palette.primary.main,
    [SlaCurrentStatusReadOnly.Paused]: palette.warning.main,
    [SlaCurrentStatusReadOnly.CompletedOnTime]: palette.success.main,
    [SlaCurrentStatusReadOnly.CompletedLate]: palette.success.main,
    [SlaCurrentStatusReadOnly.Overdue]: palette.error.main,
  };

  return (
    <Stack direction="row" spacing={2}>
      <Icon size={IconSize.MD} fillColor={iconColor[status]} folder={slaIcons[status]} />
      <Typography paddingRight={4}>{label}</Typography>
    </Stack>
  );
};
