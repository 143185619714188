import { SelectItem } from '../types';

/**
 * Generates an array of list items that can be passed into a select dropdown
 * @param arrOfListObj array of objects, the objects should represent a list item
 * @param valueKey the object key that can be used to access the item value (not displayed to the user)
 * @param labelKey either a function that will be applied to the "value", or an object key that can be used to access
 * the label value (displayed to the user)
 * @param includeEmptyValue optional parameter that can be used to display an empty string, this parameter represents
 * the value associated with the empty string
 *
 * @returns array of SelectItem objects
 */
export const generateDropDownList = <ObjType>(
  arrOfListObj: Array<Record<string, unknown> | ObjType>,
  valueKey: string,
  labelKey: string | ((value: string) => string),
  includeEmptyValue?: string,
): Array<SelectItem<string | number | readonly string[]>> => {
  const list: Array<SelectItem<string | number | readonly string[]>> = arrOfListObj?.map(
    (item: Record<string, unknown>, idx: number) => {
      const hidden = item?.hideFromSelection ? true : false;
      if (item[valueKey]) {
        try {
          const value = item[valueKey].toString();
          const label = typeof labelKey === 'string' ? item[labelKey].toString() : labelKey(value);

          return { value, label, hidden };
        } catch (err) {
          // potentially one to log in the future, for now just allowing to carry on to the empty returns
        }
      }

      // if a valid return hasn't occurred at this point return a blank label with the index as value
      return { value: idx, label: '', hidden };
    },
  );

  if (includeEmptyValue) list.push({ value: includeEmptyValue, label: '', hidden: false });

  return list;
};
