import React from 'react';
import { ThemeOptions } from '@mui/material';
import '@mui/material/Button';
import '@mui/material/Chip';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { TenantDisplayPalette } from '@askporter/grieg-types';

/* To augment a module, it must be imported
/* https://github.com/Microsoft/TypeScript-Handbook/blob/fa9e2be1024014fe923d44b1b69d315e8347e444/pages/Declaration%20Merging.md#module-augmentation
 */

// for the new 'unstyled' variant
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    unstyled: true;
    textLinked: true;
  }
}

// for the new 'text' variant
declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    text: true;
  }
}

// Set up react router global theme links https://next.material-ui.com/guides/routing/#global-theme-link
interface LinkBehaviorProps {
  href: string;
  children: React.ReactNode;
  rel?: string;
}

const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  (props: LinkBehaviorProps, ref) => {
    const { href, children, rel, ...other } = props;

    // If external link use an anchor tag instead because react router
    // does not understand them and preprends a back slash e.g. '/https://...'
    // See here for more details: https://github.com/ReactTraining/react-router/issues/1147
    if (/^https?:\/\//i.test(href) || rel === 'external')
      return (
        <a ref={ref} href={href} {...other}>
          {children}
        </a>
      );

    // Map href (Material-UI) -> to (react-router)
    return (
      <RouterLink ref={ref} to={href} {...other}>
        {children}
      </RouterLink>
    );
  },
);

LinkBehavior.displayName = 'LinkBehaviourReactRouter';

type Components = Pick<ThemeOptions, 'components'>;

export const customComponents = (c: TenantDisplayPalette): Components => {
  return {
    components: {
      MuiChip: {
        variants: [
          {
            props: { variant: 'text' },
            style: {
              padding: 0,
              background: 'transparent',
            },
          },
        ],
        styleOverrides: {
          root: {
            fontWeight: 400,
            fontSize: '0.813rem',
            lineHeight: '1.125rem',
            letterSpacing: '0.01rem',
            padding: '0 0.5rem',
            '& span': { padding: 0 },
            '& .MuiChip-icon': {
              margin: 0,
              paddingRight: '0.219rem',
            },
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
      MuiLink: {
        defaultProps: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore in line with https://github.com/mui-org/material-ui/blob/25acfab1471080223e308cb0d337dae3832f740f/docs/src/pages/guides/routing/LinkRouterWithTheme.tsx#L21-L25
          component: LinkBehavior,
          // the default has now changed to 'always'
          underline: 'hover',
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            color: c.primaryMain,
            borderColor: c.primaryMain,
            '&.Mui-selected': {
              color: c.commonWhite,
              backgroundColor: c.primaryMain,
              '&:hover': {
                backgroundColor: c.primaryDark,
              },
            },
          },
        },
      },
      MuiCardActionArea: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            '&:hover': { background: `${c.primaryMain.substring(0, 7)}14` },
            '&:active': { background: `${c.actionSelected.substring(0, 7)}14` },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
          disableFocusRipple: true,
          disableRipple: true,
        },
        variants: [
          {
            props: { variant: 'unstyled' },
            style: {
              height: 'auto',
              padding: 0,
              background: 'none',
              '&:hover': { background: 'none' },
            },
          },
          {
            props: { variant: 'textLinked' },
            style: {
              padding: 0,
              justifyContent: 'start',
              color: c.primaryMain,
              '&:hover': { background: 'none' },
            },
          },
        ],
        styleOverrides: {
          sizeLarge: {
            fontWeight: 500,
            fontSize: '1rem',
            lineHeight: '1.625rem',
            letterSpacing: '.0288rem',
          },
          sizeMedium: {
            fontWeight: 500,
            fontSize: '.875rem',
            lineHeight: '1.5rem',
            letterSpacing: '.025rem',
          },
          sizeSmall: {
            fontWeight: 500,
            fontSize: '.75rem',
            lineHeight: '1.375rem',
            letterSpacing: '.0288rem',
            height: '32px',
          },
          root: {
            textTransform: 'none',
            // $focus
            '&.Mui-focusVisible': {
              border: '2px solid #0d47a1',
            },
            // Action/b. Hover
            '&:hover': {
              // backgroundColor: c.actionHover,
            },
            // Action/c. Disabled
            '&.Mui-disabled': {
              color: c.textDisabled,
            },
            height: '48px',
            py: '14px',
          },
          /* Styles applied to the icon element if supplied and `size="small"`. */
          iconSizeSmall: {
            '& > svg:first-of-type': {
              fontSize: 16,
            },
          },
          /* Styles applied to the icon element if supplied and `size="medium"`. */
          iconSizeMedium: {
            '& > svg:first-of-type': {
              fontSize: 24,
            },
          },
          /* Styles applied to the icon element if supplied and `size="large"`. */
          iconSizeLarge: {
            '& > svg:first-of-type': {
              fontSize: 32,
            },
          },
          containedPrimary: {
            border: '1px solid inherit',
          },
          containedSecondary: {
            border: '1px solid inherit',
          },
        },
      },
      MuiFormControl: {
        defaultProps: {
          margin: 'normal',
        },
      },
      MuiFilledInput: {
        // link: https://share.goabstract.com/3dbc4b96-71f0-43bd-9df2-e696113013e2
        // version: https://share.goabstract.com/3dbc4b96-71f0-43bd-9df2-e696113013e2?sha=9e24644c6281c787ad934ac2d0c9cbef177feaa6
        // Filled: view only
        variants: [
          {
            props: { readOnly: true },
            style: {
              backgroundColor: '#fafafa',
              pointerEvents: 'none',
              borderRadius: '2px 2px 1px 1px',
              '&&.Mui-focused': {
                backgroundColor: '#fafafa',
              },
              '&&&:before': {
                borderBottom: 'none',
              },
              '&&&:after': {
                border: 'none',
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            // :enabled
            backgroundColor: c.backgroundDefault,
            borderRadius: '4px 4px 1px 1px',
            '&:hover': {
              backgroundColor: c.actionHover,
            },
            // :focused
            '&&.Mui-focused': {
              borderRadius: '4px',
              backgroundColor: c.actionFocus,
              '&:before': {
                borderBottom: 'none',
              },
              '&:after': {
                borderRadius: '4px',
                border: `2px solid ${c.primaryMain}`,
                height: '100%',
              },
            },
            // :active
            '&:active': {
              '&&&:after': {
                borderRadius: '4px 4px 0px 0px',
                border: `solid ${c.primaryMain}`,
                borderWidth: '0 0 2px',
                height: '100%',
              },
            },
            // :disabled
            '&.Mui-disabled': {
              borderRadius: '2px 2px 1px 1px',
              backgroundColor: c.actionDisabledBackground,
              color: c.textDisabled,
              '&:before': {
                borderBottom: 'none',
              },
            },
          },
        },
      },
      MuiTooltip: {
        // Palette/Other/c. Tooltip background, box shadow and color
        styleOverrides: {
          tooltip: {
            backgroundColor: '#707070',
            color: 'white',
            fontWeight: 500,
            fontSize: '.625rem',
            lineHeight: '.875rem',
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            '&.MuiAppBar-colorDefault': {
              // Mui has common.black and common.white colours
              // defined in the palette. We don't override these
              backgroundColor: c.commonWhite,
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: c.textPrimary,
            '&.Mui-selected': { color: c.textPrimary, padding: 0, fontWeight: 600 },
            padding: 0,
            marginRight: '0.5rem',
            textTransform: 'none',
            minWidth: '70px',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: '1.5rem',
            letterSpacing: '.0094rem',
            /** hide the edge browser reveal and clear icons (we adorn our own icons) */
            'input::-ms-reveal, input::-ms-clear': {
              display: 'none',
            },
          },
        },
      },
      // Requires further exploration
      // MuiInputLabel: {
      //   styleOverrides: {
      //     root: {
      //       fontWeight: 400,
      //       fontSize: '.75rem',
      //       lineHeight: '.75rem',
      //       letterSpacing: '.0094rem',
      //     },
      //   },
      // },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontWeight: 400,
            fontSize: '.75rem',
            lineHeight: '1.25rem',
            letterSpacing: '.025rem',
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            fontWeight: 400,
            fontSize: '1.25rem',
            lineHeight: '1.25rem',
            letterSpacing: '.0088rem',
          },
        },
      },
      MuiAlertTitle: {
        styleOverrides: {
          root: {
            fontWeight: 500,
            fontSize: '1rem',
            lineHeight: '150%',
            letterSpacing: '.0094rem',
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            '& .MuiTableCell-root': {
              fontWeight: 500,
              fontSize: '.875rem',
              lineHeight: '1.5rem',
              letterSpacing: '.0106rem',
            },
          },
        },
      },

      MuiSelect: {
        styleOverrides: {
          select: {
            '&.MuiFilledInput-input:focus': { backgroundColor: 'transparent' },
          },
        },
      },
    },
  };
};
