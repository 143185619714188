type CountryTranslation = Record<string, Record<string, string>>;

export const countryTranslation: CountryTranslation = {
  en: {
    ac: 'Ascension Island',
    ad: 'Andorra',
    ae: 'United Arab Emirates',
    af: 'Afghanistan',
    ag: 'Antigua and Barbuda',
    ai: 'Anguilla',
    al: 'Albania',
    am: 'Armenia',
    ao: 'Angola',
    aq: 'Antarctica',
    ar: 'Argentina',
    as: 'American Samoa',
    at: 'Austria',
    au: 'Australia',
    aw: 'Aruba',
    ax: 'Åland Islands',
    az: 'Azerbaijan',
    ba: 'Bosnia and Herzegovina',
    bb: 'Barbados',
    bd: 'Bangladesh',
    be: 'Belgium',
    bf: 'Burkina Faso',
    bg: 'Bulgaria',
    bh: 'Bahrain',
    bi: 'Burundi',
    bj: 'Benin',
    bl: 'Saint Barthélemy',
    bm: 'Bermuda',
    bn: 'Brunei Darussalam',
    bo: 'Bolivia, plurinational state of',
    bq: 'Bonaire, Sint Eustatius and Saba',
    br: 'Brazil',
    bs: 'Bahamas',
    bt: 'Bhutan',
    bv: 'Bouvet Island',
    bw: 'Botswana',
    by: 'Belarus',
    bz: 'Belize',
    ca: 'Canada',
    cc: 'Cocos (Keeling) Islands',
    cd: 'Congo, Democratic Republic of the',
    cf: 'Central African Republic',
    cg: 'Congo',
    ch: 'Switzerland',
    ci: "Cote d'Ivoire",
    ck: 'Cook Islands',
    cl: 'Chile',
    cm: 'Cameroon',
    cn: 'China',
    co: 'Colombia',
    cr: 'Costa Rica',
    cu: 'Cuba',
    cv: 'Cape Verde',
    cw: 'Curaçao',
    cx: 'Christmas Island',
    cy: 'Cyprus',
    cz: 'Czech Republic',
    de: 'Germany',
    dj: 'Djibouti',
    dk: 'Denmark',
    dm: 'Dominica',
    do: 'Dominican Republic',
    dz: 'Algeria',
    ec: 'Ecuador',
    ee: 'Estonia',
    eg: 'Egypt',
    eh: 'Western Sahara',
    er: 'Eritrea',
    es: 'Spain',
    et: 'Ethiopia',
    fi: 'Finland',
    fj: 'Fiji',
    fk: 'Falkland Islands (Malvinas)',
    fm: 'Micronesia, Federated States of',
    fo: 'Faroe Islands',
    fr: 'France',
    ga: 'Gabon',
    gb: 'United Kingdom',
    gd: 'Grenada',
    ge: 'Georgia',
    gf: 'French Guiana',
    gg: 'Guernsey',
    gh: 'Ghana',
    gi: 'Gibraltar',
    gl: 'Greenland',
    gm: 'Gambia',
    gn: 'Guinea',
    gp: 'Guadeloupe',
    gq: 'Equatorial Guinea',
    gr: 'Greece',
    gs: 'South Georgia and the South Sandwich Islands',
    gt: 'Guatemala',
    gu: 'Guam',
    gw: 'Guinea-Bissau',
    gy: 'Guyana',
    hk: 'Hong Kong',
    hm: 'Heard Island and McDonald Islands',
    hn: 'Honduras',
    hr: 'Croatia',
    ht: 'Haiti',
    hu: 'Hungary',
    id: 'Indonesia',
    ie: 'Ireland',
    il: 'Israel',
    im: 'Isle of Man',
    in: 'India',
    io: 'British Indian Ocean Territory',
    iq: 'Iraq',
    ir: 'Iran, Islamic Republic of',
    is: 'Iceland',
    it: 'Italy',
    je: 'Jersey',
    jm: 'Jamaica',
    jo: 'Jordan',
    jp: 'Japan',
    ke: 'Kenya',
    kg: 'Kyrgyzstan',
    kh: 'Cambodia',
    ki: 'Kiribati',
    km: 'Comoros',
    kn: 'Saint Kitts and Nevis',
    kp: "Korea, Democratic People's republic of",
    kr: 'Korea, Republic of',
    kw: 'Kuwait',
    ky: 'Cayman Islands',
    kz: 'Kazakhstan',
    la: "Lao People's Democratic Republic",
    lb: 'Lebanon',
    lc: 'Saint Lucia',
    li: 'Liechtenstein',
    lk: 'Sri Lanka',
    lr: 'Liberia',
    ls: 'Lesotho',
    lt: 'Lithuania',
    lu: 'Luxembourg',
    lv: 'Latvia',
    ly: 'Libyan Arab Jamahiriya',
    ma: 'Morocco',
    mc: 'Monaco',
    md: 'Moldova',
    me: 'Montenegro',
    mf: 'Saint Martin (French Part)',
    mg: 'Madagascar',
    mh: 'Marshall Islands',
    mk: 'Macedonia, The Former Yugoslav Republic Of',
    ml: 'Mali',
    mm: 'Burma',
    mn: 'Mongolia',
    mo: 'Macao',
    mp: 'Northern Mariana Islands',
    mq: 'Martinique',
    mr: 'Mauritania',
    ms: 'Montserrat',
    mt: 'Malta',
    mu: 'Mauritius',
    mv: 'Maldives',
    mw: 'Malawi',
    mx: 'Mexico',
    my: 'Malaysia',
    mz: 'Mozambique',
    na: 'Namibia',
    nc: 'New Caledonia',
    ne: 'Niger',
    nf: 'Norfolk Island',
    ng: 'Nigeria',
    ni: 'Nicaragua',
    nl: 'Netherlands',
    no: 'Norway',
    np: 'Nepal',
    nr: 'Nauru',
    nu: 'Niue',
    nz: 'New Zealand',
    om: 'Oman',
    os: 'South Ossetia',
    pa: 'Panama',
    pe: 'Peru',
    pf: 'French Polynesia',
    pg: 'Papua New Guinea',
    ph: 'Philippines',
    pk: 'Pakistan',
    pl: 'Poland',
    pm: 'Saint Pierre and Miquelon',
    pn: 'Pitcairn',
    pr: 'Puerto Rico',
    ps: 'Palestinian Territory, Occupied',
    pt: 'Portugal',
    pw: 'Palau',
    py: 'Paraguay',
    qa: 'Qatar',
    re: 'Reunion',
    ro: 'Romania',
    rs: 'Serbia',
    ru: 'Russian Federation',
    rw: 'Rwanda',
    sa: 'Saudi Arabia',
    sb: 'Solomon Islands',
    sc: 'Seychelles',
    sd: 'Sudan',
    se: 'Sweden',
    sg: 'Singapore',
    sh: 'Saint Helena, Ascension And Tristan Da Cunha',
    si: 'Slovenia',
    sj: 'Svalbard and Jan Mayen',
    sk: 'Slovakia',
    sl: 'Sierra Leone',
    sm: 'San Marino',
    sn: 'Senegal',
    so: 'Somalia',
    sr: 'Suriname',
    ss: 'South Sudan',
    st: 'Sao Tome and Principe',
    sv: 'El Salvador',
    sx: 'Sint Maarten',
    sy: 'Syrian Arab Republic',
    sz: 'Swaziland',
    ta: 'Tristan da Cunha',
    tc: 'Turks and Caicos Islands',
    td: 'Chad',
    tf: 'French Southern Territories',
    tg: 'Togo',
    th: 'Thailand',
    tj: 'Tajikistan',
    tk: 'Tokelau',
    tl: 'Timor-Leste',
    tm: 'Turkmenistan',
    tn: 'Tunisia',
    to: 'Tonga',
    tr: 'Turkey',
    tt: 'Trinidad and Tobago',
    tv: 'Tuvalu',
    tw: 'Taiwan, Province of China',
    tz: 'Tanzania, United Republic Of',
    ua: 'Ukraine',
    ug: 'Uganda',
    um: 'United States Minor Outlying Islands',
    us: 'United States',
    uy: 'Uruguay',
    uz: 'Uzbekistan',
    va: 'Holy See (Vatican City State)',
    vc: 'Saint Vincent and the Grenadines',
    ve: 'Venezuela',
    vg: 'Virgin Islands, British',
    vi: 'Virgin Islands, U.S.',
    vn: 'Vietnam',
    vu: 'Vanuatu',
    wf: 'Wallis and Futuna',
    ws: 'Samoa',
    xk: 'Kosovo',
    ye: 'Yemen',
    yt: 'Mayotte',
    za: 'South Africa',
    zm: 'Zambia',
    zw: 'Zimbabwe',
    zz: 'International',
  },
  de: {
    ab: 'Abchasien',
    ac: 'Ascension',
    ad: 'Andorra',
    ae: 'Vereinigte Arabische Emirate',
    af: 'Afghanistan',
    ag: 'Antigua und Barbuda',
    ai: 'Anguilla',
    al: 'Albanien',
    am: 'Armenien',
    ao: 'Angola',
    aq: 'Antarktis',
    ar: 'Argentinien',
    as: 'Amerikanisch-Samoa',
    at: 'Österreich',
    au: 'Australien',
    aw: 'Aruba',
    ax: 'Åland-Inseln',
    az: 'Aserbaidschan',
    ba: 'Bosnien und Herzegowina',
    bb: 'Barbados',
    bd: 'Bangladesch',
    be: 'Belgien',
    bf: 'Burkina Faso',
    bg: 'Bulgarien',
    bh: 'Bahrain',
    bi: 'Burundi',
    bj: 'Benin',
    bl: 'St. Barthélemy',
    bm: 'Bermuda',
    bn: 'Brunei',
    bo: 'Bolivien',
    bq: 'Bonaire, Sint Eustatius und Saba',
    br: 'Brasilien',
    bs: 'Bahamas',
    bt: 'Bhutan',
    bv: 'Bouvetinsel',
    bw: 'Botswana',
    by: 'Weißrussland',
    bz: 'Belize',
    ca: 'Kanada',
    cc: 'Kokosinseln',
    cd: 'Kongo (DR)',
    cf: 'Zentralafrikanische Republik',
    cg: 'Kongo (Republik)',
    ch: 'Schweiz',
    ci: 'Elfenbeinküste',
    ck: 'Cookinseln',
    cl: 'Chile',
    cm: 'Kamerun',
    cn: 'China',
    co: 'Kolumbien',
    cr: 'Costa Rica',
    cu: 'Kuba',
    cv: 'Kap Verde',
    cw: 'Curaçao',
    cx: 'Weihnachtsinsel',
    cy: 'Zypern',
    cz: 'Tschechische Republik',
    de: 'Deutschland',
    dj: 'Dschibuti',
    dk: 'Dänemark',
    dm: 'Dominica',
    do: 'Dominikanische Republik',
    dz: 'Algerien',
    ec: 'Ecuador',
    ee: 'Estland',
    eg: 'Ägypten',
    eh: 'Westsahara',
    er: 'Eritrea',
    es: 'Spanien',
    et: 'Äthiopien',
    fi: 'Finnland',
    fj: 'Fidschi',
    fk: 'Falklandinseln',
    fm: 'Mikronesien',
    fo: 'Färöer',
    fr: 'Frankreich',
    ga: 'Gabun',
    gb: 'Vereinigtes Königreich',
    gd: 'Grenada',
    ge: 'Georgien',
    gf: 'Französisch-Guayana',
    gg: 'Guernsey',
    gh: 'Ghana',
    gi: 'Gibraltar',
    gl: 'Grönland',
    gm: 'Gambia',
    gn: 'Guinea',
    gp: 'Guadeloupe',
    gq: 'Äquatorialguinea',
    gr: 'Griechenland',
    gs: 'Südgeorgien und die Südlichen Sandwichinseln',
    gt: 'Guatemala',
    gu: 'Guam',
    gw: 'Guinea-Bissau',
    gy: 'Guyana',
    hk: 'Hongkong',
    hm: 'Heard und McDoncaldinseln',
    hn: 'Honduras',
    hr: 'Kroatien',
    ht: 'Haiti',
    hu: 'Ungarn',
    id: 'Indonesien',
    ie: 'Irland',
    il: 'Israel',
    im: 'Isle of Man',
    in: 'Indien',
    io: 'Britisches Territorium im Indischen Ozean',
    iq: 'Irak',
    ir: 'Iran',
    is: 'Island',
    it: 'Italien',
    je: 'Jersey',
    jm: 'Jamaika',
    jo: 'Jordanien',
    jp: 'Japan',
    ke: 'Kenia',
    kg: 'Kirgistan',
    kh: 'Kambodscha',
    ki: 'Kiribati',
    km: 'Komoren',
    kn: 'St. Kitts und Nevis',
    kp: 'Nordkorea',
    kr: 'Südkorea',
    kw: 'Kuwait',
    ky: 'Caymaninseln',
    kz: 'Kasachstan',
    la: 'Laos',
    lb: 'Libanon',
    lc: 'St. Lucia',
    li: 'Liechtenstein',
    lk: 'Sri Lanka',
    lr: 'Liberia',
    ls: 'Lesotho',
    lt: 'Litauen',
    lu: 'Luxemburg',
    lv: 'Lettland',
    ly: 'Libyen',
    ma: 'Marokko',
    mc: 'Monaco',
    md: 'Moldau',
    me: 'Montenegro',
    mf: 'St. Martin',
    mg: 'Madagaskar',
    mh: 'Marshallinseln',
    mk: 'Nordmazedonien',
    ml: 'Mali',
    mm: 'Myanmar',
    mn: 'Mongolei',
    mo: 'Sonderverwaltungsregion Macau',
    mp: 'Nördliche Marianen',
    mq: 'Martinique',
    mr: 'Mauretanien',
    ms: 'Montserrat',
    mt: 'Malta',
    mu: 'Mauritius',
    mv: 'Malediven',
    mw: 'Malawi',
    mx: 'Mexiko',
    my: 'Malaysia',
    mz: 'Mosambik',
    na: 'Namibia',
    nc: 'Neukaledonien',
    ne: 'Niger',
    nf: 'Norfolkinsel',
    ng: 'Nigeria',
    ni: 'Nicaragua',
    nl: 'Niederlande',
    no: 'Norwegen',
    np: 'Nepal',
    nr: 'Nauru',
    nu: 'Niue',
    nz: 'Neuseeland',
    om: 'Oman',
    os: 'South Ossetia',
    pa: 'Panama',
    pe: 'Peru',
    pf: 'Französisch-Polynesien',
    pg: 'Papua-Neuguinea',
    ph: 'Philippinen',
    pk: 'Pakistan',
    pl: 'Polen',
    pm: 'Saint-Pierre und Miquelon',
    pn: 'Pitcairninseln',
    pr: 'Puerto Rico',
    ps: 'Palästina',
    pt: 'Portugal',
    pw: 'Palau',
    py: 'Paraguay',
    qa: 'Katar',
    re: 'Réunion',
    ro: 'Rumänien',
    rs: 'Serbien',
    ru: 'Russland',
    rw: 'Ruanda',
    sa: 'Saudi-Arabien',
    sb: 'Salomoninseln',
    sc: 'Seychellen',
    sd: 'Sudan',
    se: 'Schweden',
    sg: 'Singapur',
    sh: 'St. Helena',
    si: 'Slowenien',
    sj: 'Spitzbergen und Jan Mayen',
    sk: 'Slowakei',
    sl: 'Sierra Leone',
    sm: 'San Marino',
    sn: 'Senegal',
    so: 'Somalia',
    sr: 'Suriname',
    ss: 'Südsudan',
    st: 'São Tomé und Príncipe',
    sv: 'El Salvador',
    sx: 'Sint Maarten',
    sy: 'Syrien',
    sz: 'Swasiland',
    ta: 'Tristan da Cunha',
    tc: 'Turks- und Caicosinseln',
    td: 'Tschad',
    tf: 'Französische Süd- und Antarktisgebiete',
    tg: 'Togo',
    th: 'Thailand',
    tj: 'Tadschikistan',
    tk: 'Tokelau',
    tl: 'Osttimor',
    tm: 'Turkmenistan',
    tn: 'Tunesien',
    to: 'Tonga',
    tr: 'Türkei',
    tt: 'Trinidad und Tobago',
    tv: 'Tuvalu',
    tw: 'Taiwan',
    tz: 'Tansania',
    ua: 'Ukraine',
    ug: 'Uganda',
    um: 'Amerikanisch-Ozeanien',
    us: 'Vereinigte Staaten von Amerika',
    uy: 'Uruguay',
    uz: 'Usbekistan',
    va: 'Vatikanstadt',
    vc: 'St. Vincent und die Grenadinen',
    ve: 'Venezuela',
    vg: 'Britische Jungferninseln',
    vi: 'Amerikanische Jungferninseln',
    vn: 'Vietnam',
    vu: 'Vanuatu',
    wf: 'Wallis und Futuna',
    ws: 'Samoa',
    xk: 'Kosovo',
    ye: 'Jemen',
    yt: 'Mayotte',
    za: 'Südafrika',
    zm: 'Sambia',
    zw: 'Simbabwe',
    zz: 'International',
  },
};
