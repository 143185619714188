/**
 * Strips off the non-writable Cognito attributes from the passed object
 *
 * @param userAttributes - an object with user attributes
 * @returns Object
 */
export const filterReadOnlyAttributes = (userAttributes: Record<string, unknown>): Record<string, unknown> => {
  // see https://github.com/askporter/front-end/issues/561
  const writableAttributes = ['email', 'family_name', 'given_name', 'phone_number'];
  return Object.keys(userAttributes).reduce((prev, curr) => {
    if (writableAttributes.includes(curr)) {
      return { ...prev, [curr]: userAttributes[curr] };
    }
    return prev;
  }, {});
};
