import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { Chip } from '@askporter/component-library';
import { TFunction } from '@askporter/config-provider';
import { ExportStatus } from '../../../../../types';

interface ExportStatusChipProps {
  t: TFunction;
  status: ExportStatus;
}

/**
 * The chip for the export status.
 */
export const ExportStatusChip: React.FC<ExportStatusChipProps> = ({ t, status }: ExportStatusChipProps) => {
  return (
    <Chip
      icon={<CircleIcon fontSize="inherit" sx={{ color: 'inherit' }} />}
      label={t(`export:export_list:table:status_chip:${status.toLowerCase()}`)}
      color={status === 'COMPLETED' ? 'success' : status === 'PENDING' ? undefined : 'error'}
    />
  );
};
