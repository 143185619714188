import { SearchAssetFilter } from './SearchAssetFilter';
import { SearchAssetSort } from './SearchAssetSort';
import { SearchFreeText } from './SearchFreeText';
import { TimezoneName } from './TimezoneName';

export interface AssetExportCreate {
  name: string;
  timezone: TimezoneName;
  freeText?: SearchFreeText;
  format: AssetExportCreate_FormatEnum;
  fields?: Array<AssetExportCreate_FieldsEnum>;
  filter?: SearchAssetFilter;
  sort?: SearchAssetSort;
}

export type AssetExportCreate_FormatEnum = 'STANDARD' | 'EXCEL_COMPATIBLE';

export const AssetExportCreate_FormatEnum = {
  STANDARD: 'STANDARD' as AssetExportCreate_FormatEnum,
  EXCELCOMPATIBLE: 'EXCEL_COMPATIBLE' as AssetExportCreate_FormatEnum,
};

export type AssetExportCreate_FieldsEnum =
  | 'ASSET_DETAILS'
  | 'ASSET_SETTINGS'
  | 'REGISTRATION_TOKEN'
  | 'ONSITE_ASSISTANT';

export const AssetExportCreate_FieldsEnum = {
  ASSETDETAILS: 'ASSET_DETAILS' as AssetExportCreate_FieldsEnum,
  ASSETSETTINGS: 'ASSET_SETTINGS' as AssetExportCreate_FieldsEnum,
  REGISTRATIONTOKEN: 'REGISTRATION_TOKEN' as AssetExportCreate_FieldsEnum,
  ONSITEASSISTANT: 'ONSITE_ASSISTANT' as AssetExportCreate_FieldsEnum,
};
