/* eslint-disable functional/immutable-data */
import { SearchElementDateRange } from '@askporter/grieg-types';

/**
 * a simple clean up function that handles removing null values
 */
export const cleanUpFilter = <T extends Record<string, any>>(filter: T): Partial<T> => {
  Object.keys(filter).forEach((key) => {
    if (filter[key] === null) {
      delete filter[key];
    }

    // ignore anything that isn't an object
    if (typeof filter[key] !== 'object' || Array.isArray(filter[key])) return;

    const objectFilter = filter[key] as Record<string, unknown>;

    if ('before' in objectFilter && objectFilter.before === null) {
      delete (filter[key] as SearchElementDateRange)['before'];
    }
    if ('after' in objectFilter && objectFilter.after === null) {
      delete (filter[key] as SearchElementDateRange)['after'];
    }
  });

  return filter;
};
