import React from 'react';
import { FormControlLabel, SxProps, Theme } from '@mui/material';
import Radio from '@mui/material/Radio';

export interface RadioFilterItemProps {
  filterKey: string;
  label: string;
  value: 'true' | 'false' | 'none';
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  radioStyle?: SxProps<Theme>;
  labelPaddingRight?: number;
}

/**
 * Renders a Radio for use within a FilterSection within the RadioGroup
 * @param filterKey - the filter key (category of filters, i.e. Priority)
 * @param labelPlacement - the position of the label
 */
export const RadioFilterItem: React.FC<React.PropsWithChildren<RadioFilterItemProps>> = ({
  filterKey,
  label,
  value,
  labelPlacement = 'end',
  radioStyle = { mx: 1.75 },
  labelPaddingRight = 4,
}: RadioFilterItemProps) => {
  const labelStyles = {
    '.MuiFormControlLabel-label': { flexGrow: 1, overflow: 'hidden' },
    width: '100%',
    m: 0,
    pl: 0,
    pr: labelPaddingRight,
  };

  return (
    <FormControlLabel
      value={value}
      control={<Radio color="primary" sx={radioStyle} />}
      label={label}
      labelPlacement={labelPlacement}
      sx={labelStyles}
      data-testid={`${filterKey}-radio-${label}`}
    />
  );
};
