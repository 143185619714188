import { AllowedFileTypes } from '../types';

/**
 * Gets a file name and extension from a given file path
 * @param filePath - string file path, for example /somefolder/file1.png
 * @returns an object containing the file name and type, the above example that would be { name: file1, type: png }
 */
export const getFileDetails = (filePath: string): { name: string; type: AllowedFileTypes | null } => {
  if (!filePath) return { name: '', type: null };

  let name, type: AllowedFileTypes | null;
  const extensionIndex = filePath.lastIndexOf('.');
  const fileNameIndex = filePath.lastIndexOf('/');

  // if neither a slash or full stop are present return the filePath as name and type as empty
  if (extensionIndex + fileNameIndex === -2) return { name: filePath, type: null };

  try {
    name = filePath.slice(fileNameIndex + 1, extensionIndex === -1 ? filePath.length : extensionIndex);
  } catch (err) {
    name = '';
  }

  try {
    const extensionWithQuery = filePath.slice(extensionIndex + 1, filePath.length);
    const regexMatch = extensionWithQuery.match(/[^a-zA-Z0-9]/);
    const extension = regexMatch ? extensionWithQuery.slice(0, regexMatch.index) : extensionWithQuery;
    type = extensionIndex === -1 ? null : (extension.toLowerCase() as AllowedFileTypes);
  } catch (err) {
    type = null;
  }

  return { name, type };
};
