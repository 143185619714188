export type PermissionRole = 'SVCUSR' | 'SUPP' | 'MANAGER' | 'SVCUSR_SPR' | 'AGENT' | 'AGENT_SPR' | 'CLIENT_ADMIN' | 'PLAT_ADMIN' | 'OWNER' | 'OWNER_SPR' | 'SUPP_SPR';

export const PermissionRole = {
  SVCUSR: 'SVCUSR' as PermissionRole,
  SUPP: 'SUPP' as PermissionRole,
  MANAGER: 'MANAGER' as PermissionRole,
  SVCUSRSPR: 'SVCUSR_SPR' as PermissionRole,
  AGENT: 'AGENT' as PermissionRole,
  AGENTSPR: 'AGENT_SPR' as PermissionRole,
  CLIENTADMIN: 'CLIENT_ADMIN' as PermissionRole,
  PLATADMIN: 'PLAT_ADMIN' as PermissionRole,
  OWNER: 'OWNER' as PermissionRole,
  OWNERSPR: 'OWNER_SPR' as PermissionRole,
  SUPPSPR: 'SUPP_SPR' as PermissionRole,
};
