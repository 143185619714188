import React from 'react';
import { InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { utils } from '@rjsf/core';
import { CustomWidgetProps } from '../types';

const SelectWidget = ({
  id,
  options = {},
  label,
  disabled,
  readonly,
  value,
  onChange,
  onBlur,
  onFocus,
  schema,
  uiSchema,
  formContext: { onHasChanged },
}: CustomWidgetProps): JSX.Element => {
  const { enumOptions, emptyValue = '' } = options;

  const _onChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    onChange(value === '' ? emptyValue : value);
    setTimeout(() => onHasChanged({ id, value }), 0);
  };
  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onBlur(id, value);
  const _onFocus = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value);
  const displayLabel = utils.getDisplayLabel(schema, uiSchema);

  return (
    <>
      <InputLabel variant="filled" htmlFor={id}>
        {displayLabel && (label || schema.title)}
      </InputLabel>
      <Select
        id={id}
        data-testid="select-widget"
        label={displayLabel && (label || schema.title)}
        value={typeof value === 'undefined' ? emptyValue : value}
        disabled={disabled}
        readOnly={readonly}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
        variant="filled"
      >
        {(enumOptions as Array<{ value: string; label: string }>).map(({ value, label }) => {
          return (
            <MenuItem key={value} value={value} disabled={disabled || readonly}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

export default SelectWidget;
