export type RoleSlug = 'UNREG' | 'SVCUSR' | 'SVCUSR_SPR' | 'AGENT' | 'AGENT_SPR' | 'CLIENT_ADMIN' | 'PLAT_ADMIN' | 'OWNER' | 'OWNER_SPR' | 'SUPP' | 'SUPP_SPR';

export const RoleSlug = {
  UNREG: 'UNREG' as RoleSlug,
  SVCUSR: 'SVCUSR' as RoleSlug,
  SVCUSRSPR: 'SVCUSR_SPR' as RoleSlug,
  AGENT: 'AGENT' as RoleSlug,
  AGENTSPR: 'AGENT_SPR' as RoleSlug,
  CLIENTADMIN: 'CLIENT_ADMIN' as RoleSlug,
  PLATADMIN: 'PLAT_ADMIN' as RoleSlug,
  OWNER: 'OWNER' as RoleSlug,
  OWNERSPR: 'OWNER_SPR' as RoleSlug,
  SUPP: 'SUPP' as RoleSlug,
  SUPPSPR: 'SUPP_SPR' as RoleSlug,
};
