import React from 'react';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { DateAndTimePickerProps, DateAndTimePicker } from '../../..';

interface DateRangeFilterProps {
  t: (key: string) => string;
  filterKey: string;
  beforeValue?: string;
  afterValue?: string;
  onChange?: ({ value, key, target, index }: { value: string; key: string; target: string; index: number }) => void;
  index?: number;
  controls?: DateAndTimePickerProps['controls'];
  isUrlQuerySensitive?: boolean;
  displayFlex?: boolean;
  disabledPx?: boolean;
}

const DateRangeFilter: React.FC<React.PropsWithChildren<DateRangeFilterProps>> = ({
  t,
  filterKey,
  beforeValue,
  afterValue,
  onChange,
  index,
  controls = ['calendar', 'time'],
  isUrlQuerySensitive = false,
  displayFlex = false,
  disabledPx = false,
}: DateRangeFilterProps) => {
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const localeProps: DateAndTimePickerProps['localeProps'] = {
    okText: t('ns.common:date_picker:ok'),
    clearText: t('ns.common:date_picker:clear'),
    cancelText: t('ns.common:date_picker:cancel'),
    toggleVisibilityText: t('ns.common:date_picker:toggle_visibility'),
  };

  const afterInputRef = React.useRef(null);
  const beforeInputRef = React.useRef(null);

  const query = useLocation().search;
  const urlQueryFilters = JSON.parse(decodeURIComponent(new URLSearchParams(query).get('filter')));

  React.useEffect(() => {
    if (isUrlQuerySensitive) {
      if (beforeInputRef?.current && !urlQueryFilters?.created?.before && !beforeValue) {
        beforeInputRef.current.querySelector('input').value = '';
      }
      if (afterInputRef?.current && !urlQueryFilters?.created?.after && !afterValue) {
        afterInputRef.current.querySelector('input').value = '';
      }
    }
  }, [urlQueryFilters, isUrlQuerySensitive, beforeInputRef, afterInputRef, afterValue, beforeValue]);

  const isValidDate = (value: string) => !isNaN(Date.parse(value));
  const handleDateChange = (target: string) => (e: string) => {
    const value = e as string;

    if (!value) {
      onChange({ value: null, key: filterKey, target, index });
    }
    if (isValidDate(value)) {
      onChange({ value, key: filterKey, target, index });
    }
  };

  return (
    <Box sx={[{ px: disabledPx ? 0 : 4 }, displayFlex && { display: 'flex', gap: 2 }]}>
      <DateAndTimePicker
        value={afterValue}
        isSmallDevice={isSmallDevice}
        onChange={handleDateChange('after')}
        inputProps={{
          ref: afterInputRef,
          label: t('ns.search_filters:date:after_label'),
          variant: 'outlined',
          id: `after-datetime-${filterKey}`,
          inputProps: {
            // Trigger a re-render when new afterValue gets loaded
            key: new Date(afterValue).getTime(),
          },
          sx: { width: '100% ' },
        }}
        stepMinute={10}
        controls={controls}
        localeProps={localeProps}
      />
      <DateAndTimePicker
        value={beforeValue}
        isSmallDevice={isSmallDevice}
        onChange={handleDateChange('before')}
        inputProps={{
          ref: beforeInputRef,
          label: t('ns.search_filters:date:before_label'),
          variant: 'outlined',
          id: `before-datetime-${filterKey}`,
          inputProps: {
            // Trigger a re-render when new beforeValue gets loaded
            key: new Date(beforeValue).getTime(),
          },
          sx: { width: '100% ' },
        }}
        stepMinute={10}
        localeProps={localeProps}
        controls={controls}
      />
    </Box>
  );
};

export { DateRangeFilter };
