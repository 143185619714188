import React from 'react';
import { useTheme, Theme } from '@mui/material';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { TenantDisplayPalette } from '@askporter/grieg-types';
import { createAPTheme, fallbackPalette, fallbackTheme } from './utils';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { globalStyleOverrides } from './utils/overrides/styles';

export { fallbackPalette, fallbackTheme, useTheme };
export type { Theme };

interface ThemeProviderProps {
  palette?: TenantDisplayPalette;
  children: React.ReactNode[] | React.ReactNode;
}

/**
 * Provider component which passes down the theme data
 *
 * @param palette - tenant display palette
 * @param children - The React children components to wrap with the provider
 * @returns MuiThemeProvider Material UI's ThemeProvider component with all the children
 */
export const ThemeProvider: React.FC<React.PropsWithChildren<ThemeProviderProps>> = ({
  palette = fallbackPalette,
  children,
}: ThemeProviderProps) => {
  const theme = createAPTheme(palette);

  return (
    <MuiThemeProvider theme={theme}>
      <GlobalStyles styles={globalStyleOverrides(theme)} />
      {children}
    </MuiThemeProvider>
  );
};
