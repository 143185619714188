import React from 'react';
import { DialogTitle, DialogContent, FormControlLabel, DialogActions } from '@mui/material';
import { Button, MuiSwitch as Switch } from '@askporter/component-library';

interface CustomiseViewProps {
  t: (key: string) => string;
  cookies: {
    rum: boolean;
  };
  toggleCookiePreference: (cookie: string) => void;
  setDialogView: (view: 'INITIAL' | 'CUSTOMISE') => void;
  handleSaveOnClick: () => void;
}

const CustomiseView: React.FC<CustomiseViewProps> = ({
  t,
  cookies,
  toggleCookiePreference,
  setDialogView,
  handleSaveOnClick,
}) => {
  return (
    <>
      <DialogTitle variant="h5">{t('cookie_banner:customise:title')}</DialogTitle>
      <DialogContent>
        <FormControlLabel
          value="rum"
          control={<Switch color="primary" checked={cookies.rum} onChange={() => toggleCookiePreference('rum')} />}
          label={t('cookie_banner:customise:rum_switch_label')}
          labelPlacement="start"
          sx={{ justifyContent: 'space-between', width: '100%', m: 0 }}
        />
      </DialogContent>
      <DialogActions sx={{ alignItems: 'space-between' }} disableSpacing>
        <Button onClick={() => setDialogView('INITIAL')}>{t('cookie_banner:customise:back_button')}</Button>
        <Button variant="contained" sx={{ ml: 6 }} onClick={handleSaveOnClick}>
          {t('cookie_banner:customise:save_button')}
        </Button>
      </DialogActions>
    </>
  );
};

export default CustomiseView;
