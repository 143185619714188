import React, { ReactNode } from 'react';
import { ReactErrorBoundary } from '@askporter/component-library';
import { FallbackRenderer } from '../../types';
import { getGlobalObject } from '../../utils';

export interface ErrorBoundaryProps {
  fallback: ReactNode | FallbackRenderer;
  scope?: string;
  location?: Location;
}

/**
 * Utilises the ReactErrorBoundary to send enriched errors to RUM
 */
interface RumErrorBoundaryProps {
  fallback: FallbackRenderer;
  scope?: string;
  children: ReactNode | ReactNode[];
}

const RumErrorBoundary: React.FC<React.PropsWithChildren<RumErrorBoundaryProps>> = ({
  fallback,
  scope = 'error-boundary',
  children,
}: RumErrorBoundaryProps) => {
  return (
    <ReactErrorBoundary
      fallback={fallback}
      componentDidCatch={(error: Error) => {
        const RumGlobal = getGlobalObject<Window>()?.DD_RUM;
        if (RumGlobal) {
          RumGlobal.addError(error, { scope }, 'rum-error-boundary');
        }
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default RumErrorBoundary;
