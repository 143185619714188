import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { SkeletorContainer } from './styled';

export interface SkeletorProps {
  isReadOnly?: boolean;
}

export const Skeletor: React.FC<React.PropsWithChildren<SkeletorProps>> = ({ isReadOnly }) => (
  <SkeletorContainer>
    <Box sx={{ flex: 1, p: 3 }}>
      <Skeleton sx={{ mb: 2 }} animation="wave" height={50} width="60%" />
      <Skeleton sx={{ height: 190, borderRadius: '4px/6.7px', mb: 2 }} animation="wave" variant="rectangular" />
      <Skeleton animation="wave" height={50} width="70%" sx={{ mb: 4 }} />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 4 }}>
        <Skeleton animation="wave" height={50} width="10%" />
      </Box>
      <Skeleton animation="wave" height={50} width="60%" />
      <Skeleton animation="wave" height={50} width="35%" />
    </Box>
    {!isReadOnly && <Skeleton animation="wave" height={50} width="100%" variant="rectangular" />}
  </SkeletorContainer>
);
