import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFile from '@mui/icons-material/UploadFile';
import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Media, imageArray } from '@askporter/utils';
import { IconSize } from '../Icon';

interface FileItemProps {
  /**
   * The id prefix used for testing
   */
  idPrefix: string;
  /**
   * The translation function
   */
  t: (key: string, options?: Record<string, string | number>) => string;
  /**
   * Progress indicator variant
   */
  variant?: 'circle' | 'linear';
  /**
   * If the file is disabled
   */
  disabled?: boolean;
  /**
   * Whether the progress indicator is showing
   */
  isShowingProgressIndicator?: boolean;
  /**
   * The file object
   */
  file:
    | ({
        state: 'success';
        handleRemove: () => void;
        setFilePreview: () => void;
      } & Media)
    | ({
        state: 'loading';
      } & Pick<Media, 'nameAndType'>)
    | {
        state: 'error';
        name: string;
        error: string;
        handleRemove: () => void;
      };
}

export const FileItem = ({
  idPrefix,
  t,
  file,
  variant = 'linear',
  disabled,
  isShowingProgressIndicator = true,
}: FileItemProps): JSX.Element => {
  const theme = useTheme();

  const translations = {
    loading: t('common:file_uploader:file_item:loading'),
    error: t('common:file_uploader:file_item:error'),
    success: t('common:file_uploader:file_item:success'),
    deleteAction: t('common:file_uploader:file_item:delete'),
    filePreviewAction: t('common:file_uploader:file_item:preview_file'),
  };

  return (
    <ListItem component={Card} sx={{ marginTop: '10px', paddingLeft: 0 }} data-testid={`${idPrefix}-item`}>
      <ListItemIcon sx={{ minWidth: '50px' }}>
        <IconButton
          aria-label={translations['filePreviewAction']}
          disabled={file.state !== 'success'}
          onClick={() => file.state === 'success' && file.setFilePreview()}
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '8px',
            marginRight: '16px',
            marginLeft: '16px',
          }}
        >
          {file.state === 'success' && imageArray.includes(file.nameAndType?.type) ? (
            <Avatar src={file.path} variant="rounded" alt={file.nameAndType.name} component={'span'} />
          ) : (
            <UploadFile
              sx={{
                fontSize: IconSize.MD,
                color: file.state === 'error' ? theme.palette.error.main : theme.palette.primary.main,
              }}
            />
          )}
        </IconButton>
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ component: 'span' }}
        primary={
          <Typography variant="body1" sx={{ color: file.state === 'error' && theme.palette.error.main }}>
            {file.state === 'error' ? file.name : `${file.nameAndType.name}.${file.nameAndType?.type}`}
          </Typography>
        }
        secondaryTypographyProps={{ component: 'span' }}
        secondary={
          <Stack>
            <Typography variant="body2" sx={{ color: file.state === 'error' && theme.palette.error.main }}>
              {file.state === 'error' && file.error && (
                <>
                  {file.error} <span style={{ margin: '8px' }}>•</span>
                </>
              )}
              {translations[file.state]}
            </Typography>
            {isShowingProgressIndicator && variant === 'linear' && (
              <Box sx={{ width: '100%', marginTop: 1 }}>
                <LinearProgress
                  aria-label={translations[file.state]}
                  color={file.state === 'error' ? 'error' : 'primary'}
                  value={file.state === 'error' ? 40 : 100}
                  variant={file.state === 'loading' ? 'indeterminate' : 'determinate'}
                />
              </Box>
            )}
          </Stack>
        }
      />
      <ListItemSecondaryAction>
        <Stack direction="row" spacing={5} alignItems="center">
          {(file.state === 'success' || file.state === 'error') && (
            <IconButton
              disabled={disabled}
              edge="end"
              onClick={() => file.handleRemove()}
              aria-label={`${translations['deleteAction']} ${
                file.state === 'error' ? file.name : `${file.nameAndType.name}.${file.nameAndType?.type}`
              }`}
            >
              <DeleteIcon />
            </IconButton>
          )}
          {isShowingProgressIndicator &&
            variant === 'circle' &&
            (file.state === 'loading' ? (
              <CircularProgress color={'primary'} aria-label={translations[file.state]} />
            ) : file.state === 'success' ? (
              <CheckCircleIcon sx={{ color: theme.palette.success.main, fontSize: IconSize.MD }} />
            ) : null)}
        </Stack>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
