import React from 'react';
import { ListItemAvatar } from '@mui/material';
import { UserSearchResultExternal } from '@askporter/grieg-types';
import { FullUserRole, fullName } from '@askporter/utils';
import { SelectListItem, UserAvatar, AvatarSize } from '../../';

export interface UserListItemProps {
  item: UserSearchResultExternal;
  t: (key: string, options?: Record<string, string | number>) => string;
  isSmallDevice: boolean;
  handleChange: () => void;
  isChecked: boolean;
}
export const UserListItem: React.FC<React.PropsWithChildren<UserListItemProps>> = ({
  item,
  t,
  isSmallDevice,
  handleChange,
  isChecked,
}: UserListItemProps) => {
  return (
    <SelectListItem
      title={fullName(item?.givenName, item?.familyName)}
      details={FullUserRole[item?.roleSlug] ? t(`ns.${FullUserRole[item?.roleSlug]}`) : null}
      handleChange={handleChange}
      isChecked={isChecked}
      isSmallDevice={isSmallDevice}
      icon={
        <ListItemAvatar>
          <UserAvatar
            givenName={item?.givenName}
            familyName={item?.familyName}
            imagePath={item?.profilePhoto?.filePath}
            dataTestId={'user-list-item-avatar'}
            avatarSize={AvatarSize.MD}
          />
        </ListItemAvatar>
      }
    />
  );
};
