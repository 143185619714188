import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { UserAvatar } from '@askporter/component-library';
import type { TFunction } from '@askporter/config-provider';
import { ExportsListExternal } from '@askporter/grieg-types';
import { formatDate } from '@askporter/utils';
import { ExportStatus } from '../../../../types';
import { ExportDownloadButton } from '../../../ExportDownloadButton';
import { ExportNameField } from './ExportNameField';
import { ExportStatusChip } from './ExportStatusChip';
import { ExportViewButton } from './ExportViewButton';

interface TableBodyContentProps {
  t: TFunction;
  data: ExportsListExternal;
  isUserAdmin: boolean;
}

/**
 * The TableBodyContent component is responsible for rendering the exports data.
 */
export const TableBodyContent: React.FC<TableBodyContentProps> = ({ t, data, isUserAdmin }: TableBodyContentProps) => {
  return (
    <>
      {data?.exports?.map(({ uid, name, type, resultCount, progress: { status }, createdBy, requestedAt }) => (
        <TableRow key={uid}>
          <TableCell>
            <ExportNameField name={name} type={type} t={t} />
          </TableCell>
          <TableCell>{resultCount}</TableCell>
          <TableCell>
            <ExportStatusChip status={status} t={t} />
          </TableCell>
          {isUserAdmin && (
            <TableCell>
              <UserAvatar
                givenName={createdBy?.givenName}
                familyName={createdBy?.familyName}
                imagePath={createdBy.profilePhoto?.filePath}
                mode="full"
              />
            </TableCell>
          )}
          <TableCell>{formatDate(requestedAt, 'dd MMM yy HH:mm')}</TableCell>
          <TableCell>
            <ExportViewButton t={t} uid={uid} />
          </TableCell>
          <TableCell>
            {(status as ExportStatus) === 'COMPLETED' && (
              <ExportDownloadButton
                uid={uid}
                label={t('export:export_list:table:download_button')}
                onClick={() => ({})}
              />
            )}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
