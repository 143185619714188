import React from 'react';
import { useParams } from 'react-router-dom';
import {
  AssetTypeReadExternal,
  OrgTypeExtMetadataReadExternal,
  TaskFull,
  TaskTypeReadExternal,
} from '@askporter/grieg-types';
import { PageMeta } from '../../';
import { DynamicTitleType } from './types';

interface DynamicPageMetaProps {
  /**
   * The type of dynamic page title e.g. EXTMETADATA or ACTIVITY
   */
  type: DynamicTitleType;
  /**
   * The name of the uid page parameter
   */
  idParamName?: string;
  /**
   * The entity type data to obtain the ext metadata config or activities from
   */
  entityTypeData: AssetTypeReadExternal | OrgTypeExtMetadataReadExternal | TaskTypeReadExternal;
  /**
   * The data object of the entity
   */
  entityData?: TaskFull;
  /**
   * In case there is no 'displayName' from the API
   */
  fallbackTitle: string;
  /**
   * For the Content-Language tag
   */
  language: string;
  /**
   * The favicon url
   */
  favicon: string;
  /**
   * The touch icon url
   */
  touch: string;
}

/**
 * Sets the HTML page title to the 'displayName' of the current
 * asset/org/task ext metadata group or task activity
 */
export const DynamicPageMeta: React.FC<React.PropsWithChildren<DynamicPageMetaProps>> = ({
  type,
  idParamName = 'uid',
  entityTypeData,
  fallbackTitle,
  entityData,
  language,
  favicon,
  touch,
}: DynamicPageMetaProps) => {
  const params = useParams<{ [key: string]: string }>();
  const uid: string = params[idParamName];

  let title;
  if (type === DynamicTitleType.EXTMETADATA) {
    const extMetadataConfig = entityTypeData?.extMetadataConfig || [];
    const extMetadataGroup = extMetadataConfig.map((c) => c.extMetadataGroupConfig).find((c) => c.uid === uid);
    title = extMetadataGroup?.displayName || fallbackTitle;
  } else if (type === DynamicTitleType.ACTIVITY && entityData) {
    const activity = entityData?.taskActivities?.find((a) => a.uid === uid);
    if (activity) {
      const { activityConfig } =
        (entityTypeData as TaskTypeReadExternal)?.activities?.find(
          ({ activityConfig }) => activity.activityConfigUid === activityConfig.uid,
        ) || {};
      title = activityConfig?.displayName || fallbackTitle;
    } else {
      title = fallbackTitle;
    }
  } else {
    title = fallbackTitle;
  }

  return <PageMeta title={title} favicon={favicon} touch={touch} language={language} />;
};
