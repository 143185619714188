import React from 'react';
import { Permissions } from '@askporter/auth-provider';
import { TeamExportModalFormControlType } from '../../../types';
import { exportTeamsCheckboxFieldsPermissionMapping } from '../../../utils/exportCheckboxFieldPermissionMapping';
import { ExportFieldCheckbox } from '../../ExportFieldCheckbox';

interface ExportTeamsFieldsProps {
  /**
   * The translation function
   */
  t: (key: string, options?: Record<string, string | number>) => string;
  /**
   * The form control
   */
  control: TeamExportModalFormControlType;
}

/**
 * Renders the checkboxes for the teams export modal based on the user's role
 */
export const ExportTeamsFields: React.FC<ExportTeamsFieldsProps> = ({ t }) => {
  return (
    <>
      <Permissions requiredRoles={exportTeamsCheckboxFieldsPermissionMapping.TEAM_ESSENTIALS}>
        <ExportFieldCheckbox
          fieldId="TEAM_ESSENTIALS"
          disabled={true}
          label={t('teams:export_modal:team_essentials:checkbox:label')}
          subLabel={t('teams:export_modal:team_essentials:checkbox:subLabel')}
          isChecked={true}
        />
      </Permissions>
    </>
  );
};
