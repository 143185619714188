export type ExtendedMetadataDataType = 'STRING' | 'MULTILINESTRING' | 'INTEGER' | 'NUMBER' | 'DATETIME' | 'DATE' | 'BOOLEAN' | 'FILE' | 'MULTIFILE' | 'LABEL' | 'DAYTIME' | 'DAYTIMERANGE' | 'MULTILANGSTRING';

export const ExtendedMetadataDataType = {
  STRING: 'STRING' as ExtendedMetadataDataType,
  MULTILINESTRING: 'MULTILINESTRING' as ExtendedMetadataDataType,
  INTEGER: 'INTEGER' as ExtendedMetadataDataType,
  NUMBER: 'NUMBER' as ExtendedMetadataDataType,
  DATETIME: 'DATETIME' as ExtendedMetadataDataType,
  DATE: 'DATE' as ExtendedMetadataDataType,
  BOOLEAN: 'BOOLEAN' as ExtendedMetadataDataType,
  FILE: 'FILE' as ExtendedMetadataDataType,
  MULTIFILE: 'MULTIFILE' as ExtendedMetadataDataType,
  LABEL: 'LABEL' as ExtendedMetadataDataType,
  DAYTIME: 'DAYTIME' as ExtendedMetadataDataType,
  DAYTIMERANGE: 'DAYTIMERANGE' as ExtendedMetadataDataType,
  MULTILANGSTRING: 'MULTILANGSTRING' as ExtendedMetadataDataType,
};
