import React from 'react';
import { Photo as PhotoIcon, Description as DescriptionIcon, Movie as MovieIcon } from '@mui/icons-material';
import { AllowedFileTypes } from '@askporter/utils';

export const fileIconMapping: Record<string, JSX.Element> = {
  [AllowedFileTypes.JPG]: <PhotoIcon />,
  [AllowedFileTypes.JPEG]: <PhotoIcon />,
  [AllowedFileTypes.PNG]: <PhotoIcon />,
  [AllowedFileTypes.DOC]: <DescriptionIcon />,
  [AllowedFileTypes.DOCX]: <DescriptionIcon />,
  [AllowedFileTypes.PDF]: <DescriptionIcon />,
  [AllowedFileTypes.MP4]: <MovieIcon />,
  [AllowedFileTypes.HEIC]: <MovieIcon />,
  [AllowedFileTypes.HEIF]: <MovieIcon />,
};
