import i18next from 'i18next';

const FALLBACK_LANGUAGE = 'en-GB';

export const formatNumber = (value: number): string => {
  if (typeof value === 'undefined') {
    return undefined;
  }

  try {
    const formatter = new Intl.NumberFormat(i18next.language || FALLBACK_LANGUAGE);

    return formatter.format(value);
  } catch (_) {
    const formatter = new Intl.NumberFormat(FALLBACK_LANGUAGE);

    return formatter.format(value);
  }
};
