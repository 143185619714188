import React from 'react';
import { useLocation } from 'react-router-dom';
import { LocalErrorBoundary, ErrorBoundaryAlert } from '@askporter/component-library';
import { useRum } from '../Rum';
import RumErrorBoundary from '../RumErrorBoundary';

interface Props {
  children: JSX.Element[] | JSX.Element;
  t: (key: string) => string;
}

/**
 * A local error boundary that will utilise the RUM error boundary if RUM is enabled, otherwise it will use the
 * standard LocalErrorBoundary.
 */
const LocalErrorBoundaryWithRumIfEnabled: React.FC<React.PropsWithChildren<Props>> = ({ t, children }: Props) => {
  const { pathname } = useLocation();
  const { rumIsAllowed } = useRum();

  if (rumIsAllowed)
    return (
      <RumErrorBoundary
        fallback={({ error }) => {
          return (
            <ErrorBoundaryAlert
              title={t('ns.error_page:error')}
              error={error}
              fallbackErrMessage={t('ns.error_page:app_error')}
              pathname={pathname}
            />
          );
        }}
      >
        {children}
      </RumErrorBoundary>
    );
  else return <LocalErrorBoundary t={t}>{children}</LocalErrorBoundary>;
};

export default LocalErrorBoundaryWithRumIfEnabled;
