import React from 'react';
import { Datepicker, locale } from '@mobiscroll/react';
import type { MbscDatepickerOptions } from '@mobiscroll/react/dist/src/core/components/datepicker/datepicker';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { TextFieldProps, TextField, InputAdornment, InputProps, Tooltip } from '@mui/material';

const getSelectedLanguage = () => {
  try {
    const selectedLanguage = localStorage.getItem('selectedLanguage');

    if (selectedLanguage) return selectedLanguage;
    return 'en-GB';
  } catch (err) {
    return 'en-GB';
  }
};

type TextFieldWithTooltipProps = TextFieldProps & {
  readOnly?: boolean;
  readOnlyText?: string;
};

const TextFieldWithTooltip = React.forwardRef<HTMLDivElement>(function TextFieldWithTooltip(
  { readOnly, readOnlyText, ...props }: TextFieldWithTooltipProps,
  ref,
) {
  return (
    <Tooltip title={readOnly ? readOnlyText : undefined} placement="top-end">
      <TextField {...props} ref={ref} />
    </Tooltip>
  );
});

export interface SelectDateAndTimePickerProps extends MbscDatepickerOptions {
  /**
   * Whether we are on a device with a small screen
   */
  isSmallDevice?: boolean;
  /**
   * Translations for the buttons
   */
  localeProps: {
    okText: string;
    clearText: string;
    cancelText: string;
    toggleVisibilityText: string;
  };
  /**
   * The props passed to the Select Component
   */
  textFieldProps: TextFieldWithTooltipProps;
  /**
   * The input startAdornment
   */
  startAdornment: JSX.Element;
  /**
   * The function that gets called when value is changed, and date picker is closed
   */
  onChange: (date: string) => void;
  /**
   * A string value that gets prefixed to the id
   */
  idPrefix: string;
}

/**
 * Simply a DateTime picker component that uses mobiscroll's datepicker with some sensible defaults
 */
export const SelectDateAndTimePicker: React.FC<React.PropsWithChildren<SelectDateAndTimePickerProps>> = ({
  localeProps,
  textFieldProps,
  pages = 1,
  isSmallDevice,
  startAdornment,
  onChange,
  disabled = false,
  idPrefix,
  returnFormat = 'jsdate',
  ...props
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const datePicker = React.useRef(null);
  const inputRef = React.useRef(null);
  const localeCode = getSelectedLanguage();
  const dateLocale = locale[localeCode] || locale[localeCode?.split('-')[0]]; // yes

  const InputProps: InputProps = {
    ref: inputRef,
    readOnly: textFieldProps.readOnly,
    disabled: disabled,
    startAdornment,
    endAdornment: !disabled && (
      <InputAdornment position="end">{isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</InputAdornment>
    ),
  };

  const handleClear = () => {
    datePicker.current.setVal(null);
    datePicker.current.close();
    onChange(null);
  };

  return (
    <>
      <Datepicker
        ref={datePicker}
        display="anchored"
        anchor={inputRef.current}
        onOpen={() => setIsOpen(true)}
        onClose={() => {
          onChange(datePicker.current.getVal());
          setIsOpen(false);
        }}
        locale={dateLocale}
        inputComponent={TextFieldWithTooltip}
        inputProps={{
          ...textFieldProps,
          'data-testid': `${idPrefix}-select-date-and-time-picker`,
          InputProps,
        }}
        pages={isSmallDevice ? 1 : pages}
        theme="material"
        themeVariant="light"
        touchUi={isSmallDevice}
        returnFormat={returnFormat}
        buttons={[
          {
            text: localeProps.clearText,
            handler: handleClear,
          },
        ]}
        {...props}
        disabled={disabled}
      />
    </>
  );
};
