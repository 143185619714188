import { DateTimeReadOnly } from './DateTimeReadOnly';
import { LangCode } from './LangCode';
import { SearchFreeText } from './SearchFreeText';
import { SearchTaskFilter } from './SearchTaskFilter';
import { SearchTaskSort } from './SearchTaskSort';
import { TimezoneName } from './TimezoneName';
import { UIDReadOnly } from './UIDReadOnly';
import { URNReadOnly } from './URNReadOnly';
import { UserRefHydrated } from './UserRefHydrated';

export interface TaskExportReadExternal {
  uid: UIDReadOnly;
  urn: URNReadOnly;
  name: string;
  languageCode: LangCode;
  timezone: TimezoneName;
  resultCount?: number;
  progress: any;
  requestedAt: DateTimeReadOnly;
  freeText?: SearchFreeText;
  format: TaskExportReadExternal_FormatEnum;
  createdBy: UserRefHydrated;
  type: TaskExportReadExternal_TypeEnum;
  fields: Array<TaskExportReadExternal_FieldsEnum>;
  filter: SearchTaskFilter;
  sort: SearchTaskSort;
}

export type TaskExportReadExternal_FormatEnum = 'STANDARD' | 'EXCEL_COMPATIBLE';

export const TaskExportReadExternal_FormatEnum = {
  STANDARD: 'STANDARD' as TaskExportReadExternal_FormatEnum,
  EXCELCOMPATIBLE: 'EXCEL_COMPATIBLE' as TaskExportReadExternal_FormatEnum,
};

export type TaskExportReadExternal_TypeEnum = 'TASK';

export const TaskExportReadExternal_TypeEnum = {
  TASK: 'TASK' as TaskExportReadExternal_TypeEnum,
};

export type TaskExportReadExternal_FieldsEnum = 'TASK_RESOURCES';

export const TaskExportReadExternal_FieldsEnum = {
  TASKRESOURCES: 'TASK_RESOURCES' as TaskExportReadExternal_FieldsEnum,
};
