import React, { useEffect } from 'react';
import { reloadResources } from 'i18next';
import { useConfig } from '../Config';
import { bustCache } from '../utils';
import { i18nInit } from './i18n';
import initUserLanguage from './utils/userLanguage';
export type { LanguageOption } from './fatalErrorTranslation';
export { languageOptions, fatalErrorTranslations } from './fatalErrorTranslation';

interface LanguageProviderProps {
  children: React.ReactNode[] | React.ReactNode;
}

/**
 * Wrapper component which initializes i18n, i18n becomes accessible through useTranslation()
 *
 * @param children - The React child components to wrap with the provider
 * @returns LanguageProvider component with children
 */
export const LanguageProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }: LanguageProviderProps) => {
  const { tenantLanguages } = useConfig();
  const fallbackLanguage = 'en-GB';
  const language = initUserLanguage(tenantLanguages, fallbackLanguage);
  const [ready, setReady] = React.useState(false);

  const customHeaders = () => {
    return {
      'x-ap-lang': localStorage.getItem('selectedLanguage') || fallbackLanguage,
    };
  };

  // We only want to initialise i18n once, useEffect with an empty array allows us to do this
  useEffect(() => {
    i18nInit({
      language,
      customHeaders,
      onMissingKey: () =>
        bustCache(() => {
          reloadResources();
        }),
    });
    setReady(true);
  }, []);

  return <>{ready ? children : null}</>;
};
