import React from 'react';
import { Box, Typography } from '@mui/material';
import { formatNumber } from '@askporter/utils';

export interface FilterCountProps {
  /**
   * the number of search results matching the filter
   */
  count: number;
  /**
   * the optional data test id passed to the count
   */
  testId?: string;
}

/**
 * Renders a count next to the filter name
 */
export const FilterCount: React.FC<React.PropsWithChildren<FilterCountProps>> = ({
  count,
  testId,
}: FilterCountProps) => {
  return (
    <Box sx={{ pr: 4 }}>
      <Typography
        color="textSecondary"
        variant="subtitle2"
        data-testid={testId ? `${testId}-filter-count` : 'filter-count'}
      >
        {formatNumber(count)}
      </Typography>
    </Box>
  );
};
