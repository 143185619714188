import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useAuthProvider, SessionState } from '@askporter/auth-provider';
import { Loading } from '@askporter/component-library';

interface RequireAuthProps {
  /**
   * The children to render if the user is authenticated.
   */
  children: React.JSX.Element;
}

export const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const { sessionState, feedbackMessage } = useAuthProvider();
  const history = useHistory();

  switch (sessionState) {
    case SessionState.pendingRefresh:
    case SessionState.valid:
      return children;
    case SessionState.invalid:
    case SessionState.illegal: {
      return (
        <Redirect
          to={{
            pathname: '/sign-in',
            state: {
              to: feedbackMessage === 'signed out' ? undefined : history.location.pathname,
            },
          }}
        />
      );
    }
    case SessionState.pendingRehydrate:
    default:
      return <Loading />;
  }
};
