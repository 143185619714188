export type SlaCurrentStatusReadOnly = 'NA' | 'RUNNING' | 'PAUSED' | 'COMPLETED_ON_TIME' | 'COMPLETED_LATE' | 'OVERDUE';

export const SlaCurrentStatusReadOnly = {
  NA: 'NA' as SlaCurrentStatusReadOnly,
  RUNNING: 'RUNNING' as SlaCurrentStatusReadOnly,
  PAUSED: 'PAUSED' as SlaCurrentStatusReadOnly,
  COMPLETEDONTIME: 'COMPLETED_ON_TIME' as SlaCurrentStatusReadOnly,
  COMPLETEDLATE: 'COMPLETED_LATE' as SlaCurrentStatusReadOnly,
  OVERDUE: 'OVERDUE' as SlaCurrentStatusReadOnly,
};
