import { useCallback, useState } from 'react';
import { t } from 'i18next';
import { useAuthProvider, AuthResult, CognitoError } from '@askporter/auth-provider';
import { useToast } from '@askporter/component-library';
import { useConfig } from '@askporter/config-provider';
import { getUserFriendlyError } from '@askporter/utils';

/**
 * Handles the forgot password process
 */
export const useForgotPassword = (): {
  handleStart: (username: string) => Promise<boolean>;
  confirmNewPassword: (username: string, code: string, password: string) => Promise<boolean>;
  handleChangePassword: (oldPassword: string, newPassword: string) => Promise<boolean>;
  startLoading: boolean;
  confirmNewPasswordLoading: boolean;
  changePasswordLoading: boolean;
} => {
  const { startForgotPassword, confirmPassword, changePassword } = useAuthProvider();
  const { setToast } = useToast();
  const {
    tenantContact: { email },
  } = useConfig();
  const [startLoading, setStartLoading] = useState<boolean>(false);
  const [confirmNewPasswordLoading, setConfirmNewPasswordLoading] = useState<boolean>(false);
  const [changePasswordLoading, setChangePasswordLoading] = useState<boolean>(false);

  /**
   * Initiates the password reset process, returns a boolean to limit how verbose feedback is given this is a function
   * that is called from a public page
   *
   * @param username - the username (email) of the user that requires a password reset
   * @returns A boolean which indicates whether function call was successful or not (this is not indicative of whether
   * the reset itself was successful)
   */
  const handleStart = useCallback(async (username: any) => {
    return (async () => {
      try {
        setStartLoading(true);
        await startForgotPassword(username);

        return true;
      } catch (error) {
        return false;
      } finally {
        setStartLoading(false);
      }
    })();
  }, []);

  /**
   * Attempts to reset the user's password using the single use reset code and new password
   *
   * @param username - the username (email) of the user account that is being reset
   * @param code - the code provided by email to the user, this is required to reset a password while unauthenticated
   * @param password - the new password
   * @returns A boolean indicating whether the password reset was successful or not
   */
  const confirmNewPassword = useCallback(async (username: any, code: any, password: any) => {
    return (async () => {
      try {
        setConfirmNewPasswordLoading(true);
        const res: AuthResult = await confirmPassword(username, code, password);
        setToast({
          open: true,
          severity: res.complete ? 'success' : 'error',
          children: res.complete
            ? t('ns.common:success:change_password')
            : getUserFriendlyError(t, 'Unexpected', undefined, { email }),
        });

        return res.complete;
      } catch (err) {
        const cognitoError: CognitoError = err.error;

        setToast({
          open: true,
          severity: 'error',
          children:
            (cognitoError && getUserFriendlyError(t, cognitoError.code, cognitoError.message, { email })) ||
            getUserFriendlyError(t, 'Unexpected', undefined, { email }),
        });

        return false;
      } finally {
        setConfirmNewPasswordLoading(false);
      }
    })();
  }, []);

  /**
   * Attempts to reset the user's password using the old and new password
   *
   * @param oldPassword - the old password
   * @param newPassword - the new password
   * @returns A boolean indicating whether the password reset was successful or not
   */
  const handleChangePassword = useCallback(async (oldPassword: any, newPassword: any) => {
    return (async () => {
      try {
        setChangePasswordLoading(true);
        const res: AuthResult = await changePassword(oldPassword, newPassword);
        setToast({
          open: true,
          severity: res.complete ? 'success' : 'error',
          children: res.complete
            ? t('ns.common:success:change_password')
            : getUserFriendlyError(t, 'Unexpected', undefined, { email }),
        });

        return res.complete;
      } catch (err) {
        const cognitoError: CognitoError = err.error;

        const errMessage = cognitoError && cognitoError.message;
        // eslint-disable-next-line functional/immutable-data
        if (errMessage === 'Incorrect username or password.') cognitoError.message = 'Incorrect password';

        setToast({
          open: true,
          severity: 'error',
          children:
            (cognitoError && getUserFriendlyError(t, cognitoError.code, cognitoError.message, { email })) ||
            getUserFriendlyError(t, 'Unexpected', undefined, { email }),
        });

        return false;
      } finally {
        setChangePasswordLoading(false);
      }
    })();
  }, []);

  return {
    handleStart,
    confirmNewPassword,
    handleChangePassword,
    startLoading,
    confirmNewPasswordLoading,
    changePasswordLoading,
  };
};
