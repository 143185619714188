import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { IPAddressLocation } from '../types';

export const fetchIPAddressLocation = async (): Promise<IPAddressLocation> => {
  try {
    const ipApiResponse = await axios.get('https://ipapi.co/json');

    const ipData: IPAddressLocation = ipApiResponse.data;

    return ipData;
  } catch (err) {
    return null;
  }
};

export const useIPAddress = (): UseQueryResult<IPAddressLocation, unknown> => {
  const query = useQuery('ipLookup', fetchIPAddressLocation);
  return query;
};
