/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import { InputLabel, Theme, useMediaQuery } from '@mui/material';
import { utils } from '@rjsf/core';
import { isEqual } from 'date-fns';
import { DateAndTimePicker, DateAndTimePickerProps } from '../../';
import { CustomWidgetProps } from '../types';

const { localToUTC, utcToLocal, getDisplayLabel } = utils;

const DateTimeWidget: React.FC<React.PropsWithChildren<CustomWidgetProps>> = ({
  id,
  placeholder,
  readonly: readOnly,
  disabled,
  label,
  value,
  onChange,
  autofocus,
  schema,
  uiSchema,
  formContext: { onHasChanged, t },
}: CustomWidgetProps) => {
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const _value = utcToLocal(value);
  const _onChange = (v: string) => {
    onChange(localToUTC(v));
  };
  const _onHasChanged = (newValue: string, oldValue: string) => {
    if (!oldValue && newValue) {
      onHasChanged({ id, value: newValue });
    } else {
      const datesAreEqual = isEqual(new Date(newValue), new Date(oldValue));

      if (!datesAreEqual) onHasChanged({ id, value: newValue });
    }
  };

  const localeProps: DateAndTimePickerProps['localeProps'] = {
    okText: t('ns.common:date_picker:ok'),
    clearText: t('ns.common:date_picker:clear'),
    cancelText: t('ns.common:date_picker:cancel'),
    toggleVisibilityText: t('ns.common:date_picker:toggle_visibility'),
  };

  const displayLabel = getDisplayLabel(schema, uiSchema);

  return (
    <>
      <InputLabel variant="filled" htmlFor={`${id}-input`}>
        {displayLabel ? label || schema.title : false}
      </InputLabel>
      <DateAndTimePicker
        value={_value}
        onChange={(value: string) => {
          _onChange(value);
          onHasChanged && setTimeout(() => _onHasChanged(value, _value), 0);
        }}
        isSmallDevice={isSmallDevice}
        localeProps={localeProps}
        disabled={disabled}
        controls={['calendar', 'time']}
        filledInputOnly={true}
        inputProps={{
          inputProps: { 'data-testid': 'datetime-widget' },
          id: `${id}-input`,
          key: `${Date.now()}-datetime-input`,
          placeholder: placeholder,
          autoFocus: autofocus,
          disabled: disabled,
          readOnly,
        }}
      />
    </>
  );
};

export default DateTimeWidget;
