export const DEFAULT_STATUS_KEYS = {
  saving: 'ns.common:form:field:saving',
  saved: 'ns.common:form:field:saved',
  error: 'ns.common:form:field:error_saving',
  failed: 'ns.common:form:field:form_validation_failed',
};

export const translateObjectValues =
  (translateFn: (s: string) => string) =>
  (translations: { [key: string]: string }): { [key: string]: string } =>
    Object.entries(translations).reduce((acc, [key, val]) => {
      return { ...acc, [key]: translateFn(val) };
    }, {});

export const EMPTY_STATUS_KEYS = {
  saving: '',
  saved: '',
  error: '',
  failed: '',
};
