import React, { useState } from 'react';
import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';

export interface CreateButtonProps {
  text: string;
  isSmallDevice: boolean;
  render?: ({ handleClose, anchorElement }: { handleClose: () => void; anchorElement: HTMLElement }) => JSX.Element;
  onClick?: (event?: React.FormEvent) => void;
}

const CreateButton = ({ text, isSmallDevice, render, onClick }: CreateButtonProps): JSX.Element => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const btnSize = isSmallDevice
    ? {
        width: 48,
        height: 48,
      }
    : {};

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (render) {
      setAnchorElement(event.currentTarget);
    }
    if (onClick) {
      onClick(event);
    }
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={!isSmallDevice && <Add />}
        onClick={handleClick}
        data-testid="create-button"
        sx={{
          minWidth: 48,
          ...btnSize,
        }}
      >
        {isSmallDevice ? <Add sx={{ width: 16, height: 16 }} /> : text}
      </Button>

      {!!anchorElement && render({ handleClose, anchorElement })}
    </>
  );
};

export default CreateButton;
