import cloneDeep from 'lodash/cloneDeep';
import { SearchTaskSort, SearchElementDateRange, SearchTaskFilter } from '@askporter/grieg-types';
import { TaskState } from '../types';

/**
 * Transforms state into object suitable for POST request
 * @param filter - object containing filters from state
 *
 * @returns object containing transformed filters
 */
export const generateTaskSearchFilters = (filter: TaskState['search']['filter']): Partial<SearchTaskFilter> => {
  const filters: SearchTaskFilter = cloneDeep(filter);

  // check other filters and remove any empties
  Object.keys(filter).forEach((key: keyof SearchTaskFilter) => {
    // check for empty arrays
    if (Array.isArray(filters[key])) {
      const arrayFilter = filters[key] as Array<string>;
      if (arrayFilter.length < 1) delete filters[key];
    }

    // check for date types with both elements (before & after) missing
    else if (key !== 'actionOn' && 'before' in filters[key] && 'after' in filters[key]) {
      const dateFilter = filters[key] as SearchElementDateRange;
      if (!dateFilter.before && !dateFilter.after) delete filters[key];
    }
  });

  return filters;
};

export const taskStateDefaults = (): TaskState => {
  const defaults: TaskState = {
    search: {
      extendedMetadataStats: [],
      freeText: '',
      sort: SearchTaskSort.RECOMMENDED,
      page: 1,
      filter: {
        created: {
          before: null,
          after: null,
        },
        resolutionDueDate: {
          before: null,
          after: null,
        },
        resolutionDueDateTime: {
          before: null,
          after: null,
        },
        responseDueDateTime: {
          before: null,
          after: null,
        },
        linkedAsset: [],
        linkedUser: [],
        assignedTeam: [],
        assignedUser: [],
        assignedTo: [],
        statusType: ['OPEN'],
        priority: [],
        status: [],
        taskType: [],
      },
      oboUser: undefined,
    },
    listScrollPos: 0,
    isSearchEnabled: true,
    hasUserSelectedSort: false,
  };

  return defaults;
};
