import { Theme, useMediaQuery } from '@mui/material';

/**
 * Determines how many menu items should be shown based on breakpoints. Based on
 * https://www.figma.com/file/jo4rBgaYJnlX1DoSHms4tm/Ask%3A-Web-Components?node-id=104%3A38605
 * with some alterations to ensure no overlap
 */
export const useResponsiveProperties = (): {
  smUp: boolean;
  maxFullNavButtons: number;
  maxIconNavButtons: number;
} => {
  let maxFullNavButtons = 5;
  let maxIconNavButtons = 2;

  if (useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))) {
    maxFullNavButtons = 4;
  }

  if (useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))) {
    maxFullNavButtons = 3;
    maxIconNavButtons = 1;
  }

  if (useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))) {
    maxFullNavButtons = 0;
    maxIconNavButtons = 1;
  }

  return {
    smUp: useMediaQuery((theme: Theme) => theme.breakpoints.up('sm')),
    maxFullNavButtons,
    maxIconNavButtons,
  };
};
