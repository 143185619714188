import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { Publish as PublishIcon } from '@mui/icons-material';
import { CircularProgress, FormControl } from '@mui/material';
import { captureException } from '@askporter/exception-logger';
import { UploadedFile } from '@askporter/utils';
import { Button, ButtonProps } from '../../';
import uploadFiles, { SimplifiedIAPIClient } from './uploadFiles';

interface MultiFileUploadButtonProps {
  onChange?: (uploadState: Array<UploadedFile>) => void;
  variant?: ButtonProps['variant'];
  label?: string;
  t: (key: string, options?: Record<string, string | number>) => string;
  API: () => SimplifiedIAPIClient;
}

export const MultiFileUploadButton = ({
  onChange = () => ({}),
  variant = 'contained',
  label,
  t,
  API,
}: MultiFileUploadButtonProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileUploadState, setFileUploadState] = useState<Array<UploadedFile>>();

  const handleFileAdd: ChangeEventHandler<HTMLInputElement> = async (e) => {
    setIsLoading(true);
    const uploadResult = await uploadFiles(Array.from(e?.target?.files), API, captureException);
    setFileUploadState(uploadResult);
    setIsLoading(false);
  };

  useEffect(() => {
    if (fileUploadState) {
      onChange(fileUploadState);
      setFileUploadState(undefined);
    }
  }, [fileUploadState, onChange]);

  return (
    <FormControl>
      <Button
        component="label"
        disabled={isLoading}
        variant={variant}
        data-testid={`upload-button`}
        endIcon={<PublishIcon />}
        html-for="single-file-input"
      >
        {isLoading && <CircularProgress data-testid="loading-indicator" />}
        {!isLoading && (
          <>
            <input
              autoComplete="off"
              id="single-file-input"
              multiple={true}
              onChange={handleFileAdd}
              style={{ display: 'none' }}
              type="file"
            />
            {label || t('ns.common:file_upload:multiple:input_button')}
          </>
        )}
      </Button>
    </FormControl>
  );
};
