import { CognitoUserPool, CognitoUserAttribute, CognitoUser } from 'amazon-cognito-identity-js';
import { UserAttributes } from '../../types';

export interface AuthResult {
  complete: boolean;
  message: string;
  value?: string;
  error?: {
    code: string;
    message: string;
  };
}

export type UnauthenticatedResult = Pick<AuthResult, 'error' | 'message'>;

export enum SessionState {
  invalid,
  pendingRehydrate,
  pendingRefresh,
  valid,
  illegal,
}

export interface AuthState {
  feedbackMessage: string;
  sessionState?: SessionState;
  userPool?: CognitoUserPool;
  cognitoUser?: CognitoUser;
  userProfile: Partial<UserAttributes> | undefined;
  authToken: string | undefined;
  cognitoUserName?: string;
}

export interface SetAuthState {
  sessionState: SessionState;
  feedbackMessage?: string;
}

export interface AuthenticateUser {
  cognitoUser: CognitoUser;
  cognitoUserName: string;
  feedbackMessage?: string;
}

export interface ResetState {
  sessionState: SessionState;
  feedbackMessage?: string;
}

export interface RefreshSession {
  cognitoUser: CognitoUser;
  sessionState: SessionState;
  authToken: string;
  feedbackMessage?: string;
}

export interface RestoreSession {
  cognitoUser: CognitoUser;
  sessionState: SessionState;
  authToken: string;
  feedbackMessage?: string;
}

export interface SetAuthToken {
  authToken: string;
  feedbackMessage?: string;
}

export interface AuthAction {
  type:
    | 'setProfile'
    | 'setAuthToken'
    | 'setFeedback'
    | 'setAuthState'
    | 'setPool'
    | 'setCognitoUserName'
    | 'setCognitoUser'
    | 'restoreSession'
    | 'refreshSession'
    | 'authenticateUser'
    | 'resetAuthState';
  payload:
    | Partial<UserAttributes>
    | string
    | SetAuthState
    | SetAuthToken
    | AuthenticateUser
    | ResetState
    | RefreshSession
    | CognitoUser
    | CognitoUserPool
    | CognitoUserAttribute[];
}

export interface CognitoError extends Error {
  code?: string;
}

export type ApUserAttribute = 'email' | 'phone_number';

export type VerificationTask = 'sendCode' | 'inputCode';

export interface GetAttributesRes extends AuthResult {
  cognitoUserAttributes?: CognitoUserAttribute[];
}

export interface SetAttributesRes extends AuthResult {
  result?: string;
}
