import { RegexRule } from '../types';

export const regexRules: RegexRule[] = [
  {
    pattern: /([A-Z])/,
    name: 'uppercase',
  },
  {
    pattern: /([a-z])/,
    name: 'lowercase',
  },
  {
    pattern: /([0-9])/,
    name: 'numbers',
  },
  {
    pattern: /([^A-Za-z0-9])/,
    name: 'special',
  },
];

/**
 * Utility function to check how many rules (regex) the
 * passed value (password) conforms to
 *
 * @param value - the value to validate
 * @param regexRules - the set of rules to validate against
 *
 * @returns array of rule names that could not be matched
 */
export const checkUnmatchedRules = (value: string, regexRules: RegexRule[]): string[] => {
  const errors = regexRules.reduce<string[]>((prev, curr) => {
    if (!curr.pattern.test(value)) {
      return [...prev, curr.name];
    }
    return prev;
  }, []);
  return errors;
};
