import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { UserAttributes } from '../../types';
import {
  AuthState,
  AuthAction,
  SetAuthState,
  RestoreSession,
  RefreshSession,
  AuthenticateUser,
  ResetState,
  SessionState,
  SetAuthToken,
} from './types';

export const authInitialState: AuthState = {
  feedbackMessage: 'init',
  sessionState: SessionState.pendingRehydrate,
  userPool: undefined,
  userProfile: undefined,
  authToken: undefined,
  cognitoUser: undefined,
  cognitoUserName: undefined,
};

/**
 * Reducer for maintaining Auth provider state
 * @param state   - the state object
 * @param action  - the required state action
 */
export function authReducer(state: AuthState, action: AuthAction): AuthState {
  let payload;
  switch (action.type) {
    case 'setProfile':
      payload = action.payload as Partial<UserAttributes>;
      return { ...state, userProfile: payload };
    case 'setAuthToken':
      payload = action.payload as SetAuthToken;
      return { ...state, ...payload };
    case 'setFeedback':
      payload = action.payload as string;
      return { ...state, feedbackMessage: payload };
    case 'setAuthState':
      payload = action.payload as SetAuthState;
      return { ...state, ...payload };
    case 'setPool':
      payload = action.payload as CognitoUserPool;
      return { ...state, userPool: payload };
    case 'setCognitoUser':
      payload = action.payload as CognitoUser;
      return { ...state, cognitoUser: payload };
    case 'setCognitoUserName':
      payload = action.payload as string;
      return { ...state, cognitoUserName: payload };
    case 'restoreSession':
      payload = action.payload as RestoreSession;
      return { ...state, ...payload };
    case 'refreshSession':
      payload = action.payload as RefreshSession;
      return { ...state, ...payload };
    case 'authenticateUser':
      payload = action.payload as AuthenticateUser;
      return { ...state, ...payload };
    case 'resetAuthState':
      payload = action.payload as ResetState;
      return {
        ...authInitialState,
        userPool: state.userPool,
        cognitoUserName: state.cognitoUserName,
        sessionState: payload.sessionState,
        feedbackMessage: payload.feedbackMessage,
      };
    default:
      break;
  }
  return state;
}
