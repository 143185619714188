import React, { lazy } from 'react';

/**
 * refreshes the page if imported module can't be found, likely caused by module hash mismatch.
 * https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
 *
 * @param componentImport module import i.e, import('/../../pages/Landing')
 * @returns loaded module import
 */
function lazyWithRetry<ComponentProps = Record<string, unknown>>(
  componentImport: () => Promise<any>,
): React.LazyExoticComponent<React.FC<React.PropsWithChildren<ComponentProps>>> {
  return lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false',
    );
    try {
      const component = await componentImport();
      window.localStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem('page-has-been-force-refreshed', 'true');

        return window.location.reload();
      }
      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });
}

export { lazyWithRetry };
