import { AuthAction, AuthResult, CognitoError } from './types';
import { ClientMetadata, CognitoUserAttribute, CognitoUserPool, ISignUpResult } from 'amazon-cognito-identity-js';
import { wrapError } from '.';

/**
 * Attempt to sign up a user with basic profile details
 * @param givenname   - user's given name
 * @param familyname  - user's family name
 * @param username    - user's username
 * @param userpass    - user's password
 * @param userphone   - user's normalized phone number
 * @param dispatch    - reference to the auth state reducer dispatch method
 * @param userPool    - userPool object
 * @param clientMetadata - any additional data to send to Cognito
 */
export const cognitoSignUp = (
  givenname: string,
  familyname: string,
  username: string,
  userpass: string,
  userphone: string,
  dispatch: React.Dispatch<AuthAction>,
  userPool: CognitoUserPool,
  clientMetadata?: ClientMetadata,
): Promise<AuthResult> => {
  if (username !== '' && userpass !== '') {
    const attributeList = [
      new CognitoUserAttribute({ Name: 'given_name', Value: givenname }),
      new CognitoUserAttribute({ Name: 'family_name', Value: familyname }),
      new CognitoUserAttribute({ Name: 'email', Value: username }),
    ];

    if (userphone) {
      attributeList.push(new CognitoUserAttribute({ Name: 'phone_number', Value: userphone }));
    }

    return new Promise((resolve, reject) => {
      userPool.signUp(
        username,
        userpass,
        attributeList,
        null,
        (err: CognitoError, result: ISignUpResult) => {
          if (err) {
            return reject({
              complete: false,
              error: wrapError(err, 'Unable to sign up'),
              message: `Sign up failed: ${err.message}`,
            });
          } else {
            dispatch({ type: 'setCognitoUser', payload: result.user });
            const { DeliveryMedium, Destination } = result.codeDeliveryDetails;
            let message = 'Account created.';
            if (DeliveryMedium && Destination) {
              message = `Account created, check ${DeliveryMedium} account ${Destination} to complete.`;
            }
            return resolve({
              complete: true,
              message: message,
            });
          }
        },
        clientMetadata,
      );
    });
  }
};
