import { useState } from 'react';

export interface UseExportModalWrapperReturn {
  exportLimitDialog: {
    isExportLimitDialogOpen: boolean;
    openExportLimitDialog: () => void;
    closeExportLimitDialog: () => void;
  };
  exportModal: {
    isExportModalOpen: boolean;
    openExportModal: () => void;
  };
}
/**
 * A react hook to manage the Export Modal Wrapper state
 */
export const useExportModalWrapper = (): UseExportModalWrapperReturn => {
  const [isExportLimitDialogOpen, setIsExportLimitDialogOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const openExportLimitDialog = () => setIsExportLimitDialogOpen(true);
  const closeExportLimitDialog = () => setIsExportLimitDialogOpen(false);

  const openExportModal = () => setIsExportModalOpen(true);

  return {
    exportLimitDialog: {
      isExportLimitDialogOpen,
      openExportLimitDialog,
      closeExportLimitDialog,
    },
    exportModal: {
      isExportModalOpen,
      openExportModal,
    },
  };
};
