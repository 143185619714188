import React, { useState } from 'react';
import { Box, IconButton, Theme, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { Button, Icon, IconSize, ReturnButton, SearchBox, SearchBoxProps } from '../../';
import { ExportButton, ExportButtonProps } from '../Buttons/ExportButton';
import Section from './components/Section';
export { Section as PageSection };

export interface LinkedListViewProps {
  /**
   * The translation function
   */
  t: (key: string, options?: Record<string, string | number>) => string;
  /**
   * main title for the page
   */
  pageTitle: string;
  /**
   * small viewport or not
   */
  isSmallDevice: boolean;
  /**
   * optional, override for the page container
   */
  dataTestId?: string;
  /**
   * optional, object describing the add button
   */
  addButton?: {
    text: string;
    onClick: () => void;
    testId?: string;
    disabled?: boolean;
  };
  /**
   * optional, object describing the export button
   */
  exportButton?: ExportButtonProps;
  /**
   * optional, if not provided the search box will not be displayed
   */
  searchBox?: SearchBoxProps;
  /**
   * optional, if not provided the filter button will not be displayed
   */
  filterButton?: {
    text: string;
    onClick: () => void;
  };
  /**
   * optional, component to render some quick filters underneath the search input
   */
  quickFiltersComponent?: React.ReactNode;
  /**
   * indicator and message for no results, will not render sections if noresults.isVisible = true
   */
  noResults?: {
    isVisible: boolean;
    message: string;
  };
  /**
   * rendered as long as noResults.isVisible is not true array of sections describing the main
   * content for the linked list
   */
  sections: {
    title?: string;
    testId?: string;
    key: string;
    children: JSX.Element | JSX.Element[];
  }[];
  /**
   * optional children for cases where the sections prop is not enough (like pagination or LinkForms)
   */
  children?: JSX.Element | JSX.Element[];
  /**
   * optional, when true removes the default x padding around content (per figma designs)
   */
  hideContentPx?: boolean;
  /**
   * optional, when true removes the default x padding on non-small devices
   */
  noPxOnLargeDevices?: boolean;
  /**
   * optional defaults to false, indicates that the button should use the returnTo path from useReturnTo hook
   */
  isReturn?: boolean;
}

/**
 * Renders a LinkedListView which is a generic page template that helps drive consistency
 */
export const LinkedListView: React.FC<React.PropsWithChildren<LinkedListViewProps>> = ({
  pageTitle,
  isSmallDevice,
  dataTestId,
  addButton,
  exportButton,
  searchBox,
  filterButton,
  quickFiltersComponent,
  noResults,
  sections,
  children,
  hideContentPx,
  noPxOnLargeDevices,
  isReturn = false,
  t,
}: LinkedListViewProps) => {
  const theme = useTheme<Theme>();
  const [smallDeviceSearchVisible, setSmallDeviceSearchVisible] = useState(false);

  const Title: JSX.Element = (
    <Typography variant={'h5'} component="h2">
      {pageTitle}
    </Typography>
  );

  const Search: JSX.Element = searchBox && (
    <>
      <Box
        display="flex"
        flexWrap="nowrap"
        sx={{ width: '100%', mb: isSmallDevice ? 0 : 4, '& .MuiFormControl-root': { my: 0 } }}
      >
        <SearchBox
          {...searchBox}
          onSubmit={(searchValue: string) => {
            isSmallDevice && setSmallDeviceSearchVisible(false);
            searchBox.onSubmit(searchValue);
          }}
          disableSelfSpacing={true}
          wrapperBoxProps={{ flex: 1 }}
        />
        {!isSmallDevice && filterButton ? (
          <Button
            data-testid="filter-button"
            sx={{ ml: 2, width: 100, height: 56 }}
            onClick={filterButton.onClick}
            color="primary"
            variant="outlined"
            endIcon={
              <Icon fillColor={theme.palette.primary.main} size={IconSize.SM} folder="actions/08-chevron-right" />
            }
          >
            {filterButton.text}
          </Button>
        ) : null}
      </Box>
      {quickFiltersComponent}
    </>
  );

  const Content = (
    <>
      {noResults && noResults?.isVisible ? (
        <Typography>{noResults.message}</Typography>
      ) : (
        sections?.map(({ key, ...rest }) => {
          return <Section key={key} {...rest} />;
        })
      )}
      {children}
    </>
  );

  const commonIconButtonStyles: SxProps = {
    borderRadius: 0.5,
    border: '1px solid',
    borderColor: 'primary.main',
    width: '48px',
    height: '48px',
  };

  const pagePx = isSmallDevice || noPxOnLargeDevices ? 0 : theme?.custom?.spacing?.sm;
  const contentPx = hideContentPx ? 0 : pagePx;

  return (
    <Box data-testid={dataTestId ?? 'linked-list-view'}>
      <Box display="flex" flexDirection="column" px={pagePx}>
        {/* Page header section, buttons, title on desktop & search on small devices */}
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
          mb={isSmallDevice ? 4 : 8}
          flexWrap="wrap"
        >
          {isSmallDevice && smallDeviceSearchVisible ? (
            Search
          ) : (
            <>
              {isSmallDevice ? <ReturnButton t={t} sx={{ mb: 0 }} isReturn={isReturn} /> : Title}
              <Box>
                {isSmallDevice && !!searchBox && (
                  <IconButton
                    data-testid={'linked-list-search-button'}
                    sx={{ ...commonIconButtonStyles }}
                    onClick={() => setSmallDeviceSearchVisible(true)}
                    aria-label={searchBox?.searchButtonAria}
                  >
                    <Icon fillColor={theme.palette.primary.main} size={IconSize.SM} folder="actions/12-search" />
                  </IconButton>
                )}
                {exportButton && (
                  <ExportButton
                    {...exportButton}
                    isSmallDevice={isSmallDevice}
                    dataTestId="linked-list-view-secondary-button"
                  />
                )}
                {addButton ? (
                  isSmallDevice ? (
                    // mobile buttons
                    <IconButton
                      data-testid={addButton?.testId || 'linked-list-add-button'}
                      color="secondary"
                      sx={{ ...commonIconButtonStyles, ml: 4, backgroundColor: 'primary.main' }}
                      onClick={addButton.onClick}
                      aria-label={addButton?.text}
                      disabled={addButton?.disabled}
                    >
                      <Icon fillColor={theme.palette.common.white} size={IconSize.SM} folder="actions/02-add" />
                    </IconButton>
                  ) : (
                    <Button
                      data-testid={addButton?.testId || 'linked-list-add-button'}
                      color="primary"
                      variant="contained"
                      startIcon={
                        <Icon fillColor={theme.palette.common.white} size={IconSize.SM} folder="actions/02-add" />
                      }
                      onClick={addButton.onClick}
                      aria-label={addButton?.text}
                      disabled={addButton?.disabled}
                    >
                      {addButton.text}
                    </Button>
                  )
                ) : (
                  // render a box the same size as a button to ensure spacing is consistent
                  <Box height={24} />
                )}
              </Box>
            </>
          )}
        </Box>

        {/* Mobile title  */}
        {isSmallDevice && <Box mb={4}>{Title}</Box>}

        {/* Desktop search */}
        {!isSmallDevice && Search}
      </Box>
      <Box px={contentPx} pb={isSmallDevice ? 10 : undefined}>
        {Content}
      </Box>
    </Box>
  );
};
