import React from 'react';
import { Box, FilledInput, FormControl, InputAdornment, InputLabel, Typography } from '@mui/material';
import { TenantLanguage } from '@askporter/grieg-types';
import { getCountryCodeFromLanguageCode } from '@askporter/utils';
import AutoSaveStatusText, {
  DEFAULT_STATUS_KEYS,
  FieldAutoSaveStatuses,
  translateObjectValues,
} from '../AutoSaveStatusText';

interface Props {
  id: string;
  title: string | React.ReactNode;
  t: (key: string) => string;
  values: Record<string, string>;
  tenantLanguages: TenantLanguage[];
  minRows?: number;
  disabled?: boolean;
  readonly?: boolean;
  onChange?: (languageCode: string, value: string) => void;
  onBlur?: (languageCode: string, value: string) => void;
  autoSaveStatuses?: FieldAutoSaveStatuses;
  clearAutosaveStatus?: (id: string) => void;
}

export const MultiLangTextField: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  title,
  t,
  values,
  tenantLanguages,
  minRows = 3,
  disabled,
  readonly,
  onChange,
  onBlur,
  autoSaveStatuses,
  clearAutosaveStatus,
}: Props) => {
  const translateKeys = translateObjectValues(t);

  return (
    <Box>
      <Box>{typeof title === 'string' ? <Typography variant="h6">{title}</Typography> : title}</Box>
      {(tenantLanguages || []).map(({ languageCode, languageName }) => (
        <FormControl fullWidth={true} key={`${languageCode}-form-control`}>
          <InputLabel variant="filled" htmlFor={`${id}-${languageCode}`}>
            {languageName}
          </InputLabel>
          <FilledInput
            id={`${id}-${languageCode}`}
            disabled={disabled}
            readOnly={readonly}
            value={values?.[languageCode]}
            multiline={true}
            minRows={minRows}
            onChange={(e) => onChange(languageCode, e?.target?.value)}
            onBlur={(e) => onBlur(languageCode, e?.target?.value)}
            startAdornment={
              <InputAdornment position="start">
                <img
                  width={32}
                  alt={`flag-${languageCode}`}
                  src={`/media/images/shared/flags/${getCountryCodeFromLanguageCode(languageCode)}.png`}
                />
              </InputAdornment>
            }
          />
          <AutoSaveStatusText
            statusText={translateKeys(DEFAULT_STATUS_KEYS)}
            autosaveStatus={autoSaveStatuses?.[languageCode]}
            clearAutosaveStatus={() => clearAutosaveStatus(id)}
            label={languageName}
          />
        </FormControl>
      ))}
    </Box>
  );
};
