import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { utils } from '@rjsf/core';
import { CustomWidgetProps } from '../types';

const { schemaRequiresTrueValue } = utils;

const CheckboxWidget: React.FC<React.PropsWithChildren<CustomWidgetProps>> = ({
  schema,
  id,
  value,
  disabled,
  readonly,
  label,
  autofocus,
  onChange,
  onBlur,
  onFocus,
  formContext: { onHasChanged },
}: CustomWidgetProps) => {
  const required = schemaRequiresTrueValue(schema);

  return (
    <FormControlLabel
      control={
        <Checkbox
          id={id}
          checked={typeof value === 'undefined' ? false : value}
          required={required}
          disabled={disabled || readonly}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autofocus}
          onChange={(event) => {
            onChange(event.target.checked);
            setTimeout(() => onHasChanged({ id, value: event.target.checked }), 0);
          }}
          onBlur={({ target: { value } }: React.FocusEvent<HTMLButtonElement>) => onBlur(id, value)}
          onFocus={({ target: { value } }: React.FocusEvent<HTMLButtonElement>) => onFocus(id, value)}
        />
      }
      label={label}
    />
  );
};

export default CheckboxWidget;
