import { QueryFunction } from 'react-query';
import { SearchTaskRequest, SearchTaskResultExternal } from '@askporter/grieg-types';
import { API } from '../api';

type PostQueryKey = [string, { postData: SearchTaskRequest; isForFetchingAllStats?: boolean }];

/**
 * React query POST function for task searches
 *
 * @param queryKey - tuple which is passed into axios, the first entry should contain the API path and the second entry
 * should include the POST data and config
 *
 * @returns data from API call
 */
export const postQueryFn: QueryFunction<SearchTaskResultExternal, PostQueryKey> = async ({
  queryKey,
}): Promise<SearchTaskResultExternal> => {
  const { postData, isForFetchingAllStats } = queryKey[1];
  let additionalHeaders = {};
  if (isForFetchingAllStats) {
    // mainly for Cypress to distinguish between requests
    additionalHeaders = { 'x-usecase': 'all_stats' };
  } else {
    // mainly for Cypress to distinguish between requests
    additionalHeaders = { 'x-usecase': 'filtered_search' };
  }
  const { data } = await API().post({ path: `${queryKey[0]}`, payload: postData, additionalHeaders });

  return data;
};
