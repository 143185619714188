import React from 'react';
import { AuthAction, AuthResult, SessionState } from './types';
import { CognitoIdToken, CognitoUser } from 'amazon-cognito-identity-js';
import { refreshCognitoSession } from '.';

/**
 * Retrieve a cognitoIdToken from the current user session, this IdToken is used in requests made to the platform.
 * @param dispatch        - reference to the auth state reducer dispatch method
 * @param cognitoUser     - instance of the current user object
 * @returns                 An IdToken string
 */
export const getCognitoIdToken = async (
  dispatch: React.Dispatch<AuthAction>,
  cognitoUser: CognitoUser,
): Promise<string | undefined> => {
  try {
    dispatch({ type: 'setFeedback', payload: `getting APIToken from user session` });
    let idToken = getIdToken(cognitoUser);

    if (idToken) {
      // check for expiry on idToken
      const { exp } = idToken.payload;
      const now = Date.now();
      if (Math.floor(now / 1000) >= exp) {
        // now is past the expiration time of the idToken
        // attempt refresh with refreshToken
        const res: AuthResult = await refreshCognitoSession(dispatch, cognitoUser, SessionState.pendingRefresh);
        dispatch({ type: 'setFeedback', payload: `refreshing expired token` });
        if (res.complete) {
          idToken = getIdToken(cognitoUser);
        }
      }
      const authToken = idToken.getJwtToken();
      dispatch({
        type: 'setAuthToken',
        payload: { authToken, feedbackMessage: `returning token, valid until ${new Date(exp * 1000)}` },
      });
      return authToken;
    } else {
      dispatch({ type: 'setFeedback', payload: `token get fail` });
      return;
    }
  } catch (err) {
    dispatch({ type: 'setFeedback', payload: `getAPIToken error: ${err}` });
    return;
  }
};

/**
 * Returns the IdToken stored in the cognito user object
 * @internal
 */
const getIdToken = (cognitoUser: CognitoUser): CognitoIdToken | undefined => {
  if (cognitoUser) {
    const token = cognitoUser.getSignInUserSession().getIdToken();
    return token;
  }
  return;
};
