/**
 * Basic phone number validation
 * @param number - the phone number to validate
 * @returns boolean
 */
export const phoneNumberIsValid = (number: string): boolean => {
  if (!number) return true;
  if (number.length < 8 || number.length > 15) return false;

  return true;
};
