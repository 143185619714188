export type SearchAssetSort = 'RELEVANCE' | 'TITLE_DESC' | 'TITLE_ASC' | 'CREATED_DESC' | 'CREATED_ASC' | 'CLOSEST';

export const SearchAssetSort = {
  RELEVANCE: 'RELEVANCE' as SearchAssetSort,
  TITLEDESC: 'TITLE_DESC' as SearchAssetSort,
  TITLEASC: 'TITLE_ASC' as SearchAssetSort,
  CREATEDDESC: 'CREATED_DESC' as SearchAssetSort,
  CREATEDASC: 'CREATED_ASC' as SearchAssetSort,
  CLOSEST: 'CLOSEST' as SearchAssetSort,
};
