import React from 'react';
import { CircularProgress, Button, ButtonProps } from '@mui/material';

export interface LoadingButtonProps extends ButtonProps {
  href?: string;
  onClick?: (event?: React.SyntheticEvent) => void;
  type?: 'button' | 'submit' | 'reset';
  isLoading?: boolean;
  children?: React.ReactNode;
  spinnerDataTestId?: string;
  dataTestId?: string;
}

/**
 * Renders a button with a loading spinner if the loading state is true, extends a normal button so defaults can
 * be overridden easily
 *
 * @param href -
 * @param onClick - (optional) the onClick function for the button
 * @param dataTestId - (optional) the data-testid string for the button
 * @param type - (optional) defaults to button, but can be updated to submit or reset
 * @param variant - (optional) the Material-UI button style variant, defaults to contained
 * @param isLoading - (optional) true if a network request has been made
 * @param children - (optional) the button element text, if a link is also provided the link is positioned on the left
 * @param spinnerDataTestId - (optional) the data-testid string for the spinner
 * @param sx - (optional) a Material-UI button style object
 * @param color - (optional) button colour e.g. primary, secondary etc
 */
export const LoadingButton: React.FC<React.PropsWithChildren<LoadingButtonProps>> = ({
  href,
  onClick,
  dataTestId = 'loading-button',
  type,
  isLoading,
  children,
  spinnerDataTestId = 'button-spinner',
  disabled,
  ...buttonProps
}: LoadingButtonProps) => {
  return (
    <Button
      href={href}
      onClick={onClick}
      variant="contained"
      data-testid={dataTestId}
      type={type}
      disabled={isLoading || disabled}
      {...buttonProps}
    >
      {children}
      {isLoading && (
        <CircularProgress
          size={24}
          sx={{ position: 'absolute', top: '50%', left: '50%', mt: '-12px', ml: '-12px' }}
          data-testid={spinnerDataTestId}
        />
      )}
    </Button>
  );
};
