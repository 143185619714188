import { ExportsInProgressArray, ExportsInProgressItem } from '../types';

/**
 * Add a pending export to the local storage
 * @param uid The UID of the export
 */
const addPendingExportToLocalStorage = ({ uid, userUid }: { uid: ExportsInProgressItem['uid']; userUid: string }) => {
  const exportsInProgressLocalStorage = localStorage.getItem(`${userUid}exportsInProgress`);
  const exportsInProgress: ExportsInProgressArray = exportsInProgressLocalStorage
    ? JSON.parse(exportsInProgressLocalStorage)
    : [];

  localStorage.setItem(
    `${userUid}exportsInProgress`,
    JSON.stringify([{ uid, status: 'PENDING' }, ...exportsInProgress]),
  );
};

/**
 * Update the exports in local storage
 */
const updateExportsItemInLocalStorage = ({ uid, status, userUid }: ExportsInProgressItem & { userUid: string }) => {
  const exportsInProgressLocalStorage = localStorage.getItem(`${userUid}exportsInProgress`);
  const exportsInProgress: ExportsInProgressArray = exportsInProgressLocalStorage
    ? JSON.parse(exportsInProgressLocalStorage)
    : [];

  const updatedExportsInProgress = exportsInProgress.map((item) => (item.uid === uid ? { uid, status } : item));

  localStorage.setItem(`${userUid}exportsInProgress`, JSON.stringify(updatedExportsInProgress));
};

/**
 * Remove the export item from the local storage
 */
const removeExportsItemInLocalStorage = ({ uid, userUid }: { uid: ExportsInProgressItem['uid']; userUid: string }) => {
  const exportsInProgressLocalStorage = localStorage.getItem(`${userUid}exportsInProgress`);
  const exportsInProgress: ExportsInProgressArray = exportsInProgressLocalStorage
    ? JSON.parse(exportsInProgressLocalStorage)
    : [];

  const updatedExportsInProgress = exportsInProgress.filter((item) => item.uid !== uid);

  localStorage.setItem(`${userUid}exportsInProgress`, JSON.stringify(updatedExportsInProgress));
};

/**
 * A function to remove all non-pending exports from the local storage
 */
const removeNonPendingExportsFromLocalStorage = ({ userUid }: { userUid: string }) => {
  const exportsInProgressLocalStorage = localStorage.getItem(`${userUid}exportsInProgress`);
  const exportsInProgress: ExportsInProgressArray = exportsInProgressLocalStorage
    ? JSON.parse(exportsInProgressLocalStorage)
    : [];

  const updatedExportsInProgress = exportsInProgress.filter((item) => item.status === 'PENDING');

  localStorage.setItem(`${userUid}exportsInProgress`, JSON.stringify(updatedExportsInProgress));
};

const removeAllExportsFromLocalStorage = ({ userUid }: { userUid: string }) => {
  localStorage.removeItem(`${userUid}exportsInProgress`);
};

/**
 * Get all exports in progress from the local storage
 */
const getExportsInProgressFromLocalStorage = ({ userUid }: { userUid: string }): ExportsInProgressArray => {
  const exportsInProgressLocalStorage = localStorage.getItem(`${userUid}exportsInProgress`);
  return exportsInProgressLocalStorage ? JSON.parse(exportsInProgressLocalStorage) : [];
};

/**
 * Saves the ExportsInProgressSnackbar open state to the local storage
 */
const toggleExportsInProgressSnackbarStateLocalStorage = (isOpen: boolean) => {
  localStorage.setItem('isExportsInProgressSnackbarOpen', JSON.stringify(isOpen));
};

/**
 * Get the ExportsInProgressSnackbar open state from the local storage
 */
const getExportsInProgressSnackbarStateLocalStorage = (): boolean => {
  const isExportsInProgressSnackbarOpen = localStorage.getItem('isExportsInProgressSnackbarOpen');
  return isExportsInProgressSnackbarOpen ? JSON.parse(isExportsInProgressSnackbarOpen) : false;
};

export {
  addPendingExportToLocalStorage,
  getExportsInProgressFromLocalStorage,
  toggleExportsInProgressSnackbarStateLocalStorage,
  getExportsInProgressSnackbarStateLocalStorage,
  updateExportsItemInLocalStorage,
  removeExportsItemInLocalStorage,
  removeNonPendingExportsFromLocalStorage,
  removeAllExportsFromLocalStorage,
};
