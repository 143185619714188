import React, { ReactElement } from 'react';
import { Tag, Person, Delete, Group, Business } from '@mui/icons-material';
import { SearchPeopleFilter_ScopeEnum as PeopleEnum, RoleSlug } from '@askporter/grieg-types';
import { ManagerRoles, checkPermissions } from '@askporter/utils';
import { OptionsParams } from '../../OptionsDropdown';
import { PeopleGroupActions } from '../types';

interface OptionType {
  name: string;
  icon: ReactElement;
  handleClick?: (arg?: any) => void;
}

/**
 * Displays list of options in popper
 *
 * @param loggedInUserRole - the role of the logged in user
 * @param type - whether it's a user, team or org
 * @param t - The translation function
 * @param actions - list of onClicks for the different menu items
 *
 */

interface OptionsParamsType {
  loggedInUserRole: RoleSlug;
  type: PeopleEnum;
  t: (key: string, options?: Record<string, string | number>) => string;
  actions: PeopleGroupActions;
  isInitiator?: boolean;
  onlyRemove?: boolean;
  role?: RoleSlug;
  enableMakeAssignee?: boolean;
  enableRemoveAssignee?: boolean;
  isAssignee?: boolean;
}

export const getOptionsParams = ({
  loggedInUserRole,
  type,
  t,
  actions,
  isInitiator,
  onlyRemove,
  role,
  enableMakeAssignee = true,
  enableRemoveAssignee = true,
  isAssignee = false,
}: OptionsParamsType): OptionsParams => {
  const isSuperUser = checkPermissions(loggedInUserRole, [RoleSlug.SVCUSRSPR, RoleSlug.SUPPSPR]);
  const isSuperSupplierUser = checkPermissions(loggedInUserRole, [RoleSlug.SVCUSRSPR, RoleSlug.SUPPSPR]);
  const isManagerUser = checkPermissions(loggedInUserRole, ManagerRoles);
  const isRoleManager = checkPermissions(role, ManagerRoles);

  if (!actions) {
    return null;
  }

  const options: OptionType[] = [];

  if (onlyRemove && !isInitiator) {
    options.push({
      name: t('ns.linked_people:options_remove'),
      icon: <Delete />,
      handleClick: actions.remove,
    });

    return {
      title: null,
      options,
    };
  }

  if (isManagerUser || isSuperUser) {
    if (
      (type !== PeopleEnum.USERS || (type === PeopleEnum.USERS && isRoleManager)) &&
      !isSuperSupplierUser &&
      enableMakeAssignee
    ) {
      options.push({
        name: t('ns.linked_people:options_make_assignee'),
        icon: <Tag />,
        handleClick: actions.makeAssignee,
      });
    }

    options.push({
      name: t(`ns.linked_people:options_view_${type.toLowerCase().slice(0, -1)}`),
      icon: {
        [PeopleEnum.USERS]: <Person />,
        [PeopleEnum.TEAMS]: <Group />,
        [PeopleEnum.ORGS]: <Business />,
      }[type],
      handleClick: actions.viewProfile,
    });
  }

  if (actions.remove && !isInitiator) {
    // if enableRemoveAssignee is false, and this is an assignee option, don't show the remove option
    if (!isAssignee || (isAssignee && enableRemoveAssignee)) {
      options.push({
        name: t('ns.linked_people:options_remove'),
        icon: <Delete />,
        handleClick: actions.remove,
      });
    }
  }

  return {
    title: t('ns.linked_people:options_title'),
    options,
  };
};
