import { PermissionRole, RoleSlug } from '@askporter/grieg-types';

// refer to issue https://github.com/askporter/front-end/issues/1533
export const getAllowedRolesByRolePolicy = (policy: PermissionRole): Array<RoleSlug> => {
  const mapping = {
    [PermissionRole.SVCUSR]: [RoleSlug.SVCUSR, RoleSlug.SVCUSRSPR],
    [PermissionRole.SVCUSRSPR]: [RoleSlug.SVCUSRSPR],
    [PermissionRole.SUPP]: [RoleSlug.SUPP, RoleSlug.SUPPSPR],
    [PermissionRole.SUPPSPR]: [RoleSlug.SUPPSPR],
    [PermissionRole.MANAGER]: [
      RoleSlug.AGENT,
      RoleSlug.AGENTSPR,
      RoleSlug.OWNER,
      RoleSlug.OWNERSPR,
      RoleSlug.CLIENTADMIN,
      RoleSlug.PLATADMIN,
    ],
    [PermissionRole.OWNER]: [RoleSlug.OWNER, RoleSlug.OWNERSPR],
    [PermissionRole.OWNERSPR]: [RoleSlug.OWNERSPR],
    [PermissionRole.AGENT]: [RoleSlug.AGENT, RoleSlug.AGENTSPR, RoleSlug.CLIENTADMIN, RoleSlug.PLATADMIN],
    [PermissionRole.AGENTSPR]: [
      RoleSlug.AGENTSPR,
      RoleSlug.OWNER,
      RoleSlug.OWNERSPR,
      RoleSlug.CLIENTADMIN,
      RoleSlug.PLATADMIN,
    ],
    [PermissionRole.CLIENTADMIN]: [RoleSlug.CLIENTADMIN, RoleSlug.PLATADMIN],
    [PermissionRole.PLATADMIN]: [RoleSlug.PLATADMIN],
  };

  return mapping[policy];
};
