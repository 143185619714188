import { Component, ErrorInfo, ReactNode, isValidElement } from 'react';

export type FallbackRender = (errorData: {
  /** The error that was caught. */
  error: Error;
  /** A function to reset the error state. */
  resetError(): void;
}) => React.ReactElement;

interface Props {
  /**
   * The child components to be rendered.
   */
  children: ReactNode;
  /**
   * A fallback component to be rendered if an error occurs.
   */
  fallback: FallbackRender | ReactNode;
  /**
   * A function to be called when an error occurs.
   */
  componentDidCatch?(error: Error): void;
}

interface State {
  hasError: boolean;
  error?: Error;
}

/**
 * A minimal React Error Boundary component
 */
class ReactErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, _errorInfo: ErrorInfo): void {
    if (typeof this.props.componentDidCatch === 'function') {
      this.props.componentDidCatch(error);
    }
  }

  resetError = (): void => {
    this.setState({ hasError: false });
  };

  render(): ReactNode {
    const { hasError } = this.state;
    const { children, fallback } = this.props;

    if (hasError) {
      if (isValidElement(fallback) || typeof fallback === 'string') {
        return fallback;
      } else if (typeof fallback === 'function') {
        return fallback({ error: this.state.error, resetError: this.resetError });
      }
    }

    return children;
  }
}

export { ReactErrorBoundary };
