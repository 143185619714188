import React from 'react';
import { Box } from '@mui/material';

export interface FilterWrapperProps {
  children: React.ReactNode;
  px?: string | number;
}

/**
 * For wrapping the CheckboxFilterItem and FilterCount so they are displayed next to each other
 *
 * @param children - React components to wrap
 */
export const FilterWrapper: React.FC<React.PropsWithChildren<FilterWrapperProps>> = ({
  children,
  px = 0,
}: FilterWrapperProps) => {
  return <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: 'grey.100', px }}>{children}</Box>;
};
