import { SearchFreeText } from './SearchFreeText';
import { SearchUserFilter } from './SearchUserFilter';
import { SearchUserSort } from './SearchUserSort';
import { TimezoneName } from './TimezoneName';

export interface UserExportCreate {
  name: string;
  timezone: TimezoneName;
  freeText?: SearchFreeText;
  format: UserExportCreate_FormatEnum;
  fields?: Array<UserExportCreate_FieldsEnum>;
  filter?: SearchUserFilter;
  sort?: SearchUserSort;
}

export type UserExportCreate_FormatEnum = 'STANDARD' | 'EXCEL_COMPATIBLE';

export const UserExportCreate_FormatEnum = {
  STANDARD: 'STANDARD' as UserExportCreate_FormatEnum,
  EXCELCOMPATIBLE: 'EXCEL_COMPATIBLE' as UserExportCreate_FormatEnum,
};

export type UserExportCreate_FieldsEnum = 'ACCOUNT_SETTINGS' | 'LINKED_TO_USER';

export const UserExportCreate_FieldsEnum = {
  ACCOUNTSETTINGS: 'ACCOUNT_SETTINGS' as UserExportCreate_FieldsEnum,
  LINKEDTOUSER: 'LINKED_TO_USER' as UserExportCreate_FieldsEnum,
};
