import React, { useState } from 'react';
import { FilledInput } from '@mui/material';
import { FieldProps } from '@rjsf/core';
import { getCountryCodeFromLanguageCode } from '@askporter/utils';
import {
  Accordion,
  MuiMaterial,
  MuiIcons,
  AutoSaveStatusText,
  translateObjectValues,
  DEFAULT_STATUS_KEYS,
  AutoSaveStatus,
  RJSFProps,
} from '../..';

const { ExpandMore: ExpandMoreIcon } = MuiIcons;
const { FormControl, InputLabel, Divider, InputAdornment, AccordionDetails, AccordionSummary, Box, Typography } =
  MuiMaterial;

interface MultiLangFieldState {
  hasChanged: boolean;
  data: Record<string, string>;
  lastChaged?: string;
}

const MultiLangField = ({
  formData = {},
  idSchema,
  schema,
  onChange,
  onBlur,
  onFocus,
  disabled,
  readonly,
  options,
  formContext,
}: FieldProps): JSX.Element => {
  const { onHasChanged, autosaveStatuses, clearAutosaveStatus, t, tenantLanguages }: RJSFProps['formContext'] =
    formContext;
  const translateKeys = translateObjectValues(t);
  const tenantDefaultLang = (tenantLanguages || []).find((lang) => lang.defaultLanguage);
  const id = idSchema?.$id;

  const autosaveStatus = autosaveStatuses?.[id];
  const showAutosaveStatus: boolean = !!autosaveStatus && autosaveStatus !== AutoSaveStatus.NONE;

  const initialState = tenantLanguages?.reduce((prev, curr) => {
    return { ...prev, [curr?.languageCode]: formData?.[curr?.languageCode] };
  }, {});

  const [state, setState] = useState<MultiLangFieldState>({
    hasChanged: false,
    data: initialState,
    lastChaged: undefined,
  });

  const _onChange = (value: string, languageCode: string) => {
    const newData = { ...(state.data || {}), [languageCode]: value };
    onChange(newData);

    setState({ ...state, data: newData, hasChanged: true });
  };

  const _onBlur = (value: string, languageCode: string) => {
    state.hasChanged && onHasChanged({ id, value });
    onBlur(id, value);

    setState({ ...state, lastChaged: languageCode, hasChanged: false });
  };

  const _onFocus = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value);

  return (
    <Box>
      <Box>
        <Typography variant="h6">{schema?.title}</Typography>
      </Box>
      {formData[tenantDefaultLang?.languageCode] && (
        <Box sx={{ mt: 7.5 }}>
          <FormControl fullWidth={true}>
            <InputLabel variant="filled" htmlFor={'default-lang-input-readonly'}>
              {tenantDefaultLang?.languageName}
            </InputLabel>
            <FilledInput
              id={'default-lang-input-readonly'}
              readOnly={true}
              value={formData?.[tenantDefaultLang?.languageCode]}
              multiline={true}
              minRows={(options?.rows as number) || 3}
            />
          </FormControl>
        </Box>
      )}
      <Divider sx={{ mt: 6 }} />
      <Accordion
        titleComponent={
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">{t('common:form:multi_lang:accordion_header')}</Typography>
          </AccordionSummary>
        }
        contentComponent={
          <AccordionDetails>
            {(tenantLanguages || []).map(({ languageCode, languageName }) => (
              <FormControl fullWidth={true} key={`${languageCode}-form-control`}>
                <InputLabel variant="filled" htmlFor={`${idSchema?.$id}-${languageCode}`}>
                  {languageName}
                </InputLabel>
                <FilledInput
                  id={`${idSchema?.$id}-${languageCode}`}
                  disabled={disabled}
                  readOnly={readonly}
                  value={state.data?.[languageCode] || formData?.[languageCode]}
                  multiline={true}
                  minRows={(options?.rows as number) || 3}
                  onChange={(e) => _onChange(e?.target?.value, languageCode)}
                  onBlur={(e) => _onBlur(e?.target?.value, languageCode)}
                  onFocus={_onFocus}
                  startAdornment={
                    <InputAdornment position="start">
                      <img
                        width={32}
                        alt={`flag-${languageCode}`}
                        src={`/media/images/shared/flags/${getCountryCodeFromLanguageCode(languageCode)}.png`}
                      />
                    </InputAdornment>
                  }
                />
                {showAutosaveStatus && state.lastChaged === languageCode && (
                  <AutoSaveStatusText
                    statusText={translateKeys(DEFAULT_STATUS_KEYS)}
                    autosaveStatus={autosaveStatus}
                    clearAutosaveStatus={() => clearAutosaveStatus(id)}
                    label={languageName}
                  />
                )}
              </FormControl>
            ))}
          </AccordionDetails>
        }
        displayDivider={false}
      />
    </Box>
  );
};

export default MultiLangField;
