export {
  fullNavButtons,
  fullNavButtonLinks,
  iconNavButtons,
  createIconNavButtonLinks,
  menuOnlyNavButtons,
  menuOnlyNavButtonLinks,
} from './links';
export type { IconLink, NavbarLink, FullNavButton, IconNavButton, MenuOnlyNavButton } from './links';
export { useResponsiveProperties } from './useResponsiveProperties';
export { checkIfNavButtonIsAllowed } from './checkIfNavButtonIsAllowed';
export const isNavDisabled = (currentLocation: string, itemHref?: string) => {
  if (itemHref === '/app/account') return currentLocation.includes('account');
  if (itemHref === '/app/admin-config' && currentLocation.includes('admin-config')) return true;
  if (currentLocation === itemHref) return true;
  return false;
};
export const disabledNavStyle = (currentLocation: string, itemHref?: string) => {
  const isDisabled = isNavDisabled(currentLocation, itemHref);
  return {
    isDisabled,
    disabledNavStyles: {
      '&.Mui-disabled': {
        opacity: isDisabled ? 0.6 : 1,
      },
    },
  };
};
