import React from 'react';
import { FilledTextFieldProps, OutlinedTextFieldProps, TextField } from '@mui/material';
import ReactCurrencyInput from 'react-currency-input-field';
import { currencyMap } from './currencyMap';

export interface CurrencyInputProps {
  onChange: (monetaryValueAsString: string) => void;
  intlConfig: { locale: string; currency: string };
  value: string | undefined;
  textFieldProps?: Partial<FilledTextFieldProps | OutlinedTextFieldProps>;
  name?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

const CustomInput = React.forwardRef(
  ({ textFieldProps, ...props }: Pick<CurrencyInputProps, 'textFieldProps' | 'value'>, ref) => {
    return (
      <TextField
        inputRef={ref}
        variant="filled"
        {...props}
        {...textFieldProps}
        // a problem has been observed with react-currency-input-field whereby the forwarded props include a formatted
        // string representation of the value, which is necessary, however this string does not always clear when the
        // actual controlled value switches to undefined meaning the incorrect value could be displayed to the user.
        // To mitigate this we only pass the formatted value into our custom input if the value is not defined.
        value={textFieldProps?.value === undefined ? '' : props?.value}
      />
    );
  },
);
// eslint-disable-next-line functional/immutable-data
CustomInput.displayName = 'CustomInput';

export const CurrencyInput: React.FC<React.PropsWithChildren<CurrencyInputProps>> = React.forwardRef(
  ({ onChange, onBlur, intlConfig, value, textFieldProps: tfProps, name }: CurrencyInputProps, _ref) => {
    const textFieldProps = { ...tfProps, name, value };
    return (
      <ReactCurrencyInput
        customInput={CustomInput}
        allowDecimals={true}
        value={value}
        /**
         * This is a workaround for a bug in react-currency-input-field where the backspace key does not work as expected, for
         * values with a suffix detailed in this issue: https://github.com/askporter/front-end/issues/4828
         */
        intlConfig={{ ...intlConfig, currency: undefined }}
        prefix={currencyMap[intlConfig.currency as keyof typeof currencyMap]}
        onValueChange={onChange}
        onBlur={onBlur}
        {...{ textFieldProps }}
      />
    );
  },
);
// eslint-disable-next-line functional/immutable-data
CurrencyInput.displayName = 'CurrencyInput';
