import { AssetExportReadExternal, TaskExportReadExternal, UserExportReadExternal } from '@askporter/grieg-types';
import { FetchExportByUidData } from '../../../../../utils/fetchExportByUid';

const isTaskExport = (exportDetail: FetchExportByUidData): exportDetail is TaskExportReadExternal =>
  exportDetail.type === 'TASK';

const isAssetExport = (exportDetail: FetchExportByUidData): exportDetail is AssetExportReadExternal =>
  exportDetail.type === 'ASSET';

const isUserExport = (exportDetail: FetchExportByUidData): exportDetail is UserExportReadExternal =>
  exportDetail.type === 'USER';

/**
 * On pages like Tasks, a default filter is applied to the list view(eg. statusType: ['OPEN']).
 * Passing a filter with the default filter undefined(e.g. statusType: undefined), would make the list view page apply the default filter(for tasks this would be statusType: ['OPEN']), instead of not applying any filter.
 * If the filter object doesn't have the default value, it should be added so the list view is displayed correctly.
 * @param exportDetail - an object containing the export detail
 * @returns filter - the filter from the export with default values applied
 */
export const getFilterWithDefaultValues = (exportDetail: FetchExportByUidData) => {
  if (isTaskExport(exportDetail)) {
    return {
      ...exportDetail.filter,
      statusType: exportDetail.filter?.statusType ? exportDetail.filter.statusType : [],
    };
  } else if (isAssetExport(exportDetail)) {
    return {
      ...exportDetail.filter,
      active: exportDetail.filter?.active ? exportDetail.filter.active : [],
    };
  } else if (isUserExport(exportDetail)) {
    return {
      ...exportDetail.filter,
      enabled: exportDetail.filter?.enabled ? exportDetail.filter.enabled : [],
    };
  }
  return exportDetail.filter;
};
