import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface URLQueryParamsProps {
  state: Record<string, unknown>;
}

/**
 * This component doesn't render any UI, it updates URL query params based on given state object, e.g.
 * if you pass in an object like \{ freeText: 'term', page: 1 \}
 * then URL params will be appended with freeText=term&page=1
 *
 * A key called 'filter' on the state object is a special one and the value is expected to be a complex object.
 * This value gets JSON stringified and urlencoded.
 *
 * @param state - key-value object
 */
export const URLQueryParams = ({ state }: URLQueryParamsProps): null => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    let queryChanged = false;
    const stateKeys = Object.keys(state);

    stateKeys.forEach((key) => {
      if (
        !['filter', 'extendedMetadataStats', 'additionalInformation', 'searchFilter'].includes(key) &&
        state[key] != null &&
        query.get(key) !== state[key].toString()
      ) {
        const currentUrlValue = query.get(key);
        const newValue = state[key].toString();

        if (currentUrlValue !== null && newValue === '') {
          query.delete(key);
          queryChanged = true;
        } else if (newValue !== '') {
          query.set(key, newValue);
          queryChanged = true;
        }
      } else if (['filter', 'extendedMetadataStats', 'additionalInformation', 'searchFilter'].includes(key)) {
        const filterLength = Object.keys(state[key] || {}).length;
        const newEncodedQuery = encodeURIComponent(JSON.stringify(state[key]));
        const currentUrlFilter = query.get(key);

        if (filterLength && currentUrlFilter !== newEncodedQuery) {
          query.set(key, newEncodedQuery);
          queryChanged = true;
        } else if (currentUrlFilter !== null && filterLength === 0) {
          query.delete(key);
          queryChanged = true;
        }
      }
    });

    if (queryChanged) history.replace({ search: query.toString() });
  }, [state]);

  return null;
};

export { parseURLQueryParams } from './parseURLQueryParams';
