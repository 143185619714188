import React, { ElementType } from 'react';
import { Grid, GridProps, GridSize, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

interface AskPorterGridProps extends GridProps {
  xxs?: GridSize;
  component?: ElementType<any>; // mirroring the type seen when hovering the component prop
}

/**
 * Temporary solution to what seems to be a lack of support for custom breakpoints in the new Grid, in previous versions
 * of MUI it was possible to address this (below), however the source code for Grid has changed since.
 *
 * @param xxs - the number of columns out of 12 you wish to fill
 * @param others - accepts all other grid props from [Grid API](https://material-ui.com/api/grid/)
 *
 * @returns Grid component
 */
export const AskPorterGrid: React.FC<React.PropsWithChildren<AskPorterGridProps>> = ({
  xxs,
  xs,
  ...other
}: AskPorterGridProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.only('xxs'));

  // If the current breakpoint is xxs and an xxs value was provided then calculate the flex values
  if (matches && xxs) {
    const flexPercentage = (parseInt(xxs.toString()) / 12) * 100 || 12;

    return (
      <Grid
        {...other}
        sx={{ flexBasis: `${flexPercentage}%`, flexGrow: 0, maxWidth: `${flexPercentage}%`, ...other.sx }}
      />
    );
  }

  // As we destructured xs we should add it back in if it exists
  if (xs) return <Grid xs={xs} {...other} />;

  // Otherwise if xxs exists but xs doesn't we should pass xxs in to permit standard grid behavior
  if (xxs) return <Grid xs={xxs} {...other} />;

  // Otherwise return a grid with just other props
  return <Grid {...other} />;
};
