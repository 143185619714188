import * as React from 'react';
import { Badge, BadgeProps as MuiBadgeProps, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CustomAvatar } from '../.';

export interface CustomBadgeProps extends MuiBadgeProps {
  /**
   * whether the badge is visible or not
   */
  isVisible: boolean;
  /**
   * color of the badge dot
   */
  badgeColor?: string;
}

/**
 * CustomBadge
 */
export const CustomBadge: React.FC<React.PropsWithChildren<CustomBadgeProps>> = ({
  isVisible,
  children,
  ...customBadgeProps
}: CustomBadgeProps): JSX.Element => {
  return <Badge {...customBadgeProps}>{children}</Badge>;
};

const StyledBadge = styled(CustomBadge)(
  ({ isVisible, badgeColor }) =>
    isVisible && {
      '& .MuiBadge-badge': {
        backgroundColor: badgeColor,
        boxShadow: `0 0 0 2px #ffffff`,
      },
    },
);

export interface AvatarBadgeProps extends MuiBadgeProps {
  /**
   * whether the badge is visible or not
   */
  isVisible: boolean;
  /**
   * the Avatar variant required to determine position of dot
   */
  avatarVariant: CustomAvatar['variant'];
  /**
   * the Avatar element as a child
   */
  children?: React.ReactNode;
  /**
   * the data-testid string for the badge
   */
  dataTestId?: string;
  /**
   * color of the badge dot
   */
  badgeColor?: string;
}

/**
 * Badge that shows up on the Avatar component
 */
export const AvatarBadge: React.FC<React.PropsWithChildren<AvatarBadgeProps>> = ({
  dataTestId = 'avatar-badge',
  children,
  avatarVariant,
  isVisible,
  badgeColor,
  ...avatarBadgeProps
}: AvatarBadgeProps): JSX.Element => {
  const avatarOverlapVariantMap: Record<CustomAvatar['variant'], MuiBadgeProps['overlap']> = {
    circular: 'circular',
    rounded: 'rectangular',
    square: 'rectangular',
  };

  const {
    palette: {
      success: { main },
    },
  } = useTheme();

  return (
    <StyledBadge
      overlap={avatarOverlapVariantMap[avatarVariant]}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant="dot"
      data-testid={`${dataTestId}-visible-${isVisible}`}
      isVisible={isVisible}
      sx={{ width: '100%', height: '100%' }}
      badgeColor={badgeColor || main}
      {...avatarBadgeProps}
    >
      {children}
    </StyledBadge>
  );
};
