type CheckboxEssentialFields =
  | 'TASK_ESSENTIALS'
  | 'ASSET_ESSENTIALS'
  | 'USER_ESSENTIALS'
  | 'ORG_ESSENTIALS'
  | 'TEAM_ESSENTIALS';

const CheckboxEssentialFields = {
  TASK_ESSENTIALS: 'TASK_ESSENTIALS' as CheckboxEssentialFields,
  ASSET_ESSENTIALS: 'ASSET_ESSENTIALS' as CheckboxEssentialFields,
  USER_ESSENTIALS: 'USER_ESSENTIALS' as CheckboxEssentialFields,
  ORG_ESSENTIALS: 'ORG_ESSENTIALS' as CheckboxEssentialFields,
  TEAM_ESSENTIALS: 'TEAM_ESSENTIALS' as CheckboxEssentialFields,
};

export { CheckboxEssentialFields };
