export const getPageTitleByErrorStatusCode = (statusCode: number, t: (key: string) => string): string | undefined => {
  const title = {
    202: '',
    500: `${t('ns.error_page:500:page_title')}`,
    502: `${t('ns.error_page:500:page_title')}`,
    401: `${t('ns.error_page:403:page_title')}`,
    403: `${t('ns.error_page:403:page_title')}`,
  }[statusCode];

  return title;
};
