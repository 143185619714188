import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, FormHelperText, FormHelperTextProps, useTheme } from '@mui/material';
import { SystemProps, Theme } from '@mui/system';
import { Icon, IconSize } from '../Icon';

export { DEFAULT_STATUS_KEYS, EMPTY_STATUS_KEYS, translateObjectValues } from './utils';

export enum AutoSaveStatus {
  NONE = 'NONE',
  SAVING = 'SAVING',
  SAVED = 'SAVED',
  ERROR = 'ERROR',
  FORM_VALIDATION_FAILED = 'FORM_VALIDATION_FAILED',
}

export interface FieldAutoSaveStatuses {
  [id: string]: AutoSaveStatus;
}

interface AutoSaveStatusTextProps {
  /**
   * the autosave status, this is used to determine what text is displayed. Also a suffix for data-testid
   */
  autosaveStatus: AutoSaveStatus;
  /**
   * function used to clear status
   */
  clearAutosaveStatus: () => void;
  /**
   *  combined with status to generate aria label, also prefixes data-testid
   */
  label: string;
  /**
   * Translate function.
   */
  statusText?: {
    saving?: string;
    saved?: string;
    error?: string;
    failed?: string;
  };
  /**
   * system props applied to a box container. overrides defaults like ml, and pt.
   */
  boxProps?: SystemProps<Theme>;
  /**
   * object containing a string that will be displayed in place of the standard status text once, a clear function is
   * required to remove the text
   */
  oneTimeOverride?: {
    statusText: string;
    clearStatusText: () => void;
  };
}

type HelperProps = { formHelperTextDefaultProps: FormHelperTextProps; icon?: JSX.Element };
const defaultProps: HelperProps = { formHelperTextDefaultProps: { children: '', error: false } };

/**
 * Renders an AutoSaveStatusText component, used to indicate the autosave status, e.g. saving, saved, error
 */
export const AutoSaveStatusText: React.FC<React.PropsWithChildren<AutoSaveStatusTextProps>> = ({
  autosaveStatus,
  clearAutosaveStatus,
  label,
  statusText,
  boxProps,
  oneTimeOverride,
}: AutoSaveStatusTextProps) => {
  const {
    palette: {
      success: { main: successColor },
      info: { main: infoColor },
      error: { main: errorColor },
      warning: { main: warningColor },
    },
  } = useTheme();

  const [props, setProps] = useState<HelperProps>(defaultProps);

  useEffect(() => {
    const restoreHelperDefaults = (autosaveStatusWhenCalled: AutoSaveStatus) =>
      setTimeout(() => {
        if (autosaveStatus === autosaveStatusWhenCalled) {
          setProps(defaultProps);
          clearAutosaveStatus();
          if (oneTimeOverride?.clearStatusText) oneTimeOverride.clearStatusText();
        }
      }, 3000);

    switch (autosaveStatus) {
      case AutoSaveStatus.SAVING:
        setProps({
          icon: <CircularProgress size={16} data-testid="autosave-spinner" />,
          formHelperTextDefaultProps: {
            children: oneTimeOverride?.statusText || statusText?.saving || '',
            error: false,
            sx: { color: infoColor },
          },
        });
        break;
      case AutoSaveStatus.SAVED:
        setProps({
          icon: <Icon fillColor={successColor} size={IconSize.SM} folder="status/01-general/06-tick" />,
          formHelperTextDefaultProps: {
            children: oneTimeOverride?.statusText || statusText?.saved || '',
            error: false,
            sx: { color: successColor },
          },
        });
        restoreHelperDefaults(autosaveStatus);
        break;
      case AutoSaveStatus.ERROR:
        setProps({
          icon: <Icon fillColor={errorColor} size={IconSize.SM} folder="status/01-general/04-error-circle" />,
          formHelperTextDefaultProps: {
            children: oneTimeOverride?.statusText || statusText?.error || '',
            error: true,
            sx: { color: errorColor },
          },
        });
        restoreHelperDefaults(autosaveStatus);
        break;
      case AutoSaveStatus.FORM_VALIDATION_FAILED:
        setProps({
          icon: <Icon fillColor={warningColor} size={IconSize.SM} folder="status/01-general/03-warning-triangle" />,
          formHelperTextDefaultProps: {
            children: oneTimeOverride?.statusText || statusText?.failed || '',
            error: true,
            sx: { color: warningColor },
          },
        });
        restoreHelperDefaults(autosaveStatus);
        break;
      default:
        break;
    }
  }, [autosaveStatus]);

  return (
    <Box display="flex" alignItems="center" aria-label={`${label} ${autosaveStatus}`} ml={3.5} pt={1} {...boxProps}>
      {props.icon}
      <FormHelperText
        {...props.formHelperTextDefaultProps}
        data-testid={`${label}-autosave-status-text-${autosaveStatus}`}
        sx={{ ml: 2, ...props.formHelperTextDefaultProps?.sx }}
      />
    </Box>
  );
};

export default AutoSaveStatusText;
