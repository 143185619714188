import React, { useContext, useState } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { ErrorModel, TenantConfig } from '@askporter/grieg-types';
import { FatalError } from './types';
import { defaultConfig, remediateErrors, validateConfig } from './utils';
import { fetchStaticTenant } from './utils/fetchStaticTenant';

export interface ConfigProviderProps {
  /**
   * Child components to be rendered when not loading and not errored (also see continueOnStatusCodes)
   */
  children: React.ReactNode[] | React.ReactNode;
  /**
   * Component to render on error
   */
  errorPageComponent: React.ReactNode[] | React.ReactNode;
  /**
   * Component to render when loading
   */
  loadingComponent: React.ReactNode[] | React.ReactNode;
  /**
   * The config provider will default to displaying the errorPageComponent if calling the static/tenant endpoint fails,
   * providing a specific error code and component will render that component when the code is hit instead
   */
  codeSpecificErrorPages?: { code: number; component: React.ReactNode[] | React.ReactNode }[];
}

// Assign a set of initial values for the context. Once the config data has been fetched with React query we validate it
// and assign some more meaningful (usable) default/fallback values if anything is missing
export const ConfigContext = React.createContext<TenantConfig>({
  tenantName: '',
  tenantDisplay: {
    palette: defaultConfig.tenantDisplay.palette,
    links: {
      privacyPolicyUrl: '',
      termsOfUseUrl: '',
      landingZonePath: '',
    },
    logos: {
      logo: '',
      logoSmall: '',
      favicon: '',
    },
    coverImages: [],
  },
  tenantContact: {
    phoneNumber: '',
    email: '',
  },
  tenantBot: {
    botName: '',
    principalConversationTypeUrn: '',
    avatar: '',
  },
  tenantCognito: {
    userPool: '',
    appClientId: '',
    issuer: '',
  },
  tenantIntegrations: {
    analyticsId: 27,
    mapsKey: '',
  },
  featureFlags: {},
  tenantLanguages: [],
});

/**
 * Hook for obtaining the tenant data from the Config context
 *
 * @returns The config object
 */
export const useConfig = (): TenantConfig => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig hook must be used within an ConfigProvider component');
  }
  return context;
};

/**
 * Provider component which passes down the config/tenant data
 *
 * @param children - The React children components to wrap with the provider
 * @returns ConfigProvider component with children
 */
export const ConfigProvider: React.FC<React.PropsWithChildren<ConfigProviderProps>> = ({
  children,
  errorPageComponent,
  loadingComponent,
  codeSpecificErrorPages = [],
}: ConfigProviderProps) => {
  const [isDataValidated, setIsDataValidated] = useState<boolean>(false);
  const [fatalError, setFatalError] = useState<FatalError>();
  const [validatedData, setValidatedData] = useState<TenantConfig>();

  const { isLoading, error } = useQuery<TenantConfig, AxiosError<ErrorModel>>(
    'static/tenant',
    () => fetchStaticTenant(),
    {
      retry: 2,
      onSuccess: (data) => {
        setValidatedData(data);
        // Create a copy of the data to avoid mutating the original in remediateErrors
        const configData = { ...data };
        // Once the config data has been retrieved, validate it, resolve and report any errors.
        const { configErrors, fatalError: fatalErr } = validateConfig(configData);

        if (fatalErr) setFatalError(fatalErr);

        if (Object.keys(configErrors).length > 0) {
          remediateErrors(configErrors, configData);
        }

        if (fatalError === undefined) setIsDataValidated(true);

        setValidatedData((prevValues) => {
          return { ...prevValues, ...configData };
        });
      },
      onError: (error) => {
        const { configErrors, fatalError: fatalErr } = validateConfig(error);

        const configData: TenantConfig = {};

        if (fatalErr) setFatalError(fatalErr);

        if (Object.keys(configErrors).length > 0) {
          remediateErrors(configErrors, configData);
        }

        setValidatedData((prevValues) => {
          return { ...prevValues, ...configData };
        });
      },
    },
  );

  // If we hit a fatal error, we render a minimal version of the app withe a fallback theme, without this we would need
  // to consider including various providers (such as )
  if (fatalError && !isLoading) {
    const errorComponent =
      codeSpecificErrorPages.find(({ code }) => code === error?.response?.status)?.component || errorPageComponent;

    return <ConfigContext.Provider value={validatedData}>{errorComponent}</ConfigContext.Provider>;
  }
  // display a loader while we validate and fetch config
  else if (!isDataValidated || isLoading) return <>{loadingComponent}</>;
  else return <ConfigContext.Provider value={validatedData}>{children}</ConfigContext.Provider>;
};
