import React from 'react';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { Icon, IconSize } from '../../../../../..';
import { disabledNavStyle, IconLink, NavbarLink } from '../../../../utils';

interface MenuListItemsProps {
  navItems: Array<IconLink | NavbarLink>;
  t: (key: string) => string;
  divider?: boolean;
}

const MenuListItems: React.FC<MenuListItemsProps> = ({ navItems, t, divider = false }) => {
  return (
    <>
      {navItems.map((item, idx) => {
        if ('href' in item) {
          const { isDisabled, disabledNavStyles } = disabledNavStyle(location.pathname, item?.href);

          return (
            <Box key={`${item?.href}_${item?.translation}`}>
              <ListItem disablePadding>
                <ListItemButton
                  component={Link}
                  to={item?.href}
                  sx={{
                    width: '200px',
                    textDecoration: 'none',
                    color: 'inherit',
                    ...disabledNavStyles,
                  }}
                  disabled={isDisabled}
                  divider={divider}
                >
                  <ListItemIcon>
                    <Icon size={IconSize.MD} folder={item?.iconFolder} />
                  </ListItemIcon>
                  <ListItemText>{t(item?.translation)}</ListItemText>
                </ListItemButton>
              </ListItem>
            </Box>
          );
        } else if ('hamburger' in item) return <Box key={`hamburger_item_${idx}`}>{item?.hamburger}</Box>;
      })}
    </>
  );
};

export default MenuListItems;
