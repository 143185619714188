import { SideBarLayout, SplitPageLayout, SimplePageLayout, layoutsType } from '../types';

// To simplify the usage of the Layout component (e.g. avoid having to state the type), we can detect the type using
// predicates: https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
export const isSideBarLayout = (layout: layoutsType): layout is SideBarLayout => {
  return ((layout as SideBarLayout).aside && (layout as SideBarLayout).main) !== undefined;
};

export const isSplitPageLayout = (layout: layoutsType): layout is SplitPageLayout => {
  return ((layout as SplitPageLayout).main && (layout as SplitPageLayout).right) !== undefined;
};

export const isSimplePageLayout = (layout: layoutsType): layout is SimplePageLayout => {
  return (layout as SimplePageLayout).main !== undefined && Object.keys(layout).length === 1;
};
