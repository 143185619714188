import { SlaCurrentStatusReadOnly } from '@askporter/grieg-types';
import { IconFolder } from '../types';

export const slaIcons: Record<string, IconFolder> = {
  [SlaCurrentStatusReadOnly.RUNNING]: 'tasks/task-list/task-card-sla/sla-running',
  [SlaCurrentStatusReadOnly.PAUSED]: 'tasks/task-list/task-card-sla/sla-paused',
  [SlaCurrentStatusReadOnly.COMPLETEDONTIME]: 'tasks/task-list/task-card-sla/sla-completed',
  [SlaCurrentStatusReadOnly.COMPLETEDLATE]: 'tasks/task-list/task-card-sla/sla-completed-late',
  [SlaCurrentStatusReadOnly.OVERDUE]: 'tasks/task-list/task-card-sla/sla-overdue',
};
