import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import { Button, ButtonProps } from '../Buttons/Button';
import useReturnTo from './hooks/useReturnTo';

export { useReturnTo };

export interface ReturnButtonProps extends Omit<ButtonProps, 'onClick' | 'startIcon'> {
  isReturn?: boolean;
  defaultReturnPath?: string;
  defaultReturnPageTitle?: string;
  children?: string | JSX.Element | JSX.Element[];
  onClick?: () => void;
  dataTestId?: string;
  t: (key: string) => string;
}

/**
 * Renders a return button, pre-styled with a back icon.
 * There are two modes for the ReturnButton:
 *
 * 1. Using the returnTo path from the useReturnTo hook - which provides the path that was marked as being
 *    the one to return to (look for functions that use the setReturnToThisPage method).
 *
 * 2. Acts like a browser back button
 *
 *
 * @param isReturn - indicates that the button should use the returnTo path from useReturnTo hook
 * @param defaultReturnPath - fallback path if the button should use returnTo but it is not available
 * @param defaultReturnPageTitle - the text to use for the button when returning to the returnTo path
 * @param children - the button text for when the button is not using the returnTo path
 * @param onClick - optional override for custom on click behaviour
 * @param dataTestId - optional test id defaults to return-button
 * @param ButtonProps - accepts all standard button props apart from onClick and startIcon which are omitted
 *
 * @returns a Return button displaying the provided child text.
 */
export const ReturnButton: React.FC<React.PropsWithChildren<ReturnButtonProps>> = ({
  isReturn,
  defaultReturnPath,
  defaultReturnPageTitle,
  children,
  onClick,
  dataTestId = 'return-button',
  sx,
  t,
  ...other
}: ReturnButtonProps) => {
  const history = useHistory();
  const { returnTo, setReturnTo } = useReturnTo();
  const styles = sx || { marginBottom: '16px', textTransform: 'none' };

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (isReturn && returnTo) {
      history.push(returnTo);
      setReturnTo(undefined);
    } else if (isReturn && defaultReturnPath) {
      history.push(defaultReturnPath);
    } else {
      history.goBack();
    }
  };

  const returnToPath = returnTo?.split('?')?.[0];
  const isReturningToDefaultPath = isReturn && returnToPath === defaultReturnPath;

  return (
    <Button data-testid={dataTestId} startIcon={<ArrowBackIosIcon />} onClick={handleClick} sx={styles} {...other}>
      {isReturningToDefaultPath ? defaultReturnPageTitle : children || t('ns.common:back')}
    </Button>
  );
};
