import { DateTimeReadOnly } from './DateTimeReadOnly';
import { LangCode } from './LangCode';
import { SearchFreeText } from './SearchFreeText';
import { SearchUserFilter } from './SearchUserFilter';
import { SearchUserSort } from './SearchUserSort';
import { TimezoneName } from './TimezoneName';
import { UIDReadOnly } from './UIDReadOnly';
import { URNReadOnly } from './URNReadOnly';
import { UserRefHydrated } from './UserRefHydrated';

export interface UserExportReadExternal {
  uid: UIDReadOnly;
  urn: URNReadOnly;
  name: string;
  languageCode: LangCode;
  timezone: TimezoneName;
  resultCount?: number;
  progress: any;
  requestedAt: DateTimeReadOnly;
  freeText?: SearchFreeText;
  format: UserExportReadExternal_FormatEnum;
  createdBy: UserRefHydrated;
  type: UserExportReadExternal_TypeEnum;
  fields: Array<UserExportReadExternal_FieldsEnum>;
  filter: SearchUserFilter;
  sort: SearchUserSort;
}

export type UserExportReadExternal_FormatEnum = 'STANDARD' | 'EXCEL_COMPATIBLE';

export const UserExportReadExternal_FormatEnum = {
  STANDARD: 'STANDARD' as UserExportReadExternal_FormatEnum,
  EXCELCOMPATIBLE: 'EXCEL_COMPATIBLE' as UserExportReadExternal_FormatEnum,
};

export type UserExportReadExternal_TypeEnum = 'USER';

export const UserExportReadExternal_TypeEnum = {
  USER: 'USER' as UserExportReadExternal_TypeEnum,
};

export type UserExportReadExternal_FieldsEnum = 'ACCOUNT_SETTINGS' | 'LINKED_TO_USER';

export const UserExportReadExternal_FieldsEnum = {
  ACCOUNTSETTINGS: 'ACCOUNT_SETTINGS' as UserExportReadExternal_FieldsEnum,
  LINKEDTOUSER: 'LINKED_TO_USER' as UserExportReadExternal_FieldsEnum,
};
