import React from 'react';
import { Close } from '@mui/icons-material';
import { Drawer as MuiDrawer, Typography, Box, IconButton, DrawerProps as MuiDrawerProps } from '@mui/material';

interface DrawerProps {
  /**
   * The content of the drawer.
   */
  children: React.ReactNode;
  /**
   * On close callback function
   */
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  /**
   * If true the Drawer will appear
   */
  open: boolean;
  /**
   * The title text to display at the top of the Drawer
   */
  title: string | JSX.Element;
  /**
   * Any additional styles to apply to the Drawer
   */
  sx?: MuiDrawerProps['sx'];
  /**
   * The data test attribute
   */
  dataTestId?: string;
  /**
   * Drawer PaperProps
   */
  PaperProps?: Partial<MuiDrawerProps['PaperProps']>;
}

/**
 * Drawer component is mainly used to display the mobile version of Autocomplete component or a fixed set of options or text.
 */
export const Drawer: React.FC<React.PropsWithChildren<DrawerProps>> = ({
  children,
  onClose,
  open,
  title,
  sx,
  dataTestId,
  PaperProps,
}: DrawerProps) => {
  return (
    <MuiDrawer
      open={open}
      onClose={() => onClose(false)}
      anchor="bottom"
      transitionDuration={400}
      sx={sx}
      data-testid={dataTestId}
      PaperProps={PaperProps}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} pl={6} pt={8} pr={6}>
        <Typography variant="h6">{title}</Typography>
        <IconButton aria-label="close" onClick={() => onClose(false)} size="large">
          <Close sx={{ color: 'black' }} />
        </IconButton>
      </Box>
      <Box sx={{ p: 6 }}>{children}</Box>
    </MuiDrawer>
  );
};
