import { keys } from 'lodash';
import { DayOfWeek } from '@askporter/grieg-types';
import { DayTimeType } from '../types';

export const getAllDayTimes = (dayTimes: DayTimeType[]): DayTimeType[] => {
  const result = keys(DayOfWeek).map((day: DayOfWeek) => {
    const matchingValue = dayTimes?.find((dayTime) => dayTime.days.includes(day));

    if (matchingValue) {
      return {
        days: matchingValue.days,
        time: matchingValue.time,
      };
    } else {
      return {
        days: [day],
        time: '',
      };
    }
  });

  // Deduplicate the result array
  const uniqueResult = result.reduce((acc, current) => {
    const existing = acc.find((item) => JSON.stringify(item) === JSON.stringify(current));
    if (!existing) {
      acc.push(current);
    }
    return acc;
  }, []);

  return uniqueResult;
};
