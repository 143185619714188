import React from 'react';
import { Box, Typography } from '@mui/material';
import { Button } from '../../index';

interface SearchFiltersProps {
  t: (key: string) => string;
  filterCount?: number;
  onClearAllClick: () => void;
  children?: React.ReactNode;
}

const SearchFilters: React.FC<React.PropsWithChildren<SearchFiltersProps>> = ({
  t,
  filterCount,
  onClearAllClick,
  children,
}: SearchFiltersProps) => {
  return (
    <>
      <Box sx={{ mb: 4 }} display="flex" alignItems="baseline" justifyContent="space-between" data-testid="filter-list">
        <Typography variant="h6" component="h2">
          {t('ns.task_filter:header')}
        </Typography>
        <Button onClick={onClearAllClick} sx={{ textTransform: 'none', mr: 1 }} disabled={!filterCount}>
          {t('ns.task_filter:clear_all_button')}
        </Button>
      </Box>

      {children}
    </>
  );
};

export { SearchFilters };
