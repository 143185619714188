import { TransformedTaskFilterValues } from '../types';

/**
 * Logic to determine what filters should be displayed based on a user provided search term
 * @param filterValues - the TransformedTaskFilterValues
 * @param filterSearchValue - the user defined search term
 *
 * @returns TransformedTaskFilterValues with only results matching the user search
 */
export const filtersToDisplay = (
  filterValues: TransformedTaskFilterValues,
  filterSearchValue: string,
): TransformedTaskFilterValues | false => {
  // Create a new array containing just the filters that match the search term. Where no search value is provided all
  // items are returned
  const filterItems = filterValues?.map((filterValue) => {
    const displayName = filterValue?.displayName?.toLowerCase();
    const searchValue = filterSearchValue?.toLowerCase();

    if (displayName?.includes(searchValue)) return filterValue;
  });

  if (filterItems?.every((item) => item === undefined)) return false;
  return filterItems;
};
