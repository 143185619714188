import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { UserAttributes } from '../../types';
/**
 * Retrieve basic user details as a formatted object
 * @param attributes - instance of CognitoUserAttributes
 * @returns            a UserProfile object
 */
export const createUserProfile = (attributes: CognitoUserAttribute[]): Partial<UserAttributes> => {
  const profile: { [key: string]: string } = {};
  attributes.forEach((attr: CognitoUserAttribute) => {
    profile[attr.Name] = attr.Value;
  });

  const { email, picture, ...rest } = profile;

  return {
    username: email,
    avatar: picture,
    ...rest,
  };
};
