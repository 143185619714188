/**
 *  A safe way to get a user full name string in the format of "givenName familyName"
 * @param givenName - the given name of the user
 * @param familyName - the family name of the user
 * @returns the full name of the user or undefined if both params are missing. If only one is missing, the other one is returned.
 */
export const fullName = (givenName: string, familyName: string): string | undefined => {
  if (!givenName && !familyName) return undefined;
  if (givenName && familyName) return `${givenName} ${familyName}`;
  if (givenName) return givenName;
  return familyName;
};
