import { DayOfWeek } from '@askporter/grieg-types';

export const filterNoServiceTimeDays = <T>(dayTimes: Record<DayOfWeek, T>): Record<DayOfWeek, T> => {
  const newDayTimes = { ...dayTimes };
  Object.keys(dayTimes).forEach((day: DayOfWeek) => {
    if (dayTimes[day] === null) {
      delete newDayTimes[day];
    }
  });
  return newDayTimes;
};
