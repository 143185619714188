import { useCallback } from 'react';
import { SearchExtendedMetadataFilterCriteria } from '@askporter/client-grieg-lyric';
import { TaskState } from '@askporter/utils';
import { Actions } from '../types';

/**
 * Event handlers for the TaskFilterList
 */
export const useTaskFilterHandlers = (
  { setFilters, clearAllFilters }: Partial<Actions>,
  setFilterSearch: React.Dispatch<React.SetStateAction<Record<string, string>>>,
  filters: TaskState['search']['filter'],
): {
  handleSearchOnChange: (e: React.ChangeEvent<HTMLInputElement>, filterKey: string) => void;
  handleClearAll: () => void;
  handleDateTimeOnChange: (key: keyof TaskState['search']['filter'], target: string, value: string) => void;
} => {
  const handleSearchOnChange = (e: React.ChangeEvent<HTMLInputElement>, filterKey: string) => {
    const value = e.currentTarget.value;

    setFilterSearch((prevValues) => {
      return { ...prevValues, [filterKey]: value };
    });
  };

  const handleClearAll = useCallback(() => clearAllFilters(), [clearAllFilters]);

  const handleDateTimeOnChange = useCallback(
    (
      key: keyof Omit<TaskState['search']['filter'], 'actionOn'>,
      target: string,
      value: string | SearchExtendedMetadataFilterCriteria,
    ) => {
      setFilters({ [key]: { ...filters[key], [target]: value } });
    },
    [setFilters],
  );

  return {
    handleSearchOnChange,
    handleClearAll,
    handleDateTimeOnChange,
  };
};
