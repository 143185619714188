import React from 'react';
import { Box } from '@mui/material';
import { MuiCheckbox, MuiFormControlLabel, Typography, useTheme } from '@askporter/component-library';
import { ExportFieldCheckboxProps } from './fieldTypes';

export const ExportFieldCheckbox: React.FC<ExportFieldCheckboxProps> = ({
  fieldId,
  label,
  subLabel,
  isChecked,
  onChange = () => {},
  disabled = false,
}) => {
  const {
    custom: { spacing },
  } = useTheme();

  return (
    <MuiFormControlLabel
      data-testid={fieldId}
      disabled={disabled}
      control={
        <MuiCheckbox
          inputProps={{ 'data-testid': `${fieldId}-checkbox` } as React.InputHTMLAttributes<HTMLInputElement>}
          checked={isChecked}
          onChange={({ target: { checked } }) => onChange(checked)}
        />
      }
      labelPlacement="end"
      label={
        <Box sx={{ paddingTop: spacing.md }}>
          <Typography variant="body1">{label}</Typography>
          <Typography variant="body2" sx={{ paddingTop: spacing.md }}>
            {subLabel}
          </Typography>
        </Box>
      }
      sx={{
        alignItems: 'flex-start',
        marginTop: spacing.lg,
        '& .MuiCheckbox-root': {
          paddingTop: spacing.md,
        },
      }}
      disableTypography={true}
    />
  );
};
