import React from 'react';
import { Box, useTheme } from '@mui/material';
import { usePermissions } from '@askporter/auth-provider';
import { SearchTaskFilter } from '@askporter/client-grieg-lyric';
import { QuickFilterButton } from '@askporter/component-library';
import { TaskFilterPermissions } from '@askporter/utils';
import { Actions } from '../../types';
import { todayAsIsoRange } from '../../utils';

export interface QuickFiltersProps {
  t: (key: string, options?: Record<string, string | number>) => string;
  filters: SearchTaskFilter;
  filterPermissions: TaskFilterPermissions;
  actions: Actions;
}

/**
 * Renders the task Quick filter buttons
 * @param t - translation function
 * @param filters - filter state
 * @param actions - reducer action functions
 * @param filterPermissions - object describing the filter visibility
 */
export const QuickFilters: React.FC<React.PropsWithChildren<QuickFiltersProps>> = ({
  t,
  filters,
  actions,
  filterPermissions,
}: QuickFiltersProps) => {
  const showQuickFilterDueToday = usePermissions(filterPermissions.quickFilterDueToday);
  const showQuickFilterRespondToday = usePermissions(filterPermissions.quickFilterRespondToday);
  const showQuickFilterResolveToday = usePermissions(filterPermissions.quickFilterResolveToday);
  const {
    custom: { spacing },
  } = useTheme();

  return (
    <Box
      mb={4}
      aria-label={t('ns.common:quick_filters_group:aria_label')}
      sx={{ width: '100%', flexWrap: 'wrap', gap: spacing.md }}
    >
      {showQuickFilterDueToday && (
        <QuickFilterButton
          displayName={t('ns.task_filter:quick_filter:due_today')}
          filterKey="dueToday"
          value={JSON.stringify(filters.resolutionDueDate) === JSON.stringify(todayAsIsoRange())}
          onClick={() => {
            const today = todayAsIsoRange();

            actions.setFilters({
              resolutionDueDate:
                JSON.stringify(filters.resolutionDueDate) === JSON.stringify(today)
                  ? { before: null, after: null }
                  : today,
            });
          }}
        />
      )}
      {showQuickFilterRespondToday && (
        <QuickFilterButton
          displayName={t('task_filter:quick_filter:respond_today')}
          filterKey="respondToday"
          value={JSON.stringify(filters.responseDueDateTime) === JSON.stringify(todayAsIsoRange())}
          onClick={() => {
            const today = todayAsIsoRange();

            actions.setFilters({
              responseDueDateTime:
                JSON.stringify(filters.responseDueDateTime) === JSON.stringify(today)
                  ? { before: null, after: null }
                  : today,
            });
          }}
        />
      )}
      {showQuickFilterResolveToday && (
        <QuickFilterButton
          displayName={t('task_filter:quick_filter:resolve_today')}
          filterKey="resolveToday"
          value={JSON.stringify(filters.resolutionDueDateTime) === JSON.stringify(todayAsIsoRange())}
          onClick={() => {
            const today = todayAsIsoRange();

            actions.setFilters({
              resolutionDueDateTime:
                JSON.stringify(filters.resolutionDueDateTime) === JSON.stringify(today)
                  ? { before: null, after: null }
                  : today,
            });
          }}
        />
      )}
    </Box>
  );
};
