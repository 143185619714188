import React from 'react';
import { Box, Link, Typography } from '@mui/material';

export interface FooterProps {
  /** Translation function */
  t: (key: string) => string;
  /** url link to privacy policy defaults to /privacy if undefined */
  privacyPolicyUrl?: string;
  /** url link to terms of use defaults to /terms if undefined */
  termsOfUseUrl?: string;
  /** Language picker component */
  languagePicker?: React.ReactNode;
}

/** Footer component containing language picker and various links */
const FooterComponent: React.FC<React.PropsWithChildren<FooterProps>> = ({
  t,
  privacyPolicyUrl = '/privacy',
  termsOfUseUrl = '/terms',
  languagePicker,
}: FooterProps) => {
  return (
    <Box
      component="footer"
      data-testid="footer"
      sx={{
        height: '100%',
        width: '100%',
        backgroundColor: 'primary.main',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
      }}
    >
      {languagePicker}
      <Typography variant="body2" color="primary.contrastText">
        <Link
          component="a"
          href={privacyPolicyUrl}
          color="inherit"
          target="_blank"
          rel="noreferrer"
          data-testid="footer-privacy"
        >
          {t('ns.global:footer:privacy')}
        </Link>
        {' - '}
        <Link
          component="a"
          href={termsOfUseUrl}
          color="inherit"
          target="_blank"
          rel="noreferrer"
          data-testid="footer-terms"
        >
          {t('ns.global:footer:terms')}
        </Link>
      </Typography>
    </Box>
  );
};

export const Footer = React.memo(FooterComponent);
