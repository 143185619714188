import { createTheme } from '@mui/material';
import { fallbackPalette } from './fallbackPalette';
import { breakpoints, customPalette, customTypography } from './overrides';

// Create a minimal theme, this will only be used when a fatal error occurs
export const fallbackTheme = createTheme({
  breakpoints,
  typography: customTypography.typography,
  palette: customPalette(fallbackPalette),
  components: { MuiCssBaseline: { styleOverrides: `html, body, #root  { height: 100% }` } },
});
