import React, { createContext, useContext, useEffect, useState } from 'react';
import { useProfile } from '@askporter/profile-provider';
import { Config } from '../../types';
import { areWeAllowedRum, instrumentRum, setRumUser } from '../../utils';
import CookieManagement from '../CookieManagement';

interface RumProps {
  config: Config;
  navigation: JSX.Element;
  children: JSX.Element[] | JSX.Element;
}

interface RumContext {
  rumIsAllowed: boolean;
}

const RumContext = createContext<RumContext>({
  rumIsAllowed: false,
});

export const useRum = (): RumContext => {
  const context = useContext(RumContext);

  if (context === undefined) {
    throw new Error('RumContext must be used within a Rum component');
  }

  return context;
};

/**
 * This component is responsible for detecting whether RUM should be used and instrumenting it. If RUM is not allowed
 * then it will not instrument RUM and instead the cookie management component will be rendered, meaning if a user
 * has not accepted nor rejected cookies they will be shown the relevant view
 */
export const Rum: React.FC<React.PropsWithChildren<RumProps>> = ({ config, children }: RumProps) => {
  const [rumIsAllowed, setRumIsAllowed] = useState(false);
  const { data: profileData, isLoading: profileIsLoading } = useProfile();

  useEffect(() => {
    if (profileData) {
      setRumIsAllowed(areWeAllowedRum({ ...config, cookieConsent: profileData?.consent?.cookieConsent === true }));
    } else if (!profileIsLoading) {
      // TODO loading is complete and consent is not available, so fall back to local storage #3589
    }
  }, [profileData]);

  useEffect(() => {
    if (rumIsAllowed === true) {
      instrumentRum(config);
      setRumUser({ uid: profileData?.uid, roleSlug: profileData?.roleSlug });
    }
  }, [rumIsAllowed]);

  if (rumIsAllowed) return <RumContext.Provider value={{ rumIsAllowed }}>{children}</RumContext.Provider>;

  return (
    <RumContext.Provider value={{ rumIsAllowed }}>
      <CookieManagement>{children}</CookieManagement>
    </RumContext.Provider>
  );
};

export default Rum;
