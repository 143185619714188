import React from 'react';
import { Typography } from '@mui/material';
import { FieldProps } from '@rjsf/core';

const LabelField = (props: FieldProps): JSX.Element => {
  const { schema } = props;

  return (
    <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
      {schema.title}
    </Typography>
  );
};

export default LabelField;
