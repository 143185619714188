export function toggleValueInList<T>(value: T, list: Array<T>): Array<T> {
  let newList;
  if (list.includes(value)) {
    newList = list.filter((i: T) => value !== i);
  } else {
    newList = [...list, value];
  }
  return newList;
}

export function toggleValueInListByProperty<T>(value: T, list: Array<T>, property: keyof T): Array<T> {
  let newList;
  if (list.findIndex((i: T) => i?.[property] === value?.[property]) >= 0) {
    newList = list.filter((i: T) => value?.[property] !== i?.[property]);
  } else {
    newList = [...list, value];
  }
  return newList;
}
