export interface SearchPeopleFilter {
  scope?: Array<SearchPeopleFilter_ScopeEnum>;
}

export type SearchPeopleFilter_ScopeEnum = 'USERS' | 'TEAMS' | 'ORGS';

export const SearchPeopleFilter_ScopeEnum = {
  USERS: 'USERS' as SearchPeopleFilter_ScopeEnum,
  TEAMS: 'TEAMS' as SearchPeopleFilter_ScopeEnum,
  ORGS: 'ORGS' as SearchPeopleFilter_ScopeEnum,
};
