import React from 'react';
import { Box, useTheme } from '@mui/material';
import { RoleSlug } from '@askporter/grieg-types';
import { fullName, getInitials, getInitialsFromLongName } from '@askporter/utils';
import { Avatar, AvatarSize, roleIconMap } from '../Avatar';
import Icon, { IconSize } from '../Icon';

export interface UserAvatarProps {
  /**
   * optional - picture-only mode just shows the picture; full mode shows name and role too
   */
  mode?: 'picture-only' | 'full';
  /**
   * optional - user's given name
   */
  givenName?: string;
  /**
   * optional - user's family name
   */
  familyName?: string;
  /**
   * optional - user's long name
   */
  longName?: string;
  /**
   * optional - url path to the user's profile photo
   */
  imagePath?: string;
  /**
   * optional - if present and isMultiple is not true, the user's role icon will be displayed
   */
  userRole?: RoleSlug;
  /**
   * optional - the translated user role label
   */
  userRoleText?: string;
  /**
   * optional - when isMultiple is true will show an icon that represents a group of people
   */
  isMultiple?: boolean;
  /**
   * optional - the data-testid string for the Avatar
   */
  dataTestId?: string;
  /**
   * optional - boolean to indicate if the avatar is loading
   */
  isLoading?: boolean;
  /**
   * optional - the AvatarSize
   */
  avatarSize?: AvatarSize;
  /**
   * boolean to indicate if the avatar badge should show
   */
  badge?: { isVisible: boolean; color?: string };
}

/**
 * UserAvatar that composes Avatar and user name methods
 */
export const UserAvatar: React.FC<React.PropsWithChildren<UserAvatarProps>> = ({
  mode = 'picture-only',
  givenName,
  familyName,
  longName,
  imagePath,
  userRole,
  userRoleText,
  isMultiple,
  dataTestId = 'user-avatar',
  isLoading,
  avatarSize = AvatarSize.MD,
  ...userAvatarProps
}: UserAvatarProps): JSX.Element => {
  const userFullName = fullName(givenName, familyName);
  const {
    palette: {
      action: { active },
    },
  } = useTheme();

  return (
    <Avatar
      dataTestId={dataTestId}
      altText={longName ? longName : userFullName}
      isLoading={isLoading}
      avatar={{
        mode: 'user',
        variant: 'circular',
        imagePath: imagePath,
        size: { container: avatarSize },
        initials: longName
          ? getInitialsFromLongName(longName)
          : givenName && familyName && getInitials(givenName, familyName),
        avatarUser: { userRole, isMultiple },
        avatarText: mode === 'full' && {
          mode: 'header',
          subTitle: userRoleText,
          subTitleIcon: (
            <Box sx={{ mr: 1 }}>
              <Icon
                {...(userRole ? roleIconMap[userRole] : { folder: 'system/06-user' })}
                fillColor={active}
                size={IconSize.SM}
              />
            </Box>
          ),
          title: userFullName,
        },
      }}
      {...userAvatarProps}
    />
  );
};
