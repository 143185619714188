import { languageOptions, fatalErrorTranslations, LanguageOption } from '../../Language';
import { ErrorPageStrings } from '../types';

const getErrorStrings = (): ErrorPageStrings => {
  try {
    // try and get the user's preferred language
    const selectedLanguage = localStorage.getItem('selectedLanguage') as LanguageOption;
    const browserLanguage = window.navigator?.language as LanguageOption;

    if (languageOptions.includes(selectedLanguage)) {
      return fatalErrorTranslations[selectedLanguage];
    } else if (languageOptions.includes(browserLanguage)) {
      return fatalErrorTranslations[browserLanguage];
    }

    // fallback to english
    return fatalErrorTranslations['en-GB'];
  } catch (err) {
    return fatalErrorTranslations['en-GB'];
  }
};

export default getErrorStrings;
