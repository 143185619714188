import React from 'react';
import { Stack } from '@mui/material';
import { Button } from '@askporter/component-library';
import type { TFunction } from '@askporter/config-provider';

interface TablePaginationButtonsProps {
  t: TFunction;
  isPreviousButtonVisible: boolean;
  isNextButtonVisible: boolean;
  handlePreviousButtonClick: () => void;
  handleNextButtonClick: () => void;
}

/**
 * The TablePaginationButtons component is responsible for pagination buttons.
 */
export const TablePaginationButtons: React.FC<TablePaginationButtonsProps> = ({
  t,
  isNextButtonVisible,
  isPreviousButtonVisible,
  handleNextButtonClick,
  handlePreviousButtonClick,
}: TablePaginationButtonsProps) => {
  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
      {isPreviousButtonVisible && (
        <Button variant="outlined" onClick={handlePreviousButtonClick}>
          {t('export:export_list:table:previous_button')}
        </Button>
      )}
      {isNextButtonVisible && (
        <Button variant="outlined" onClick={handleNextButtonClick}>
          {t('export:export_list:table:next_button')}
        </Button>
      )}
    </Stack>
  );
};
