import React from 'react';
import { Close } from '@mui/icons-material';
import {
  Dialog as MuiDialog,
  Typography,
  Box,
  DialogContent,
  DialogActions,
  IconButton,
  Breakpoint,
} from '@mui/material';
import { Drawer } from '../Drawer';

export interface DialogProps {
  /**
   * Closes the Dialog/Drawer
   */
  onClose: () => void;
  /**
   * Opens the Dialog/Drawer
   */
  isOpen: boolean;
  /**
   * Whether to show a Dialog or Drawer component
   */
  isSmallDevice: boolean;
  /**
   * Dialog content
   */
  children: React.ReactNode;
  /**
   * Title of the Dialog
   */
  title: string | JSX.Element;
  /**
   * Optional subtitle
   */
  subtitle?: string | JSX.Element;
  /**
   * The children will be under the DOM hierarchy of the parent component
   */
  disablePortal?: boolean;
  /**
   * Usually 'Ok', 'Confirm', 'Cancel' etc buttons or can be any other component
   */
  actions: JSX.Element | JSX.Element[];
  /**
   * The width of the 'Dialog' container component
   */
  containerWidth?: string;
  /**
   * The height of the 'DialogContent' component
   */
  contentHeight?: string;
  /**
   * The max width of the dialog, given as a screen size breakpoint.
   */
  maxWidth?: Breakpoint;
}

const defaults = {
  containerWidth: '720px',
  contentHeight: 'auto',
};

/**
 * Renders the Dialog component used for Confirmation, Alert, or form modals.
 */
export const Dialog: React.FC<React.PropsWithChildren<DialogProps>> = ({
  onClose,
  isOpen,
  isSmallDevice,
  children,
  title,
  subtitle,
  disablePortal = false,
  actions,
  containerWidth = defaults.containerWidth,
  contentHeight = defaults.contentHeight,
  maxWidth = 'sm',
}: DialogProps) => {
  return isSmallDevice ? (
    <Drawer title={title} open={isOpen} onClose={onClose}>
      <Box sx={{ width: '100%' }}>
        <DialogContent sx={{ height: contentHeight, p: 0 }} data-testid="dialog-content">
          {children}
        </DialogContent>
        <DialogActions sx={{ px: 0, pt: 6, pb: 0 }}>{actions}</DialogActions>
      </Box>
    </Drawer>
  ) : (
    <MuiDialog
      disablePortal={disablePortal}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      maxWidth={maxWidth}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} pl={6} pt={8} pr={6}>
        <Typography variant="h6" id="modal-title">
          {title}
        </Typography>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose} size="large">
            <Close sx={{ color: 'black' }} />
          </IconButton>
        ) : null}
      </Box>
      {subtitle && (
        <Box px={6}>
          <Typography component="h6" variant="subtitle2">
            {subtitle}
          </Typography>
        </Box>
      )}
      <Box sx={{ width: containerWidth, maxWidth: '100%' }}>
        <DialogContent sx={{ height: contentHeight }} data-testid="dialog-content">
          {children}
        </DialogContent>
        <DialogActions sx={{ p: 6 }}>{actions}</DialogActions>
      </Box>
    </MuiDialog>
  );
};
