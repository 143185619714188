import { createTheme, Theme } from '@mui/material';
import { TenantDisplayPalette } from '@askporter/grieg-types';
import { customPalette, customTypography, breakpoints, customComponents } from './overrides';

/*
  have to use module augmentation to add new variables to the Theme and ThemeOptions. This way we can have custom spacing, etc.
  official guide: https://mui.com/material-ui/customization/theming/#custom-variables
  stackoverflow example: https://stackoverflow.com/questions/58168798/add-custom-theme-variable-in-createtheme
*/

declare module '@mui/material/styles' {
  interface CustomTheme {
    custom?: {
      spacing?: {
        xxs: number;
        xs: number;
        sm: number;
        md: number;
        lg: number;
        xl: number;
        '2xl': number;
        '3xl': number;
        '4xl': number;
        '5xl': number;
        '6xl': number;
        '7xl': number;
        '8xl': number;
        '9xl': number;
        '10xl': number;
        '11xl': number;
      };
      radius?: {
        xxs: number;
        xs: number;
        sm: number;
        md: number;
        lg: number;
        xl: number;
        '2xl': number;
        '3xl': number;
        '4xl': number;
        full: number;
      };
      width?: {
        xxs: number;
        xs: number;
        sm: number;
        md: number;
        lg: number;
        xl: number;
        '2xl': number;
        '3xl': number;
        '4xl': number;
        '5xl': number;
        '6xl': number;
      };
    };
  }

  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

/**
 * Creates the Material UI theme object with our design tokens
 *
 * @param configPalette - the tenant's colours conforming to the ConfigPalette type
 *
 * @returns Theme - the MUI Theme object
 */
export const createAPTheme = (configPalette: TenantDisplayPalette): Theme =>
  createTheme({
    components: customComponents(configPalette).components,
    breakpoints,
    spacing: (factor: number) => `${0.25 * factor}rem`,
    typography: customTypography.typography,
    palette: customPalette(configPalette),
    custom: {
      spacing: {
        xxs: 0.5, // 0.125rem, 2px
        xs: 1, // 0.25rem, 4px
        sm: 1.5, // 0.375rem, 6px
        md: 2, // 0.5rem, 8px
        lg: 3, // 0.75rem, 12px
        xl: 4, // 1rem, 16px
        '2xl': 5, // 1.25rem, 20px
        '3xl': 6, // 1.5rem, 24px
        '4xl': 8, // 2rem, 32px
        '5xl': 10, // 2.5rem, 40px
        '6xl': 12, // 3rem, 48px
        '7xl': 16, // 4rem, 64px
        '8xl': 20, // 5rem, 80px
        '9xl': 24, // 6rem, 96px
        '10xl': 32, // 8rem, 128px
        '11xl': 40, // 10rem, 160px
      },
      radius: {
        xxs: 0.5,
        xs: 1,
        sm: 1.5,
        md: 2,
        lg: 2.5,
        xl: 3,
        '2xl': 4,
        '3xl': 5,
        '4xl': 6,
        full: 9999,
      },
      width: {
        xxs: 60, // 20rem, 320px
        xs: 72, // 24rem, 384px
        sm: 90, // 30rem, 480px
        md: 105, // 35rem, 560px
        lg: 120, // 40rem, 640px
        xl: 144, // 48rem, 768px
        '2xl': 192, // 64rem, 1024px
        '3xl': 240, // 80rem, 1280px
        '4xl': 270, // 90rem, 1440px
        '5xl': 300, // 100rem, 1600px
        '6xl': 360, // 120rem, 1920px
      },
    },
  });

export { fallbackTheme } from './fallbackTheme';
export { fallbackPalette } from './fallbackPalette';
