import React from 'react';
import { useQueryClient } from 'react-query';
import { Button } from '@askporter/component-library';
import { UID } from '@askporter/grieg-types';
import fetchExportDownload from './fetchExportDownload';

interface ExportDownloadButtonProps {
  uid: UID;
  label: string;
  onClick: () => void;
}

/**
 * A button to download the export file, this button also handles the removal of the export from the exports in progress list
 * @param uid - The export uid
 * @param label - The button label
 * @param onClick - The function to call when the button is clicked
 */
export const ExportDownloadButton: React.FC<ExportDownloadButtonProps> = ({ uid, label, onClick }) => {
  const queryClient = useQueryClient();

  const handleDownload = async () => {
    const { data: exportFileBlob, fileName } = await queryClient.fetchQuery(`exports/${uid}/download`, () =>
      fetchExportDownload(uid),
    );

    const fileBlobUrl = window.URL.createObjectURL(exportFileBlob);
    const anchor = document.createElement('a');

    document.body.appendChild(anchor);

    anchor.setAttribute('href', fileBlobUrl);
    anchor.setAttribute('download', fileName);
    anchor.setAttribute('data-testid', 'download-anchor');

    anchor.click();

    window.URL.revokeObjectURL(fileBlobUrl);

    onClick();
  };
  return (
    <Button variant="text" onClick={handleDownload}>
      {label}
    </Button>
  );
};
