import React from 'react';
import { ListItem, Box, Typography, Checkbox } from '@mui/material';

export interface SelectListItemProps {
  /**
   * The title of the list item
   */
  title: string;
  /**
   * List item description
   */
  details?: string;
  /**
   * Function to handle the change event
   */
  handleChange: () => void;
  /**
   * Whether the list item is checked or not
   */
  isChecked: boolean;
  /**
   * Whether the device is small or not
   */
  isSmallDevice: boolean;
  /**
   * The icon to display
   */
  icon?: JSX.Element;
}

/**
 * Selectable list item usually dipslayed in a Link modal
 */
export const SelectListItem: React.FC<React.PropsWithChildren<SelectListItemProps>> = ({
  title,
  details,
  handleChange,
  isChecked,
  isSmallDevice,
  icon,
}: SelectListItemProps) => {
  return (
    <ListItem
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4,
        width: '100%',
        justifyContent: 'space-between',
        '& .MuiListItemAvatar-root': { minWidth: 'unset' },
      }}
      data-testid="select-list-item"
    >
      {icon ? icon : null}
      <Box
        sx={{
          display: isSmallDevice ? 'block' : 'flex',
          gap: 2,
          width: '100%',
          alignItems: 'baseline',
        }}
      >
        <Typography variant="body1" component="h2">
          {title}
        </Typography>
        {details && (
          <>
            {isSmallDevice ? null : '●'}
            <Typography variant="subtitle2" color="textSecondary" component="span" mb={0}>
              {details}
            </Typography>
          </>
        )}
      </Box>
      <Checkbox
        checked={isChecked}
        aria-label={title}
        onChange={() => handleChange()}
        data-testid="select-list-item-checkbox"
      />
    </ListItem>
  );
};
