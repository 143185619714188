import React from 'react';
import { Box, FormControl, InputLabel } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Typography,
  Dialog,
  TextField,
  LoadingButton,
  useToast,
  MenuItem,
  Select,
} from '@askporter/component-library';
import {
  AssetExportCreate,
  OrgExportCreate,
  SearchFreeText,
  TaskExportCreate,
  TaskExportCreate_FormatEnum,
  TeamExportCreate,
  UserExportCreate,
} from '@askporter/grieg-types';
import { useExportContext } from '../../../providers';
import { ExportModalFormTypes, ExportResourceNameFilterAndSortUnion } from '../../../types';
import { generateExportFileName } from '../../../utils/generateExportFileName';
import { ExportFields } from '../../ExportFields';
import { useExportMutations } from './useExportMutations';

export type ExportModalProps = {
  /**
   * Whether the dialog is open
   */
  isOpen: boolean;
  /**
   * callback to close the dialog
   */
  onClose: () => void;
  /**
   * Whether the device is small
   */
  isSmallDevice: boolean;
  /**
   * The translation function
   */
  t: (key: string, options?: Record<string, string | number>) => string;
  /**
   * cancel export callback
   */
  cancelExport: () => void;
  /**
   * The title of the dialog
   */
  title: string;
  /**
   * The freeText
   */
  freeText: SearchFreeText;
} & ExportResourceNameFilterAndSortUnion;

/**
 * The Export modal component that contains all the common logic for the export forms
 */
export const ExportModal = ({
  isOpen,
  onClose,
  isSmallDevice,
  t,
  cancelExport,
  title,
  resourceName,
  filter,
  sort,
  freeText,
}: ExportModalProps): JSX.Element => {
  const { showError } = useToast();
  const { addPendingExportsInProgressItem } = useExportContext();
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<ExportModalFormTypes>({
    defaultValues: {
      timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
      freeText: freeText || undefined,
    },
  });
  const { taskExportMutation, assetExportMutation, userExportMutation, orgExportMutation, teamExportMutation } =
    useExportMutations();

  const handleError = () => {
    showError(t('common:export:export_modal:error'));
    onClose();
  };

  const handleSuccess = ({ uid }: { uid: string }) => {
    if (!uid) {
      /**
       * In the case where we get a false success, e.g. 200 is returned
       * but no valid data is returned, we should show an error message to the user
       */
      showError(t('common:export:export_modal:error'));
      onClose();
      return;
    }
    onClose();
    addPendingExportsInProgressItem({ uid });
  };

  const onSubmit = (data: ExportModalFormTypes) => {
    switch (resourceName) {
      case 'tasks':
        {
          const payload: TaskExportCreate = {
            ...(data as TaskExportCreate),
            filter,
            sort,
          };
          // POST /api/v1/exports/task
          taskExportMutation.mutate(payload, {
            onError: handleError,
            onSuccess: ({ data: { uid } }) => handleSuccess({ uid }),
          });
        }
        break;
      case 'assets':
        {
          const payload: AssetExportCreate = {
            ...(data as AssetExportCreate),
            filter,
            sort,
          };
          // POST /api/v1/exports/asset
          assetExportMutation.mutate(
            { ...payload },
            { onError: handleError, onSuccess: ({ data: { uid } }) => handleSuccess({ uid }) },
          );
        }
        break;
      case 'users':
        {
          const payload: UserExportCreate = {
            ...(data as UserExportCreate),
            filter,
            sort,
          };
          // POST /api/v1/exports/user
          userExportMutation.mutate(
            { ...payload },
            { onError: handleError, onSuccess: ({ data: { uid } }) => handleSuccess({ uid }) },
          );
        }
        break;
      case 'orgs':
        {
          const payload: OrgExportCreate = {
            ...(data as OrgExportCreate),
            filter,
            sort,
          };
          // POST /api/v1/exports/org
          orgExportMutation.mutate(
            { ...payload },
            { onError: handleError, onSuccess: ({ data: { uid } }) => handleSuccess({ uid }) },
          );
        }
        break;
      case 'teams': {
        const payload: TeamExportCreate = {
          ...(data as TeamExportCreate),
          filter,
          sort,
        };
        // POST /api/v1/exports/team
        teamExportMutation.mutate(
          { ...payload },
          { onError: handleError, onSuccess: ({ data: { uid } }) => handleSuccess({ uid }) },
        );
      }
    }
  };

  const isLoading =
    taskExportMutation.isLoading ||
    assetExportMutation.isLoading ||
    userExportMutation.isLoading ||
    orgExportMutation.isLoading ||
    teamExportMutation.isLoading;

  return (
    <Dialog
      onClose={onClose}
      isOpen={isOpen}
      isSmallDevice={isSmallDevice}
      title={title}
      actions={
        <>
          <Button variant="outlined" onClick={cancelExport}>
            {t('common:export:export_modal:cancel_export_button')}
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            form={`${resourceName}-export-form`}
            isLoading={isLoading}
            disabled={!isValid}
          >
            {t('common:export:export_modal:start_export_button')}
          </LoadingButton>
        </>
      }
    >
      <form id={`${resourceName}-export-form`} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="name"
          rules={{
            required: true,
          }}
          control={control}
          defaultValue={generateExportFileName({ resourceName, t })}
          render={({ field }) => (
            <TextField
              variant="outlined"
              label={t('common:export:export_modal:export_name')}
              fullWidth
              inputProps={{ ...field }}
            />
          )}
        />
        <Typography variant="h6" sx={{ marginTop: 5 }}>
          {t('common:export:export_modal:fields_to_export')}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <ExportFields t={t} resourceName={resourceName} control={control} />
        </Box>
        <Typography variant="h6" sx={{ marginTop: 5 }}>
          {t('common:export:export_modal:export_format_select')}
        </Typography>
        <Controller
          name="format"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange } }) => (
            <FormControl fullWidth>
              <InputLabel id="format-select-label">{t('common:export:export_modal:export_format_select')}</InputLabel>
              <Select
                style={{ width: '100%' }}
                defaultValue={''}
                onChange={onChange}
                labelId="format-select-label"
                label={t('common:export:export_modal:export_format_select')}
              >
                {Object.values(TaskExportCreate_FormatEnum).map((formats) => {
                  return (
                    <MenuItem key={formats} value={formats}>
                      {t(`common:export:export_modal:${formats.toLowerCase()}`)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        />
      </form>
      <Typography sx={{ marginTop: 5 }}>{t('common:export:export_modal:export_info')}</Typography>
    </Dialog>
  );
};
