import { AuthResult, AuthAction, CognitoError } from './types';
import { ClientMetadata, CognitoUser } from 'amazon-cognito-identity-js';
import { wrapError } from '.';
/**
 * Attempt to verify a user attribute with a code
 * @param dispatch    - reference to the auth state reducer dispatch method
 * @param code        - user's validation code, supplied via SMS or email
 * @param cognitoUser - the current session user
 * @param clientMetadata - any additional data to send to Cognito
 * @returns             Promise with auth result
 */
export const cognitoConfirmWithCode = async (
  dispatch: React.Dispatch<AuthAction>,
  code: string,
  cognitoUser: CognitoUser,
  clientMetadata?: ClientMetadata,
): Promise<AuthResult> => {
  try {
    if (code && cognitoUser) {
      dispatch({ type: 'setFeedback', payload: `confirming user account with code` });
      return doConfirmation(code, cognitoUser, dispatch, clientMetadata);
    } else {
      dispatch({ type: 'setFeedback', payload: `attempt to confirm without user or code` });
      return Promise.reject({
        complete: false,
        error: wrapError(`Unable to confirm without user or code`),
        message: `attempt to confirm without user or code`,
      });
    }
  } catch (err) {
    const fallbackErrMessage = 'Something went wrong with confirming registration';
    return Promise.reject({
      complete: false,
      error: wrapError(err, fallbackErrMessage),
      message: `verified ${err}`,
    });
  }
};

const doConfirmation = async (
  code: string,
  cognitoUser: CognitoUser,
  dispatch: React.Dispatch<AuthAction>,
  clientMetadata?: ClientMetadata,
): Promise<AuthResult> => {
  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(
      code,
      true,
      (err: CognitoError) => {
        if (err) {
          return reject({
            complete: false,
            error: wrapError(err, 'Unable to confirm registration'),
            message: `Account confirmation failed: ${err.message}`,
          });
        } else {
          const message = 'Account confirmed.';
          dispatch({ type: 'setFeedback', payload: message });
          return resolve({
            complete: true,
            message: message,
          });
        }
      },
      clientMetadata,
    );
  });
};
