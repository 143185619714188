import React, { useState } from 'react';
import { Tooltip, useTheme } from '@mui/material';
import { Breadcrumb, IconButton, Icon, IconSize, BreadcrumbProps } from '../../';
import { TooltipState } from './types';

export interface CopyBreadcrumbProps extends Pick<BreadcrumbProps, 'text' | 'href'> {
  /**
   * Function to call when the copy button is clicked
   */
  copyFunction: () => string;
  /**
   * The ARIA label
   */
  'aria-label': string;
  /**
   * The colour for the copy icon
   */
  iconColor?: string;
}

/** Renders a Breadcrumb with a tooltip that contains a copy button */
export const CopyBreadcrumb: React.FC<React.PropsWithChildren<CopyBreadcrumbProps>> = ({
  copyFunction,
  iconColor,
  'aria-label': ariaLabel,
  ...breadcrumbProps
}: CopyBreadcrumbProps) => {
  const [tooltipState, setTooltipState] = useState<TooltipState>({ visible: false, message: '' });
  const {
    palette: {
      text: { secondary: defaultIconColor },
    },
  } = useTheme();

  const handleTooltipClose = () => {
    setTooltipState({ visible: false, message: '' });
  };

  const handleTooltipOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    const message = copyFunction() || '';
    setTooltipState({ visible: true, message });
  };

  return (
    <Breadcrumb
      {...breadcrumbProps}
      endAdornment={
        <Tooltip
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="right"
          arrow
          PopperProps={{
            disablePortal: true,
          }}
          open={tooltipState.visible}
          title={tooltipState.message}
          data-testid="tooltip-breadcrumb"
        >
          <IconButton
            size="small"
            data-testid="tooltip-breadcrumb-button"
            aria-label={ariaLabel}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              handleTooltipOpen(e);
              setTimeout(handleTooltipClose, 1500);
            }}
          >
            <Icon size={IconSize.SM} folder="actions/05-copy" fillColor={iconColor || defaultIconColor} />
          </IconButton>
        </Tooltip>
      }
    />
  );
};
