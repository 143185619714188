export function parseURLQueryParams<T>(searchStateKeys: Array<string>): T {
  const query = new URLSearchParams(window.location.search);

  let parsedParams: Record<string, unknown> = {};

  searchStateKeys.forEach((key) => {
    const value = query.get(key);

    if (value !== null) {
      switch (key) {
        case 'oboUser':
          if (value.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$')) {
            parsedParams = { ...parsedParams, oboUser: value };
          }
          break;
        case 'freeText':
        case 'sort':
          parsedParams = { ...parsedParams, [key]: value };
          break;

        case 'page':
          try {
            if (isNaN(parseInt(value))) throw new Error('Not a number');

            parsedParams = { ...parsedParams, page: parseInt(value) };
          } catch (error) {
            parsedParams = { ...parsedParams, page: 1 };
          }
          break;

        case 'searchFilter':
        case 'page_token':
        case 'filter':
        case 'extendedMetadataStats':
        case 'additionalInformation':
          try {
            parsedParams = { ...parsedParams, [key]: JSON.parse(decodeURIComponent(value)) };
          } catch (err) {
            // if the params are not parsable then they are likely to be invalid, so we ignore them
          }
          break;

        default:
          break;
      }
    }
  });

  return parsedParams as T;
}
