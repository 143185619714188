export const passwordRules: (
  t: (key: string, options?: Record<string, string | number>) => string,
) => Record<string, string> = (t) => {
  return {
    lowercase: t('ns.common:password_criteria:lower_case'),
    uppercase: t('ns.common:password_criteria:upper_case'),
    numbers: t('ns.common:password_criteria:numbers'),
    special: t('ns.common:password_criteria:special'),
  };
};
