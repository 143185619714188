import React, { useContext, createContext, useState, useEffect } from 'react';
import { useToast } from '@askporter/component-library';
import { useProfile } from '@askporter/profile-provider';
import { ExportsInProgressSnackbar } from '../components/ExportsInProgressSnackbar';
import {
  addPendingExportToLocalStorage,
  updateExportsItemInLocalStorage,
  getExportsInProgressFromLocalStorage,
  toggleExportsInProgressSnackbarStateLocalStorage,
  removeExportsItemInLocalStorage,
  removeNonPendingExportsFromLocalStorage,
  removeAllExportsFromLocalStorage,
} from '../utils/exportsLocalStorage';
import { handleShowingExportCompletedToast } from './handleShowingExportsCompletedToast';
import {
  AddPendingExportsInProgressItem,
  UpdateExportsInProgressItem,
  RemoveExportsInProgressItem,
  ExportsInProgressArray,
} from './providerTypes';

/*
  This context is used to handle the display of the ExportsInProgressSnackbar
*/
interface ExportContextProps {
  /**
   * Function to open ExportsInProgressSnackbar
   */
  openExportsInProgressSnackbar: () => void;
  /**
   * Function to close ExportsInProgressSnackbar
   */
  closeExportsInProgressSnackbar: () => void;
  /**
   * Add new export progress item, manage the state and local storage
   */
  addPendingExportsInProgressItem: AddPendingExportsInProgressItem;
  /**
   * Update a progress item from PENDING state to either COMPLETED or FAILED
   */
  updateExportsInProgressItem: UpdateExportsInProgressItem;
  /**
   * Remove a progress item from the list
   */
  removeExportsInProgressItem: RemoveExportsInProgressItem;
  /**
   * Clear all exports from the list
   */
  clearAllExports: () => void;
}

const ExportContext = createContext<ExportContextProps>({
  openExportsInProgressSnackbar: () => {},
  closeExportsInProgressSnackbar: () => {},
  addPendingExportsInProgressItem: () => {},
  updateExportsInProgressItem: () => {},
  removeExportsInProgressItem: () => {},
  clearAllExports: () => {},
});

/**
 * Hook for using ExportContext
 */
export const useExportContext = (): ExportContextProps => {
  const context = useContext(ExportContext);

  if (context === undefined) {
    throw new Error('ExportContext must be used within a ExportProvider component');
  }

  return context;
};

/**
 * As the provider will wrap components, it needs to accepts children
 */
interface ExportProviderProps {
  /**
   * React node as children
   */
  children: React.ReactNode;
  /**
   * The pathname
   */
  pathname: string;
}

export const ExportProvider = ({ children, pathname }: ExportProviderProps): React.JSX.Element => {
  const { setToast, closeToast } = useToast();
  const { data: { uid: userUid } = {} } = useProfile();
  const [isExportsInProgressSnackbarOpen, setIsExportsInProgressSnackbarOpen] = useState(false);
  const [isExportsInProgressSnackbarExpanded, setIsExportsInProgressSnackbarExpanded] = useState(false);
  const [exportProgressList, setExportProgressList] = useState<ExportsInProgressArray>([]);

  const openExportsInProgressSnackbar = () => {
    setIsExportsInProgressSnackbarOpen(true);
    // We are tracking the state of the snackbar in the local storage
    toggleExportsInProgressSnackbarStateLocalStorage(true);
  };

  const closeExportsInProgressSnackbar = () => {
    setIsExportsInProgressSnackbarOpen(false);

    setExportProgressList((prev) => prev.filter((item) => item.status === 'PENDING'));
    removeNonPendingExportsFromLocalStorage({ userUid });

    toggleExportsInProgressSnackbarStateLocalStorage(false);
  };

  useEffect(() => {
    if (userUid) {
      const exportsInProgress = getExportsInProgressFromLocalStorage({ userUid });

      setExportProgressList(exportsInProgress);

      const pendingExports = exportsInProgress.filter(({ status }) => status === 'PENDING');

      // If there are pending exports, we should open the snackbar
      if (pendingExports.length > 0) openExportsInProgressSnackbar();
    }
  }, [userUid]);

  const addPendingExportsInProgressItem: AddPendingExportsInProgressItem = ({ uid }) => {
    setExportProgressList((prev) => [{ uid, status: 'PENDING' }, ...prev]);
    addPendingExportToLocalStorage({ uid, userUid });
    openExportsInProgressSnackbar();
    setIsExportsInProgressSnackbarExpanded(true);
  };

  const updateExportsInProgressItem: UpdateExportsInProgressItem = ({ uid, status, name }) => {
    // Update the export item in the state with the new status
    setExportProgressList((prev) => prev.map((item) => (item.uid === uid ? { uid, status } : item)));
    // Update the export item in local storage with the new status
    updateExportsItemInLocalStorage({ uid, status, userUid });

    handleShowingExportCompletedToast({
      uid,
      status,
      isExportsInProgressSnackbarOpen,
      closeToast,
      name,
      setToast,
      removeExportsInProgressItem,
    });
  };

  const removeExportsInProgressItem = (uid: string) => {
    if (exportProgressList?.length === 1) {
      closeExportsInProgressSnackbar();
    }
    // Remove the export item from the state
    setExportProgressList((prev) => prev.filter((item) => item.uid !== uid));
    // Remove the export item from local storage
    removeExportsItemInLocalStorage({ uid, userUid });
  };

  const clearAllExports = () => {
    setExportProgressList([]);
    removeAllExportsFromLocalStorage({ userUid });
    setIsExportsInProgressSnackbarOpen(false);
  };

  return (
    <ExportContext.Provider
      value={{
        openExportsInProgressSnackbar,
        closeExportsInProgressSnackbar,
        addPendingExportsInProgressItem,
        updateExportsInProgressItem,
        removeExportsInProgressItem,
        clearAllExports,
      }}
    >
      <ExportsInProgressSnackbar
        isOpen={pathname !== '/app/exports' && isExportsInProgressSnackbarOpen}
        exports={exportProgressList}
        {...{ isExportsInProgressSnackbarExpanded, setIsExportsInProgressSnackbarExpanded }}
      />
      {children}
    </ExportContext.Provider>
  );
};
