import React from 'react';
import { Box } from '@mui/material';
import { TaskFullExternalLink } from '@askporter/grieg-types';
import { HeaderExternalLinkButton } from '../../../../HeaderExternalLinkButton';
import { ReturnButton } from '../../../../ReturnButton';
import { TitleHeaderProps, TitleHeader } from '../../../../TitleHeader';
import { Header } from '../Header';

interface DetailsPageHeaderProps {
  t: (key: string, options?: Record<string, string | number>) => string;
  isSmallDevice: boolean;
  defaultReturnPath?: string;
  defaultReturnPageTitle?: string;
  titleHeaderProps: Omit<TitleHeaderProps, 'isSmallDevice'>;
  breadcrumbs?: React.ReactNode;
  externalLink?: TaskFullExternalLink;
}

export const DetailsPageHeader: React.FC<DetailsPageHeaderProps> = ({
  t,
  isSmallDevice,
  defaultReturnPath,
  defaultReturnPageTitle,
  titleHeaderProps,
  breadcrumbs,
  externalLink,
}) => {
  return (
    <Header isListPage={false} isSmallDevice={isSmallDevice}>
      {breadcrumbs}
      {!breadcrumbs && !isSmallDevice ? (
        <ReturnButton
          t={t}
          isReturn
          defaultReturnPath={defaultReturnPath}
          defaultReturnPageTitle={t(defaultReturnPageTitle)}
        />
      ) : null}
      <TitleHeader {...titleHeaderProps} isSmallDevice={isSmallDevice} externalLink={externalLink} />
      {externalLink && isSmallDevice ? (
        <Box width="100%" mt={4}>
          <HeaderExternalLinkButton externalLink={externalLink} />
        </Box>
      ) : null}
    </Header>
  );
};
