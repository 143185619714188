import React from 'react';
import { FieldProps } from '@rjsf/core';
import isEqual from 'lodash/isEqual';
import { Media, formatMediaItem } from '@askporter/utils';
import { SingleFileUpload } from '../../stories/FileUpload';
const SingleFileUploadField = ({
  formData,
  idSchema,
  schema,
  onChange,
  required,
  uiSchema,
  disabled,
  readonly,
  formContext: { onHasChanged, t, API },
}: FieldProps): JSX.Element => {
  const value = formData && formData?.file ? formatMediaItem(formData) : null;
  const isRequired = uiSchema && !!uiSchema['ui:softRequired'] ? true : required;
  const _onChange = (uploadedFile: Media) => {
    const newValue = uploadedFile && uploadedFile?.file ? formatMediaItem(uploadedFile) : null;
    onChange(newValue);

    if (!isEqual(value, newValue)) setTimeout(() => onHasChanged({ id: idSchema.$id, value: newValue }), 0);
  };

  return (
    <SingleFileUpload
      id={idSchema.$id}
      label={schema.title}
      onChange={_onChange}
      value={value}
      required={isRequired}
      disabled={disabled || readonly}
      t={t}
      API={API}
    />
  );
};

export default SingleFileUploadField;
