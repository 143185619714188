import React, { Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { GeneralErrorBoundary, Loading } from '@askporter/component-library';
import { useRum } from '../Rum';
import RumErrorBoundary from '../RumErrorBoundary';
import ThemedError from '../ThemedError';

interface Props {
  children: JSX.Element[] | JSX.Element;
  navigation: JSX.Element;
}

/**
 * A general error boundary that will utilise the RUM error boundary if RUM is enabled, otherwise it will use the
 * standard GeneralErrorBoundary.
 */
const GeneralErrorBoundaryWithRumIfEnabled: React.FC<React.PropsWithChildren<Props>> = ({
  navigation,
  children,
}: Props) => {
  const { rumIsAllowed } = useRum();
  const { pathname } = useLocation();
  const [pathWithError, setPathWithError] = React.useState<string | null>(null);

  const fallback = (error: Error) => {
    return (
      <Suspense fallback={<Loading />}>
        <ThemedError errMsg={error?.toString()} navigation={navigation} />
      </Suspense>
    );
  };

  const errorStatusCheck = (error: Error, resetError: () => void) => {
    if (pathWithError && pathname !== pathWithError) {
      resetError();
      setPathWithError(null);
    }
    if (error) setPathWithError(pathname);
  };

  if (rumIsAllowed)
    return (
      <RumErrorBoundary
        fallback={({ error, resetError }) => {
          errorStatusCheck(error, resetError);
          return fallback(error);
        }}
      >
        {children}
      </RumErrorBoundary>
    );
  else
    return (
      <GeneralErrorBoundary
        fallback={({ error, resetError }) => {
          errorStatusCheck(error, resetError);
          return fallback(error);
        }}
      >
        {children}
      </GeneralErrorBoundary>
    );
};

export default GeneralErrorBoundaryWithRumIfEnabled;
