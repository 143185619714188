import React from 'react';
import { TableRow, TableCell, Skeleton, Stack } from '@mui/material';
import { AvatarSize } from '@askporter/component-library';

interface TableLoadingSkeletonProps {
  isUserAdmin: boolean;
}

export const TableLoadingSkeleton: React.FC<TableLoadingSkeletonProps> = ({
  isUserAdmin,
}: TableLoadingSkeletonProps) => {
  return (
    <>
      {[...new Array(5)].map((_, index) => (
        <TableRow key={index}>
          <TableCell>
            <Skeleton variant="text" />
            <Skeleton variant="text" width={'50%'} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          {isUserAdmin && (
            <TableCell>
              <Stack direction="row" spacing={2}>
                <Skeleton variant="circular" width={AvatarSize.SM} height={AvatarSize.SM} />
                <Skeleton variant="text" width={'50%'} />
              </Stack>
            </TableCell>
          )}
          <TableCell>
            <Skeleton variant="text" width={'40%'} />
            <Skeleton variant="text" width={'25%'} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
