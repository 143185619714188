import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import { captureException } from '@askporter/exception-logger';
import { SearchElementDateRange } from '@askporter/grieg-types';

const searchPattern = new RegExp('/api/v1/[a-zA-Z-]+/search');

const cleanseFilter = (item: Record<string, SearchElementDateRange>, key: string) => {
  // We should check whether filter[key] is 'truthy' before attempting
  // to check whether the 'before' or 'after' properties exist and are 'null'.
  //
  // There might be a filter with a value that is 'undefined'
  // which will log an exception.
  //
  // This is unnecessary as it will be stripped off
  // in the next 'if' statement anyway
  if (item[key]) {
    if (item[key]?.before === null) {
      delete item[key].before;
    }
    if (item[key]?.after === null) {
      delete item[key].after;
    }
  }

  // this should strip out filters with empty values (like undefined / null)
  if (isEmpty(item[key])) {
    delete item[key];
  }
};

/**
 * Ensures empty freeText and filters are not sent to grieg
 */
export const setupGriegIntercepts = (): void => {
  axios.interceptors.request.use(
    (config) => {
      if (searchPattern.test(config?.url)) {
        // remove free text if empty or undefined
        try {
          if ('freeText' in config?.data && (config?.data?.freeText === '' || config?.data?.freeText === undefined)) {
            delete config?.data?.freeText;
          }
        } catch (error) {
          captureException(error);
        }

        try {
          if ('filter' in config?.data) {
            Object.keys(config?.data?.filter).forEach((key) => {
              // extended metadata filters are a sub category of filters, they should be cleansed first
              if (key === 'extendedMetadata') {
                Object.keys(config?.data?.filter?.[key]).forEach((extendedMetadataKey) => {
                  if (
                    config?.data?.filter[key]?.[extendedMetadataKey]?.in &&
                    !config?.data?.filter[key]?.[extendedMetadataKey]?.in?.length
                  ) {
                    delete config?.data?.filter[key]?.[extendedMetadataKey]?.in;
                  }

                  cleanseFilter(config?.data?.filter[key], extendedMetadataKey);
                });
              }

              // cleanse the root filter key, this is performed after extended metadata because if extended metadata
              // ends up being {} it should still be removed
              cleanseFilter(config?.data?.filter, key);
            });
          }
        } catch (error) {
          captureException(error);
        }

        // remove filter if empty or undefined
        try {
          if (
            'filter' in config?.data &&
            (config?.data?.filter === undefined || Object.keys(config?.data?.filter).length === 0)
          ) {
            delete config?.data?.filter;
          }
        } catch (error) {
          captureException(error);
        }

        // remove empty extendedMetadataStats
        try {
          if (
            'extendedMetadataStats' in config?.data &&
            Object.keys(config?.data?.extendedMetadataStats || {}).length === 0
          ) {
            delete config?.data?.extendedMetadataStats;
          }
        } catch (error) {
          captureException(error);
        }
      }

      return config;
    },
    (error) => {
      captureException(error);
    },
  );
};
