import React from 'react';
import { TextField, FilledTextFieldProps, useTheme } from '@mui/material';

export interface TitleFieldProps
  extends Exclude<FilledTextFieldProps, 'variant' | 'hiddenLabel' | 'size' | 'sx' | 'InputProps' | 'inputProps'> {
  readOnly?: boolean;
  inputTestId?: string;
}

/**
 * Renders a white FilledInput component matching the title field designs
 */
export const TitleField: React.FC<React.PropsWithChildren<TitleFieldProps>> = ({
  readOnly = false,
  inputTestId = 'title-field',
  ...rest
}: TitleFieldProps) => {
  const theme = useTheme();

  return (
    <TextField
      variant="filled"
      hiddenLabel
      size="small" // generally avoiding the use of small, however this is a heavily customised variant so it is needed
      sx={{ my: 0 }}
      InputProps={{
        readOnly: readOnly,
        sx: {
          bgcolor: 'background.paper',
          '&:before, &&:hover::before': { borderBottom: 'none' },
          '&:hover, &&.Mui-focused': { bgcolor: 'background.paper' },
          '& input': { pl: 1 },
          ...theme.typography['h6'],
        },
      }}
      inputProps={{ 'data-testid': inputTestId }}
      {...rest}
    />
  );
};
