import React, { useReducer } from 'react';
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  TableFooter,
} from '@mui/material';
import { useQuery } from 'react-query';
import { usePermissions } from '@askporter/auth-provider';
import { URLQueryParams, parseURLQueryParams, useTheme } from '@askporter/component-library';
import type { TFunction } from '@askporter/config-provider';
import { RoleSlug } from '@askporter/grieg-types';
import fetchExports from './fetchExports';
import reducer, { ExportListState, initialState } from './reducer';
import { TableBodyContent } from './TableBodyContent';
import { TableLoadingSkeleton } from './TableLoadingSkeleton';
import { TablePaginationButtons } from './TablePaginationButtons';

interface ExportListProps {
  t: TFunction;
}

/**
 * The ExportList component is the main component for the Export List page.
 */
export const ExportList: React.FC<ExportListProps> = ({ t }) => {
  const {
    custom: { spacing },
  } = useTheme();
  const isUserAdmin = usePermissions([RoleSlug.CLIENTADMIN, RoleSlug.PLATADMIN]);
  const [state, dispatch] = useReducer(reducer, initialState(), (state): ExportListState => {
    // Parse the page_token from the URL query params
    const { page_token } = parseURLQueryParams<Pick<ExportListState, 'page_token'>>(Object.keys(state));
    return { ...state, page_token: JSON.stringify(page_token) };
  });

  const { data, isLoading } = useQuery(['export-list', { page_token: state?.page_token }], fetchExports);

  return (
    <>
      <URLQueryParams
        // only page_token is saved in the URL query params
        // if page_token is undefined, pass empty string in so it gets cleared from the URL
        state={{ page_token: state?.page_token || '' }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: spacing['5xl'],
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>{t('export:export_list:table:exports_header')}</TableCell>
                <TableCell>{t('export:export_list:table:results_header')}</TableCell>
                <TableCell>{t('export:export_list:table:status_header')}</TableCell>
                {isUserAdmin && <TableCell>{t('export:export_list:table:executed_by_header')}</TableCell>}
                <TableCell>{t('export:export_list:table:date_header')}</TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableLoadingSkeleton isUserAdmin={isUserAdmin} />
              ) : (
                <TableBodyContent t={t} data={data} isUserAdmin={isUserAdmin} />
              )}
            </TableBody>
            {!isLoading && (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={isUserAdmin ? 7 : 6}>
                    <TablePaginationButtons
                      t={t}
                      isPreviousButtonVisible={!!state.previousPageTokens.length}
                      isNextButtonVisible={!!data?.page?.nextPageToken}
                      handlePreviousButtonClick={() => dispatch({ type: 'HANDLE_PREVIOUS_BUTTON_CLICK' })}
                      handleNextButtonClick={() =>
                        dispatch({ type: 'HANDLE_NEXT_BUTTON_CLICK', payload: data?.page?.nextPageToken })
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
