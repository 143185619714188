import React from 'react';
import { Box, Typography } from '@mui/material';
import { Button } from '@askporter/component-library';
import { useTranslation } from '@askporter/config-provider';
import { ExportDownloadButton } from '../ExportDownloadButton';

interface ExportCompletedToastProps {
  /**
   * The export uid
   */
  uid: string;
  /**
   * The export name
   */
  name: string;
  /**
   * The function to call when the toast is closed
   */
  closeToast: () => void;
}

export const ExportCompletedToast: React.FC<ExportCompletedToastProps> = ({ uid, name, closeToast }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        minWidth: '200px',
      }}
    >
      <Typography variant="h6">{t('export:export_complete_toast:title')}</Typography>
      <Typography variant="body1">{name}</Typography>

      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Button variant="secondary" onClick={closeToast}>
          {t('export:export_complete_toast:dismiss_button')}
        </Button>
        <ExportDownloadButton
          label={t('export:export_complete_toast:download_button')}
          uid={uid}
          onClick={closeToast}
        />
      </Box>
    </Box>
  );
};
