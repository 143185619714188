import React from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorBoundaryAlert } from '../components/ErrorBoundaryAlert';
import { ReactErrorBoundary } from '../components/ReactErrorBoundary';

export interface LocalErrorBoundaryProps {
  /**
   * Translation function
   * @param key - translation key
   * @returns translated string
   */
  t: (key: string) => string;
  /**
   * The child components that should be wrapped with the error boundary
   */
  children: React.ReactNode;
}

/**
 * An error boundary used to catch localised errors, that is an error that occurs within a section of the application
 * rather than an entire page error.
 */
const LocalErrorBoundary: React.FC<React.PropsWithChildren<LocalErrorBoundaryProps>> = ({
  t,
  children,
}: LocalErrorBoundaryProps) => {
  const { pathname } = useLocation();

  return (
    <ReactErrorBoundary
      fallback={({ error, resetError }) => {
        return (
          <ErrorBoundaryAlert
            title={t('ns.error_page:error')}
            error={error}
            fallbackErrMessage={t('ns.error_page:app_error')}
            resetError={resetError}
            pathname={pathname}
          />
        );
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export { LocalErrorBoundary };
