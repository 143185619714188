import {
  PersonSearchResultExternal,
  RoleSlug,
  UserReadExternal,
  UserSearchResultExternal,
} from '@askporter/grieg-types';

/**
 * Checks if the user is an anonymous user.
 * @param user - User object
 * @returns True if the user is an anonymous user, false otherwise.
 */
export const isAnonymousUser = (
  user: UserReadExternal | UserSearchResultExternal | PersonSearchResultExternal,
): boolean => {
  if (user?.roleSlug === RoleSlug.UNREG && !user?.givenName && !user?.familyName) return true;
  return false;
};
