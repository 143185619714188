import { differenceInMilliseconds } from 'date-fns';

/**
 * Function for obtaining the duration of a call, this is calculated by taking the difference in minutes between the
 * start and end time of the call
 */
export const getDurationInMs = (startAt = '', endAt = ''): number | '' => {
  if (!startAt || !endAt) return '';

  const start = new Date(startAt);
  const end = new Date(endAt);

  const duration = differenceInMilliseconds(end, start);

  return duration;
};
