import React, { useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { DayOfWeek } from '@askporter/grieg-types';
import { IconButton } from '../../Buttons/IconButton';
import Icon, { IconSize } from '../../Icon';
import { createDateObjectFromTimeString } from '../utils';
import { SelectDayTimeModal } from './components/SelectDayTimeModal';
import { DayTimeType, DayTimeState } from './types';
import { defaultDays, parseDayTime, selectAllDays, sortDays, reducer, days, getAllDayTimes } from './utils';

export type { DayTimeType };

export interface DayTimeProps {
  /**
   * (optional) the title to render above the component
   */
  title?: string;
  /**
   * (optional) the data-testid string for the button
   */
  dataTestId?: string;
  /**
   * (optional) the existing value / data to render
   */
  value?: Array<DayTimeType>;
  /**
   * the translation function
   */
  t: (key: string, options?: Record<string, string | number>) => string;
  /**
   * whether or not we are on a device with a small screen
   */
  isSmallDevice: boolean;
  /**
   * callback function when the 'Add' button is clicked
   */
  onAdd: (value: Array<DayTimeType>) => void;
  /**
   * if disabled then buttons are not rendered, only the data is shown
   */
  disabled?: boolean;
}

/**
 * Allows the user to set a time for multiple week days
 */
export const DayTime: React.FC<React.PropsWithChildren<DayTimeProps>> = ({
  t,
  title,
  value,
  dataTestId,
  isSmallDevice,
  onAdd,
  disabled,
}: DayTimeProps) => {
  const parsedDayTimes = parseDayTime(getAllDayTimes(value));

  const initialState: DayTimeState = {
    isVisible: false,
    selectedDays: defaultDays,
    dayTimes: parsedDayTimes,
    timeValue: null,
    noServiceCheckbox: false,
  };

  const [{ isVisible, selectedDays, dayTimes, timeValue, noServiceCheckbox }, dispatch] = React.useReducer(
    reducer,
    initialState,
  );

  useEffect(() => {
    const newParsedDayTimes = parseDayTime(getAllDayTimes(value));
    dispatch({ type: 'addDayTime', payload: newParsedDayTimes });
  }, [value]);

  return (
    <>
      <Box data-testid={dataTestId} sx={{ width: { xxs: '100%', md: '400px' } }}>
        {title && (
          <Typography component="h4" variant="h6">
            {title}
          </Typography>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
            my: 7.25,
          }}
        >
          {Object.keys(dayTimes || {})
            .sort(sortDays)
            .map((day: DayOfWeek) => (
              <Box
                key={`${day}`}
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '44px' }}
              >
                <Box>
                  <Typography variant="subtitle1">{t(`common:day_of_week:${day}`)}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle1">
                    {dayTimes[day] ? dayTimes[day] : t('day_time:no_times_set')}
                  </Typography>
                  {!disabled && (
                    <IconButton
                      onClick={() =>
                        dispatch({
                          type: 'selectDay',
                          payload: {
                            selectedDays: {
                              ...defaultDays,
                              [days[day]]: true,
                            },
                            timeValue: createDateObjectFromTimeString(dayTimes[day]),
                          },
                        })
                      }
                      label={t(`day_time:edit:${day}`)}
                      aria-label={t(`day_time:edit:${day}`)}
                      sx={{ ml: 4.62 }}
                    >
                      <Icon folder="actions/24-edit" size={IconSize.MD} />
                    </IconButton>
                  )}
                </Box>
              </Box>
            ))}
        </Box>

        {!disabled && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Button
              onClick={() =>
                dispatch({
                  type: 'selectAllDays',
                  payload: selectAllDays,
                })
              }
              variant="outlined"
            >
              {t('day_time:edit:all_hours')}
            </Button>
            <Button
              onClick={() =>
                dispatch({
                  type: 'selectMonFri',
                  payload: {
                    ...defaultDays,
                    0: true,
                    1: true,
                    2: true,
                    3: true,
                    4: true,
                  },
                })
              }
              variant="outlined"
            >
              {t('day_time:edit:mon_fri')}
            </Button>
            <Button
              onClick={() =>
                dispatch({
                  type: 'selectSatSun',
                  payload: {
                    ...defaultDays,
                    5: true,
                    6: true,
                  },
                })
              }
              variant="outlined"
            >
              {t('day_time:edit:sat_sun')}
            </Button>
          </Box>
        )}
      </Box>
      {!disabled && (
        <SelectDayTimeModal
          selectedDays={selectedDays}
          title={t('day_time:select_day_time:title')}
          t={t}
          isSmallDevice={isSmallDevice}
          isVisible={isVisible}
          setIsModalVisible={(isVisible: boolean) => dispatch({ type: 'setModalVisibility', payload: isVisible })}
          handleClose={() => dispatch({ type: 'setModalVisibility', payload: false })}
          onAdd={onAdd}
          dataTestId="select-day-time-modal-body"
          value={value}
          setTimeValue={(timeValue: Date | null) => dispatch({ type: 'setTimeValue', payload: timeValue })}
          timeValue={timeValue}
          setNoServiceCheckbox={(noServiceCheckbox: boolean) =>
            dispatch({ type: 'setNoServiceCheckbox', payload: noServiceCheckbox })
          }
          noServiceCheckbox={noServiceCheckbox}
          setSelectedDays={(selectedDays: Record<DayOfWeek, boolean>) =>
            dispatch({ type: 'setSelectedDays', payload: selectedDays })
          }
          addDayTime={(dayTimesPayload: Record<DayOfWeek, string>) =>
            dispatch({ type: 'addDayTime', payload: dayTimesPayload })
          }
          dayTimes={dayTimes}
        />
      )}
    </>
  );
};
