import React, { useContext, createContext, useEffect } from 'react';
import { useQuery, MutationOptions, RefetchOptions, QueryObserverResult, QueryStatus } from 'react-query';
import { ProfileReadExternal } from '@askporter/client-grieg-lyric';

export interface ProfileContextProps {
  data: ProfileReadExternal | undefined;
  isError: boolean;
  isFetched: boolean;
  isFetching: boolean;
  isLoading: boolean;
  isLoadingError: boolean;
  isRefetchError: boolean;
  isSuccess: boolean;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<ProfileReadExternal, unknown>>;
  status: QueryStatus;
}

export interface ProfileProviderProps {
  children: React.ReactNode;
  authToken: string | undefined;
  isLanguageConsistent: (profilePreferredLanguage: string) => boolean;
  setLanguage: ({
    languageCode,
    profileMutationOpts,
  }?: {
    languageCode?: string;
    profileMutationOpts?: MutationOptions;
  }) => void;
  API: () => {
    get: ({ path }: { path: string }) => Promise<ProfileReadExternal>;
  };
}

export const ProfileContext = createContext<ProfileContextProps>({
  data: undefined,
  isError: false,
  isFetched: false,
  isFetching: false,
  isLoading: false,
  isLoadingError: false,
  isRefetchError: false,
  isSuccess: false,
  refetch: null,
  status: 'idle',
});

export const useProfile = (): ProfileContextProps => {
  const context = useContext(ProfileContext);

  if (context === undefined) {
    throw new Error('ProfileContext must be used within a ProfileProvider component');
  }
  return context;
};

export const ProfileProvider = ({
  children,
  authToken,
  isLanguageConsistent,
  setLanguage,
  API,
}: ProfileProviderProps): JSX.Element => {
  const profileAPIPath = 'profile';
  const profileQuery = useQuery(profileAPIPath, () => API().get({ path: profileAPIPath }), {
    enabled: Boolean(authToken),
  });
  const {
    data,
    isError,
    isFetched,
    isFetching,
    isLoading,
    isLoadingError,
    isRefetchError,
    isSuccess,
    refetch,
    status,
  }: ProfileContextProps = profileQuery;

  useEffect(() => {
    if (data?.preferredLanguage && !isLanguageConsistent(data?.preferredLanguage)) {
      setLanguage();
    }
  }, [data?.preferredLanguage]);

  return (
    <ProfileContext.Provider
      value={{
        data,
        isError,
        isFetched,
        isFetching,
        isLoading,
        isLoadingError,
        isRefetchError,
        isSuccess,
        refetch,
        status,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
