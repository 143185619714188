import React from 'react';
import { SessionState, useAuthProvider } from '@askporter/auth-provider';
import { Navigation } from '@askporter/component-library';
import { useConfig, useTranslation } from '@askporter/config-provider';
import { useProfile } from '@askporter/profile-provider';
import { useLogout } from '../../utils';

type Props = { simple?: true; simpleNavigationTitle?: string };

/** Provides the necessary data to the component library header  */
const CoreNavigation: React.FC<React.PropsWithChildren<Props>> = ({ simple, simpleNavigationTitle }: Props) => {
  const { t } = useTranslation();
  const { handleSignOut } = useLogout();
  const config = useConfig();
  const { sessionState } = useAuthProvider();
  const { data: profile = {} } = useProfile();
  const isAuthenticated = SessionState[sessionState] === 'valid' ? true : false;

  if (isAuthenticated && !simple)
    return <Navigation mode="complete" t={t} config={config} userProfile={profile} handleSignOut={handleSignOut} />;
  else if (!isAuthenticated || simple)
    return <Navigation mode="simple" t={t} config={config} simpleNavigationTitle={simpleNavigationTitle} />;

  return null;
};

export default CoreNavigation;
