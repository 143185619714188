import { DateTimeReadOnly } from './DateTimeReadOnly';
import { LangCode } from './LangCode';
import { SearchAssetFilter } from './SearchAssetFilter';
import { SearchAssetSort } from './SearchAssetSort';
import { SearchFreeText } from './SearchFreeText';
import { TimezoneName } from './TimezoneName';
import { UIDReadOnly } from './UIDReadOnly';
import { URNReadOnly } from './URNReadOnly';
import { UserRefHydrated } from './UserRefHydrated';

export interface AssetExportReadExternal {
  uid: UIDReadOnly;
  urn: URNReadOnly;
  name: string;
  languageCode: LangCode;
  timezone: TimezoneName;
  resultCount?: number;
  progress: any;
  requestedAt: DateTimeReadOnly;
  freeText?: SearchFreeText;
  format: AssetExportReadExternal_FormatEnum;
  createdBy: UserRefHydrated;
  type: AssetExportReadExternal_TypeEnum;
  fields: Array<AssetExportReadExternal_FieldsEnum>;
  filter: SearchAssetFilter;
  sort: SearchAssetSort;
}

export type AssetExportReadExternal_FormatEnum = 'STANDARD' | 'EXCEL_COMPATIBLE';

export const AssetExportReadExternal_FormatEnum = {
  STANDARD: 'STANDARD' as AssetExportReadExternal_FormatEnum,
  EXCELCOMPATIBLE: 'EXCEL_COMPATIBLE' as AssetExportReadExternal_FormatEnum,
};

export type AssetExportReadExternal_TypeEnum = 'ASSET';

export const AssetExportReadExternal_TypeEnum = {
  ASSET: 'ASSET' as AssetExportReadExternal_TypeEnum,
};

export type AssetExportReadExternal_FieldsEnum =
  | 'ASSET_DETAILS'
  | 'ASSET_SETTINGS'
  | 'REGISTRATION_TOKEN'
  | 'ONSITE_ASSISTANT';

export const AssetExportReadExternal_FieldsEnum = {
  ASSETDETAILS: 'ASSET_DETAILS' as AssetExportReadExternal_FieldsEnum,
  ASSETSETTINGS: 'ASSET_SETTINGS' as AssetExportReadExternal_FieldsEnum,
  REGISTRATIONTOKEN: 'REGISTRATION_TOKEN' as AssetExportReadExternal_FieldsEnum,
  ONSITEASSISTANT: 'ONSITE_ASSISTANT' as AssetExportReadExternal_FieldsEnum,
};
