import React, { useContext, createContext, useState } from 'react';
import { Toast, ToastProps as FullToastProps } from '../../';

export type ToastProviderToastProps = Omit<FullToastProps, 'onClose'> & {
  /**
   * Optional duration, indicates how long toast will be displayed for and defaults 6000ms
   */
  autoHideDuration?: null | number;
  /**
   * Optional function to be called when the toast is closed by clicking the close icon
   * useful in the case of needing to perform an action when the toast is closed
   * NOTE: this function only gets called when the close icon is clicked
   */
  onToastClose?: () => void;
};

export interface ToastContextProps {
  /**
   * Provides full control over what is displayed in the toast
   */
  setToast: (toast: ToastProviderToastProps) => void;
  /**
   * Convenience method for showing errors, allows just a string to be provided
   */
  showError: (message: string) => void;
  /**
   * Convenience method for showing success, allows just a string to be provided
   */
  showSuccess: (message: string) => void;
  /**
   * Clears current toast
   */
  closeToast: () => void;
}

/**
 * As the provider will wrap the app, it needs to accepts children
 */
export interface ToastProviderProps {
  /**
   * React node as children
   */
  children: React.ReactNode;
}

export const ToastContext = createContext<ToastContextProps>({
  setToast: undefined,
  showError: undefined,
  showSuccess: undefined,
  closeToast: undefined,
});

/**
 * Hook for obtaining the toast function from the context
 */
export const useToast = (): ToastContextProps => {
  const context = useContext(ToastContext);

  if (context === undefined) {
    throw new Error('ToastContext must be used within a ToastProvider component');
  }
  return context;
};

const initialToastState: ToastProviderToastProps = {
  children: '',
  severity: undefined,
  open: false,
};

export const ToastProvider = ({ children: providerChildren }: ToastProviderProps): JSX.Element => {
  const [{ children: toastChildren, onToastClose = () => {}, ...toast }, setToast] =
    useState<ToastProviderToastProps>(initialToastState);

  const closeToast = () => {
    onToastClose();
    setToast(initialToastState);
  };

  return (
    <ToastContext.Provider
      value={{
        setToast,
        showError: (message) => setToast({ open: true, severity: 'error', children: message }),
        showSuccess: (message) => setToast({ open: true, severity: 'success', children: message }),
        closeToast,
      }}
    >
      {toast.open ? (
        <Toast
          {...toast}
          onClose={closeToast}
          data-testid={`${toast.severity}-snackbar`}
          // refer to key description here https://mui.com/material-ui/api/snackbar/ below is used to ensure
          // autoHideDuration is applied properly
          key={`${JSON.stringify(toastChildren)}_${toast.severity}_${toast.title}`}
        >
          {toastChildren}
        </Toast>
      ) : null}
      {providerChildren}
    </ToastContext.Provider>
  );
};
