import { useEffect, useState, useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import { DropzoneInputProps, DropzoneRootProps, useDropzone } from 'react-dropzone';
import { AxiosError } from '@askporter/api-client';
import { captureException } from '@askporter/exception-logger';
import { Media, SimplifiedIAPIClient, transformUploadData, uploadFiles, UploaderFileTypes } from '@askporter/utils';

interface UseDragAndDropProps {
  /**
   * Api client
   */
  API: () => SimplifiedIAPIClient;
  /**
   * Existing files
   */
  existingFiles?: Array<Media>;
  /**
   * If multiple files are allowed
   */
  multiple: boolean;
  /**
   * Callback when files are uploaded
   */
  onChange: (files: Array<Media>) => void;
  /**
   * Accepted file types
   */
  accept: UploaderFileTypes;
  /**
   * Is file upload disabled
   */
  disabled: boolean;
}

type FilesFailedToUpload = { error: unknown; name: string };

type UseDragAndDropReturn = {
  filesUploading: Pick<Media, 'nameAndType'>[];
  filesNewlyUploaded: Media[];
  filesPreviouslyUploaded: Media[];
  setFilesSuccessfullyUploaded: React.Dispatch<React.SetStateAction<Media[]>>;
  filesFailedToUpload: Array<FilesFailedToUpload>;
  setFilesFailedToUpload: React.Dispatch<React.SetStateAction<FilesFailedToUpload[]>>;
  unsupportedFileError: boolean;
  mapOfURNToObjectURL: Record<string, string>;
  dragAndDrop: {
    getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
    getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
  };
};

/**
 * A react hook that combines the drag and drop functionality with the file upload functionality
 */
export const useDragAndDropUpload = ({
  API,
  existingFiles,
  multiple,
  onChange,
  accept,
  disabled,
}: UseDragAndDropProps): UseDragAndDropReturn => {
  const [filesUploading, setFilesUploading] = useState<Array<Pick<Media, 'nameAndType'>>>([]);
  const [filesSuccessfullyUploaded, setFilesSuccessfullyUploaded] = useState<Array<Media>>(existingFiles || []);
  const [filesFailedToUpload, setFilesFailedToUpload] = useState<Array<FilesFailedToUpload>>([]);
  const [unsupportedFileError, setUnsupportedFileError] = useState(false);
  const [mapOfURNToObjectURL, setMapOfURNToObjectURL] = useState<Record<string, string>>({});

  useEffect(() => {
    // if the existing files have changed, update the state
    if (!isEqual(existingFiles, filesSuccessfullyUploaded)) {
      setFilesSuccessfullyUploaded(existingFiles || []);
    }
  }, [existingFiles]);

  useEffect(() => {
    // existingFiles is undefined when no value is passed causing the onChange to be called mutiple times on render
    if (!isEqual(existingFiles || [], filesSuccessfullyUploaded)) onChange(filesSuccessfullyUploaded);
  }, [filesSuccessfullyUploaded]);

  useEffect(() => {
    /**
     * URL.createObjectURL() is used when files is being uploaded to create a reference to the file.
     * We need to revoke the object URL when the component is unmounted to prevent memory leaks.
     */
    return () => {
      Object.values(mapOfURNToObjectURL).length > 0 && Object.values(mapOfURNToObjectURL).forEach(URL.revokeObjectURL);
    };
  }, []);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      const uploadingFiles = acceptedFiles.map((file: { name: string; type: string }) => ({
        nameAndType: {
          name: file.name.split('.')[0],
          type: file.type.split('/')[1],
        },
      }));
      // set the files that are uploading to show loading file state
      setFilesUploading(uploadingFiles);

      const uploadResults = await uploadFiles(acceptedFiles, API, captureException);

      const succesfullyUploadedFiles = uploadResults
        .filter(({ uid, error }) => uid && !error)
        .map((d) => {
          setMapOfURNToObjectURL((current) => ({ ...current, [d.urn]: d.objectURL }));
          return d;
        })
        .map((d) => transformUploadData(d));

      setFilesUploading([]);

      if (multiple) {
        setFilesSuccessfullyUploaded([...succesfullyUploadedFiles, ...filesSuccessfullyUploaded]);
      } else {
        setFilesSuccessfullyUploaded([...succesfullyUploadedFiles]);
      }

      setFilesFailedToUpload([
        ...uploadResults
          .filter(({ uid, error }) => !uid || !!error)
          .map(({ error, name }) => ({
            error: (error as AxiosError)?.response?.data?.message,
            name,
          })),
      ]);
    },
    [filesSuccessfullyUploaded],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    onDropRejected: () => setUnsupportedFileError(true),
    onDropAccepted: () => setUnsupportedFileError(false),
    multiple,
    disabled,
  });

  const existingFilesURN = existingFiles?.map((file) => file.file.fileRef);

  return {
    filesUploading,
    filesNewlyUploaded: filesSuccessfullyUploaded.filter((file) => !existingFilesURN?.includes(file.file.fileRef)),
    filesPreviouslyUploaded: filesSuccessfullyUploaded.filter((file) => existingFilesURN?.includes(file.file.fileRef)),
    setFilesSuccessfullyUploaded,
    filesFailedToUpload,
    setFilesFailedToUpload,

    unsupportedFileError,

    // for file preview
    mapOfURNToObjectURL,

    dragAndDrop: {
      getRootProps,
      getInputProps,
    },
  };
};
