import React from 'react';
import { Box } from '@mui/material';
import getImagePath from './utils/getImagePath';

/**
 * Renders an image element, if an array of image strings are provided one will be selected at random
 *  @param imageSrc - Represents the src attribute of the image
 */

interface CoverImage {
  imageSrc?: string | Array<string>;
}

const CoverImage = ({ imageSrc }: CoverImage): JSX.Element => {
  const imagePath = getImagePath(imageSrc);

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        backgroundImage: `url(${imagePath})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    />
  );
};

export default React.memo(CoverImage);
