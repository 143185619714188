export const getInitials = (givenName: string, familyName: string): string => {
  if (!givenName && !familyName) return '';
  if (givenName?.length >= 1 && familyName?.length >= 1) return `${givenName[0]}${familyName[0]}`;
  if (givenName?.length >= 1) return givenName[0];
  if (familyName?.length >= 1) return familyName[0];
  return '';
};

export const getInitialsFromLongName = (name: string): string => {
  if (!name) return '';

  try {
    const splitName = name.split(' ');

    if (splitName.length > 1) return `${splitName[0][0]}${splitName[splitName.length - 1][0]}`;
    else return `${splitName[0][0]}`;
  } catch {
    return '';
  }
};
