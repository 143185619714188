import React from 'react';
import { RoleSlug } from '@askporter/grieg-types';
import { useAuthProvider } from '..';
import { checkPermissions } from './checkPermissions';
export interface PermissionProps {
  requiredRoles: RoleSlug | RoleSlug[];
}

export interface PermissionPropsWithChildren extends PermissionProps {
  children: React.ReactNode;
}

/**
 * Check whether the user has the acceptable role to access / view feature
 *
 * @param requiredRoles - the required role(s) needed to access / view feature
 * @returns boolean - true / false flag - whether the user can access / view feature
 */
export function usePermissions(requiredRoles: PermissionProps['requiredRoles']): boolean {
  const { userProfile = {} } = useAuthProvider();

  return checkPermissions(userProfile['custom:ap_role'], requiredRoles);
}

/**
 * Resolve an object of permission mappings by returning true false assertions against each key
 *
 * @param permissionMapping - object containing a list of required roles
 * @returns resolved permissions object - keys with true / false flag - whether the user can access / view feature
 */
export function useResolvePermissions<Key>(
  permissionMapping: Record<keyof Key, PermissionProps['requiredRoles']>,
): Record<keyof Key, boolean> {
  const { userProfile = {} } = useAuthProvider();
  const resolvedPermissions: Partial<Record<keyof Key, boolean>> = {};

  Object.keys(permissionMapping || [])?.forEach((key) => {
    const k = key as keyof Key;

    try {
      const checkPermissionResult = checkPermissions(userProfile['custom:ap_role'], permissionMapping[k]);

      resolvedPermissions[k] = checkPermissionResult;
    } catch {
      resolvedPermissions[k] = false;
    }
  });

  return resolvedPermissions as Record<keyof Key, boolean>;
}

/**
 * Check whether the user has the acceptable role to access / view feature
 *
 * @param requiredRoles - the required role(s) needed to access / view feature
 * @param children - the React children components to render if user has permissions
 *
 * @returns - React children or null depending on whether the user can access / view feature
 */
export const Permissions: React.FC<React.PropsWithChildren<PermissionPropsWithChildren>> = ({
  requiredRoles,
  children,
}: PermissionPropsWithChildren) => {
  const { userProfile = {} } = useAuthProvider();

  return checkPermissions(userProfile['custom:ap_role'], requiredRoles) ? <>{children}</> : null;
};
