import React, { useState } from 'react';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormControl,
  InputLabel,
  FilledInput,
  FormHelperText,
  Skeleton,
  Box,
  Alert,
  InputAdornment,
} from '@mui/material';
import { Accordion, Icon, IconSize } from '../../../index';

interface FilterSectionProps {
  filterKey: string;
  filterSectionTitle: string;
  children: React.ReactNode | React.ReactNode[];
  searchValue?: string;
  searchLabel?: string;
  searchOnChange?: (e: React.SyntheticEvent, filterKey: string) => void;
  isExpandedOnMount?: boolean;
  t: (key: string) => string;
  isLoading?: boolean;
  isError?: boolean;
}

/**
 * Renders a FilterSection component which provides an expandable accordion, filter items can be passed as children
 * @param filterKey - the filter key (category of filters, i.e. Priority)
 * @param filterSectionTitle - accordion title
 * @param children - the filter items for this filter section
 * @param searchOnChange - the onChange handler for the search input box
 * @param searchValue - the state value for search
 * @param searchLabel - the label txt for the search input box
 * @param isExpandedOnMount - should it be open by default
 * @param t - the translation function
 */
const FilterSection: React.FC<React.PropsWithChildren<FilterSectionProps>> = ({
  filterKey,
  filterSectionTitle,
  children,
  searchOnChange,
  searchValue,
  searchLabel,
  isExpandedOnMount,
  t,
  isLoading,
  isError,
}: FilterSectionProps) => {
  const [defaultExpanded] = useState(isExpandedOnMount);
  const [hasFocus, setHasFocus] = useState(false);
  const shrinkLabel = hasFocus || searchValue !== '' ? true : false;
  const noChildren = !children || (Array.isArray(children) && children.length === 0);

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      data-testid={`filter-section--${filterKey}`}
      titleComponent={
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: 0 }}>
          {filterSectionTitle}
        </AccordionSummary>
      }
      contentComponent={
        <AccordionDetails sx={{ px: 0 }}>
          {isError ? (
            <Alert severity="error" data-testid={`${filterKey}-section-error`}>
              {t('ns.common:filter_section_error')}
            </Alert>
          ) : (
            <>
              {searchValue !== undefined && (
                <FormControl fullWidth>
                  <InputLabel
                    variant="filled"
                    htmlFor={`${filterKey}-search`}
                    shrink={shrinkLabel}
                    sx={{ pl: shrinkLabel ? 0 : 8 }}
                  >
                    {searchLabel}
                  </InputLabel>
                  <FilledInput
                    id={`${filterKey}-search`}
                    name={`${filterKey}-search-input`}
                    value={searchValue}
                    onChange={(e) => searchOnChange(e, filterKey)}
                    inputProps={{ 'data-testid': `${filterKey}-search-input` }}
                    onFocus={() => setHasFocus(true)}
                    onBlur={() => setHasFocus(false)}
                    startAdornment={
                      <InputAdornment position="start" sx={{ alignItems: shrinkLabel ? 'baseline' : undefined }}>
                        <Icon size={IconSize.SM} folder="actions/12-search" />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              )}
              <FormGroup
                sx={
                  isLoading
                    ? {}
                    : {
                        backgroundColor: 'grey.100',
                      }
                }
              >
                {isLoading && (
                  <>
                    <Box sx={{ display: 'flex', px: 4, py: 2 }}>
                      <Box sx={{ mr: 4 }}>
                        <Skeleton variant="rectangular" width={20} height={20} />
                      </Box>
                      <Box sx={{ width: '60%' }}>
                        <Skeleton animation="wave" height={20} width="100%" />
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', px: 4, py: 2 }}>
                      <Box sx={{ mr: 4 }}>
                        <Skeleton variant="rectangular" width={20} height={20} />
                      </Box>
                      <Box sx={{ width: '45%' }}>
                        <Skeleton animation="wave" height={20} width="100%" />
                      </Box>
                    </Box>
                  </>
                )}
                {!noChildren && !isLoading && children}
                {noChildren && !isLoading && (
                  <FormHelperText data-testid="no-filter-search-results">
                    {t('ns.task_filter:no_search_results')}
                  </FormHelperText>
                )}
              </FormGroup>
            </>
          )}
        </AccordionDetails>
      }
    ></Accordion>
  );
};

export { FilterSection };
