import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { ExportButton, ExportButtonProps } from '../../../../Buttons/ExportButton';
import CreateButton, { CreateButtonProps } from '../../CreateButton';

interface HeaderProps {
  isListPage: boolean;
  title?: string;
  isSmallDevice: boolean;
  createButton?: CreateButtonProps;
  exportButton?: ExportButtonProps;
  tabs?: () => React.ReactNode;
  children?: React.ReactNode;
}

export const Header: React.FC<HeaderProps> = ({
  isListPage,
  title,
  isSmallDevice,
  createButton,
  exportButton,
  tabs,
  children,
}: HeaderProps) => {
  const theme = useTheme();

  return (
    <Box>
      {isListPage ? (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="nowrap">
            {title && (
              <Typography variant="h5" component="h1" data-testid="title">
                {title}
              </Typography>
            )}
            <Box>
              {exportButton && <ExportButton {...exportButton} />}
              {createButton && <CreateButton {...createButton} isSmallDevice={isSmallDevice} />}
            </Box>
          </Box>
          {tabs && (
            <Box data-testid="tabs" sx={{ mb: theme.custom.spacing.xs }}>
              {tabs()}
            </Box>
          )}
        </>
      ) : (
        <>{children}</>
      )}
    </Box>
  );
};

export default Header;
