import React from 'react';
import IosShareIcon from '@mui/icons-material/IosShare';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, ListItem } from '@askporter/component-library';
import { useExportContext } from '../../../providers';
import type { ExportsInProgressItem } from '../../../types';
import { ExportDownloadButton } from '../../ExportDownloadButton';

export interface ExportsInProgressListItemProps extends ExportsInProgressItem {
  /**
   * The translation function
   */
  t: (key: string, options?: Record<string, string | number>) => string;
  /**
   * The loading state of the list item
   */
  isLoading: boolean;
  /**
   * The name of the export
   */
  name: string;
}

export const ExportsInProgressListItem: React.FC<ExportsInProgressListItemProps> = ({
  t,
  isLoading,
  uid,
  status,
  name,
}) => {
  const { removeExportsInProgressItem } = useExportContext();

  const handleOnClick = () => {
    removeExportsInProgressItem(uid);
  };
  return (
    <ListItem
      isLoading={isLoading}
      startComponent={<IosShareIcon />}
      primaryText={name}
      primaryTextNoWrap={true}
      testId="exports-in-progress-list-item"
      endComponent={
        status === 'PENDING' ? (
          // Vertical padding is used to make the Progress indicator the same height as the buttons
          <Box sx={{ paddingX: '16px', paddingY: '9px' }}>
            <CircularProgress size={24} />
          </Box>
        ) : status === 'COMPLETED' ? (
          <ExportDownloadButton
            label={t('export:export_progress_snackbar:list_item:download_button')}
            uid={uid}
            onClick={handleOnClick}
          />
        ) : (
          <Button variant="text" color="error" href="/app/exports" onClick={handleOnClick}>
            {t('export:export_progress_snackbar:list_item:failed_button')}
          </Button>
        )
      }
      {...(status === 'PENDING'
        ? {
            component: 'a',
            href: '/app/exports',
            onClick: handleOnClick,
            sx: {
              color: 'text.primary',
            },
          }
        : {})}
    />
  );
};
