import { isToday, format, differenceInCalendarDays, differenceInSeconds, differenceInMinutes, getDay } from 'date-fns';
import { formatDate } from './formatDate';

/**
 * Transforms iso timestamp into a reader friendly format
 * @param timestamp - the
 * @param t - translation function
 *
 * @returns - formatted string
 */
export const formatTimestamp = (
  timestamp: string,
  t: (key: string, options?: Record<string, string | number>) => string,
): {
  time: string;
  day: string;
  isToday?: boolean;
} => {
  const fallbackResponse = { time: '', day: '' };
  if (!timestamp) return fallbackResponse;

  try {
    const now = new Date();
    const timestampAsDate = new Date(timestamp);

    const time = getTimePart(timestampAsDate, now, t);
    const day = getDayPart(timestampAsDate, now, t);

    return { time, day, isToday: isToday(timestampAsDate) };
  } catch (error) {
    return fallbackResponse;
  }
};

export const getDayPart = (
  timestamp: Date,
  now: Date,
  t: (key: string, options?: Record<string, string | number>) => string,
): string => {
  const diffInCalendarDays = differenceInCalendarDays(now, timestamp);

  if (isToday(timestamp)) return t('ns.task_messages:today');
  if (diffInCalendarDays === 1) return t('ns.task_messages:yesterday');
  if (diffInCalendarDays < 8) return t(`ns.task_messages:day_of_week:${getDay(timestamp)}`);
  return formatDate(timestamp.toISOString(), 'dd MMM yyyy');
};

export const getTimePart = (
  timestamp: Date,
  now: Date,
  t: (key: string, options?: Record<string, string | number>) => string,
): string => {
  if (differenceInSeconds(now, timestamp) < 60) return t('ns.task_messages:just_now');

  const diffInMinutes = differenceInMinutes(now, timestamp);
  if (diffInMinutes < 60)
    return t('ns.task_messages:minutes_ago', { count: Math.abs(differenceInMinutes(timestamp, now)) });
  if (diffInMinutes < 120) return t('ns.task_messages:one_hour_ago');

  return format(timestamp, 'HH:mm');
};
