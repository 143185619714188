import React from 'react';
import { Box, BoxProps } from '@mui/material';
import { FormProps, UiSchema, withTheme, ISubmitEvent, FieldProps } from '@rjsf/core';
import { FieldAutoSaveStatuses } from '../';
import AutocompleteWidget from './AutocompleteWidget';
import CheckboxWidget from './CheckboxWidget';
import CustomTitleField from './CustomTitleField';
import DateTimeWidget from './DateTimeWidget';
import DateWidget from './DateWidget';
import FieldTemplate from './FieldTemplate';
import FilledInputWidget from './FilledInputWidget';
import FilledTextAreaWidget from './FilledTextAreaWidget';
import SelectWidget from './SelectWidget';
import { CustomWidgetProps, HasChangedEvent } from './types';
import { dataTypeTransformMap } from './utils';

export type { ISubmitEvent, UiSchema, FieldProps };
export { dataTypeTransformMap };

const ThemedForm = withTheme({
  idPrefix: 'rjsf_root',
  FieldTemplate,
  widgets: {
    TextareaWidget: FilledTextAreaWidget,
    TextWidget: FilledInputWidget,
    DateWidget,
    DateTimeWidget,
    SelectWidget,
    CheckboxWidget,
    AutocompleteWidget: AutocompleteWidget,
  },
  fields: {
    TitleField: CustomTitleField,
  },
});

export interface RJSFProps extends FormProps<any> {
  sx?: BoxProps['sx'];
  readOnly?: boolean;
  onHasChanged?: HasChangedEvent;
  autosaveStatuses?: FieldAutoSaveStatuses;
  clearAutosaveStatus?: (id: string) => void;
  formContext: CustomWidgetProps['formContext'];
}

/**
 * Renders a RJSF component
 * @param FormProps - accepts all props of type FormProps
 * @param sx - styles to apply to a MUI Box that wraps the html form component
 * @param readOnly - whether we should set all the widgets to readonly state
 * @param onHasChanged - function that fires when the data for widget has changed
 */
export const RJSF: React.FC<React.PropsWithChildren<RJSFProps>> = ({
  sx,
  readOnly = false,
  uiSchema,
  formContext,
  onHasChanged: onHasChangedFn,
  autosaveStatuses = {},
  clearAutosaveStatus: clearAutosaveStatusFn,
  ...other
}: RJSFProps) => {
  const modifiedUiSchema: UiSchema = { 'ui:readonly': readOnly, ...uiSchema };
  const onHasChanged = onHasChangedFn ? onHasChangedFn : (): void => null;
  const clearAutosaveStatus = clearAutosaveStatusFn ? clearAutosaveStatusFn : (): void => null;

  return (
    <Box
      sx={{
        '& fieldset': {
          all: 'unset',
          '& > legend': {
            fontWeight: 500,
            fontSize: '1.25rem',
            lineHeight: '1.75rem',
            letterSpacing: '0.009rem',
          },
        },
        '& .RJSF-width-48-percent': { width: '48%' },
        '& .RJSF-float-right': { float: 'right' },
        ...sx,
      }}
      data-testid="RJSF"
    >
      <ThemedForm
        {...other}
        uiSchema={modifiedUiSchema}
        formContext={{ ...formContext, onHasChanged, autosaveStatuses, clearAutosaveStatus }}
      />
    </Box>
  );
};
