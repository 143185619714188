export type TwilioFlexInsightsRole = 'wfo.full_access' | 'wfo.team_leader' | 'wfo.data_analyst' | 'wfo.data_auditor' | 'wfo.quality_manager' | 'wfo.quality_process_manager' | 'wfo.dashboard_viewer';

export const TwilioFlexInsightsRole = {
  wfofullaccess: 'wfo.full_access' as TwilioFlexInsightsRole,
  wfoteamleader: 'wfo.team_leader' as TwilioFlexInsightsRole,
  wfodataanalyst: 'wfo.data_analyst' as TwilioFlexInsightsRole,
  wfodataauditor: 'wfo.data_auditor' as TwilioFlexInsightsRole,
  wfoqualitymanager: 'wfo.quality_manager' as TwilioFlexInsightsRole,
  wfoqualityprocessmanager: 'wfo.quality_process_manager' as TwilioFlexInsightsRole,
  wfodashboardviewer: 'wfo.dashboard_viewer' as TwilioFlexInsightsRole,
};
