import { RoleSlug } from '@askporter/grieg-types';
import { ManagerRoles, TaskFilterPermissions } from '../../types';

// jira: AP-359
export const taskListFilters: TaskFilterPermissions = {
  quickFilterDueToday: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  quickFilterRespondToday: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  quickFilterResolveToday: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  linkedAsset: [...ManagerRoles, RoleSlug.SVCUSRSPR, RoleSlug.SVCUSR, RoleSlug.SUPP, RoleSlug.SUPPSPR],
  assignedUser: ManagerRoles,
  assignedTeam: ManagerRoles,
  created: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  dueStatus: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  statusType: [...ManagerRoles, RoleSlug.SVCUSRSPR, RoleSlug.SVCUSR, RoleSlug.SUPP, RoleSlug.SUPPSPR],
  priority: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  respondStatus: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  resolveStatus: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  status: [...ManagerRoles, RoleSlug.SVCUSRSPR, RoleSlug.SVCUSR, RoleSlug.SUPP, RoleSlug.SUPPSPR],
  dueSlaStatus: [], //TODO https://github.com/askporter/front-end/issues/4188
  taskType: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  extendedMetadata: ManagerRoles,
};

// jira: AP-1282
export const teamLinkedTasksFilters: TaskFilterPermissions = {
  quickFilterDueToday: ManagerRoles,
  quickFilterRespondToday: ManagerRoles,
  quickFilterResolveToday: ManagerRoles,
  linkedAsset: ManagerRoles,
  assignedUser: ManagerRoles,
  assignedTeam: [],
  created: ManagerRoles,
  dueStatus: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  statusType: ManagerRoles,
  priority: ManagerRoles,
  respondStatus: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  resolveStatus: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  status: ManagerRoles,
  dueSlaStatus: [], //TODO https://github.com/askporter/front-end/issues/4188
  taskType: ManagerRoles,
  extendedMetadata: ManagerRoles,
};

// jira: AP-1282
export const orgLinkedTasksFilters: TaskFilterPermissions = {
  quickFilterDueToday: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  quickFilterRespondToday: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  quickFilterResolveToday: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  linkedAsset: [...ManagerRoles, RoleSlug.SVCUSRSPR, RoleSlug.SUPPSPR],
  assignedUser: ManagerRoles,
  assignedTeam: ManagerRoles,
  created: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  dueStatus: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  statusType: [...ManagerRoles, RoleSlug.SVCUSRSPR, RoleSlug.SUPPSPR],
  priority: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  respondStatus: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  resolveStatus: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  status: [...ManagerRoles, RoleSlug.SVCUSRSPR, RoleSlug.SUPPSPR],
  dueSlaStatus: [], //TODO https://github.com/askporter/front-end/issues/4188
  taskType: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  extendedMetadata: ManagerRoles,
};

// jira: AP-1282 - currently exactly the same as org
export const userLinkedTasksFilters = orgLinkedTasksFilters;

// jira: AP-1282
export const assetLinkedTasksFilters: TaskFilterPermissions = {
  quickFilterDueToday: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  quickFilterRespondToday: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  quickFilterResolveToday: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  linkedAsset: [],
  assignedUser: ManagerRoles,
  assignedTeam: ManagerRoles,
  created: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  dueStatus: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  statusType: [...ManagerRoles, RoleSlug.SUPPSPR, RoleSlug.SUPP, RoleSlug.SVCUSR, RoleSlug.SVCUSRSPR],
  priority: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  respondStatus: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  resolveStatus: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  status: [...ManagerRoles, RoleSlug.SUPPSPR, RoleSlug.SUPP, RoleSlug.SVCUSR, RoleSlug.SVCUSRSPR],
  dueSlaStatus: [], //TODO https://github.com/askporter/front-end/issues/4188
  taskType: [...ManagerRoles, RoleSlug.SVCUSRSPR],
  extendedMetadata: ManagerRoles,
};
