// in order to override the breakpoints, see https://material-ui.com/customization/breakpoints/#breakpoints
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxs: true;
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

export const breakpoints = {
  values: {
    xxs: 0,
    xs: 375,
    sm: 768,
    md: 1024,
    lg: 1440,
    xl: 1920,
  },
};
