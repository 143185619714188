import { ExtendedMetadataAttributeConfigReadExternalValidationRules } from './ExtendedMetadataAttributeConfigReadExternalValidationRules';
import { ExtendedMetadataDataType } from './ExtendedMetadataDataType';
import { UIDReadOnly } from './UIDReadOnly';

export interface ExtendedMetadataAttributeConfigReadExternal {
  uid: UIDReadOnly;
  jsonElementName: string;
  dataType: ExtendedMetadataDataType;
  required?: ExtendedMetadataAttributeConfigReadExternal_RequiredEnum;
  validationRules?: ExtendedMetadataAttributeConfigReadExternalValidationRules;
  filterable?: boolean;
  uiEditDisabled?: boolean;
  displayName?: string;
}

export type ExtendedMetadataAttributeConfigReadExternal_RequiredEnum = 'SOFT' | 'HARD' | 'NONE';

export const ExtendedMetadataAttributeConfigReadExternal_RequiredEnum = {
  SOFT: 'SOFT' as ExtendedMetadataAttributeConfigReadExternal_RequiredEnum,
  HARD: 'HARD' as ExtendedMetadataAttributeConfigReadExternal_RequiredEnum,
  NONE: 'NONE' as ExtendedMetadataAttributeConfigReadExternal_RequiredEnum,
};
