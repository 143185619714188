import { ExportResourceNames } from '../types';

export const generateExportFileName = ({
  resourceName,
  t,
}: {
  resourceName: ExportResourceNames;
  t: (key: string, options?: Record<string, string | number>) => string;
}): string => {
  const date = new Date();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const day = date.getDate().toString().padStart(2, '0');
  const fullYear = date.getFullYear().toString();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  const dateSuffix = `${fullYear}-${month}-${day}-${hours}-${minutes}`;

  return t(`common:export:export_modal:export_name:default_${resourceName}`, { dateSuffix });
};
