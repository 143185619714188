export const convertAllUndefinedPropsToNull = (obj: { [key: string]: any }): void => {
  Object.keys(obj || {}).forEach((key: string) => {
    if (key && obj[key] === undefined) {
      obj[key] = null;
    }

    if (typeof obj[key] === 'object') {
      convertAllUndefinedPropsToNull(obj[key]);
    }
  });
};
