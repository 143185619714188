import React from 'react';
import { Button, Dialog } from '@askporter/component-library';

export interface ExportLimitDialogProps {
  /**
   * Whether the dialog is open
   */
  isOpen: boolean;
  /**
   * callback to close the dialog
   */
  onClose: () => void;
  /**
   * Whether the device is small
   */
  isSmallDevice: boolean;
  /**
   * The translation function
   */
  t: (key: string, options?: Record<string, string | number>) => string;
  /**
   * apply filters callback
   */
  applyFilters: () => void;
  /**
   * proceed with export callback
   */
  proceedWithExport: () => void;
}
export const ExportLimitDialog = ({
  isOpen,
  onClose,
  isSmallDevice,
  t,
  applyFilters,
  proceedWithExport,
}: ExportLimitDialogProps): JSX.Element => {
  return (
    <Dialog
      onClose={onClose}
      isOpen={isOpen}
      isSmallDevice={isSmallDevice}
      title={t('common:export:export_limit_dialog:title')}
      actions={
        <>
          <Button variant="outlined" onClick={applyFilters}>
            {t('common:export:export_limit_dialog:apply_button')}
          </Button>
          <Button variant="contained" onClick={proceedWithExport}>
            {t('common:export:export_limit_dialog:proceed_button')}
          </Button>
        </>
      }
    >
      {t('common:export:export_limit_dialog:content')}
    </Dialog>
  );
};
