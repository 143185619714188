import { v4 as uuidv4 } from 'uuid';
import { captureException } from '@askporter/exception-logger';

const localStorageItemName = 'sessionCacheIdentifier';

/**
 * Generates a cache buster uuid that is stored local storage, if one already exists it is replaced with the new uuid
 */
export const setCacheBuster = (): void => {
  try {
    const uuid = uuidv4();
    window.localStorage.setItem(localStorageItemName, uuid);
  } catch (err) {
    captureException(err);
  }
};

/**
 * Generates a cache buster uuid that is stored local storage
 *
 */
export const getCacheBuster = (): string => {
  try {
    const value = window.localStorage.getItem(localStorageItemName);

    // if the sessionCacheIdentifier undefined is encountered, a new one should be set
    if (!value) {
      setCacheBuster();
      const value = window.localStorage.getItem(localStorageItemName);

      return value;
    }
    return value;
  } catch (err) {
    captureException(err);

    // in an error scenario, we shouldn't block a cache buster value being used, so return the timestamp as a string
    return Date.now().toString();
  }
};

/**
 * Generates a cache buster uuid that is stored local storage
 */
export const clearCacheBuster = (): void => {
  window.localStorage.removeItem(localStorageItemName);
};
