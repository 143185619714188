import React from 'react';
import { FormHelperText } from '@mui/material';
import { SearchTaskFilter } from '@askporter/client-grieg-lyric';
import { FilterCount, FilterWrapper, CheckboxFilterItem, FilterSection } from '@askporter/component-library';
import { toggleValueInList } from '@askporter/utils';
import { ConfigBasedTaskFilterKeys, TransformedTaskFilterValues, Actions } from '../../types';
import { filtersToDisplay } from '../../utils';

export interface ConfigBasedFiltersProps {
  filters: Partial<SearchTaskFilter>;
  filterKey: ConfigBasedTaskFilterKeys;
  filterSearch: Record<ConfigBasedTaskFilterKeys, string>;
  searchOnChange: (e: React.ChangeEvent<HTMLInputElement>, filterKey: string) => void;
  t: (key: string, options?: Record<string, string | number>) => string;
  taskFilterValues: Record<ConfigBasedTaskFilterKeys, TransformedTaskFilterValues>;
  setFilters: Actions['setFilters'];
  isLoading: boolean;
  isError: boolean;
}

/**
 * Renders a ConfigBasedFilter section
 * @param filters - config based filters state
 * @param filterKey - a filter key
 * @param filterSearch - the filter search state
 * @param searchOnChange - the on change handler for search box value change
 * @param t - translations function
 * @param taskFilterValues - the retrieved values for config based task filters
 * @param setFilters - set filter reducer action
 * @param isLoading - whether we are still calculating the filter counts
 * @param isError - if true then an error message will be displayed
 */
export const ConfigBasedFilter: React.FC<React.PropsWithChildren<ConfigBasedFiltersProps>> = ({
  filters,
  filterKey,
  filterSearch,
  searchOnChange,
  t,
  taskFilterValues,
  setFilters,
  isLoading,
  isError,
}: ConfigBasedFiltersProps) => {
  return (
    <FilterSection
      key={`filter_section_${filterKey}`}
      t={t}
      isLoading={isLoading}
      isError={isError}
      filterSectionTitle={t(`ns.task_filter:section:${filterKey}`)}
      filterKey={filterKey}
      searchOnChange={searchOnChange}
      searchValue={filterSearch[filterKey]}
      searchLabel={t('ns.task_filter:search_label')}
      isExpandedOnMount={!!filters[filterKey]?.length}
    >
      {/* Section filters - (i.e. Critical, High..) */}
      {(() => {
        const displayFilters = filtersToDisplay(taskFilterValues[filterKey], filterSearch[filterKey]);

        // If the user provided search term does not match any filters a no results message is displayed
        if (displayFilters === false)
          return (
            <FormHelperText data-testid="no-filter-search-results">
              {t('ns.task_filter:no_search_results')}
            </FormHelperText>
          );

        return displayFilters?.map((filter, idx) => {
          if (filter !== undefined)
            if (filter?.uid)
              return (
                <FilterWrapper key={`task_filter_section_${filterKey}_${filter?.uid}`}>
                  <CheckboxFilterItem
                    filterKey={filterKey}
                    filterValue={{
                      ...filter,
                      userSelectedValue: filters[filterKey]?.includes(filter?.uid),
                    }}
                    onClick={() => {
                      setFilters({ [filterKey]: toggleValueInList(filter?.uid, filters[filterKey]) });
                    }}
                    index={idx}
                  />
                  <FilterCount count={filter?.count} />
                </FilterWrapper>
              );
            else if (filter?.groupId)
              return (
                <FilterWrapper key={`task_filter_section_${filterKey}_${filter?.groupId}`}>
                  <CheckboxFilterItem
                    filterKey={filterKey}
                    filterValue={{
                      displayName: filter?.displayName,
                      userSelectedValue: filter?.uidList?.some((uid) => filters[filterKey]?.includes(uid)),
                    }}
                    onClick={() => {
                      let filterList;

                      // if some or all of the uid's are present then this filter will appear as true in the
                      // userSelectedValue. To avoid getting stuck in one state, which could happen cases where only
                      // some items are present from malformed/outdated URL params we check for both some and every
                      if (
                        filter?.uidList?.every((uid) => filters[filterKey]?.includes(uid)) ||
                        filter?.uidList?.some((uid) => filters[filterKey]?.includes(uid))
                      ) {
                        filterList = filters[filterKey]?.filter((item) => {
                          return !filter?.uidList?.includes(item);
                        });
                      }
                      // if no uid's are present then the filter would be shown as unselected, so add the uid's
                      // to the list
                      else {
                        filterList = [...filters[filterKey], ...filter?.uidList];
                      }
                      setFilters({ [filterKey]: filterList });
                    }}
                    index={idx}
                  />
                  <FilterCount count={filter?.count} />
                </FilterWrapper>
              );
        });
      })()}
    </FilterSection>
  );
};
