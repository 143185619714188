import React from 'react';
import { Box, Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';

export interface ChipProps extends Omit<MuiChipProps, 'size'> {
  size?: 'extra-small' | MuiChipProps['size'];
  'data-testid'?: string;
  component?: React.ElementType;
  href?: string;
}

/**
 * Renders a Chip component
 */
export const Chip: React.FC<React.PropsWithChildren<ChipProps>> = ({
  icon,
  size: sizeProp = 'extra-small',
  ...rest
}: ChipProps) => {
  const size = sizeProp === 'extra-small' ? undefined : sizeProp;

  return (
    <MuiChip
      {...rest}
      data-testid={rest?.['data-testid'] || 'chip'}
      size={size}
      sx={{ ...(sizeProp === 'extra-small' && { height: '20px' }), ...rest?.sx }}
      // without wrapping the icon in a div MUI did not seem to add a ".MuiChip-icon" class, meaning the root style
      // overrides in the theme were not being applied
      icon={
        icon ? (
          <Box display="flex" justifyContent="center">
            {icon}
          </Box>
        ) : undefined
      }
    />
  );
};

export interface VipChipProps {
  /** Translate function */
  t: (key: string, options?: Record<string, string | number>) => string;
}

/* Renders a VipChip component */
export const VipChip: React.FC<React.PropsWithChildren<VipChipProps>> = ({ t }: VipChipProps) => {
  return <Chip sx={{ backgroundColor: '#410099', '& span': { color: '#fff' } }} label={t('ns.common:vip')} />;
};
