import React from 'react';
import { MenuItemProps } from '@mui/material';
import { SelectItem } from '@askporter/utils';

// Specifies how the options in the Autocomplete should be rendered
// See https://mui.com/api/autocomplete/ for more information
export const renderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: SelectItem<MenuItemProps['value']>,
): React.ReactNode => (
  <li {...props} key={props.id} data-testid={props.id}>
    {option.label}
  </li>
);
