import { RoleSlug } from '@askporter/grieg-types';
export interface PermissionProps {
  requiredRoles: RoleSlug | RoleSlug[];
}

export interface PermissionPropsWithChildren extends PermissionProps {
  children: React.ReactNode;
}

/**
 * Utility function to check whether the user has the acceptable
 * role to access / view feature
 *
 * @param currentUserRole - the role of the logged in user
 * @param requiredRoles - the required role(s) needed to access / view feature
 * @returns boolean - true / false flag - whether the user can access / view feature
 */
export function checkPermissions(currentUserRole: RoleSlug, requiredRoles: PermissionProps['requiredRoles']): boolean {
  if (Array.isArray(requiredRoles) && requiredRoles.length > 0) {
    if (requiredRoles.includes(currentUserRole)) {
      return true;
    }
  } else {
    if (requiredRoles === currentUserRole) {
      return true;
    }
  }
  return false;
}
