import { SearchTaskSort } from '@askporter/grieg-types';

export const isRadioButtonChecked = (value: string, radioValue: string): boolean => {
  if (
    (value === SearchTaskSort.RECOMMENDED || value === SearchTaskSort.RELEVANCE) &&
    radioValue === SearchTaskSort.RELEVANCE
  ) {
    return true;
  } else {
    return value === radioValue;
  }
};
