import React, { useState } from 'react';
import { Box, Typography, Tooltip, useTheme } from '@mui/material';
import { TaskFullExternalLink } from '@askporter/grieg-types';
import { TooltipState } from '@askporter/utils';
import { TitleField, Button, Icon, IconSize, HeaderExternalLinkButton } from '../../';

export interface TitleHeaderProps {
  isSmallDevice: boolean;
  title: string;
  subtitle: React.ReactNode;
  icon: React.ReactNode;
  onChange?: (title?: string) => void;
  hasEditAccess?: boolean | undefined;
  chips?: React.ReactNode;
  handleTooltipClose?: () => void;
  handleTooltipOpen?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  tooltipState?: TooltipState;
  externalLink?: TaskFullExternalLink;
}

/**
 * Renders a Title Header component
 * @param isSmallDevice - Whether we are in single / split column view
 * @param title - Entity title
 * @param subtitle - Secondary text / Chip under the title*
 * @param handleTitleBlur - The handler for when the title loses focus
 * @param icon - The left hand side icon / avatar
 * @param hasEditAccess - Wether updating title is allowed
 * @param chips - a place for chips to be rendered, this is rendered on the left of the context menu
 */

export const TitleHeader: React.FC<React.PropsWithChildren<TitleHeaderProps>> = ({
  isSmallDevice,
  hasEditAccess,
  title,
  onChange,
  subtitle,
  icon,
  handleTooltipOpen,
  handleTooltipClose,
  tooltipState = { visible: false, message: '' },
  chips,
  externalLink,
}) => {
  const [mobileView, setMobileView] = useState(false);
  const {
    custom: { spacing },
  } = useTheme();

  return (
    <>
      <Box width="100%" display="flex" flexDirection="row" justifyContent={'flex-start'} data-testid="title-header">
        <Box display="flex" flexDirection="row" width={'100%'}>
          {icon && (
            <Box mr={4} data-testid="icon">
              {icon}
            </Box>
          )}
          <Box sx={{ width: '100%' }}>
            {/* Title - text with tooltip or text field */}
            {!hasEditAccess ? (
              title && (
                <Tooltip title={title} placement="bottom-start" data-testid="title-tooltip">
                  <Typography variant="h6" noWrap={true} width="100%" sx={{ cursor: 'default' }}>
                    {title}
                  </Typography>
                </Tooltip>
              )
            ) : (
              <TitleField
                data-testid="title-input"
                variant="filled"
                fullWidth={true}
                defaultValue={title}
                onClick={() => {
                  setMobileView(true);
                }}
                onBlur={(event?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  const { value: newTitle } = event.currentTarget;
                  if (newTitle !== title) onChange(newTitle);
                  setMobileView(false);
                }}
                aria-label={title}
                sx={{
                  my: 0,
                  '.MuiInputBase-input': {
                    '&:focus': {
                      p: `${isSmallDevice ? '10px 4px' : '0 4px'}`,
                    },
                    p: '0',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
              />
            )}

            {/* Subtitle */}
            {isSmallDevice && mobileView ? (
              <></>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography component="div" color="textSecondary">
                  {subtitle}
                </Typography>
                <Box sx={{ pl: spacing.md }}>{chips}</Box>
                {hasEditAccess && handleTooltipOpen && handleTooltipClose && (
                  <Tooltip
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement="top"
                    arrow
                    PopperProps={{
                      disablePortal: true,
                    }}
                    open={tooltipState.visible}
                    title={tooltipState.message}
                    data-testid="copy-subtitle-tooltip"
                  >
                    <Button
                      sx={{ minWidth: 0 }}
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        handleTooltipOpen(e);
                        setTimeout(handleTooltipClose, 1500);
                      }}
                      data-testid="copy-subtitle-button"
                      aria-label={'common:copy'}
                    >
                      <Icon size={IconSize.SM} folder="actions/05-copy" />
                    </Button>
                  </Tooltip>
                )}
              </Box>
            )}
          </Box>
          {externalLink && !isSmallDevice ? <HeaderExternalLinkButton externalLink={externalLink} /> : null}
        </Box>
      </Box>
    </>
  );
};
