import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Stack } from '@mui/material';
import { Typography } from '@askporter/component-library';
import type { TFunction } from '@askporter/config-provider';
import { ExportsListExternalExports_TypeEnum } from '@askporter/grieg-types';

interface ExportNameFieldProps {
  name: string;
  type: ExportsListExternalExports_TypeEnum;
  t: TFunction;
}

/**
 * The table cell for the export name & type.
 */
export const ExportNameField: React.FC<ExportNameFieldProps> = ({ name, type, t }: ExportNameFieldProps) => {
  return (
    <Stack>
      <Typography variant="body1">{name}</Typography>
      <Typography variant="body2" sx={{ display: 'flex', flexDirection: 'row' }}>
        <ArrowForwardIcon fontSize="small" />
        {t(`export:export_list:table:export_type:${type.toLowerCase()}`)}
      </Typography>
    </Stack>
  );
};
