import { CognitoUser } from 'amazon-cognito-identity-js';
import { wrapError } from '.';
import { AuthAction, AuthResult, CognitoError } from './types';

/**
 * Attempt to reset the user's password
 * @param cognitoUser - the current (unauthenticated) session user
 * @param code - the verification code to permit new password creation
 * @param password - the new password
 * @param dispatch - reference to the auth state reducer dispatch method
 * @returns             Promise with result
 */
export const cognitoConfirmPassword = async (
  cognitoUser: CognitoUser,
  code: string,
  password: string,
  dispatch: React.Dispatch<AuthAction>,
): Promise<AuthResult> => {
  return new Promise((resolve, reject) => {
    const fallbackErrMessage = 'Unable to confirm password';
    try {
      cognitoUser.confirmPassword(code, password, {
        onSuccess() {
          dispatch({ type: 'setFeedback', payload: 'password change complete' });
          return resolve({ error: undefined, message: 'Success: password change complete', complete: true });
        },
        onFailure(err: CognitoError) {
          dispatch({ type: 'setFeedback', payload: `${err.message} (${err.code})` });
          return reject({
            error: wrapError(err, fallbackErrMessage),
            message: 'Failure: unable to change password',
            complete: false,
          });
        },
      });
    } catch (err) {
      dispatch({ type: 'setFeedback', payload: err.message });
      return reject({
        error: wrapError(err, fallbackErrMessage),
        message: 'Error: unable to change password',
        complete: false,
      });
    }
  });
};
