import { RoleSlug } from '@askporter/grieg-types';

export enum FullUserRole {
  SVCUSR = 'common:people:service_user',
  SVCUSR_SPR = 'common:people:super_service_user',
  AGENT = 'common:people:agent',
  AGENT_SPR = 'common:people:super_agent',
  CLIENT_ADMIN = 'common:people:client_admin',
  PLAT_ADMIN = 'common:people:platform_admin',
  OWNER = 'common:people:owner',
  OWNER_SPR = 'common:people:super_owner',
  SUPP = 'common:people:supplier',
  SUPP_SPR = 'common:people:super_supplier',
  UNREG = 'common:people:unreg',
}

export const ManagerRoles = [
  RoleSlug.AGENT,
  RoleSlug.AGENTSPR,
  RoleSlug.CLIENTADMIN,
  RoleSlug.PLATADMIN,
  RoleSlug.OWNER,
  RoleSlug.OWNERSPR,
];

export const ServiceUserRoles = [RoleSlug.SVCUSR, RoleSlug.SVCUSRSPR, RoleSlug.UNREG];

export const SupplierRoles = [RoleSlug.SUPP, RoleSlug.SUPPSPR];

export const AgentRoles = [RoleSlug.AGENT, RoleSlug.AGENTSPR, RoleSlug.CLIENTADMIN, RoleSlug.PLATADMIN];

export const AllRoles = [RoleSlug.SVCUSR, RoleSlug.SVCUSRSPR, ...ManagerRoles, RoleSlug.SUPP, RoleSlug.SUPPSPR];
