import { ThemeOptions } from '@mui/material';

type Typography = Pick<ThemeOptions, 'typography'>;

const fontFamily = "'IBM Plex Sans', sans-serif";

export const customTypography: Typography = {
  typography: {
    fontFamily,
    h1: {
      fontWeight: 300,
      fontSize: '3.75rem',
      lineHeight: '200%',
      letterSpacing: '-.0313rem',
    },
    h2: {
      fontWeight: 300,
      fontSize: '3rem',
      lineHeight: '116.7%',
    },
    h3: {
      fontWeight: 400,
      fontSize: '2.125rem',
      lineHeight: '123.5%',
      letterSpacing: '.0156rem',
    },
    h4: {
      fontWeight: 400,
      fontSize: '1.5rem',
      lineHeight: '133.4%',
    },
    h5: {
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: '160%',
      letterSpacing: '.0094rem',
    },
    h6: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '150%',
      letterSpacing: '.0094rem',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '175%',
      letterSpacing: '.0094rem',
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '.875rem',
      lineHeight: '157%',
      letterSpacing: '.0063rem',
    },
    body1: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '150%',
      letterSpacing: '.0313rem',
    },
    body2: {
      fontWeight: 400,
      fontSize: '.875rem',
      lineHeight: '143%',
      letterSpacing: '.0156rem',
    },
    overline: {
      fontWeight: 400,
      fontSize: '.875rem',
      lineHeight: '143%',
      letterSpacing: '.0156rem',
      textTransform: 'uppercase',
    },
    caption: {
      fontWeight: 400,
      fontSize: '.875rem',
      lineHeight: '143%',
      letterSpacing: '.0156rem',
    },
  },
};
