import React, { useEffect, CSSProperties } from 'react';
import { useTheme, Box } from '@mui/material';
import ReactPhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { countryTranslation } from './utils/countryTranslation';

interface PhoneInputProps {
  id?: string;
  onChange: (value: string) => void;
  defaultCountryCode?: string;
  required?: boolean;
  error?: boolean;
  defaultValue?: string;
  value?: string;
  label?: string;
  locale?: string;
  disabled?: boolean;
  inputStyles?: CSSProperties;
}

const KEY_SUFFIX = Date.now().toString();

/**
 * Renders a Phone Number Input component that detects
 * @param label- optional The text for the label that is positioned above the input
 * @param defaultCountryCode - optional 2 letter country code prop, defaults to gb
 */
export const PhoneInput: React.FC<React.PropsWithChildren<PhoneInputProps>> = ({
  id,
  defaultCountryCode = 'gb',
  required = false,
  error = false,
  onChange,
  defaultValue = '',
  value = '',
  label = '',
  locale,
  disabled = false,
  inputStyles,
}: PhoneInputProps) => {
  const defaultValueWithoutPrefix = defaultValue?.replace(/^\+/, '');
  const valueWithoutPrefix = value?.replace(/^\+/, '');

  const [state, setState] = React.useState({ phone: defaultValueWithoutPrefix || valueWithoutPrefix, dialCode: '' });
  const theme = useTheme();
  const localization = countryTranslation[locale || defaultCountryCode];

  const removeLeadingZeros = (phone: string) => {
    const nonZeroIndex = phone.split('').findIndex((num) => num !== '0');
    const onlyZeros = phone.split('').every((num) => num === '0');

    if (onlyZeros) return '';
    return phone?.slice(nonZeroIndex || 0) || '';
  };

  useEffect(() => {
    let newValue = '';
    const dialLen = state.dialCode.length;

    // ensure the phone number never starts with a 0, the dial code needs to always needs to start with a 1-9
    const cleansedPhone = removeLeadingZeros(state.phone);
    newValue = cleansedPhone;

    // specifically to handle cases where a user pastes/types a number like 07123456789 and the dial code is
    // already set. Refer to the following issue for the origin:  https://github.com/askporter/front-end/issues/233
    if (state.dialCode && state.phone[dialLen] === '0') {
      const cleansedSubstring = removeLeadingZeros(cleansedPhone.substring(dialLen, cleansedPhone.length));

      newValue = `${cleansedPhone.slice(0, dialLen)}${cleansedSubstring}`;
    }

    // update component state
    if (newValue !== state.phone) setState((prevState) => ({ ...prevState, phone: newValue }));

    // call the parent's onChange function, if we have a number to pass prefix it with +
    const prefixedNewValue = newValue ? `+${newValue}` : '';
    if (value !== prefixedNewValue) onChange(prefixedNewValue);
  }, [state, value]);

  useEffect(() => {
    if (defaultValueWithoutPrefix && defaultValueWithoutPrefix !== state.phone) {
      setState((prevState) => ({ ...prevState, phone: defaultValueWithoutPrefix }));
    }
  }, [defaultValue]);

  const handleChange = (phone: string, { dialCode }: CountryData) =>
    setState((prevState) => ({ ...prevState, phone, dialCode }));

  React.useEffect(() => {
    const flagDropDownElement = document.querySelector('.selected-flag');

    if (flagDropDownElement) {
      flagDropDownElement.setAttribute('tabindex', '-1');
    }
  }, []);

  return (
    <Box
      sx={{
        '& .form-control, .react-tel-input > .form-control': {
          backgroundColor: theme.palette.grey['100'],

          ':hover': {
            backgroundColor: theme.palette.grey['300'],
            background: theme.palette.grey['300'],
            transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            borderRadius: '0px',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
          },
          ':focus': {
            backgroundColor: theme.palette.grey['200'],
          },
        },
        '.react-tel-input .special-label': {
          backgroundColor: 'transparent',
          left: 48,
          top: 4,
        },
      }}
    >
      <ReactPhoneInput
        countryCodeEditable={false}
        key={`${locale}_${KEY_SUFFIX}`} // without this the localized values will not change
        localization={localization}
        value={state?.phone}
        enableLongNumbers={15}
        onChange={handleChange}
        country={defaultCountryCode}
        specialLabel={label}
        onMount={(_value: string, data: Partial<CountryData> = {}, _formattedValue: string) => {
          setState((prevState) => ({ ...prevState, dialCode: data?.dialCode || '' }));
        }}
        // Ideally 'specialLabel' would be the correct accessible label for this component
        // but it's not. In order to make this component accessible with a meaningful label
        // I have to use aria-label.
        inputProps={{ id: id || 'phone', 'data-testid': 'phone', required, 'aria-label': label || 'phone', disabled }}
        containerStyle={{
          fontFamily: `${theme.typography.fontFamily}`,
          width: '100%',
        }}
        inputStyle={{
          font: 'inherit',
          width: '100%',
          height: '56px',
          borderTop: 0,
          borderRight: 0,
          borderLeft: 0,
          borderBottom: `${error ? `2px solid ${theme.palette.error.main}` : `1px solid ${theme.palette.grey['500']}`}`,
          borderRadius: 0,
          boxShadow: 'none',
          paddingBottom: 8,
          ...inputStyles,
        }}
        buttonStyle={{
          borderRadius: 0,
          borderTop: 0,
          borderLeft: 0,
          borderRight: 0,
          marginTop: 8,
          borderBottom: `${error ? `2px solid ${theme.palette.error.main}` : `1px solid ${theme.palette.grey['500']}`}`,
        }}
        dropdownStyle={{
          borderRadius: 0,
          marginTop: `${error ? `2px` : `1px`}`,
          textAlign: 'left',
        }}
      />
    </Box>
  );
};

PhoneInput.displayName = 'PhoneInput';
