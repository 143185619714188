const { hostname, protocol, port } = window.location;

export interface Config {
  ENV: string;
  API_URL: string;
}

// Controlled by Webpack and Jest
// Can be either of the following:
// 'development' - when running webpack dev server
// 'test' - when running jest
// 'production' - when building for production with webpack
const env = process.env.NODE_ENV;
const apiSuffix = '/api/v1/';
const api = `${protocol}//${hostname}${port ? `:${port}` : ''}${apiSuffix}`;

export const config: Config = {
  ENV: env,
  API_URL: api,
};
