import { BreadcrumbsProps } from '../../Breadcrumbs';

/**
 * Calculates props Breadcrumbs to prioritize displaying the rightmost elements
 * @param breadcrumbs - JSX element array of breadcrumb items
 * @param acceptableCharLength - defaults to 150, the max number of characters to display
 * @param maxItems - defaults to 3, the max number of breadcrumbs to display
 *
 * @returns props that can be passed directly into a Breadcrumbs component
 */
export const calculateBreadcrumbProps = ({
  breadcrumbs,
  acceptableCharLength = 100,
  maxItems: maxNoOfItems = 3,
}: {
  breadcrumbs: JSX.Element[];
  acceptableCharLength?: number;
  maxItems?: number;
}): BreadcrumbsProps => {
  const itemsBeforeCollapse = 0;
  let itemsAfterCollapse = maxNoOfItems;
  let maxItems = maxNoOfItems;

  let totalChars = 0;
  breadcrumbs
    ?.slice() // a slice is used to avoid mutating the original array
    .reverse()
    .every((crumb, idx) => {
      const crumbTextLength: number = crumb?.props?.text?.length || 0;

      if (idx === maxItems) return false;

      if (totalChars + crumbTextLength > acceptableCharLength) {
        itemsAfterCollapse = idx || 1;
        maxItems = idx || 1;

        return false;
      } else totalChars += crumbTextLength;

      return true;
    });

  return { maxItems, itemsBeforeCollapse, itemsAfterCollapse };
};
