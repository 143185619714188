import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';

const rootContainer = document.getElementById('root');
const root = createRoot(rootContainer);

async function enableMocking() {
  if (process.env.ENABLE_MSW !== 'true') {
    return;
  }

  const { worker } = await import('../__mocks__/msw');

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start({
    onUnhandledRequest: 'bypass',
  });
}

enableMocking().then(() => {
  root.render(<App />);
});
