import React, { ReactNode } from 'react';
import { FormControlLabel, Checkbox, SxProps, Theme } from '@mui/material';

export interface CheckboxFilterItemProps {
  filterKey: string;
  filterValue: {
    uid?: string;
    displayName: string;
    userSelectedValue?: boolean;
  };
  children?: ReactNode;
  index: number;
  onClick: (filterKey: string, index: number) => void;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  checkboxStyle?: SxProps<Theme>;
  labelPaddingRight?: number;
}

/**
 * Renders a checkbox for use within a FilterSection
 * @param filterKey - the filter key (category of filters, i.e. Priority)
 * @param filterValue - the value of the filter item to be displayed as a label
 * @param children
 * @param index - the array index of the filter item
 * @param onClick - the function that gets called when the checkbox is checked/unchecked
 * @param labelPlacement - the position of the label
 */
const CheckboxFilterItem: React.FC<React.PropsWithChildren<CheckboxFilterItemProps>> = ({
  filterKey,
  filterValue,
  children,
  index,
  onClick,
  labelPlacement = 'end',
  checkboxStyle = { mx: 1.75 },
  labelPaddingRight = 4,
}: CheckboxFilterItemProps) => {
  const labelStyles = {
    '.MuiFormControlLabel-label': { flexGrow: 1, overflow: 'hidden' },
    width: '100%',
    m: 0,
    pl: 0,
    pr: labelPaddingRight,
  };

  return (
    <FormControlLabel
      value={filterValue.userSelectedValue}
      control={
        <Checkbox
          sx={checkboxStyle}
          color="primary"
          checked={filterValue.userSelectedValue}
          onChange={() => onClick(filterKey, index)}
        />
      }
      label={children ? <>{children}</> : filterValue.displayName}
      labelPlacement={labelPlacement}
      sx={labelStyles}
      data-testid={
        filterValue?.displayName
          ? `${filterValue?.displayName}-${filterKey}-filter-checkbox`
          : `${filterKey}-filter-checkbox`
      }
    />
  );
};

export { CheckboxFilterItem };
