// A simple object mapping error keys to AskPorter specific error messages to display to end users. Will need to load
// from string service. Generally speaking the cognito errors below are not exhaustive, they are just one's we provide
// explanatory errors for. Other cognito errors will present the generic Unexpected error
// TODO consider https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/CommonErrors.html
type TFn = (key: string, opts?: Record<string, string>) => string;

type ErrorMapByCode = Record<string, string>;
type ErrorMapByCodeAndMessage = Record<string, Record<string, string>>;
export interface ErrorMapData {
  email?: string;
}

export const errorMap = (
  data: ErrorMapData = {},
  t: TFn,
): {
  errorMapByCode: ErrorMapByCode;
  errorMapByCodeAndMessage: ErrorMapByCodeAndMessage;
} => {
  const { email } = data;

  const cognitoChangePassword = {
    // https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ChangePassword.html
    ExpiredCodeException: `${t('ns.common:error:code_expired')}`,
    LimitExceededException: `${t('ns.common:error:limit_exceeded')}`,
    InvalidPasswordException: `${t('ns.common:error:invalid_password')}`,
    PasswordResetRequiredException: `${t('ns.common:error:password_reset_required')}`,
    UserNotConfirmedException: `${t('ns.common:error:user_not_confirmed')}`,
  };

  const cognitoResendConfirmation = {
    // https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ResendConfirmationCode.html
    UserNotFoundException: `${t('ns.common:error:user_not_found')}`,

    // Custom error key
    UserAlreadyConfirmed: `${t('ns.common:error:user_already_confirmed')}`,
    ConfirmFail: `${t('ns.activate:error:fail')}`,
  };

  const cognitoLogin = {
    MissingCredentials: `${t('ns.common:error:cognito_no_match')}`,
  };

  const cognitoInvite = {
    InvalidInvitationParameters: `${t('ns.common:error:invalid_invitation_parameters')}`,
  };

  const generic = {
    Unexpected: `${t('ns.common:error:unexpected', {
      email,
    })}`,
  };

  return {
    errorMapByCode: {
      ...cognitoChangePassword,
      ...cognitoResendConfirmation,
      ...cognitoLogin,
      ...cognitoInvite,
      ...generic,
    },
    errorMapByCodeAndMessage: {
      NotAuthorizedException: {
        'Temporary password has expired and must be reset by an administrator.': `${t(
          'ns.common:error:not_authorized_exception',
          { email },
        )}`,
        'User cannot be confirmed. Current status is CONFIRMED': `${t('ns.activate:error:confirmation')}`,
        'Incorrect username or password.': `${t('ns.common:error:cognito_no_match')}`,
        'Incorrect password': `${t('ns.common:error:incorrect_password')}`,
        'User is disabled.': `${t('ns.common:error:user_account_disabled', { email })}`,
      },
      ExpiredCodeException: {
        'Invalid code provided, please request a code again.': `${t('ns.common:error:code_expired')}`,
      },
      UserNotFoundException: {
        'User does not exist.': `${t('ns.common:error:cognito_no_match')}`,
      },
      CodeMismatchException: {
        'Invalid verification code provided, please try again.': `${t('ns.common:error:link_expired')}`,
      },
      UsernameExistsException: {
        'An account with the given email already exists.': `${t('ns.sign_up:account_exists:error')}`,
      },
    },
  };
};

/**
 * Masks Cognito errors with more user friendly and less revealing error messages.
 * Cognito also uses the same '_type' but with different messages hence why this function is needed.
 *
 * @param t - translation function
 * @param code - the '_type' property from the Cognito error response
 * @param message - the 'message' property from the Cognito error response
 * @param data - the optional object which can contain information such as tenant email, bot name etc
 *
 * @returns string - user friendly error
 */
export function getUserFriendlyError(t: TFn, code: string, message?: string, data?: ErrorMapData): string {
  const { errorMapByCode, errorMapByCodeAndMessage } = errorMap(data, t);

  // if message is provided check the more specific overrides
  if (message) {
    if (errorMapByCodeAndMessage[code] && errorMapByCodeAndMessage[code][message]) {
      return errorMapByCodeAndMessage[code][message];
    } else if (errorMapByCode[code]) {
      return errorMapByCode[code];
    }
    return errorMapByCode['Unexpected'];
  } else {
    // otherwise grab the user friendly error by code
    return errorMapByCode[code] || errorMapByCode['Unexpected'];
  }
}
