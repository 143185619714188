import { NewFile, UploadData, URN } from '@askporter/grieg-types';

export type UploadedFile = UploadData & { name: string; addedAt?: string; error?: unknown; objectURL?: string };

export interface Media {
  file: {
    fileUrn?: URN;
    filePath?: string;
    fileRef?: URN;
  };

  addedAt: string;
  path?: string;
  nameAndType?: { name: string; type: AllowedFileTypes | null };
}

export enum AllowedFileTypes {
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
  DOC = 'doc',
  DOCX = 'docx',
  PDF = 'pdf',
  MP4 = 'mp4',
  HEIC = 'heic',
  HEIF = 'heif',
}

export enum UploaderFileTypes {
  JPG = '.jpg',
  JPEG = '.jpeg',
  PNG = '.png',
  DOC = '.doc',
  DOCX = '.docx',
  PDF = '.pdf',
  MP4 = '.mp4',
  HEIC = '.heic',
  HEIF = '.heif',
}

export type UnknownObject = Record<string, string>;

export interface APIOptions {
  authHeader?: boolean;
  langHeader?: boolean;
  additionalHeaders?: UnknownObject;
  queryParams?: UnknownObject;
  payload?: NewFile | File;
  isGrieg?: boolean;
  path: string;
}

export interface SimplifiedIAPIClient {
  post: (params: APIOptions) => Promise<{ data: UnknownObject }>;
  put: (params: APIOptions) => Promise<unknown>;
}
