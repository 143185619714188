import { CognitoUser } from 'amazon-cognito-identity-js';
import { wrapError } from '.';
import { AuthAction, UnauthenticatedResult, CognitoError } from './types';

/**
 * Resend the confirmation email
 * @param cognitoUser - the current (unauthenticated) session user
 * @param dispatch - reference to the auth state reducer dispatch method
 * @returns             Promise with result
 */
export const cognitoResendConfirmationCode = async (
  cognitoUser: CognitoUser,
  dispatch: React.Dispatch<AuthAction>,
): Promise<UnauthenticatedResult> => {
  return new Promise((resolve, reject) => {
    const fallbackErrMessage = 'Unable to resend confirmation code';
    try {
      cognitoUser.resendConfirmationCode((err: CognitoError, result) => {
        if (err) {
          dispatch({ type: 'setFeedback', payload: `${err.message} (${err.code})` });
          return reject({
            error: wrapError(err, fallbackErrMessage),
            message: 'Error: unable to resend confirmation code',
          });
        } else {
          dispatch({ type: 'setFeedback', payload: `No errors, result: ${JSON.stringify(result)}` });
          return resolve({
            error: undefined,
            message: 'Success: resent confirmation code to your email',
          });
        }
      });
    } catch (err) {
      dispatch({ type: 'setFeedback', payload: 'Resend confirmation code error' });
      return reject({
        error: wrapError(err, fallbackErrMessage),
        message: 'Error: unable to initiate password reset',
      });
    }
  });
};
