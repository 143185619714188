/**
 * Specific Skeletor Styled Components
 */
import { Box, styled } from '@mui/material';

/**
 * Skeletor widget container
 */
export const SkeletorContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'flex-end',
  height: '100%',
});
