import { captureException } from '@askporter/exception-logger';
import { v4 as uuidv4 } from 'uuid';

const localStorageItemName = 'stringsCacheIdentifier';

interface StringsCacheLocalStorageObject {
  k: string;
  timestamp: string;
}

/**
 * Function that gets the current time as an ISO string
 * @returns string
 */
const currentTimeAsIsoString = (): string => {
  const now = new Date();
  return now.toISOString();
};

/**
 * Generates a cache buster uuid that is stored local storage, if one already exists it is replaced with the new uuid
 */
export const setCacheBuster = (): StringsCacheLocalStorageObject => {
  try {
    const k = uuidv4();
    const value: StringsCacheLocalStorageObject = { k, timestamp: currentTimeAsIsoString() };
    window.localStorage.setItem(localStorageItemName, JSON.stringify(value));
    return value;
  } catch (err) {
    captureException(err);
    // in an error scenario, we shouldn't block a cache buster value being used, so return the timestamp as a string
    return { k: Date.now().toString(), timestamp: currentTimeAsIsoString() };
  }
};

/**
 * Generates a cache buster object that is stored local storage
 */
export const getCacheBuster = (): StringsCacheLocalStorageObject => {
  try {
    const value: StringsCacheLocalStorageObject = JSON.parse(window.localStorage.getItem(localStorageItemName));

    // if the sessionCacheIdentifier undefined is encountered, a new one should be set
    if (!value?.k) {
      setCacheBuster();
      const value: StringsCacheLocalStorageObject = JSON.parse(window.localStorage.getItem(localStorageItemName));

      return value;
    }
    return value;
  } catch (err) {
    captureException(err);

    // in an error scenario, we shouldn't block a cache buster value being used, so return the timestamp as a string
    return { k: Date.now().toString(), timestamp: currentTimeAsIsoString() };
  }
};

/**
 * Clears the cache buster object that is stored local storage
 */
export const clearCacheBuster = (): void => {
  window.localStorage.removeItem(localStorageItemName);
};

/**
 * Function to bust the cache when we detect a missing string, this could indicate that the browser cache is stale.
 * To prevent multiple attempts in quick succession (for example, if more than one string is missing) we only allow
 * this to occur once every 30 minutes
 *
 * @param onCacheBustValueChange function to call when cache buster is set
 */
export const bustCache = (onCacheBustValueChange: () => void): void => {
  try {
    const interval = 30 * 60 * 1000; // 30 minutes
    const cacheBuster = getCacheBuster();

    // if for any reason there are missing values, we need to set the k value
    if (!cacheBuster?.k || !cacheBuster?.timestamp) setCacheBuster();
    else {
      const nowMinusInterval = new Date();
      nowMinusInterval.setTime(nowMinusInterval.getTime() - interval);

      const nowMinusIntervalIsoString = nowMinusInterval.toISOString();

      // if half hour has passed since the last cache bust, bust the cache
      if (cacheBuster?.timestamp < nowMinusIntervalIsoString) {
        setCacheBuster();
        onCacheBustValueChange();
      }
    }
  } catch (err) {
    captureException(err);
  }
};
