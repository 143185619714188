import { intervalToDuration } from 'date-fns';
import { FormattedDuration } from '../../types/FormattedDuration';

/**
 * Function for obtaining an object containing duration
 * @param time - the duration in milliseconds
 * @returns object FormattedDuration
 */
export const getFormattedDurationObject = (time: number): FormattedDuration | Record<string, never> => {
  if (typeof time !== 'number' || time <= 0) return {};
  const duration = intervalToDuration({ start: 0, end: time });

  const formattedDuration = {
    hours: duration.hours,
    minutes: duration.minutes,
    seconds: duration.seconds,
  };
  return formattedDuration;
};
