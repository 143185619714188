import { ExtendedMetadataDataType } from '@askporter/grieg-types';
import DayTimeField from '../DayTimeField';
import DayTimeRangeField from '../DayTimeRangeField';
import LabelField from '../LabelField';
import MultiFileUploadField from '../MultiFileUploadField';
import MultiLangField from '../MultiLangField';
import SingleFileUploadField from '../SingleFileUploadField';

export const dataTypeTransformMap: Record<string, Record<string, unknown>> = {
  [ExtendedMetadataDataType.MULTILINESTRING]: {
    'ui:widget': 'textarea',
    'ui:extendedMetadataType': ExtendedMetadataDataType.MULTILINESTRING,
  },
  [ExtendedMetadataDataType.MULTILANGSTRING]: {
    'ui:field': MultiLangField,
    'ui:extendedMetadataType': ExtendedMetadataDataType.MULTILANGSTRING,
  },
  [ExtendedMetadataDataType.DAYTIME]: {
    'ui:field': DayTimeField,
    'ui:extendedMetadataType': ExtendedMetadataDataType.DAYTIME,
  },
  [ExtendedMetadataDataType.DAYTIMERANGE]: {
    'ui:field': DayTimeRangeField,
    'ui:extendedMetadataType': ExtendedMetadataDataType.DAYTIMERANGE,
  },
  [ExtendedMetadataDataType.MULTIFILE]: {
    'ui:field': MultiFileUploadField,
    'ui:extendedMetadataType': ExtendedMetadataDataType.MULTIFILE,
  },
  [ExtendedMetadataDataType.FILE]: {
    'ui:field': SingleFileUploadField,
    'ui:extendedMetadataType': ExtendedMetadataDataType.FILE,
  },
  [ExtendedMetadataDataType.LABEL]: {
    'ui:field': LabelField,
    'ui:extendedMetadataType': ExtendedMetadataDataType.LABEL,
  },
};
