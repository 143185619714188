import axios from 'axios';

export const fetchStaticTenant = async () => {
  const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en-GB';

  const { data } = await axios.get('/api/v1/static/tenant', {
    headers: {
      'x-ap-lang': selectedLanguage,
    },
  });
  return data;
};
