import React from 'react';
import {
  AssetExportModalFormControlType,
  ExportModalFormControlTypes,
  ExportResourceNames,
  OrgExportModalFormControlType,
  TaskExportModalFormControlType,
  TeamExportModalFormControlType,
  UserExportModalFormControlType,
} from '../../types';
import { ExportAssetsFields } from './ExportAssetsFields';
import { ExportOrgsFields } from './ExportOrgsFields';
import { ExportTasksFields } from './ExportTasksFields';
import { ExportTeamsFields } from './ExportTeamsFields';
import { ExportUsersFields } from './ExportUsersFields';

interface ExportFieldsProps {
  /**
   * The translation function
   */
  t: (key: string, options?: Record<string, string | number>) => string;
  /**
   * The resource name
   */
  resourceName: ExportResourceNames;
  /**
   * The form control
   */
  control: ExportModalFormControlTypes;
}

/**
 * The Export Fields component that decides which fields to render based on the resource name
 */
export const ExportFields: React.FC<ExportFieldsProps> = ({ t, resourceName, control }) => {
  switch (resourceName) {
    case 'tasks':
      return <ExportTasksFields t={t} control={control as TaskExportModalFormControlType} />;
    case 'assets':
      return <ExportAssetsFields t={t} control={control as AssetExportModalFormControlType} />;
    case 'users':
      return <ExportUsersFields t={t} control={control as UserExportModalFormControlType} />;
    case 'orgs':
      return <ExportOrgsFields t={t} control={control as OrgExportModalFormControlType} />;
    case 'teams':
      return <ExportTeamsFields t={t} control={control as TeamExportModalFormControlType} />;
    default:
      return null;
  }
};
