import { DayTimeAction, DayTimeState } from '../types';

export const reducer = (state: DayTimeState, action: DayTimeAction): DayTimeState => {
  switch (action.type) {
    case 'addDayTime':
      return {
        ...state,
        dayTimes: action.payload as DayTimeState['dayTimes'],
        isVisible: false,
        noServiceCheckbox: false,
        fromTimeValue: null,
        toTimeValue: null,
      };
    case 'selectDay':
      return {
        ...state,
        ...(action.payload as {
          selectedDays: DayTimeState['selectedDays'];
          fromTimeValue: DayTimeState['fromTimeValue'];
          toTimeValue: DayTimeState['toTimeValue'];
        }),
        isVisible: true,
      };
    case 'selectAllDays':
      return { ...state, selectedDays: action.payload as DayTimeState['selectedDays'], isVisible: true };
    case 'selectMonFri':
      return { ...state, selectedDays: action.payload as DayTimeState['selectedDays'], isVisible: true };
    case 'selectSatSun':
      return { ...state, selectedDays: action.payload as DayTimeState['selectedDays'], isVisible: true };
    case 'setModalVisibility':
      return {
        ...state,
        isVisible: action.payload as DayTimeState['isVisible'],
        noServiceCheckbox: false,
        fromTimeValue: null,
        toTimeValue: null,
      };
    case 'setFromTimeValue':
      return { ...state, fromTimeValue: action.payload as DayTimeState['fromTimeValue'] };
    case 'setToTimeValue':
      return { ...state, toTimeValue: action.payload as DayTimeState['toTimeValue'] };
    case 'setNoServiceCheckbox':
      return { ...state, noServiceCheckbox: action.payload as DayTimeState['noServiceCheckbox'] };
    case 'setSelectedDays':
      return { ...state, selectedDays: action.payload as DayTimeState['selectedDays'] };
    default:
      return state;
  }
};
