import { AxiosError } from 'axios';
import { ErrorModel, TenantConfig } from '@askporter/grieg-types';
import { ConfigErrors, FatalError } from '../types';
import fatalError from './fatalError';

interface ValidateConfigRes {
  configErrors: ConfigErrors;
  fatalError?: FatalError;
}
/**
 * Validate the config return any errors
 * @param data TenantConfig obtained from tenant API
 * @returns ConfigErrors object containing any errors identified through validation
 */
const validateConfig = (data: TenantConfig | AxiosError<ErrorModel>): ValidateConfigRes => {
  const errors: ConfigErrors = {};
  const name = 'ConfigError';
  const remediated = 'no remediation action taken'; // default message for errors not remediated
  const sendToExceptionLogger = true;

  // simple function to inject any essential config, used on fatal error this ensures the site renders
  const essentialDisplay = () => (errors.tenantDisplay = { name, message: 'display missing', sendToExceptionLogger });

  // as this means there is no config, we should not proceed with any other checks
  if (!data || data instanceof Error) {
    errors.config = { name, message: 'config missing', sendToExceptionLogger };
    essentialDisplay();
    return { configErrors: errors, fatalError: fatalError(500, 'config missing') };
  }

  // with no cognito details the app cannot be used, so there is no reason to proceed
  if (!data?.tenantCognito) {
    errors.tenantCognito = { name, message: 'cognito missing', sendToExceptionLogger };
    essentialDisplay();
    return { configErrors: errors, fatalError: fatalError(500, 'cognito missing') };
  }

  if (!data?.tenantName) errors.tenantName = { name, message: 'name missing', sendToExceptionLogger };
  if (!data?.tenantContact) errors.tenantContact = { name, message: 'contact missing', sendToExceptionLogger };
  if (!data?.tenantBot) errors.tenantBot = { name, message: 'bot missing', sendToExceptionLogger };
  if (!data?.tenantIntegrations)
    errors.tenantIntegrations = { name, message: 'integrations missing', sendToExceptionLogger };
  if (!data.featureFlags) errors.featureFlags = { name, message: 'featureFlags missing', sendToExceptionLogger: false };

  if (!data?.tenantDisplay) errors.tenantDisplay = { name, message: 'display missing', sendToExceptionLogger };
  else {
    if (!data?.tenantDisplay?.palette)
      errors.tenantDisplayPalette = { name, message: 'palette missing', sendToExceptionLogger };
    if (!data.tenantDisplay.logos)
      errors.tenantDisplayLogos = { name, message: 'logos missing', sendToExceptionLogger: false };
    if (!data.tenantDisplay.links)
      errors.tenantDisplayLinks = { name, message: 'links missing', remediated, sendToExceptionLogger: false };
    if (!data.tenantDisplay.coverImages)
      errors.tenantDisplayCover = { name, message: 'cover missing', remediated, sendToExceptionLogger: false };
  }

  if (!data?.tenantLanguages) errors.tenantLanguages = { name, message: 'languages missing', sendToExceptionLogger };
  else {
    if (data?.tenantLanguages?.length < 1)
      errors.tenantLanguagesEmpty = {
        name,
        message: 'languages array is empty',
        remediated: `${remediated}, language will fallback to default`,
        sendToExceptionLogger,
      };
  }

  return { configErrors: errors };
};

export default validateConfig;
