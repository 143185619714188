import React from 'react';
import { InsertDriveFile as InsertDriveFileIcon } from '@mui/icons-material';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { imageArray, AllowedFileTypes, getFileDetails } from '@askporter/utils';
import { fileIconMapping } from '../../utils';

interface ThumbnailPreviewProps {
  filePath: string;
  imgAlt?: string;
  fileType?: AllowedFileTypes;
}

/**
 * Displays a thumbnail image of the uploaded image or an icon
 * if the file type is not an image
 *
 * @param filePath - the file path of the uploaded file
 * @param imgAlt - (optional) the text to put in the 'alt' attribute when its an image
 *
 */
export const ThumbnailPreview: React.FC<React.PropsWithChildren<ThumbnailPreviewProps>> = ({
  filePath,
  fileType,
  imgAlt,
}: ThumbnailPreviewProps) => {
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const fileDetails = getFileDetails(filePath);
  let preview;
  if (imageArray.includes(fileType || fileDetails.type)) {
    preview = (
      <img loading="lazy" style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={filePath} alt={imgAlt} />
    );
  } else {
    preview = <>{fileIconMapping[fileDetails.type] || <InsertDriveFileIcon />}</>;
  }
  return (
    <Box
      data-testid="thumbnail-preview"
      sx={{
        width: isSmallDevice ? '108px' : '179px',
        height: '108px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#707070',
        bgcolor: 'background.default',
      }}
    >
      {preview}
    </Box>
  );
};
