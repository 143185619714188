export type DefaultAssetView = 'PHOTOS' | 'FIRST_EXT_METADATA' | 'LINKED_TASKS' | 'LINKED_ASSETS' | 'LINKED_PEOPLE' | 'QUICK_LINKS' | 'TASK_TYPES' | 'REG_TOKEN' | 'ON_SITE_ASSISTANT' | 'SECURITY';

export const DefaultAssetView = {
  PHOTOS: 'PHOTOS' as DefaultAssetView,
  FIRSTEXTMETADATA: 'FIRST_EXT_METADATA' as DefaultAssetView,
  LINKEDTASKS: 'LINKED_TASKS' as DefaultAssetView,
  LINKEDASSETS: 'LINKED_ASSETS' as DefaultAssetView,
  LINKEDPEOPLE: 'LINKED_PEOPLE' as DefaultAssetView,
  QUICKLINKS: 'QUICK_LINKS' as DefaultAssetView,
  TASKTYPES: 'TASK_TYPES' as DefaultAssetView,
  REGTOKEN: 'REG_TOKEN' as DefaultAssetView,
  ONSITEASSISTANT: 'ON_SITE_ASSISTANT' as DefaultAssetView,
  SECURITY: 'SECURITY' as DefaultAssetView,
};
