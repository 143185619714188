import { CognitoUser } from 'amazon-cognito-identity-js';
import { wrapError } from '.';
import { AuthResult, AuthAction, SessionState, CognitoError } from './types';
/**
 * Attempt to sign a user out
 * @returns Promise with auth result
 */
export const cognitoSignOut = async (
  dispatch: React.Dispatch<AuthAction>,
  cognitoUser: CognitoUser,
): Promise<AuthResult> => {
  return new Promise((resolve, reject) => {
    const fallbackErrMessage = 'Failed to sign out';
    try {
      // Wrapping the sign out will ensure the session is current (getSession refreshes it)
      cognitoUser.getSession(() => {
        cognitoUser.globalSignOut({
          onSuccess: () => {
            dispatch({
              type: 'resetAuthState',
              payload: { sessionState: SessionState.invalid, feedbackMessage: 'signed out' },
            });
            return resolve({ complete: true, error: undefined, message: 'signed out' });
          },
          onFailure: (err: CognitoError) => {
            dispatch({ type: 'setFeedback', payload: `${err.message} (${err.code})` });
            return reject({
              complete: false,
              error: wrapError(err, fallbackErrMessage),
              message: `sign out error ${err}`,
            });
          },
        });
      });
    } catch (err) {
      return reject({
        complete: false,
        error: wrapError(err, fallbackErrMessage),
        message: `sign out error ${err}`,
      });
    }
    return resolve({ complete: true, error: undefined, message: 'signed out' });
  });
};
