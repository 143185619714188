import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  SearchPeopleFilter_ScopeEnum as PeopleEnum,
  TaskFullLinkedOrgs,
  TaskFullLinkedTeams,
  TaskFullLinkedUsers,
  RelationshipLabel,
  URN,
  RoleSlug,
  RegistrationStatus,
} from '@askporter/grieg-types';
import { formatNumber, isAnonymousUser } from '@askporter/utils';
import { LinkedListItem, AvatarSize, UserAvatar } from '../../';
import { LinkedPeopleGroup, PeopleGroupActions } from './types';
import { getOptionsParams } from './utils';

export interface PeopleGroupProps {
  /**
   * The role of the logged in user
   */
  currentUserRole: RoleSlug;
  /**
   * Array of groups of people/teams/orgs
   */
  group: LinkedPeopleGroup[];
  /**
   * The translation function
   */
  t: (key: string, options?: Record<string, string | number>) => string;
  /**
   * Whether we are in mobile breakpoint
   */
  isSmallDevice: boolean;
  /**
   * List of onClicks for the different menu items
   */
  actions: PeopleGroupActions;
  /*
   * Only show remove option in options menu
   */
  onlyRemove?: boolean;
  /**
   * Whether to hide the ellipsis menu
   */
  hideEllipsis?: boolean;
  /**
   * Whether the CTA buttons are enabled
   */
  actionsEnabled: boolean;
  /**
   * Whether the 'Make assignee' option is enabled
   */
  enableMakeAssignee?: boolean;
  /**
   * Whether the 'Remove' option is enabled on an assignee
   */
  enableRemoveAssignee?: boolean;
}

/**
 * Renders a single people group list with ellipsis menu next to each one
 */
export const PeopleGroup = ({
  currentUserRole,
  group,
  t,
  isSmallDevice,
  actions,
  onlyRemove = false,
  hideEllipsis = false,
  actionsEnabled = false,
  enableMakeAssignee = true,
  enableRemoveAssignee = true,
}: PeopleGroupProps): JSX.Element => {
  return (
    <>
      {group.map(({ groupName, groupItems, groupKey, assignedUid, initiatorUid }) => {
        return (
          <Box
            data-testid={`people-group-${groupKey.toLowerCase()}`}
            key={groupName || groupKey}
            display="flex"
            flexDirection="column"
            gap={4}
          >
            {groupName && (
              <Typography variant="h6" data-testid="people-group-type">{`${groupName} (${formatNumber(
                groupItems.length,
              )})`}</Typography>
            )}

            {groupItems.map((entity: unknown, idx: number) => {
              let title,
                key,
                icon,
                entityUid: string,
                entityUrn: URN,
                relationshipLabel: RelationshipLabel,
                role,
                tag,
                status;

              if (groupKey === PeopleEnum.USERS) {
                const { userSummary, relLabel } = entity as TaskFullLinkedUsers;
                const { givenName, familyName, uid, registrationStatus, profilePhoto, roleSlug, urn } = userSummary;
                const isAnonymous = isAnonymousUser(userSummary);

                title = isAnonymous ? t('ns.common:people:anonymous_user') : `${givenName} ${familyName}`;
                key = `${uid}-${idx}`;
                icon = (
                  <UserAvatar
                    givenName={isAnonymous ? t('ns.common:people:anonymous_user') : givenName}
                    familyName={familyName}
                    imagePath={profilePhoto?.filePath}
                    dataTestId={'group-avatar'}
                    avatarSize={AvatarSize.LG}
                  />
                );
                entityUid = uid;
                entityUrn = urn;
                relationshipLabel = relLabel;
                role = roleSlug;
                status =
                  registrationStatus === RegistrationStatus.INVITEPENDING ? `${t('ns.common:invite_pending')}` : '';
              }

              if (groupKey === PeopleEnum.TEAMS) {
                const { teamSummary, relLabel } = entity as TaskFullLinkedTeams;
                const { name, uid, urn } = teamSummary;

                title = name;
                key = `${uid}-${idx}`;
                icon = <UserAvatar longName={name} dataTestId={'group-avatar'} avatarSize={AvatarSize.LG} />;
                entityUid = uid;
                entityUrn = urn;
                relationshipLabel = relLabel;
                status = '';
              }

              if (groupKey === PeopleEnum.ORGS) {
                const { orgSummary, relLabel } = entity as TaskFullLinkedOrgs;
                const { name, uid, urn } = orgSummary;

                title = name;
                key = `${uid}-${idx}`;
                icon = (
                  <UserAvatar
                    longName={name}
                    imagePath={orgSummary?.profilePhoto?.filePath}
                    dataTestId={'group-avatar'}
                    avatarSize={AvatarSize.LG}
                  />
                );
                entityUid = uid;
                entityUrn = urn;
                relationshipLabel = relLabel;
                status = '';
              }

              const optionMenuActions = {
                viewProfile: () => {
                  const viewPath = `/app/people/${groupKey.toLowerCase()}/${entityUid}`;
                  actions.viewProfile && actions.viewProfile(viewPath);
                },
                ...(actions.makeAssignee
                  ? {
                      makeAssignee: () => actions.makeAssignee(entityUid),
                    }
                  : {}),
                ...(actions.remove
                  ? {
                      remove: () => actions.remove({ relLabel: relationshipLabel, urn: entityUrn }),
                    }
                  : {}),
              };

              const isInitiator = initiatorUid === entityUid;
              if (assignedUid === entityUid) tag = `${t('ns.task:header:assignee')}`;
              if (isInitiator) tag = `${t('ns.linked_people:initiator')}`;

              return (
                <LinkedListItem
                  key={key}
                  title={title}
                  icon={icon}
                  isSmallDevice={isSmallDevice}
                  optionsParams={
                    hideEllipsis
                      ? undefined
                      : getOptionsParams({
                          loggedInUserRole: currentUserRole,
                          type: groupKey,
                          t,
                          actions: optionMenuActions,
                          isInitiator,
                          onlyRemove,
                          role,
                          enableMakeAssignee,
                          enableRemoveAssignee,
                          isAssignee: assignedUid === entityUid,
                        })
                  }
                  status={status}
                  tagText={tag}
                  actionsEnabled={actionsEnabled}
                />
              );
            })}
          </Box>
        );
      })}
    </>
  );
};
