/**
 * Determines whether a string or array was passed, if an array then a random string from the array will be selected
 * and returned. If a string, then the same string is returned. If no image can be established falls back to
 * /media/images/tenants/fallback/landing.jpg
 *
 * @param imageSrc
 * @returns image path string
 */
const getImagePath = (imageSrc: string | Array<string>) => {
  const fallback = '/media/images/tenants/fallback/landing.jpg';

  try {
    if (!imageSrc) return fallback;
    if (typeof imageSrc === 'string') return imageSrc;
    if (typeof imageSrc === 'object' && imageSrc.length > 0)
      return imageSrc[Math.floor(Math.random() * imageSrc.length)];
  } catch (error) {
    return fallback;
  }

  return fallback;
};

export default getImagePath;
