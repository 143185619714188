import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { Delete } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  IconButton,
  List,
  ListItem,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { captureException } from '@askporter/exception-logger';
import isEqual from 'lodash/isEqual';
import { FilePath } from '@askporter/grieg-types';
import { Media, transformUploadData } from '@askporter/utils';
import { Button, ButtonProps, FilePreview } from '../../';
import { ThumbnailPreview } from '../ThumbnailPreview';
import uploadFiles, { SimplifiedIAPIClient } from './uploadFiles';

interface SingleFileUploadProps {
  id: string;
  label?: string;
  onChange?: (uploadedFile: Media) => void;
  variant?: ButtonProps['variant'];
  value?: Media;
  required?: boolean;
  disabled?: boolean;
  t: (key: string, options?: Record<string, string | number>) => string;
  API: () => SimplifiedIAPIClient;
}

const SingleFileUpload = ({
  id,
  label,
  onChange,
  variant = 'contained',
  value,
  required = false,
  disabled = false,
  t,
  API,
}: SingleFileUploadProps): JSX.Element => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [fileSuccessfullyUploaded, setFileSuccessfullyUploaded] = useState<Media>(value);
  const [fileErrored, setFileErrored] = useState<{ uid?: string; name: string; error?: unknown }>();
  const [imagePreview, setImagePreview] = useState<FilePath | undefined>();
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [objectURL, setObjectURL] = useState<string>();

  useEffect(() => {
    return () => {
      !!objectURL && URL.revokeObjectURL(objectURL);
    };
  }, []);

  const handleFileAdd: ChangeEventHandler<HTMLInputElement> = async (e) => {
    setIsLoading(true);
    const uploadResult = await uploadFiles([e?.target?.files[0]], API, captureException);

    if (uploadResult[0].error) {
      setFileErrored(uploadResult[0]);
    } else {
      setObjectURL(uploadResult[0].objectURL);
      const uploadedFile = transformUploadData(uploadResult[0]);
      setFileSuccessfullyUploaded(uploadedFile);
      if (onChange) {
        onChange(uploadedFile);
      }
    }
    setIsLoading(false);
  };

  const handleFileRemove = () => {
    setFileSuccessfullyUploaded(null);
    if (onChange) {
      onChange(null);
    }
  };

  useEffect(() => {
    if (!isEqual(value, fileSuccessfullyUploaded)) {
      setFileSuccessfullyUploaded(value);
    }
  }, [value]);

  if (fileSuccessfullyUploaded) {
    const fileName = `${fileSuccessfullyUploaded.nameAndType?.name}.${fileSuccessfullyUploaded.nameAndType?.type}`;
    return (
      <>
        <FormLabel>{label || t('ns.common:file_upload:single:label')}</FormLabel>
        <List sx={{ border: `1px solid ${theme.palette.grey['400']}`, marginTop: 4 }} data-testid="uploaded-file">
          <ListItem sx={{ width: '100%' }}>
            <Box
              sx={{
                width: '100%',
                pl: 2,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box display="flex" sx={{ flex: '1 1 auto', minWidth: 0, alignItems: 'center' }}>
                <Button
                  variant="unstyled"
                  onClick={() =>
                    setImagePreview({
                      fileUrn: fileSuccessfullyUploaded.file.fileRef,
                      filePath: objectURL || fileSuccessfullyUploaded.path,
                    })
                  }
                  sx={{ flexShrink: 0 }}
                >
                  <ThumbnailPreview
                    filePath={objectURL || fileSuccessfullyUploaded.path}
                    fileType={fileSuccessfullyUploaded.nameAndType?.type}
                    imgAlt={label}
                  />
                </Button>
                <Typography sx={{ overflowWrap: 'break-word', overflow: 'auto', pl: 2 }}>{fileName}</Typography>
              </Box>
              {!disabled && (
                <IconButton
                  aria-label={t(`ns.common:file_upload:single:aria_remove`, { fileName })}
                  onClick={handleFileRemove}
                >
                  <Delete />
                </IconButton>
              )}
            </Box>
          </ListItem>
        </List>
        {imagePreview && (
          <FilePreview
            isSmallDevice={isSmallDevice}
            handleClose={() => setImagePreview(undefined)}
            file={imagePreview}
            t={t}
          />
        )}
      </>
    );
  }

  return (
    <FormControl disabled={disabled} required={required} fullWidth data-testid={`single-file-upload--${id}`}>
      <FormLabel htmlFor={`single-file-input--${id}`}>{label || t('ns.common:file_upload:single:label')}</FormLabel>
      {fileErrored && (
        <Alert severity="error" variant="outlined" sx={{ marginTop: 4 }} data-testid="file-errored">
          <AlertTitle>{t('ns.common:file_upload:single:file_errored')}</AlertTitle>
          {fileErrored.name}
        </Alert>
      )}
      {!disabled && (
        <Button component="label" disabled={isLoading} variant={variant}>
          {isLoading && <CircularProgress data-testid="loading-indicator" />}
          {!isLoading && (
            <>
              <input
                disabled={disabled}
                autoComplete="off"
                id={`single-file-input--${id}`}
                data-testid={`single-file-input--${id}`}
                multiple={false}
                onChange={handleFileAdd}
                style={{ display: 'none' }}
                type="file"
              />
              {t('ns.common:file_upload:single:input_button')}
            </>
          )}
        </Button>
      )}
    </FormControl>
  );
};

export default SingleFileUpload;
