import React from 'react';
import { CircularProgress, Box } from '@mui/material';

export const Loading: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Box sx={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <CircularProgress data-testid="loading" />
    </Box>
  );
};
