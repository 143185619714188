import React from 'react';
import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material';

/**
 * Renders a button with an icon in it. Consult https://mui.com/material-ui/api/icon-button/ for more usage info.
 */
function IconButtonFn<C extends React.ElementType>(
  props: MuiIconButtonProps<C, { component?: C }>,
  ref: React.Ref<HTMLButtonElement>,
) {
  return (
    <MuiIconButton {...props} ref={ref}>
      {props.children}
    </MuiIconButton>
  );
}

export const IconButton = React.forwardRef(IconButtonFn);

export type IconButtonProps = MuiIconButtonProps;
