import React from 'react';
import { StaticError } from '@askporter/component-library';

const isLocalStorageAvailable = () => {
  const item = 'canUseLocalStorage';
  try {
    localStorage.setItem(item, item);
    localStorage.removeItem(item);
    return true;
  } catch (e) {
    return false;
  }
};

type Props = { children: JSX.Element[] | JSX.Element };

const CheckForLocalStorage: React.FC<React.PropsWithChildren<Props>> = ({ children }: Props) => {
  const canUseLocalStorage = isLocalStorageAvailable();

  if (!canUseLocalStorage)
    return (
      <StaticError
        error={"Unable to access the browser's local storage. This is required to use the application."}
        hideReturnLink={true}
      />
    );
  else return <>{children}</>;
};

export default CheckForLocalStorage;
