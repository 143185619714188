import { TenantDisplayPalette } from '@askporter/grieg-types';
import { PaletteOptions } from '@mui/material';

// hint was removed in v5, this overrides and reintroduces it
declare module '@mui/material/styles/createPalette' {
  interface TypeText {
    hint: string;
  }

  interface Palette {
    tertiary: PaletteColor;
    chart: ChartColor;
  }
  interface PaletteOptions {
    tertiary?: PaletteColorOptions;
    chart: ChartColor;
  }
}

export type ChartColor = {
  chart01: string;
  chart02: string;
  chart03: string;
  chart04: string;
  chart05: string;
  chart06: string;
  chart07: string;
  chart08: string;
  chart09: string;
  chart10: string;
  chart11: string;
  chart12: string;
  chart13: string;
  chart14: string;
  chart15: string;
  chart16: string;
};

export const customPalette = (c: TenantDisplayPalette): PaletteOptions => {
  return {
    action: {
      // TODO - define approach for actions, these are not behaving as intended
      // Action/a. Active
      active: c.actionActive,
      activatedOpacity: c.actionActivatedOpacity,
      // Action/b. hover
      hover: c.actionHover,
      hoverOpacity: c.actionHoverOpacity,
      // Action/c. disabled
      disabled: c.textDisabled,
      disabledOpacity: c.actionDisabledOpacity,
      // Action/d. selected
      selected: c.actionSelected,
      selectedOpacity: c.actionSelectedOpacity,
      // Action/e. focus
      focus: c.actionFocus,
      focusOpacity: c.actionFocusOpacity,
      // Action/f. disabled background
      disabledBackground: c.actionDisabledBackground,
    },
    primary: {
      // Primary/a. Main
      main: c.primaryMain,
      // Primary/b Dark
      dark: c.primaryDark,
      // Primary/c. Light
      light: c.primaryLight,
      // Primary/d Contrast
      contrastText: c.primaryContrastText,
    },
    secondary: {
      // Secondary/a. Main
      main: c.secondaryMain,
      // Secondary/b Dark
      dark: c.secondaryDark,
      // Secondary/c. Light
      light: c.secondaryLight,
      // Secondary/d Contrast
      contrastText: c.secondaryContrastText,
    },
    tertiary: {
      // TODO tertiary to be confirmed
      // TBC: Tertiary/a. Main
      main: c.tertiaryMain,
      // TBC: Tertiary/b Dark
      dark: c.tertiaryDark,
      // TBC: Tertiary/c. Light
      light: c.tertiaryLight,
      // TBC: Tertiary/d Contrast
      contrastText: c.tertiaryContrastText,
    },
    background: {
      // $ui-background
      default: '#f5f5f5',
      // $ui-primary-container
      // $inverted-text-icon-primary
      paper: '#ffffff',
    },
    text: {
      // TODO extend to include light versions
      // Dark/a. High Emphasis
      primary: c.textPrimary,
      // Dark/b. Medium Emphasis
      secondary: c.textSecondary,
      // Dark/c. Low Emphasis
      disabled: c.textDisabled,
      // $text-icon-hint // TODO: Remove Hint?
      hint: '#757575',
    },
    error: {
      // Error/a. Main
      main: c.errorMain,
      // Error/b Dark
      dark: c.errorDark,
      // Error/c. Light
      light: c.errorLight,
      // Error/d Contrast
      contrastText: c.errorContrastText,
    },
    warning: {
      // Warning/a. Main
      main: c.warningMain,
      // Warning/b Dark
      dark: c.warningDark,
      // Warning/c. Light
      light: c.warningLight,
      // Warning/d Contrast
      contrastText: c.warningContrastText,
    },
    info: {
      // Info/a. Main
      main: c.infoMain,
      // Info/b Dark
      dark: c.infoDark,
      // Info/c. Light
      light: c.infoLight,
      // Info/d Contrast
      contrastText: c.infoContrastText,
    },
    success: {
      // Success/a. Main
      main: c.successMain,
      // Success/b Dark
      dark: c.successDark,
      // Success/c. Light
      light: c.successLight,
      // Success/d Contrast
      contrastText: c.successContrastText,
    },
    chart: {
      chart01: c.chart01,
      chart02: c.chart02,
      chart03: c.chart03,
      chart04: c.chart04,
      chart05: c.chart05,
      chart06: c.chart06,
      chart07: c.chart07,
      chart08: c.chart08,
      chart09: c.chart09,
      chart10: c.chart10,
      chart11: c.chart11,
      chart12: c.chart12,
      chart13: c.chart13,
      chart14: c.chart14,
      chart15: c.chart15,
      chart16: c.chart16,
    },
    grey: {
      '50': c.grey50,
      '100': c.grey100,
      '200': c.grey200,
      '300': c.grey300,
      '400': c.grey400,
      '500': c.grey500,
      '600': c.grey600,
      '700': c.grey700,
      '800': c.grey800,
      '900': c.grey900,
      A100: c.greyA100,
      A200: c.greyA200,
      A400: c.greyA400,
      A700: c.greyA700,
    },
    divider: c.divider,
  };
};
