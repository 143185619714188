/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import { InputLabel, InputBaseComponentProps } from '@mui/material';
import FilledInput from '@mui/material/FilledInput';
import { utils } from '@rjsf/core';
import { CustomWidgetProps } from '../types';

const { getDisplayLabel } = utils;

const FilledInputWidget = ({
  id,
  placeholder,
  readonly,
  disabled,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  autofocus,
  options,
  schema,
  uiSchema,
  required,
  formContext: { onHasChanged },
}: CustomWidgetProps): JSX.Element => {
  const [hasChanged, setHasChanged] = useState(false);
  const { emptyValue = required ? undefined : '' } = options;
  const _onChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    onChange(value === '' ? emptyValue : value);
    setHasChanged(true);
  };
  const _onBlur = ({ target: { value: newVal } }: React.FocusEvent<HTMLInputElement>) => {
    hasChanged && onHasChanged({ id, value: newVal });
    onBlur(id, newVal);
    setHasChanged(false);
  };
  const _onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      hasChanged && onHasChanged({ id, value });
      setHasChanged(false);
    }
  };

  const _onFocus = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value);

  const displayLabel = getDisplayLabel(
    schema,
    uiSchema,
    /* TODO: , rootSchema */
  );

  let inputType = 'text';
  if (schema.type === 'integer' || schema.type === 'number') {
    inputType = 'number';
  }

  const inputProps: InputBaseComponentProps = {};
  if (schema.type === 'number') inputProps.step = 'any';

  return (
    <>
      <InputLabel variant="filled" htmlFor={id}>
        {displayLabel ? label || schema.title : false}
      </InputLabel>
      <FilledInput
        data-testid={`filled-${schema.type}-input-widget`}
        id={id}
        placeholder={placeholder}
        autoFocus={autofocus}
        disabled={disabled}
        readOnly={readonly}
        type={inputType}
        value={value || value === 0 ? value : ''}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
        inputProps={inputProps}
        onKeyDown={_onKeyDown}
      />
    </>
  );
};

export default FilledInputWidget;
