import React from 'react';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { Loading, fallbackTheme, DynamicError } from '@askporter/component-library';
import { ConfigProvider as AskPorterConfigProvider, getErrorStrings, useTranslation } from '@askporter/config-provider';
import CoreNavigation from '../../components/CoreNavigation';
import MaintenancePage from '../../pages/MaintenancePage';

interface ConfigProviderProps {
  children: React.ReactNode[] | React.ReactNode;
}

const ConfigProvider = ({ children }: ConfigProviderProps): JSX.Element => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const errorPageComponent = (
    <>
      <ThemeProvider theme={fallbackTheme}>
        <BrowserRouter>
          {<DynamicError navigation={<CoreNavigation />} t={t} language={language} {...getErrorStrings()} />}
        </BrowserRouter>
      </ThemeProvider>
    </>
  );

  return (
    <AskPorterConfigProvider
      errorPageComponent={errorPageComponent}
      loadingComponent={<Loading />}
      codeSpecificErrorPages={[{ code: 503, component: <MaintenancePage shouldShowStaticErrorPage /> }]}
    >
      {children}
    </AskPorterConfigProvider>
  );
};

export default ConfigProvider;
