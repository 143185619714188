import React, { memo, useState, useCallback } from 'react';
import SingleInput from './SingleInput';
import { chunkSubString } from './utils';

export interface OTPInputProps {
  numberOfInputs: number;
  onChangeOTP?: (otp: string[]) => void;
  t: (key: string, options?: Record<string, string | number>) => string;
  errors: number[];
  maxLengthPerInput?: number;
  value?: string[] | undefined;
  disabled?: boolean;
}

/**
 * Component which renders multiple input boxes for entering a one time password / code
 *
 * @param numberOfInputs - The number of input boxes to render
 * @param onChangeOTP - the function to execute every time the value changes in one of the inputs
 * @param t - The translation function
 * @param errors - An array of indexes where the errors occur e.g. [4, 5] would mean the last two inputs are empty
 * @param maxLengthPerInput - max number of characters allowed inside each of the inputs
 * @param value - the value in the OTP input(s)
 * @param disabled - whether the inputs should be disabled or not
 *
 */
const OTPInputComponent: React.FC<React.PropsWithChildren<OTPInputProps>> = ({
  numberOfInputs,
  onChangeOTP,
  t,
  errors,
  maxLengthPerInput = 1,
  value,
  disabled = false,
}: OTPInputProps) => {
  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState(value || Array<string>(numberOfInputs).fill(''));

  // Helper to return OTP from inputs
  const handleOtpChange = useCallback(
    (otp: string[]) => {
      if (onChangeOTP) onChangeOTP(otp);
    },
    [onChangeOTP],
  );

  // Helper to return value with the right type: 'text' or 'number'
  const getRightValue = useCallback((str: string) => {
    const changedValue = str;
    return !changedValue || /\d/.test(changedValue) ? changedValue : '';
  }, []);

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str.substring(0, maxLengthPerInput) || '';
      setOTPValues(updatedOTPValues);
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues],
  );

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(numberOfInputs - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [numberOfInputs],
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index);
    },
    [focusInput],
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let shouldPreventDefault = true;
      let surplusData: string | false; // this should get populated when keypress value is longer than maxLengthPerInput

      if (e?.nativeEvent?.type === 'input') {
        const nativeEvent = e?.nativeEvent as InputEvent;
        shouldPreventDefault = !['deleteContentBackward', 'deleteContentForward'].includes(nativeEvent?.inputType);

        surplusData = nativeEvent?.data?.length > maxLengthPerInput ? nativeEvent?.data : false;
      }

      const val = getRightValue(e.currentTarget.value);
      if (!val && shouldPreventDefault) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(val);
      if (val.toString().length === maxLengthPerInput) {
        focusNextInput();
      }

      // if there is surplus data we put it into the appropriate input boxes
      if (surplusData) {
        // because we cannot differentiate between a normal clipboard copy and a prompted paste (e.g. when the browser
        // suggests a code from sms), we need to be considerate about what we do with the surplus data. If the length
        // matches the required code length then we start at idx 0, otherwise we start at the active input
        const startInputIdx = surplusData.length === otpValues.length ? 0 : activeInput;
        const updatedOTPValues = [...otpValues];
        const dataChunks = chunkSubString(surplusData, maxLengthPerInput);

        dataChunks.forEach((val, index) => {
          const targetInputIdx = startInputIdx + index;
          const changedValue = getRightValue(val);

          if (changedValue) updatedOTPValues[targetInputIdx] = changedValue;
        });

        setOTPValues(updatedOTPValues);
        handleOtpChange(updatedOTPValues);
        setActiveInput(startInputIdx + dataChunks.length - 1);
      }
    },
    [changeCodeAtFocus, focusNextInput, getRightValue],
  );

  // Handle onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  // Handle onKeyDown
  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const inputType: 'SINGLE' | 'MULTI' = maxLengthPerInput > 1 ? 'MULTI' : 'SINGLE';

      switch (e.key) {
        case 'Backspace':
          if (inputType === 'MULTI' && e.currentTarget.selectionStart === 0) {
            e.preventDefault();
            focusPrevInput();
          } else if (inputType === 'SINGLE') {
            if (otpValues[activeInput]) changeCodeAtFocus('');
            else focusPrevInput();
          }
          break;
        case 'Delete': {
          if (inputType === 'MULTI' && e.currentTarget.selectionStart === otpValues[activeInput].length) {
            e.preventDefault();
            focusNextInput();
          } else if (inputType === 'SINGLE') {
            if (otpValues[activeInput]) changeCodeAtFocus('');
            else focusNextInput();
          }
          break;
        }
        case 'ArrowLeft': {
          if (e.currentTarget.selectionStart === 0) {
            e.preventDefault();
            focusPrevInput();
          }
          break;
        }
        case 'ArrowRight': {
          if (e.currentTarget.selectionStart === otpValues[activeInput].length) {
            e.preventDefault();
            focusNextInput();
          }
          break;
        }
        case ' ': {
          e.preventDefault();
          break;
        }
        default:
          if (inputType === 'SINGLE' && e.currentTarget.selectionStart === maxLengthPerInput) focusNextInput();
          break;
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues],
  );

  // pastes the copied string into the available inputs
  const handleOnPaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData('text/plain')
        .trim()
        .slice(0, numberOfInputs * maxLengthPerInput - activeInput);

      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...otpValues];
        const pastedDataChunks = chunkSubString(pastedData, maxLengthPerInput);

        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedDataChunks.shift() || val);
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setOTPValues(updatedOTPValues);
        handleOtpChange(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, numberOfInputs - 1));
      }
    },
    [activeInput, getRightValue, numberOfInputs, otpValues],
  );

  return (
    <>
      {Array(numberOfInputs)
        .fill('')
        .map((_, index) => (
          <SingleInput
            key={`SingleInput-${index}`}
            t={t}
            idx={index}
            focus={activeInput === index}
            value={otpValues && otpValues[index]}
            onFocus={handleOnFocus(index)}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onBlur={onBlur}
            onPaste={handleOnPaste}
            errors={errors}
            disabled={disabled}
            preventSelectOnFocus={maxLengthPerInput > 1 ? true : false}
          />
        ))}
    </>
  );
};

export const OTPInput = memo(OTPInputComponent);
