interface Parameters {
  showNavigation?: boolean | 'simple';
  showFooter?: boolean | 'half';
}

interface Return {
  footerHeight: number;
  navigationHeight: number;
  contentHeight: string;
  rightContentHeight: string;
  constrainedHeight: string;
}

/**
 * Function that defines and calculates height for navigation, footer and content area
 *
 * @param showNavigation - Optional, Acceps either a Boolean indicating whether the complete navigation should be shown, defaults true.
 * Also accepts a string 'simple' for a blank navigation with the tenant logo only.
 * @param showFooter - Optional, Accepts either a Boolean indicating whether the footer (inc. nav) should be shown, defaults
 * false. Also accepts a string 'half' for SplitPageLayout ONLY which indicates footer should be 50% of screen width.
 * @returns footer and navigation heights, as well as content height and right content height (for split page layout)
 */
export const generateHeights = ({ showFooter, showNavigation }: Parameters): Return => {
  const footerHeight = showFooter === false ? 0 : 75;
  const navigationHeight = showNavigation ? 65 : 0;
  const contentHeight = `calc(100% - ${footerHeight}px)`;
  const rightContentHeight = showFooter === 'half' ? `100%` : contentHeight;
  const constrainedHeight = `calc(100% - ${navigationHeight}px)`;

  return { footerHeight, navigationHeight, contentHeight, rightContentHeight, constrainedHeight };
};
