import { CognitoUser } from 'amazon-cognito-identity-js';
import { wrapError } from '.';
import { AuthAction, UnauthenticatedResult } from './types';

/**
 * Attempt to start the forgotten password process
 * @param cognitoUser - the current (unauthenticated) session user
 * @param dispatch - reference to the auth state reducer dispatch method
 * @returns             Promise with result
 */
export const cognitoStartForgotPassword = async (
  cognitoUser: CognitoUser,
  dispatch: React.Dispatch<AuthAction>,
): Promise<UnauthenticatedResult> => {
  return new Promise((resolve, reject) => {
    const fallbackErrMessage = 'Unable to initiate password reset';
    try {
      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          dispatch({ type: 'setFeedback', payload: `Start forgot password success: ${JSON.stringify(data)}` });
          return resolve({ error: undefined, message: 'Success: password reset initiated' });
        },
        onFailure: (err) => {
          dispatch({ type: 'setFeedback', payload: 'Start forgot password failure' });
          return reject({
            error: wrapError(err, fallbackErrMessage),
            message: 'Failure: unable to initiate password reset',
          });
        },
      });
    } catch (err) {
      dispatch({ type: 'setFeedback', payload: 'Start forgot password error' });
      return reject({
        error: wrapError(err, fallbackErrMessage),
        message: 'Error: unable to initiate password reset',
      });
    }
  });
};
