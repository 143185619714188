import { CognitoUser, ICognitoUserAttributeData } from 'amazon-cognito-identity-js';
import { wrapError } from '.';
import { AuthAction, CognitoError, SetAttributesRes } from './types';

/**
 * Set attributes for current user
 *
 * @param dispatch - reference to the auth state reducer dispatch method
 * @param cognitoUser - the current session user
 * @param attributes - the attributes to be updated
 * @returns
 */
export const cognitoSetAttributes = async (
  dispatch: React.Dispatch<AuthAction>,
  cognitoUser: CognitoUser,
  attributes: ICognitoUserAttributeData[],
): Promise<SetAttributesRes> => {
  return new Promise((resolve, reject) => {
    const fallbackErrMessage = 'Error occurred while setting attribute(s)';
    try {
      cognitoUser.updateAttributes(attributes, (err: CognitoError, result) => {
        if (err) {
          dispatch({ type: 'setFeedback', payload: `${err.message} (${err.code})` });
          return reject({
            complete: false,
            result: result,
            error: wrapError(err, fallbackErrMessage),
            message: 'Error: occurred while setting attribute(s)',
          });
        }
        dispatch({ type: 'setFeedback', payload: `attributes set: ${JSON.stringify(result)}` });
        return resolve({
          complete: true,
          result: result,
          error: undefined,
          message: 'Success: attribute(s) updated',
        });
      });
    } catch (err) {
      dispatch({ type: 'setFeedback', payload: err.message });
      return reject({
        complete: false,
        error: wrapError(err, fallbackErrMessage),
        message: 'Error: unable to set attribute(s)',
      });
    }
  });
};
