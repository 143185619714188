import { SearchUserRequest, SearchUserResultExternal } from '@askporter/client-grieg-lyric';
import { ManagerRoles } from '@askporter/utils';
import { API } from './api';

export default async ({
  freeText,
  page,
  pageSize,
}: {
  freeText: string;
  page: number;
  pageSize: number;
}): Promise<SearchUserResultExternal> => {
  const payload: SearchUserRequest = {
    freeText,
    page,
    pageSize,
    filter: {
      role: ManagerRoles,
    },
  };
  const { data } = await API().post({ path: `users/search`, payload });
  return data;
};
