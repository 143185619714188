import React from 'react';
import { ToastProviderToastProps } from '@askporter/component-library';
import { ExportCompletedToast } from '../components/ExportCompletedToast';

/**
 * A function to handle showing the export completed toast, which should only be shown when the export status is 'COMPLETED' and the exports in progress snackbar is not open
 * @param uid - The uid of the export
 * @param status - The status of the export
 * @param isExportsInProgressSnackbarOpen - The state of the exports in progress snackbar
 * @param closeToast - The function to close the toast
 * @param setToast - The function to set the toast
 * @param name - The name of the export
 * @param removeExportsInProgressItem - The function to remove the export from the exports in progress list
 * @returns void
 */
export const handleShowingExportCompletedToast = ({
  uid,
  status,
  isExportsInProgressSnackbarOpen,
  closeToast,
  setToast,
  name,
  removeExportsInProgressItem,
}: {
  uid: string;
  status: 'COMPLETED' | 'FAILED';
  name: string;
  isExportsInProgressSnackbarOpen: boolean;
  closeToast: () => void;
  setToast: (toast: ToastProviderToastProps) => void;
  removeExportsInProgressItem: (uid: string) => void;
}) => {
  if (status !== 'COMPLETED' || isExportsInProgressSnackbarOpen) return;

  // Once a Toast is closed, we should remove the export from the exports in progress list
  const onToastClose = () => removeExportsInProgressItem(uid);

  setToast({
    open: true,
    severity: 'success',
    children: (
      <ExportCompletedToast
        name={name}
        uid={uid}
        closeToast={() => {
          closeToast();
          onToastClose();
        }}
      />
    ),
    autoHideDuration: null,
    ignoreClickAway: true,
    onToastClose,
  });
};
