import React, { useState, forwardRef, Ref } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InputAdornment, IconButton, TextField, TextFieldProps } from '@mui/material';

export interface PasswordField {
  testId?: string;
  error?: boolean;
  placeholder?: string;
  errorMessage?: string;
  t: (key: string, options?: Record<string, string | number>) => string;
  label?: string;
}

type PasswordFieldProps = PasswordField & Omit<TextFieldProps, 'label'>;

const PasswordFieldComponent = (
  { testId = 'password', error, placeholder, t, label, ...rest }: PasswordFieldProps,
  ref: Ref<HTMLDivElement>,
) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      variant="filled"
      ref={ref}
      error={error}
      inputProps={{ 'data-testid': testId }}
      placeholder={placeholder || `${t('ns.common:password:placeholder')}`}
      type={showPassword ? 'text' : 'password'}
      hiddenLabel={!label}
      label={<>{label}</>}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              data-testid="password-visibility-btn"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

export const PasswordField = forwardRef(PasswordFieldComponent);
