import React from 'react';
import { Box, BoxProps } from '@mui/material';

export interface LogoProps {
  logoURL: string;
  tenantName: string;
  maxHeight?: string;
  maxWidth?: string;
  padding?: number;
  wrapperSx?: BoxProps['sx'];
}

/**
 * A React component which renders the tenant logo
 *
 * @param logoURL - height pixels number
 * @param tenantName - the tenant name to include on the image tag's 'alt' attribute
 * @param padding - (optional) any padding around the logo, defaults to 4
 * @param maxHeight - (optional) the max height of the logo, defaults to 150px
 * @param maxWidth - (optional) the max width of the logo, defaults to 150px
 */
const LogoComponent: React.FC<React.PropsWithChildren<LogoProps>> = ({
  logoURL,
  tenantName,
  padding = 4,
  maxHeight = '32px', // based on the last discussion with designers
  maxWidth = 'auto',
  wrapperSx,
}: LogoProps) => (
  <Box
    sx={{
      textAlign: 'center',
      p: padding,
      ...wrapperSx,
    }}
  >
    <img
      style={{
        verticalAlign: 'middle',
        display: 'inline-block',
        maxWidth,
        maxHeight,
        width: 'auto',
        height: 'auto',
      }}
      src={logoURL}
      alt={`${tenantName} logo`}
      data-testid="logo"
    />
  </Box>
);

export const Logo = React.memo(LogoComponent);
