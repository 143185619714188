import React from 'react';
import { Typography as MuiTypography, TypographyProps as MuiTypographyProps } from '@mui/material';

interface TypographyProps extends MuiTypographyProps {
  component?: React.ElementType;
}

/**
 * Renders a Typography component
 */
export const Typography: React.FC<React.PropsWithChildren<TypographyProps>> = (props: TypographyProps) => (
  <MuiTypography {...props} />
);
