/**
 * Styled components specific to WebChat Component
 */
import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Paper, { PaperProps } from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

/**
 * WebChat page container
 */
export const WebChatPageContainer: React.FC<React.PropsWithChildren<BoxProps>> = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
});

/**
 * WebChat widget container
 */
export const WebChatWidgetContainer: React.FC<React.PropsWithChildren<PaperProps>> = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    height: '100%',
    width: '100%',
    borderRadius: '0',
  },
  [theme.breakpoints.up('sm')]: {
    height: '95%',
    width: '80%',
    borderRadius: '10px',
  },
  [theme.breakpoints.up('md')]: {
    width: '820px',
  },
  display: 'block',
  overflow: 'hidden',
  // Quick display fix
  '.webchat__send-box__main textarea': {
    lineHeight: 'inherit',
  },
}));

/**
 * ReadOnly WebChat widget container
 */
export const ReadOnlyWebChatWidgetContainer: React.FC<React.PropsWithChildren<PaperProps>> = styled(Paper)(() => ({
  height: '100%',
  width: '100%',
  display: 'block',
  overflow: 'hidden',
  // Quick display fix
  '.webchat__send-box__main textarea': {
    lineHeight: 'inherit',
  },
}));
