import React from 'react';
import './styles.css';

interface StaticErrorProps {
  title?: string;
  error: string;
  hideReturnLink?: true;
}

export const StaticError: React.FC<React.PropsWithChildren<StaticErrorProps>> = ({
  error,
  title,
  hideReturnLink,
}: StaticErrorProps) => {
  return (
    <div className="static-error-page" data-testid="static-error-page">
      <div className="header-wrapper">
        <header>
          <nav>
            <div className="logo-wrapper">
              <a href="/">
                <img src="/media/images/tenants/askporter/logo.svg" alt="logo" />
              </a>
            </div>
          </nav>
        </header>
      </div>
      <main>
        <section>
          <h1>{title || 'Error'}</h1>
          <article>
            {error}
            <br />
            {hideReturnLink ? null : <a href="/">Return to homepage</a>}
          </article>
        </section>
      </main>
    </div>
  );
};
