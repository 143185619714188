import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, useTheme } from '@mui/material';
import { ProfileReadExternal } from '@askporter/grieg-types';
import { AvatarSize, UserAvatar } from '../../../../';

interface ProfileAvatarButtonProps {
  profile: ProfileReadExternal;
  onClick: (event: React.SyntheticEvent) => void;
  isLoading: boolean;
  /**
   * Translation function
   */
  t: (key: string) => string;
}

/** Renders a ProfileAvatarButton component */
const ProfileAvatarButton: React.FC<React.PropsWithChildren<ProfileAvatarButtonProps>> = ({
  profile: { givenName, familyName, profilePhoto } = {},
  isLoading,
  onClick,
  t,
}: ProfileAvatarButtonProps) => {
  const theme = useTheme();

  return (
    <IconButton
      sx={{
        p: theme.custom.spacing.sm,
        borderRadius: theme.custom.radius.full,
        border: theme.custom.radius.xs,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={onClick}
      data-testid="menu-icon"
      aria-label={t('global:header:nav:profile_menu:aria_label')}
    >
      <MenuIcon sx={{ pr: theme.custom.spacing.sm }} />
      <UserAvatar
        givenName={givenName}
        familyName={familyName}
        imagePath={profilePhoto?.filePath}
        isLoading={isLoading}
        avatarSize={AvatarSize.SM}
      />
    </IconButton>
  );
};

export default ProfileAvatarButton;
