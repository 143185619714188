import React, { useState } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { UserSummaryExternal } from '@askporter/grieg-types';
import { fullName } from '@askporter/utils';
import { AvatarSize } from '../../Avatar';
import { UserAvatar } from '../../UserAvatar';

export interface TooltipState {
  visible: boolean;
  message: string;
}

export interface TaskCardPersonAvatarProps {
  /**
   * user for avatar
   */
  user: UserSummaryExternal;
  /**
   * user type for avatar
   */
  userType: 'assignee' | 'initiator';
  /**
   * if user is a manager
   */
  isManager?: boolean;
  /**
   * translation method
   */
  t?: (key: string) => string;
}

/**
 * TaskCardPersonAvatar with tooltips used within the TaskCard
 *
 */
export const TaskCardPersonAvatar: React.FC<React.PropsWithChildren<TaskCardPersonAvatarProps>> = ({
  user,
  userType,
  isManager,
  t,
}: TaskCardPersonAvatarProps): JSX.Element => {
  const [tooltipState, setTooltipState] = useState<TooltipState>({ visible: false, message: '' });
  const userName = fullName(user?.givenName, user?.familyName);

  const handleTooltipClose = () => {
    setTooltipState({ visible: false, message: '' });
  };

  const handleTooltipOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setTooltipState({ visible: true, message: userName });
  };

  return isManager && !!userName ? (
    <Tooltip
      disableFocusListener
      disableHoverListener
      disableTouchListener
      placement="top"
      arrow
      PopperProps={{
        disablePortal: true,
      }}
      open={tooltipState?.visible}
      title={tooltipState?.message}
      data-testid={`view-${userType}-name-tooltip`}
    >
      <IconButton
        sx={{
          mr: 2,
          p: 0,
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        onClick={(e) => {
          handleTooltipOpen(e);
          setTimeout(handleTooltipClose, 1500);
        }}
        data-testid={`view-${userType}-name-button`}
        disableRipple
        disableFocusRipple
        aria-label={t(`ns.task_list:view_task_${userType}`)}
      >
        <UserAvatar
          givenName={user.givenName}
          familyName={user.familyName}
          imagePath={user.profilePhoto?.filePath}
          dataTestId={'task-card-avatar'}
          avatarSize={AvatarSize.XS}
        />
      </IconButton>
    </Tooltip>
  ) : null;
};
